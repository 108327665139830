import React from "react";
import "./CheckboxInput.scss";

const CheckboxInput = ({ title, value, onValueChange }) => {
  return (
    <div className={"checkbox-input-wrapper"}>
      <div className={"string-input-title"}>{title}</div>
      <input
        type="checkbox"
        checked={value}
        className={"checkbox-input"}
        onChange={(e) => onValueChange(e.target.checked)}
      />
    </div>
  );
};

export default CheckboxInput;
