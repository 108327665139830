import React, { useState, useEffect } from "react";
import { isAdminUser } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import {
  getAgentRecommendedByCount,
  updateCurrency,
  activateAgent,
} from "../../services/fhpService";
import { successMessage, errorMessage } from "../../services/swalService";
import { useAppContext } from "../../contexts/AppContext";

import "./Admin.scss";
import InterestTableAdmin from "./InterestTableAdmin";
import GlobalConstantAdmin from "./GlobalConstantAdmin";

const Admin = () => {
  const isAdmin = isAdminUser();
  const navigate = useNavigate();
  const { USDrate, loadUSDRate, EURrate, loadEURRate } = useAppContext();
  const [agentRecommendedByCount, setAgentRecommendedByCount] = useState([]);
  const [currentUSDRate, setCurrentUSDRate] = useState(USDrate);
  const [newUSDRate, setNewUSDRate] = useState(null);

  const [currentEURRate, setCurrentEURRate] = useState(USDrate);
  const [newEURRate, setNewEURRate] = useState(null);

  useEffect(() => {
    setCurrentUSDRate(USDrate);
    setCurrentEURRate(EURrate);
  }, [USDrate, EURrate]);

  const loadAgents = () => {
    getAgentRecommendedByCount()
      .then(setAgentRecommendedByCount)
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
    }
    loadAgents();
  }, []);

  const onClickActivateAgent = (agentId) => {
    activateAgent(agentId).then((res) => {
      console.log({ res });
      if (res && !res?.error) {
        loadAgents();
        successMessage({
          title: "המשתמש אושר בהצלחה",
          message: "",
          confirmButtonText: "אישור",
        });
      } else {
        errorMessage("אירעה שגיאה באישור המשתמש");
      }
    });
  };

  const ActionsCellRender = ({ agentId }) => {
    return (
      <button
        className="approve-user"
        onClick={() => {
          onClickActivateAgent(agentId);
        }}
      >
        אשר משתמש
      </button>
    );
  };

  function renderAgentsTable() {
    const activatedUsers = agentRecommendedByCount.filter(
      (agent) => agent.activated
    );
    const notActivatedUsers = agentRecommendedByCount.filter(
      (agent) => !agent.activated
    );

    return (
      <>
        <h2>משתמשים פעילים ({activatedUsers.length})</h2>
        {activatedUsers.map((agent) => (
          <div className="agent-row">
            {agent.total_recommendations}: {agent.firstname} {agent.lastname} (
            {agent.id})
          </div>
        ))}
        <h2>משתמשים לא פעילים ({notActivatedUsers.length})</h2>
        {notActivatedUsers.map((agent) => (
          <div className="agent-row">
            {agent.total_recommendations}: {agent.firstname} {agent.lastname} (
            {agent.id}){ActionsCellRender({ agentId: agent.id })}
          </div>
        ))}
      </>
    );
  }

  const onUpdateClick = ({ rate, currency }) => {
    if (rate && currency) {
      const data = { rate: rate, currency: currency };
      updateCurrency(data).then(loadUSDRate());
    }
  };

  return (
    <div style={{ margin: "1em" }}>
      <InterestTableAdmin />
      <GlobalConstantAdmin />
      {renderAgentsTable()}
      <div className="rate">
        <h1> USD דולר</h1>
        <div>שער נוכחי:</div>
        <div>{currentUSDRate}₪ = 1$</div>

        <div>
          <input
            type="number"
            onChange={(e) => setNewUSDRate(e.target.value)}
          />
          ₪ = 1$
        </div>
        <button
          onClick={() => onUpdateClick({ rate: newUSDRate, currency: "USD" })}
          className="orderSummery-payment-btn"
        >
          לחץ פה על מנת לשנות את שער הדולר הנוכחי ל{newUSDRate}
        </button>
        <h1>EUR יורו</h1>
        <div>שער נוכחי:</div>
        <div>{currentEURRate}₪ = 1€</div>

        <div>
          <input
            type="number"
            onChange={(e) => setNewEURRate(e.target.value)}
          />
          ₪ = 1€
        </div>
        <button
          onClick={() => onUpdateClick({ rate: newEURRate, currency: "EUR" })}
          className="orderSummery-payment-btn"
        >
          לחץ פה על מנת לשנות את שער היורו הנוכחי ל{newEURRate}
        </button>
      </div>
    </div>
  );
};

export default Admin;
