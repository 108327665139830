export function getAllDepartureDates(ldsDates) {
  if (!ldsDates) {
    return [];
  }
  const ldsDatesArr = Array.isArray(ldsDates) ? ldsDates : [ldsDates];
  const departureDates = new Set();
  ldsDatesArr.forEach((link) => departureDates.add(link.depDate));
  return Array.from(departureDates).sort();
}

export function getReturnDatesForDeparture(ldsDates, selectedDepDate) {
  if (!ldsDates || !selectedDepDate) {
    return [];
  }

  const ldsDatesArr = Array.isArray(ldsDates) ? ldsDates : [ldsDates];
  const returnDates = ldsDatesArr
    .filter((link) => link.depDate === selectedDepDate)
    .map((link) => link.retDate);
  return returnDates.sort();
}
