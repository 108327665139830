import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./roomsfilter.css";

function RoomsFilter({ saveFilter, bedsOptions }) {
  // Translator
  const { t } = useTranslation();

  // --- States
  const [bedsFilter, setBedsFilter] = useState(null);
  const [basisFilter, setBasisFilter] = useState(null);
  const [cancelFilter, setCancelFilter] = useState(null);

  // Save changes the second the acccure
  useEffect(() => {
    const filters = {
      bedsFilter: bedsFilter,
      basisFilter: basisFilter,
      cancelFilter: cancelFilter,
    };

    // Save the filters and send them back to roomPlacor
    saveFilter(filters);
  }, [bedsFilter, basisFilter, cancelFilter]);

  // --- Functions

  // - Save filters
  const handleBaseFilterChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "null") {
      setBasisFilter(null);
    } else {
      setBasisFilter(selectedValue);
    }
  };

  const handleBedFilterChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "null") {
      setBedsFilter(null);
    } else {
      setBedsFilter(selectedValue);
    }
  };

  const handleCancelFilterChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "true") {
      setCancelFilter(true);

      return;
    }

    if (selectedValue === "false") {
      setCancelFilter(false);

      return;
    }

    setCancelFilter(null); // In case of remove filter
  };

  // Reset filters to default
  const resetFiltration = () => {
    setBedsFilter(null);
    setBasisFilter(null);
    setCancelFilter(null);

    // Reset the selected values in the dropdowns visually
    document.getElementById("Bed-Filter").selectedIndex = 0;
    document.getElementById("Meals-Filter").selectedIndex = 0;
    document.getElementById("canclation-Filter").selectedIndex = 0;
  };

  return (
    <div className="roomsFilter-wrapper tw-pb-1 tw-w-auto lg:tw-flex tw-flex-row tw-gap-[5px] tw-bg-[#f3f3f3] tw-rounded-[1%] tw-my-[3%] tw-justify-between tw-items-center">
      <div className="roomsFilter-filtrations">
        <select
          id="Bed-Filter"
          className="roomsFilter-select-wrapper"
          onChange={handleBedFilterChange}
        >
          <option defaultValue={bedsFilter} value={"null"}>
            {t("roomfilter.beds")}
          </option>

          {/* Generete difrenet options accordigly to the packages */}
          {bedsOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>

        <select
          id="Meals-Filter"
          className="roomsFilter-select-wrapper"
          onChange={handleBaseFilterChange}
        >
          <option defaultValue={basisFilter} value={"null"}>
            {t("roomfilter.meals")}
          </option>
          <option value={"RO"}>{t("roomfilter.nomeals")}</option>
          <option value={"BB"}>{t("roomfilter.breakfast")}</option>
          <option value={"HB"}>{t("roomfilter.halfboard")}</option>
          <option value={"FB"}>{t("roomfilter.fullboard")}</option>
          <option value={"AI"}>{t("roomfilter.AI")}</option>
        </select>

        <select
          id="canclation-Filter"
          className="roomsFilter-select-wrapper"
          onChange={handleCancelFilterChange}
        >
          <option defaultValue={cancelFilter}>
            {t("roomfilter.canclation")}
          </option>
          <option value={true}>{t("roomfilter.freecanclation")}</option>
          <option value={false}>{t("roomfilter.nofreecanclation")}</option>
        </select>
      </div>

      <div className="roomsFilter-reset-button-wrapper">
        <button
          type="button"
          className="roomsFilter-reset-button"
          onClick={resetFiltration}
        >
          {t("filters.reset")}
        </button>
      </div>
    </div>
  );
}

export default RoomsFilter;
