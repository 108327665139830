import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_CARD, useCards } from "../../contexts/CardsContext";
import CardsAmount from "./CardsAmount/CardsAmount";
import PaymentSplit from "./PaymentSplit/PaymentSplit";
import { getUserDetails } from "../../services/authService";
import { payLds } from "../../services/fhpService";
import { Loader } from "../../Components/Loader/Loader";
import { useSelectedResult } from "../../contexts/SelectedResultContext";
import { round2 } from "../../contexts/AppContext";

const Payment = ({
  our_order_id,
  leftToPay,
  sumpaid,
  cardsAmount,
  setCardsAmount,
}) => {
  const { cards, updateCards } = useCards();

  const { t } = useTranslation();
  const { setOurSavedLDSOrder, ourPrice } = useSelectedResult();

  const handleCardsAmountChange = (newCardsAmount) => {
    if (!isNaN(newCardsAmount) && newCardsAmount >= 1) {
      setCardsAmount(newCardsAmount);
      let tempCards = [...cards];

      if (cards.length > newCardsAmount) {
        tempCards = cards.slice(0, newCardsAmount);
      } else {
        for (let i = cards.length; i < newCardsAmount; i++) {
          tempCards.push(EMPTY_CARD);
        }
      }
      updateCards(tempCards);
    } else {
      console.error("Invalid input, resetting to default card");
      updateCards([EMPTY_CARD]);
    }
  };

  function pay(card) {
    const {
      cardholdername,
      cardnumber,
      expdate,
      cvv,
      cardholderid,
      numberofpayments,
    } = card;
    const { email, id, firstname, lastname } = getUserDetails();

    const transactionData = {
      info: JSON.stringify({
        order_id: our_order_id,
        agent: {
          id,
          firstname,
          lastname,
        },
      }),
      amount: round2(card.amountWithInterest), // TOTAL TO PAY
      tashFees: round2(card.amountWithInterest - card.amount),
      userid: cardholderid, // TEUDAT ZEHOOT
      firstname: cardholdername.split(" ")[0],
      lastname: cardholdername.split(" ")[1],
      email: email,
      tash: numberofpayments, // amount of tashloomim
      currency: "ILS", // ILS | USD | EUR
      cc: cardnumber.replace(/\s/g, ""),
      month: expdate.split("/")[0], // IMPORTANT TO BE IN THIS FORMAT: MM
      year: "20" + expdate.split("/")[1], // IMPORTANT TO BE IN THIS FORMAT: YYYY
      cvv: cvv,
      ourLdsOrderId: our_order_id,
      ourPrice,
    };
    return payLds(transactionData).then((res) => {
      if (res.data) {
        setOurSavedLDSOrder(res.data);
      }
    });
  }

  const renderTotalPays = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirections: "row",
          justifyContent: "space-around",
        }}
      >
        <h2
          className="text-header"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {t("packages.paid")}: ₪
          <span style={{ color: "#25ac03" }}>{sumpaid}</span>
        </h2>
        <h2
          className="text-header"
          style={{ display: "flex", flexDirection: "row" }}
        >
          יתרה: ₪
          <span style={{ color: "red" }}>
            {leftToPay ? leftToPay : <Loader />}
          </span>
        </h2>
      </div>
    );
  };

  return (
    <>
      <div
        className="lg:tw-w-[700px]"
        // style={{ width: 700 }}
      >
        {renderTotalPays()}

        <div style={{ padding: "10px 20px", border: "1px solid black" }}>
          <CardsAmount
            amount={cardsAmount}
            setAmount={handleCardsAmountChange}
          />
        </div>
        <div style={{ padding: 20, border: "1px solid black" }}>
          <PaymentSplit
            handlingFee={1}
            commission={1}
            priceToPay={leftToPay}
            setPriceToPay={console.log}
            setCardPaid={console.log}
            pay={pay}
          />
        </div>
      </div>
    </>
    // </CardsProvider>
  );
};

export default Payment;
