import React from "react";
import "./MainPage.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Main(props) {
  const t = useTranslation().t;
  return (
    <div className="main-page ">
      <span className="tw-text-3xl tw-font-bold lg:tw-text-5xl">
        ברוכים הבאים להוטלס ריבר!
      </span>
      <br />
      <span className="tw-text-xl lg:tw-text-3xl">
        {" "}
        אנו שמחים שבחרתם לבקר באתר שלנו, שבו חוויית התכנון של חופשות ונסיעות
        הופכת לפשוטה ונעימה יותר. באתרנו תוכלו ליצור את החבילות האולטימטיביות של
        מלונות וטיסות, המותאמות בדיוק לצרכים ולהעדפות האישיות שלכם. אנו מציעים
        מגוון רחב של אפשרויות לינה וטיסות מכל רחבי העולם, כדי להבטיח שתוכלו
        למצוא בדיוק את מה שאתם מחפשים.
      </span>
      <span className="tw-text-xl lg:tw-text-3xl">
        {" "}
        צוות הוטלס ריבר כאן כדי לספק לכם חוויה חלקה, נוחה וללא דאגות, מהרגע
        שתתחילו לתכנן ועד שתחזרו הביתה. בואו להתחיל את ההרפתקה הבאה שלכם עכשיו!
      </span>
      <div>
        <Link to="/registeragent">
          <button className="button-client">{t("navbar.joinasagent")}</button>
        </Link>
      </div>
    </div>
  );
}

export default Main;
