import React from "react";
import { useTranslation } from "react-i18next";
import { convertPrice } from "../../../../services/currencyService";
import { isAdminUser } from "../../../../services/authService";

import "./PackageTable.scss";

const PassengersNamesRenderer = ({ roomsAndPaxInfo, divKey }) => {
  let kidsNames = [];
  let adultsNames = [];

  roomsAndPaxInfo?.forEach((group) => {
    adultsNames.push(...group.adults);
    kidsNames.push(...group.kids);
  });

  return (
    <div className="passenger-name" key={divKey}>
      {adultsNames?.map((adult, index) => (
        <div
          key={index}
        >{`${adult?.title}  ${adult?.firstname}  ${adult?.lastname}  `}</div>
      ))}

      {kidsNames.length ? <>ילדים:</> : <></>}
      {kidsNames?.map((kid, index) => (
        <div
          key={index}
        >{`${kid?.title}  ${kid?.firstname}  ${kid?.lastname}  `}</div>
      ))}
    </div>
  );
};

const CollapsedPackageRow = ({ row } = {}) => {
  const { t } = useTranslation();

  const { orderid, handlingfee, commission, handlingfeepercentage } = row;
  const isAdmin = isAdminUser();

  const hotel = row?.order_response?.reservation?.hotel;
  const flights = row?.order_response?.reservation?.flight;

  const roomsAndPaxInfo = row?.passengersinfo;

  const hotelName = hotel?.hotelName;
  const firstWayFlight = flights?.[0]?.leg?.depDate;
  const returnFlight = flights?.[1]?.leg?.depDate;
  const supplierId = flights?.[0]?.leg?.supplierId;

  return (
    <div className="collapsed-wrapper">
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">{t("orders.hotelName")}</div>
          <div className="blue-name-cell">{hotelName}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">כניסה למלון</div>
          <div>{hotel?.checkInDate}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">יציאה מהמלון</div>
          <div>{hotel?.checkOutDate}</div>
        </div>

        <div className="item-wrapper">
          <div className="item-header">{t("orders.passengersNames")}</div>
          <PassengersNamesRenderer
            roomsAndPaxInfo={roomsAndPaxInfo}
            divKey={row.id}
          />
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">טיסה הלוך</div>
          <div>{firstWayFlight}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">טיסה חזור</div>
          <div>{returnFlight}</div>
        </div>
        {isAdmin && (
          <div className="item-wrapper">
            <div className="item-header">קוד ספק</div>
            <div>{supplierId}</div>
          </div>
        )}
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">{t("orders.myCommission")}</div>
          <div className="commisions-cell">{convertPrice(commission)}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.handlingFee")}</div>
          <div className="commisions-cell">
            {handlingfee ? convertPrice(handlingfee) : "אין"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollapsedPackageRow;
