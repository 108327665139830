import React from "react";
import "./Select.scss";

export const Select = ({
  title,
  options = [],
  placeholder,
  onChange,
  ...props
}) => {
  return (
    <div className="select-input-container">
      <div className="title">{title}</div>
      <select className="input" onChange={onChange} {...props}>
        <option value="" disabled selected>
          {placeholder}
        </option>
        {options.map((p) => (
          <option key={p.value} value={p.value}>
            {p.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
