import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import "./Filters.scss";

const CheckboxFilter = ({ filterName, checkboxes, expand = false }) => {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(expand);

  const changeExpand = () => {
    setIsExpand((prevState) => !prevState);
    checkboxes.map(({ onChange }) => {
      onChange();
    });
  };

  return (
    <div className="hotelFilters-filter-wrapper">
      <div
        onClick={changeExpand}
        className={`hotelFilters-filters-title-wrapper ${
          document.documentElement.dir === "ltr"
            ? "direction-rtl"
            : "direction-ltr"
        }  `}
      >
        <span className="hotelFilters-filters-title">{filterName}</span>

        {isExpand ? (
          <IoIosArrowUp className="hotelFilters-filters-arrow" />
        ) : (
          <IoIosArrowDown className="hotelFilters-filters-arrow" />
        )}
      </div>
      {isExpand && (
        <div className="hotelfilters-price-wrapper">
          <form className="hotelfilters-price-sort-wrapper">
            {checkboxes.map(({ value, label, onChange }) => {
              return (
                <label
                  className="hotelfilters-price-sort-radio"
                  style={
                    document.documentElement.dir === "ltr"
                      ? { direction: "rtl" }
                      : {}
                  }
                >
                  <input
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                    checked={value}
                    type="checkbox"
                    name="priceSorting"
                    value="pricelowtohigh"
                    className="hotelfilters-price-sort-radio-input"
                  />
                  <span className="hotelfilters-price-sort-radio-title">
                    {label}
                  </span>
                </label>
              );
            })}
          </form>
        </div>
      )}
    </div>
  );
};

export default CheckboxFilter;
