import React, { useState } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./clientsinfo.css";

// Icons
import profileIcon from "../../../../assets/images/ordersummery/profile.png";
import phoneIcon from "../../../../assets/images/ordersummery/phone.png";
import emailIcon from "../../../../assets/images/ordersummery/email.png";

const ClientsInfo = ({ clientName, clientPhone, clientEmail }) => {
  // Translator
  const { t } = useTranslation();

  return (
    <>
      <div className="summeryClientsInfo-wrapper">
        {/* text */}
        {/* <label className="summeryClientsInfo-text1">
          {t("ordersummery.text1")}
        </label>
        <label className="summeryClientsInfo-text2">
          {t("ordersummery.text2")}:
        </label> */}

        {/* Client Details */}
        <div className="summeryClientsInfo-details-wrapper-wrapper">
          {/* Title */}
          <label className="summeryClientsInfo-title">
            {t("ordersummery.clientsdetails")}
          </label>

          <div className="summeryClientsInfo-details-wrapper">
            {/* Client's Name */}
            <div className="summeryClientsInfo-details-item">
              <img
                src={profileIcon}
                className="summeryClientsInfo-details-icon"
              ></img>
              <label className="summeryClientsInfo-details-title">
                {t("ordersummery.clientsname")}
              </label>
              <label className="summeryClientsInfo-details-value">
                {clientName}
              </label>
            </div>

            {/* Phone */}
            <div className="summeryClientsInfo-details-item">
              <img
                src={phoneIcon}
                className="summeryClientsInfo-details-icon"
              ></img>
              <label className="summeryClientsInfo-details-title">
                {t("ordersummery.phone")}
              </label>
              <label className="summeryClientsInfo-details-value">
                {clientPhone}
              </label>
            </div>

            {/* Email */}
            <div className="summeryClientsInfo-details-item">
              <img
                src={emailIcon}
                className="summeryClientsInfo-details-icon"
              ></img>
              <label className="summeryClientsInfo-details-title">
                {t("ordersummery.email")}
              </label>

              <div className="summeryClientsInfo-input-label-overflow-wrapper">
                <label className="summeryClientsInfo-details-value summeryClientsInfo-input-label-overflow">
                  {clientEmail ? clientEmail.split("@")[0] : ""}
                </label>
                <label className="summeryClientsInfo-details-value summeryClientsInfo-input-label-overflow">
                  {clientEmail ? clientEmail.split("@")[1] : ""}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientsInfo;
