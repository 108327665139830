import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./roomplacer.css";

// Components
import RoomsFilter from "../RoomsFilter/roomsfilter";
import SameRoom from "./SameRoom/sameroom";
import DifferentRoom from "./DifferentRoom/differentroom";
import { useAppContext } from "../../../../../contexts/AppContext";

const RoomPlacer = ({ rooms, currentSearch, hotelInfo, checkInOutInfo }) => {
  // States
  const [allRooms, setAllRooms] = useState([]);
  const [roomFilter, setRoomFilter] = useState("");
  const [roomDisplay, setRoomDisplay] = useState([]);
  const [sliceRoomer, setSliceRoomer] = useState(5); // Show 5 option before show more button
  const [isMoreOptions, setIsMoreOptions] = useState(true);
  const [suggestedRoom, setSuggestedRoom] = useState("");
  const [bedTypes, setBedTypes] = useState([]);
  const { isGlobalConstantLoaded } = useAppContext();
  // Translator
  const { t } = useTranslation();

  // --- UseEffects

  // Sort rooms | Get suggeted room | Save rooms | Trigger filtration | Get beds filtration options
  useEffect(() => {
    let updatedRooms = [];

    // Flag with similarRoom true rooms
    updatedRooms = flagSimilarRooms(rooms);

    // Get suggested room
    const suggestedRoom = GetSuggestedRoom(updatedRooms);

    // Remove the suggestedRoom from the rooms array
    // updatedRooms = rooms.filter(room => room !== suggestedRoom);

    // After all changes save rooms
    setAllRooms(updatedRooms);

    // Get all bed types options
    getBedTypes(updatedRooms);

    // Trigger filter and sort page check
    setRoomFilter(null);
  }, []);

  useEffect(() => {
    let updatedRooms = [];

    // Flag with similarRoom true rooms
    updatedRooms = flagSimilarRooms(rooms);

    // Remove the suggestedRoom from the rooms array
    // updatedRooms = rooms.filter(room => room !== suggestedRoom);

    // Filter Rooms
    if (roomFilter) {
      updatedRooms = filterRooms(updatedRooms);
    }

    // Sorting packages inside each room by price without modifying the overall structure
    updatedRooms = updatedRooms.map((hotel) => {
      return {
        hotelID: hotel.hotelID,
        roomNames: hotel.roomNames,
        facilities: hotel.facilities,
        images: hotel.images,
        bedTypes: hotel.bedTypes,
        packages: hotel.packages.sort((a, b) => a.finalPrice - b.finalPrice),
        id: hotel.id,
        SimilarRooms: hotel.SimilarRooms,
      };
    });

    // Show only 5 rooms at first
    if (updatedRooms.length >= sliceRoomer) {
      setRoomDisplay(updatedRooms.slice(0, sliceRoomer));

      // Increament the sliceShower to later show more results
      setSliceRoomer(sliceRoomer + 5);

      // Set show more rooms
      setIsMoreOptions(true);
    } else {
      setRoomDisplay(updatedRooms);

      // Set show more rooms
      setIsMoreOptions(false);
    }
  }, [roomFilter, isGlobalConstantLoaded]);

  // --- Functions

  // Filter rooms accordingly to filters
  const filterRooms = (rooms) => {
    let roomsAfterFilters = rooms;

    // Fiter bed types
    if (roomFilter.bedsFilter !== null) {
      roomsAfterFilters = roomsAfterFilters.filter((room) => {
        if (room.bedTypes.includes(roomFilter.bedsFilter.toUpperCase())) {
          return room;
        }
      });
    }

    // Filter room basis
    if (roomFilter.basisFilter) {
      roomsAfterFilters = roomsAfterFilters.map((room) => {
        if (room && room.packages) {
          // Filter all the room that are with the same policy
          const updatedPackages = room.packages.filter((roomPackage) => {
            if (
              roomPackage.roomBasis.split(" ").pop() === roomFilter.basisFilter
            ) {
              return roomPackage;
            }
          });

          if (updatedPackages.length > 0) {
            // Create a copy of the room with updated packages
            const updatedRoom = { ...room, packages: updatedPackages };
            return updatedRoom;
          }
        }
      });
    }

    // Filter cancelation policy
    if (roomFilter.cancelFilter !== null) {
      roomsAfterFilters = roomsAfterFilters.map((room) => {
        if (room && room.packages) {
          // Filter all the room that are with the same policy
          const updatedPackages = room.packages.filter((roomPackage) => {
            if (roomPackage.refundability === roomFilter.cancelFilter) {
              return roomPackage;
            }
          });

          // Check if any packages left. if not delete room
          if (updatedPackages.length > 0) {
            // Create a copy of the room with updated packages
            const updatedRoom = { ...room, packages: updatedPackages };
            return updatedRoom;
          }
        }
      });
    }

    // Remove undefiends from list
    roomsAfterFilters = roomsAfterFilters.filter((room) => room !== undefined);

    // If the filter accured return filtered data if not return every thing
    return roomsAfterFilters;
  };

  // Flag similar room
  const flagSimilarRooms = (rooms) => {
    // Remove duplications of room and set a key of amount from the same room
    // Rooms are declared a duplicate if the price and the name of the rooms are the same
    for (let room of rooms) {
      const areAllNamesSame = room.roomNames.every(
        (name, index, arr) => name === arr[0]
      );

      if (areAllNamesSame) {
        room.SimilarRooms = true;
      }
    }

    return rooms;
  };

  // Get suggested room by the lowest price and make data ready to use
  const GetSuggestedRoom = (rooms) => {
    let min = 2147483645,
      savedRoom;

    for (let room of rooms) {
      let currentRoom = room.packages;
      let finalPrices = currentRoom.map((item) => item.finalPrice); // Extract finalPrice from each item

      // Find the minimum value in the finalPrices array
      let roomMin = Math.min(...finalPrices);

      if (min > roomMin) {
        min = roomMin;
        savedRoom = room;
      }
    }

    setSuggestedRoom(savedRoom);

    return savedRoom;
  };

  // Get bed types and sort them
  const getBedTypes = (rooms) => {
    let allBedTypes = [];

    // Go thourgh all the rooms and take all the bed types seperetly
    rooms.forEach((room) => {
      room.bedTypes.forEach((bedType) => {
        allBedTypes.push(bedType);
      });
    });

    // Remove duplicates
    const uniqueBedTypes = Array.from(new Set(allBedTypes));

    // Capitalize the first letter and make the rest lowercase
    const bedTypesOptions = uniqueBedTypes.map(
      (item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
    );

    setBedTypes(bedTypesOptions);
  };

  // Display 5 more rooms
  const showMoreOptions = (e) => {
    e.preventDefault();

    // Show only 5 rooms at first
    if (allRooms.length >= sliceRoomer) {
      setRoomDisplay(allRooms.slice(0, sliceRoomer));

      // Increament the sliceShower to later show more results
      setSliceRoomer(sliceRoomer + 5);

      // Set show more rooms
      setIsMoreOptions(true);
    } else {
      setRoomDisplay(allRooms);

      // Set show more rooms
      setIsMoreOptions(false);
    }
  };

  // Show less options
  const showLessOptions = (e) => {
    e.preventDefault();

    // Show only 5 rooms at first
    setRoomDisplay(allRooms.slice(0, 5));

    // Increament the sliceShower to later show more results
    setSliceRoomer(5);

    // Set show more rooms
    setIsMoreOptions(true);

    const roomsInfoElement = document.getElementById("Rooms-Information");
    if (roomsInfoElement) {
      roomsInfoElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="roomPlacer-component">
        {/* Rooms Filter */}
        <RoomsFilter saveFilter={setRoomFilter} bedsOptions={bedTypes} />

        {/* Suggested Room Title */}
        <div className="suggestedRoom-title-wrapper">
          <label className="suggestedRoom-title">
            {t("hotelprofile.recommanded")}
          </label>

          <label className="suggestedRoom-sub-title">
            {t("hotelprofile.cheaperoption")}
          </label>
        </div>

        {/* Suggested Room */}
        {roomDisplay && roomDisplay.length > 0 && (
          <div
            className={`${
              roomDisplay.filter(
                (roomPackage) => roomPackage.id === suggestedRoom.id
              ).length > 0
                ? `roomPlacer-suggested-room-wrapper`
                : ""
            }`}
          >
            {roomDisplay
              .filter((roomPackage) => roomPackage.id === suggestedRoom.id)
              .map((roomPackage, i) => (
                <div key={i} className="roomPlacer-regular-room-wrapper">
                  <div className="roomDisplayer-room-package">
                    {roomPackage && roomPackage.SimilarRooms ? (
                      <SameRoom
                        sameRoom={roomPackage}
                        currentSearch={currentSearch}
                        hotelInfo={hotelInfo}
                        checkInOutInfo={checkInOutInfo}
                      />
                    ) : (
                      <DifferentRoom
                        diffRoom={roomPackage}
                        currentSearch={currentSearch}
                        hotelInfo={hotelInfo}
                        checkInOutInfo={checkInOutInfo}
                      />
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}

        {/* Rest of the rooms */}
        <label className="roomDisplayer-title">
          {t("hotelprofile.pleaseselect")}
        </label>

        {roomDisplay && roomDisplay.length > 0 ? (
          <div className="roomDisplayer-room-packages-wrapper">
            {roomDisplay
              .filter((roomPackage) => roomPackage.id !== suggestedRoom.id)
              .map((roomPackage, i) => (
                <div key={i} className="roomPlacer-regular-room-wrapper">
                  <div className="roomDisplayer-room-package">
                    {roomPackage && roomPackage.SimilarRooms ? (
                      <SameRoom
                        sameRoom={roomPackage}
                        currentSearch={currentSearch}
                        hotelInfo={hotelInfo}
                        checkInOutInfo={checkInOutInfo}
                      />
                    ) : (
                      <DifferentRoom
                        diffRoom={roomPackage}
                        currentSearch={currentSearch}
                        hotelInfo={hotelInfo}
                        checkInOutInfo={checkInOutInfo}
                      />
                    )}
                  </div>
                </div>
              ))}

            {/* Display More Room */}
            {roomDisplay.length > 5 && (
              <div>
                {isMoreOptions ? (
                  <div className="roomDisplayer-more-option-wrapper">
                    <button
                      type="button"
                      onClick={showMoreOptions}
                      className="roomDisplayer-more-option-btn"
                    >
                      {t("hotelprofile.moreoptions")}
                    </button>
                  </div>
                ) : (
                  <div className="roomDisplayer-more-option-wrapper">
                    <button
                      type="button"
                      onClick={showLessOptions}
                      className="roomDisplayer-more-option-btn"
                    >
                      {t("hotelprofile.lessoptions")}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          // <div className='roomPlacer-no-rooms'></div>
          <div className="roomPlacer-no-rooms">{t("hotelprofile.norooms")}</div>
        )}
      </div>
    </>
  );
};

export default RoomPlacer;
