import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./clientsinfoagent.css";
import { useOrder } from "../../../contexts/OrderContext";

const ClientsInfoAgent = ({ clientsInfo, finishAction }) => {
  const { currentHotelOrder, setCurrentHotelOrder } = useOrder();
  // Params
  const { selectedObject } = useParams(); // Checks if picked a room / flight ...

  // Transelator
  const { t } = useTranslation();

  // Navigator
  const navigate = useNavigate();

  // States
  const [fullname, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // const [searchFilter, setSearchFilter] = useState('');

  const [isChecked, setIsChecked] = useState(true);

  // On load
  useEffect(() => {
    let savedInfo = localStorage.getItem("agent-saved-client-info");

    // Check if previous client information are saved
    if (savedInfo) {
      // Convert to array
      savedInfo = JSON.parse(savedInfo);

      // Set data in right locations
      document.getElementById("clientsInfo-fullname").value =
        savedInfo.fullname;
      document.getElementById("clientsInfo-phone").value = savedInfo.phone;
      document.getElementById("clientsInfo-email").value = savedInfo.email;

      // Save in states
      setFullName(savedInfo.fullname);
      setPhone(savedInfo.phone);
      setEmail(savedInfo.email);
    }
  }, []);

  // Remove selected package from route - If its a new search
  useEffect(() => {
    if (selectedObject && !currentHotelOrder) {
      navigate("/p/dashboard/searchoffer");
    }
  }, []);

  // Functions
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // Save Client information in local storage
  const saveClientInfo = () => {
    // Create data
    let savedData = {
      fullname: fullname,
      phone: phone,
      email: email,
    };

    // Save in local storage
    if (isChecked) {
      // let currentOrder = localStorage.getItem('currentOrder');

      // if(currentOrder) {
      //     currentOrder = JSON.parse(currentOrder);
      //     currentOrder['clientInfo'] = savedData;

      //     localStorage.setItem('currentOrder', JSON.stringify(currentOrder));
      // }
      // else {
      //     localStorage.setItem('currentOrder', JSON.stringify({'clientInfo ' : {...savedData}}));
      // }

      localStorage.setItem(
        "agent-saved-client-info",
        JSON.stringify(savedData)
      );
    } else {
      localStorage.removeItem("agent-saved-client-info");
    }
  };

  // Mark PHP box and Save Choice
  // const markPHPAndSave = (e) => {
  //     // Save the filter
  //     setSearchFilter(e.target.id);

  //     let cssClass = `clientsInfo-${e.target.id}`;

  //     // Make them unique
  //     let objs = ['plains', 'hotels', 'packages'];

  //     // Remove color from all the objects
  //     for(let obj of objs) {
  //         let object = document.getElementById(obj);

  //         if (object.classList.contains(`clientsInfo-${obj}`)) {
  //             object.classList.remove(`clientsInfo-${obj}`);
  //         }
  //     }

  //     // Add and remove the painting
  //     let obj = e.target;

  //     // Paint the current option
  //     if (!obj.classList.contains(cssClass)) {
  //         obj.classList.add(cssClass);
  //     }
  //     else {
  //         obj.classList.remove(cssClass);
  //     }
  // }

  // Transfer to next page
  const handleSubmitClientInfo = (e) => {
    e.preventDefault();

    // Save data of client
    saveClientInfo();

    // Set flag to procced to next page
    finishAction(true);

    // Send client info to parent component
    let savedData = {
      fullname: fullname,
      phone: phone,
      email: email,
    };

    clientsInfo(savedData);
  };

  return (
    <>
      <div className="clientsInfo-wrapper-wrapper">
        {/* Clients Info Form */}
        <form
          onSubmit={handleSubmitClientInfo}
          className="lg:tw-w-[25%] tw-flex tw-flex-col tw-bg-white tw-rounded-[5px] tw-py-[5%] tw-px-[6.5%] tw-shadow-[0px_2px_4px_#cbc8c8]"
        >
          {/* Title */}
          <p className="clientsInfo-title">{t("clientsinfo.title")}</p>

          {/* Client Info Wrapper */}
          <div className="clientsInfo-form-wrapper">
            {/* First & Last Name */}
            <div className="clientsInfo-input-wrapper">
              <label className="clientsInfo-input-label">
                {t("clientsinfo.name")}
              </label>
              <input
                name="name"
                id="clientsInfo-fullname"
                onChange={(e) => {
                  setFullName(e.target.value);
                }}
                className="clientsInfo-input"
                placeholder={t("clientsinfo.name")}
                required
              ></input>
            </div>

            {/* Phone */}
            <div className="clientsInfo-input-wrapper">
              <label className="clientsInfo-input-label">
                {t("clientsinfo.phone")}
              </label>
              <input
                name="phone"
                id="clientsInfo-phone"
                pattern="(\+)?\d*"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                className="clientsInfo-input"
                placeholder={t("clientsinfo.phone")}
                required
              ></input>
            </div>

            {/* Email */}
            <div className="clientsInfo-input-wrapper">
              <label className="clientsInfo-input-label clientsInfo-input-label-overflow">
                {t("clientsinfo.email")}
              </label>
              <input
                name="email"
                type="email"
                id="clientsInfo-email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="clientsInfo-input"
                placeholder={t("clientsinfo.email")}
                required
              ></input>
            </div>

            {/* Checkbox - Save Client's Details */}
            <div className="clientsInfo-checkbox-wrapper">
              <input
                checked={isChecked}
                onChange={handleCheckboxChange}
                type="checkbox"
                name="savedetails"
                className="clientsInfo-checkbox"
              ></input>
              <label
                htmlFor="savedetails"
                className="clientsInfo-checkbox-label"
              >
                {t("clientsinfo.savedetails")}
              </label>
            </div>
          </div>

          {/* Submit Button Wrapper */}
          <div className="clientsInfo-btn-wrapper">
            <button className="clientsInfo-btn">
              {t("clientsinfo.continue")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ClientsInfoAgent;
