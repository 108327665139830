import React from "react";
import "./Inputs.scss";
import { useRegistration } from "../../contexts/RegistrationContext";

export const OrangeRegistrationInput = ({
  registrationKey,
  type = "text",
  style = {},
}) => {
  const {
    formData,
    setFormKey,
    formOptions,
    formMessages,
    formTitles,
    getMissingFields,
  } = useRegistration();
  const missingFields = getMissingFields();
  const isKeyMissing = missingFields?.includes(registrationKey) && false;
  const params = {
    type,
    title: formTitles[registrationKey],
    value: formData[registrationKey],
    onChange: (e) => setFormKey(registrationKey, e.target.value),
    style: { border: isKeyMissing ? "1px solid red" : "", ...style },
    message: formMessages[registrationKey],
    options: formOptions[registrationKey],
  };
  const showEmptyOption = !formData[registrationKey] && params.options?.length;

  return (
    <div className="input-container">
      <div className="title">{params.title}</div>
      <div>
        {params?.options ? (
          <select className="input" {...params} style={{ ...params.style }}>
            {showEmptyOption && (
              <option value="" disabled selected>
                בחר אפשרות..
              </option>
            )}
            {params.options.map((p) => (
              <option key={p.value} value={p.value}>
                {p.name}
              </option>
            ))}
          </select>
        ) : (
          <input className="input" {...params} style={{ ...params.style }} />
        )}
        <div>{params.message}</div>
      </div>
    </div>
  );
};
