import React from 'react';
import {useRegistration} from "../../../contexts/RegistrationContext";
import {useNavigate} from "react-router-dom";
import {Loader} from "../../Loader/Loader";

const FinalStage = () => {
    const {formData} = useRegistration()
    const navigate = useNavigate();

    setTimeout(() => navigate("/"), "12000")

    return (
        <div style={{display:"flex", alignItems:"center", flexDirection:"column", padding:"40px 0"}}>
            <div>{formData?.firstName}</div>
            <div>הרישום בוצע בהצלחה, מיד תועבר לדף ההתחברות.</div>
            <div style={{color:"grey", fontSize:14, marginBottom:20}}>(אישור החשבון עלול לקחת מספר ימים)</div>
            <Loader/>
        </div>
    );
};

export default FinalStage;