import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import FinanceRow from "./FinanceRow";
import { isAdminUser } from "../../../services/authService";
import "./FinanceTable.scss";
import ArrowDown from "../../../assets/images/card/arrow-down.svg";
import { Loader } from "../../Loader/Loader";

const THEME = createTheme({
  typography: {
    fontFamily: `"Rubik" sans-serif`,
  },
});

const FinanceTable = ({
  finances,
  clickApproveRequest,
  clickDeclineRequest,
  addRequestComments,
}) => {
  const { t } = useTranslation();
  const isAdmin = isAdminUser();
  const [open, setOpen] = React.useState(false);

  const tableHeaders = [
    { value: t("finance.email"), isHidden: !isAdmin },
    { value: t("finance.agentName"), isHidden: !isAdmin },
    { value: t("finance.invoice"), width: "10%" },
    { value: t("finance.date"), width: "10%" },
    { value: t("finance.reqNumber"), width: "10%" },
    { value: t("finance.sum"), width: "10%" },
    { value: t("finance.status"), width: "10%" },
    { value: t("finance.comments"), width: "20%" },
    { value: t("finance.moreActions"), width: "10%", isHidden: !isAdmin },
  ];
  if (!finances) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Loader loaderText="טוען פיננסים..." />
      </div>
    );
  }

  return finances?.length === 0 ? (
    <div className="no-data">אין בקשות פיננסיות</div>
  ) : (
    <>
      <div className={"finance-table" + (!open ? " closed" : "")}>
        <div
          className="open-table-mobile-header"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div className="open-table-mobile-header-title">
            {t("finance.tableTitle")}
          </div>
          <div className="open-btn">
            {!open ? t("finance.clickToOpen") : t("finance.clickToClose")}
            <img src={ArrowDown} alt="arrow-down" />
          </div>
        </div>
        <div className="table-wrapper">
          <ThemeProvider theme={THEME}>
            <TableContainer component={Paper}>
              <Table aria-label="sticky table" stickyHeader>
                <TableHead className="table-header-row">
                  <TableRow>
                    {tableHeaders.map(({ value, width, isHidden }) => {
                      return isHidden ? null : (
                        <TableCell
                          className="table-header-cell"
                          style={{ backgroundColor: "#0258a4" }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finances.map((row, index) => (
                    <FinanceRow
                      key={row.invoice}
                      row={row}
                      index={index}
                      clickApproveRequest={clickApproveRequest}
                      clickDeclineRequest={clickDeclineRequest}
                      addRequestComments={addRequestComments}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default FinanceTable;
