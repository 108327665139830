import React, { useEffect } from "react";
import LanguageSelector from "../../../LanguageSelector/languageselector";
import "./uppernavbaragent.scss";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/images/hotelsriver-logo.jpg";
import { getUserInfo } from "../../../../services/localStorage";
import Tooltip from "@mui/material/Tooltip";
import PhoneLine from "../../../MenuBar/PhoneLine";
import { FaBars } from "react-icons/fa";
import homeIcon from "../../../../assets/images/navbar/home.png";
import bankNoteIcon from "../../../../assets/images/navbar/banknotes.png";
import customerIcon from "../../../../assets/images/navbar/customer.png";
import reportIcon from "../../../../assets/images/navbar/report.png";
import academyIcon from "../../../../assets/images/navbar/mortarboard.png";
import searchIcon from "../../../../assets/images/navbar/search.png";
import graphIcon from "../../../../assets/images/navbar/graph.png";
import settingsIcon from "../../../../assets/images/navbar/settings.svg";
import ordersIcon from "../../../../assets/images/navbar/orders.png";
import { isAdminUser, logout } from "../../../../services/authService";
import { useTranslation } from "react-i18next";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";

const UpperNavBarAgent = ({ sideBarOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companylogobase65 } = getUserInfo() || {};
  const isAdmin = isAdminUser();
  const [open, setOpen] = React.useState(false);

  const sideBarMargin = sideBarOpen ? "250px" : "60px";

  const adminMenuItems = [
    { text: t("sidenav.admin"), img: graphIcon, path: "admin" },
  ];

  const logoutAgent = () => {
    logout();
    window.location.href = "/login";
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  const menuItems = [
    { text: t("sidenav.home"), img: homeIcon, path: "home" },
    { text: t("sidenav.searchoffer"), img: searchIcon, path: "searchoffer" },
    { text: t("sidenav.orders"), img: ordersIcon, path: "orders" },
    { text: t("sidenav.finance"), img: bankNoteIcon, path: "finance" },
    { text: t("sidenav.management"), img: settingsIcon, path: "management" },

    {
      text: t("sidenav.customers"),
      img: customerIcon,
      path: "customers",
      disabled: true,
    },
    {
      text: t("sidenav.reports"),
      img: reportIcon,
      path: "reports",
      disabled: true,
    },
    {
      text: t("sidenav.academy"),
      img: academyIcon,
      path: "academy",
      disabled: true,
    },
    ...(isAdmin ? adminMenuItems : []),
  ];

  return (
    <>
      <div
        className="upper-nav-bar-agent-wrapper"
        style={{
          marginRight: sideBarMargin,
          width: `calc(100% - ${sideBarMargin} - 40px)`,
        }}
      >
        <div className="right-side">
          <button className="open-drawer-button" onClick={() => setOpen(!open)}>
            <FaBars />
          </button>
          <LanguageSelector />
          <PhoneLine />
        </div>
        <Tooltip title={"לחץ למעבר לעמוד הראשי"}>
          <img
            className="logo-img"
            src={companylogobase65 || Logo}
            alt="Hotels River Logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/p/dashboard/home`)}
          />
        </Tooltip>
      </div>
      <div className={"menu" + (open ? " open" : "")}>
        {menuItems.map((item, index) => (
          <Tooltip
            key={index}
            title={item.disabled ? "בקרוב" : undefined}
            sx={{
              fontSize: "6.2rem", // Bigger font size
              "& .MuiTooltip-tooltip": {
                padding: "10px 15px", // Bigger padding for larger area
              },
            }}
          >
            <div
              className={`menu-item ${item.disabled ? "disabled" : ""}`}
              onClick={() => {
                if (item.disabled) return;
                navigate(`/p/dashboard/${item.path}`);
                setOpen(false);
              }}
            >
              <img src={item.img} alt={item.text} />
              <span>{item.text}</span>
            </div>
          </Tooltip>
        ))}
        <ListItem
          onClick={logoutAgent}
          key={t("sidenav.logout")}
          disablePadding
          sx={{ display: "block", marginTop: "30px" }}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemText
              primary={t("sidenav.logout")}
              sx={{
                opacity: open ? 1 : 0,
                textAlign: "right",
                padding: "5px 10px",
              }}
            />
          </ListItemButton>
        </ListItem>
      </div>
    </>
  );
};

export default UpperNavBarAgent;
