import React, { useState } from "react";
import { getUserInfo, setUserLogoImage } from "../../services/localStorage";
import BaseImageUploader from "./BaseImageUploader";
import { changeUserLogo } from "../../services/authService";
import logo from "../../assets/images/hotelsriver-logo.jpg";

const LogoImageUploader = () => {
  const { companylogobase65, id } = getUserInfo() || {};
  const [base64Image, setBase64Image] = useState(companylogobase65 || logo);

  function onImageChange(newImage) {
    changeUserLogo(id, newImage)
      .then((res) => {
        setBase64Image(newImage);
        setUserLogoImage(newImage);
      })
      .catch((err) => console.error(err));
  }

  return (
    <BaseImageUploader
      imageSrc={base64Image}
      divStyle={{ height: 70, width: 200 }}
      // imageStyle={{ objectFit: "none" }}
      onImageChange={onImageChange}
    />
  );
};

export default LogoImageUploader;
