import React from "react";
import HotelRow from "./HotelRow";
import "./HotelsTable.scss";

import { useTranslation } from "react-i18next";
import { isAdminUser } from "../../../../services/authService";
import HotelCard from "./HotelCard";

const HotelsTable = ({ orders = [], cancelOrder }) => {
  const { t } = useTranslation();
  const isAdmin = isAdminUser();

  // const tableHeaders = [
  //   { value: t("orders.agentName"), width: "10%", isHidden: !isAdmin },
  //   { value: t("orders.mainContact"), width: "10%" },
  //   { value: t("orders.hotelName"), width: "20%" },
  //   { value: t("orders.stayDates"), width: "10%" },
  //   { value: t("orders.orderDate"), width: "10%" },
  //   { value: t("orders.cancelUntil"), width: "10%" },
  //   { value: t("orders.priceToPay"), width: "10%" },
  //   { value: t("orders.paidSum"), width: "10%" },
  //   { value: t("orders.commisions"), width: "10%" },
  // ];
  // return (
  //   <TableContainer component={Paper} style={{ overflow: "auto" }}>
  //     <Table stickyHeader>
  //       <TableHead>
  //         <TableRow className="table-header-row">
  //           <TableCell className="blue-header-cell"></TableCell>

  //           {tableHeaders.map(({ value, width, isHidden }) => {
  //             return isHidden ? null : (
  //               <TableCell className="blue-header-cell" width={width}>
  //                 {value}
  //               </TableCell>
  //             );
  //           })}
  //         </TableRow>
  //       </TableHead>
  //       <TableBody>
  //         {orders.map((row, index) => (
  //           <HotelRow
  //             cancelOrder={cancelOrder}
  //             key={row.name}
  //             row={row}
  //             index={index}
  //           />
  //         ))}
  //       </TableBody>
  //     </Table>
  //   </TableContainer>
  // );
  return (
    <div className="hotels-cards">
      {orders.map((row, index) => (
        <div key={index}>
          {/* <HotelRow
            cancelOrder={cancelOrder}
            key={row.name}
            row={row}
            index={index}
          /> */}
          <HotelCard
            cancelOrder={cancelOrder}
            key={row.id}
            row={row}
            index={index}
          />
        </div>
      ))}
    </div>
  );
};

export default HotelsTable;
