import React from 'react';
import {FormFields, useRegistration} from "../../../contexts/RegistrationContext";
import RegistrationBox from "../RegistrationBox";
import { OrangeRegistrationInput} from "../Inputs";

const LoginDetails = ({missingFields}) => {
    const {formData} = useRegistration()

    const isPasswordsDifferent = formData[FormFields.ACCOUNT_INFO.PASSWORD] !== formData[FormFields.ACCOUNT_INFO.PASSWORD_AGAIN] && formData[FormFields.ACCOUNT_INFO.PASSWORD_AGAIN]!== ""

    const inputs = [
        FormFields.ACCOUNT_INFO.PASSWORD,
        FormFields.ACCOUNT_INFO.PASSWORD_AGAIN,
    ]

    return (
        <RegistrationBox title="פרטי התחברות">
            {inputs.map((key) => <OrangeRegistrationInput registrationKey={key}/>)}
            <span style={{color:"red", textAlign:"center"}}>{isPasswordsDifferent && "הסיסמאות חייבות להיות זהות"}</span>
        </RegistrationBox>
    );
};

export default LoginDetails;