import React from 'react';
import "./RegistrationBox.css"
import {RegistrationTitle} from "./Titles";

const RegistrationBox = ({children, title, ...params}) => {
    return (
        <div className="registration-box" >
            <RegistrationTitle title={title}/>
            <div className="registration-box-body" {...params}>{children}</div>
        </div>
    );
};

export default RegistrationBox;