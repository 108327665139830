import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Google
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

// Global Variables
import { CLIENT_ID } from "../../assets/GlobalVariables";

// Services
import { login, getUserDetails } from "../../services/authService";

// CSS
import "./googlelogin.css";

// Icons
import GoogleLogo from "../../assets/images/icons/google_logo.png";

const GoogleLoginButton = ({ googleData, onGoogleDataChange }) => {
  // Navigator
  const navigate = useNavigate();

  useEffect(() => {
    // Load gapi and set up configuration
    gapi.load("auth2", function () {
      gapi.auth2.init({
        client_id: CLIENT_ID,
        scope: "profile",
        // uxMode: "redirect",
        cookiePolicy: "single_host_origin",
        // redirect_uri: window.location.href,
        // prompt: "select_account"
      });
    });
  });

  const responseGoogle = async (response) => {
    // Save data sent by google
    onGoogleDataChange(response.profileObj);

    try {
      let googleData = response.profileObj;

      // Create data
      let data = {
        email: googleData.email,
        password: "",
        externalID: googleData.googleId,
      };

      // Authonticate
      let res = await login(data);

      if (res.status === "ok") {
        // Redirect user
        let userType = getUserDetails().usertype;

        // Redirect user accordnigly to his type
        if (userType === "clients") {
          navigate(`/p`);
        } else if (userType === "agents") {
          navigate(`/p/dashboard/home`);
        } else if (userType === "administrators") {
          navigate(`/p/administration`);
        } else {
          // pass
        }
      } else {
        // Error handler
      }
    } catch {
      // pass
    }
  };

  return (
    <GoogleLogin
      clientId={CLIENT_ID}
      render={(renderProps) => (
        <button
          className="google-login-button"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy="single_host_origin"
      className="google-login-button"
      // isSignedIn={true}
      prompt="select_account"
      // uxMode="redirect"
      // redirectUri={window.location.href}
    />
  );
};

export default GoogleLoginButton;

// import { GoogleLogin } from '@react-oauth/google';

// export default GoogleLoginButton;
// const GoogleLoginButton = () => {

//   const responseGoogle = (response) => {
//       console.log(response); // Handle the response from Google login
//   };

//   const clickGoogle = () => {
//     console.log(document.getElementsByClassName('nsm7Bb-HzV7m-LgbsSe'))
//     // document.getElementsByClassName('nsm7Bb-HzV7m-LgbsSe jVeSEe i5vt6e-Ia7Qfc uaxL4e-RbRzK')[0].click();
//     document.getElementById('container').click();
//   }

//   return (
//     <div>
//         <div className='google-login-btn-wrapper'>
//           <GoogleLogin
//               // render={renderProps => (
//               //     <button
//               //       className="google-login-button"
//               //       onClick={renderProps.onClick}
//               //       disabled={renderProps.disabled}
//               //     >
//               //       <img src={GoogleLogo} alt="Google Logo" className="google-logo" />
//               //     </button>
//               //   )}
//               onSuccess={responseGoogle}
//               onFailure={responseGoogle}
//               cookiePolicy="single_host_origin"
//               className='google-login-button'
//               id='google-login-button'
//               // isSignedIn={true}
//               prompt="select_account"
//               // uxMode="redirect"
//               // redirectUri={window.location.href}
//           />
//         </div>
//         <img onClick={clickGoogle} src={GoogleLogo} alt="Google Logo" className="google-logo" />
//     </div>
//   )
// }

// export default GoogleLoginButton;
