import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Gallary
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./differentroom.css";

// Images
import CoinIcon from "../../../../../../assets/images/icons/coin.png";
import NoBeds from "../../../../../../assets/images/icons/bed.png";

function DifferentRoom({ diffRoom, currentSearch }) {
  // Navigator
  const navigate = useNavigate();

  // Translator
  const { t } = useTranslation();

  // States
  const [isZoom, setIsZoom] = useState(false);

  // --- Functions

  // Order Room
  const orderRoom = (e, selectedRoom, selectedPackage) => {
    e.preventDefault();

    selectedRoom["searchPhrase"] = currentSearch;
    selectedRoom["selectedPackage"] = selectedPackage;

    navigate("/p/dashboard/searchoffer/" + selectedRoom.PackageId);
  };

  // Actions
  const zoomImage = () => {
    setIsZoom(!isZoom);
  };

  return (
    <div className="suggestedRoom-wrapper">
      {/* Room Details */}
      <div className="suggestedRoom-object">
        {/* Room Data */}
        <div className="suggestedRoom-object-rooms">
          {diffRoom &&
            diffRoom.Rooms &&
            Object.values(diffRoom.Rooms).map((room, index) => (
              <div key={index} className="suggestedRoom-object-room">
                {/* Rooms Pictures */}
                <div
                  className={`suggestedRoom-object-room-img ${
                    isZoom ? "suggestedRoom-object-room-img-zoom-wrapper" : ""
                  }`}
                >
                  <div
                    className={`${
                      isZoom ? "suggestedRoom-object-room-img-zoom" : ""
                    }`}
                  >
                    {room.RoomContent && room.RoomContent.Images.length > 0 ? (
                      <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showArrows={room.RoomContent.Images.length - 1}
                      >
                        {room.RoomContent.Images.map((imageUrl, index) => (
                          <div
                            key={index}
                            onClick={zoomImage}
                            className={`${
                              isZoom
                                ? "suggestedRoom-object-room-img-zoom-cursor"
                                : ""
                            }`}
                          >
                            <img
                              className={`${
                                isZoom
                                  ? "roomPlacer-image-zoom"
                                  : "roomPlacer-image"
                              }`}
                              src={imageUrl}
                              alt={`Image ${index}`}
                            />
                          </div>
                        ))}
                      </Carousel>
                    ) : (
                      <div className="roomPlacer-nobedimage-wrapper">
                        <img
                          className="roomPlacer-nobedimage-img"
                          src={NoBeds}
                          alt="No hotel room images"
                        ></img>
                        <label className="roomPlacer-nobedimage-title">
                          {t("hotelprofile.noimages")}
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="suggestedRoom-object-room-border"></div>

                {/* Room describtion */}
                <div className="suggestedRoom-object-describtion-wrapper">
                  <label className="suggestedRoom-object-describtion-title">
                    <label className="same-room-duplication">
                      {room.SimilarRooms ? (
                        <span>&times;{room.SimilarRooms} </span>
                      ) : (
                        ""
                      )}
                    </label>

                    {room.RoomName}
                  </label>

                  <label className="suggestedRoom-object-describtion-sub-title">
                    {room.BedType
                      ? room.BedType.charAt(0) +
                        room.BedType.slice(1).toLowerCase() +
                        " Bed"
                      : ""}{" "}
                  </label>
                </div>

                <div className="suggestedRoom-object-room-border"></div>

                {/* Free Cancelietion */}
                <div className="suggestedRoom-object-cancelation">
                  <div className="suggestedRoom-object-cancelation-wrapper">
                    <label
                      className={`suggestedRoom-object-cancelation-title ${
                        diffRoom.Refundability === 1
                          ? "suggestedRoom-object-cancelation-title-refund"
                          : "suggestedRoom-object-cancelation-title-no-refund"
                      }`}
                    >
                      {diffRoom.Refundability === 1
                        ? t("hotelprofile.freecancelation")
                        : t("hotelprofile.nofreecancelation")}
                    </label>
                    <label className="suggestedRoom-object-cancelation-sub-title">
                      {diffRoom.Refundability === 1
                        ? t("hotelprofile.freecancelationtext")
                        : t("hotelprofile.nofreecancelationtext")}
                    </label>
                  </div>

                  <img
                    className="suggestedRoom-object-cancelation-img"
                    src={CoinIcon}
                  ></img>
                </div>
              </div>
            ))}
        </div>

        <div className="suggestedRoom-object-border"></div>

        {/* Pricing */}
        {diffRoom && diffRoom.HotelId && (
          <form
            onSubmit={(e) => orderRoom(e, diffRoom)}
            className="suggestedRoom-object-pricing-wrapper"
          >
            <div className="suggestedRoom-object-pricing">
              <label
                id="diff-price"
                className="suggestedRoom-object-pricing-title"
              >
                {diffRoom.PackagePrice.FinalPrice}
              </label>
              <label className="suggestedRoom-object-pricing-sub-title">
                {t("hotelprofile.includetaxes")}
              </label>
            </div>

            <button className="suggestedRoom-object-pricing-btn" type="submit">
              {t("hotelprofile.ordernow")}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default DifferentRoom;
