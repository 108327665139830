import React, { useState } from "react";

// CSS
import "./databox.css";

const DataBox = ({ idOfBox, title, value, iconSrc, color }) => {
  return (
    <>
      <div
        className={`dataBox-border dataBox-border-${color} tw-mx-1 tw-flex tw-flex-col tw-rounded-lg tw-gap-1 tw-w-full tw-text-center`}
      >
        <div className={`dataBox-icon-wrapper`}>
          <img className={`dataBox-icon`} src={iconSrc} alt={title}></img>
        </div>

        <label className={`dataBox-title dataBox-text-${color}`}>{title}</label>

        <label id={idOfBox} className={`dataBox-value dataBox-text-${color}`}>
          {value}
        </label>
      </div>
    </>
  );
};

export default DataBox;
