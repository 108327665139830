import { useState, useEffect, useRef } from "react";

// Languages
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// CSS
import "./languageselector.css";

// Icons
import israelIcon from "../../assets/images/flags/israel.png";
import usaIcon from "../../assets/images/flags/united-states.png";
// import francelIcon from '../../assets/images/flags/france.png';

function LanguageSelector() {
  // Translator
  const { t } = useTranslation();

  // Langauges
  const languages = [
    { code: "he", label: "עברית", flag: israelIcon },
    { code: "en", label: "English", flag: usaIcon },
    // { code: 'fr', label: 'Français', flag: francelIcon },
  ];

  // Constnats
  const langDetails = languages.find(
    (lang) => lang.code === localStorage.getItem("i18nextLng")
  );

  // States
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    t("languages.language")
  );
  const [selectedFlag, setSelectedFlag] = useState(
    langDetails ? langDetails.flag : "he"
  );

  // Refs
  const dropdownRef = useRef(null);

  // Refresh routes - Add routes if needed a refresh on that page
  const refreshRoutes = ["/p/dashboard/success"];

  // --- UseEffects

  // Add listener to close the dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // --- Functions

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const refresher = () => {
    const currentRoute = window.location.pathname;

    if (refreshRoutes.includes(currentRoute)) {
      // Force reload the current page
      window.location.reload(true);
      return;
    }

    // Check if some part of the text is in any of the texts in the list
    for (const route of refreshRoutes) {
      if (currentRoute.startsWith(route)) {
        // Force reload the current page
        window.location.reload(true);
        return;
      }
    }
  };

  const changeLanguage = (lng, label, flag) => {
    // Change the label and the flag
    setSelectedLanguage(label);
    setSelectedFlag(flag);

    // Change the langauge for the website
    i18next.changeLanguage(lng);

    // Close dropdown
    setIsOpen(false);

    // Refresh if needed
    refresher();
  };

  return (
    <div className="language-selector" ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img
          src={selectedFlag}
          alt="Flags of languages"
          className="flag-icon"
        />
        {selectedLanguage}
        <span
          className={`arrow-icon ${
            isOpen ? "arrow-icon-up" : "arrow-icon-down"
          }`}
        >
          &#8250;
        </span>
      </button>

      {/* Drowdown */}
      {isOpen && (
        <div className="dropdown-menu">
          {/* {languages.map((option) => (
                <button  
                    key={option.code}
                    className="dropdown-item"
                    onClick={() => changeLanguage(option.code, option.label, option.flag)}
                  >
                  <img src={option.flag} alt="flag" className="flag-icon" />
                  {option.label}
                </button>
              ))}  */}
          <div
            className="dropdown-item"
            onClick={() => changeLanguage("he", "עברית", israelIcon)}
          >
            <img src={israelIcon} alt="flag" className="flag-icon" />
            עברית
          </div>
          <div
            className="dropdown-item"
            onClick={() => changeLanguage("en", "English", usaIcon)}
          >
            <img src={usaIcon} alt="flag" className="flag-icon" />
            English
          </div>
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
