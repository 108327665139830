import { convertPrice } from "../../../services/currencyService";
import React from "react";

const PaymentInterest = ({ currentCard }) => {
  if (!currentCard.amount) {
    return null;
  }
  return (
    <span style={{ color: "black", fontSize: 16 }}>
      {`שים לב, לתשלום תתווסף ריבית תשלומים בסך`}
      <span style={{ color: "red", margin: "0 5px", fontWeight: "bold" }}>
        {convertPrice(currentCard.paymentInterest)}
      </span>
      <div style={{ fontSize: 16, color: "grey" }}>
        {`(${convertPrice(currentCard.amountWithInterest )} = ${currentCard.numberofpayments} חודשים * ${convertPrice(currentCard.amountWithInterest / currentCard.numberofpayments)})`}
      </div>
    </span>
  );
};

export default PaymentInterest;
