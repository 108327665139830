import React, { useState } from "react";
import { Link } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./bottomnavbarclient.css";

const BottomNavBarClient = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bottomNav-bar">
        <div className="bottomNav-bar-bottom"></div>
      </div>
    </>
  );
};

export default BottomNavBarClient;
