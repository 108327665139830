import axios from "axios";
import { showErrorToast } from "./swalService";

export async function performPostFetch(api, data, error) {
  try {
    return await axios.post(api, data);
  } catch (err) {
    if (error) {
      showErrorToast(error);
    }
    console.log({ err });
    // throw err;
  }
}
export async function performGetFetch(api, data = {}, error) {
  try {
    return await axios.get(api, data).then((res) => res.data);
  } catch (err) {
    if (error) {
      showErrorToast(error);
    }
    console.log({ err });
  }
}
