import React, { useState, useEffect } from "react";
import "./DropdownDateInput.scss";

const DropdownDateInput = ({ handleChange, value, disabled }) => {
  const defaultProps = {
    disabled,
    className: "dropdown-date-input",
  };
  const [defaultDay, defaultMonth, defaultYear] = value
    ?.split("/")
    .map(Number) || ["", "", ""];

  const [day, setDay] = useState(defaultDay);
  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);

  useEffect(() => {
    if (day && month && year && value !== formatDate()) {
      handleChange(formatDate());
    }
  }, [day, month, year, handleChange]);

  const handleDayChange = (event) => setDay(event.target.value);

  const handleMonthChange = (event) => setMonth(event.target.value);

  const handleYearChange = (event) => setYear(event.target.value);

  const formatDate = () => {
    return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
  };

  const renderDayPicker = () => {
    return (
      <select
        value={day}
        onChange={handleDayChange}
        {...defaultProps}
        style={{ borderRadius: "10px 0 0 10px" }}
      >
        <option value="">יום</option>
        {[...Array(31).keys()].map((day) => (
          <option key={day + 1} value={day + 1}>
            {day + 1}
          </option>
        ))}
      </select>
    );
  };

  const renderMonthPicker = () => {
    return (
      <select
        value={month}
        onChange={handleMonthChange}
        {...defaultProps}
        style={{ borderRadius: 0, margin: "0 -1px" }}
      >
        <option value="">חודש</option>
        {[...Array(12).keys()].map((month) => (
          <option key={month + 1} value={month + 1}>
            {month + 1}
          </option>
        ))}
      </select>
    );
  };

  const renderYearPicker = () => {
    return (
      <select
        value={year}
        onChange={handleYearChange}
        {...defaultProps}
        style={{ borderRadius: "0 10px 10px 0" }}
      >
        <option value="">שנה</option>
        {[...Array(100).keys()].map((index) => {
          const yearValue = new Date().getFullYear() - index;
          return (
            <option key={yearValue} value={yearValue}>
              {yearValue}
            </option>
          );
        })}
      </select>
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {renderYearPicker()}
      {renderMonthPicker()}
      {renderDayPicker()}
    </div>
  );
};

export default DropdownDateInput;
