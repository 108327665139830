import React from "react";

export const Title = ({ children, ...props }) => {
  return (
    <span className="lg:tw-text-lg" {...props}>
      {children}
    </span>
  );
};
export const SubTitle = ({ children, ...props }) => {
  return (
    <span style={{ fontSize: 13 }} {...props}>
      {children}
    </span>
  );
};
