import React, { useState } from "react";
import { BsCurrencyExchange } from "react-icons/bs";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import { OrangeButton, OrangeButtonNoWidth } from "../../../buttons/Buttons";

import "./HandlingFeePicker.scss";

const quickAddCommisionAdd = [
  { value: 200, type: "+" },
  { value: 400, type: "+" },
  { value: 600, type: "+" },
  { value: 800, type: "+" },
];
const quickAddCommisionPercentage = [
  { value: 5, type: "%" },
  { value: 10, type: "%" },
  { value: 12, type: "%" },
  { value: 15, type: "%" },
];

const HandlingFeePicker = ({
  updateHandleFeeData,
  handleFeeData,
  extendHandleFeeDefault = false,
}) => {
  const [extendHandleFee, setExtendHandleFee] = useState(
    extendHandleFeeDefault
  );
  const [editingHandleFee, setEditingHandleFee] = useState(
    handleFeeData?.value
  );
  const [editingHandleFeeOption, setEditingHandleFeeOption] = useState(
    handleFeeData?.type
  );

  const { t } = useTranslation();

  const openCloseHandleFee = () => {
    setExtendHandleFee(!extendHandleFee);
  };
  const saveCommission = (commissionSum) => {
    const updatedCommission =
      commissionSum || Number(document.getElementById("commission").value);

    if (parseFloat(updatedCommission) >= 0) {
      updateHandleFeeData(editingHandleFeeOption, updatedCommission);
    }
  };

  return (
    <div className="payment-order-commition-wrapper">
      <div className="payment-commission-wrapper" onClick={openCloseHandleFee}>
        <div className="payment-commission-main-item">
          <span>{t("paymentagent.addcommission")}</span>
          <BsCurrencyExchange />
        </div>

        {!extendHandleFee ? <AiOutlineCaretDown /> : <AiOutlineCaretUp />}
      </div>

      {extendHandleFee && (
        <div className="payment-commission-drop-wrapper-wrapper">
          <div className="payment-commission-drop-wrapper">
            <div className="payment-commission-drop-title-wrapper">
              <select
                value={editingHandleFeeOption}
                className="commission-options"
                name="commission-options"
                id="commission-options"
                onChange={(e) => {
                  setEditingHandleFeeOption(e.target.value);
                }}
              >
                <option value="%">%</option>
                <option value="+">+</option>
              </select>
              <span className="payment-commission-drop-title">
                {t("paymentagent.addedcommission")}
              </span>

              <BsCurrencyExchange className="payment-commission-drop-title-icon" />
            </div>

            <div className="payment-commission-drop-update-wrapper">
              <input
                type="number"
                defaultValue={editingHandleFee}
                className="payment-commission-drop-update-input"
                id="commission"
                min={0}
                onChange={(e) => setEditingHandleFee(e.target.value)}
              />
            </div>
          </div>
          <div className="quick-add-commision-btns">
            {quickAddCommisionAdd.map((item) => {
              return (
                <OrangeButtonNoWidth
                  className="tw-w-[4em] lg:tw-w-[4.5vw]"
                  style={{
                    // padding: "15px",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById("commission").value = item.value;
                    setEditingHandleFeeOption(item.type);
                    setEditingHandleFee(item.value);
                  }}
                >
                  {item.value}
                  {item.type}
                </OrangeButtonNoWidth>
              );
            })}
          </div>
          <div className="quick-add-commision-btns">
            {quickAddCommisionPercentage.map((item) => {
              return (
                <OrangeButtonNoWidth
                  className="tw-w-[4em] lg:tw-w-[4.5vw]"
                  style={{
                    // padding: "15px",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    document.getElementById("commission").value = item.value;
                    setEditingHandleFeeOption(item.type);
                    setEditingHandleFee(item.value);
                  }}
                >
                  {item.value}
                  {item.type}
                </OrangeButtonNoWidth>
              );
            })}
          </div>
          <button
            className="payment-commission-drop-update-btn"
            onClick={() => saveCommission()}
          >
            {t("paymentagent.update")}
          </button>
        </div>
      )}
    </div>
  );
};

export default HandlingFeePicker;
