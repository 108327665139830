import React from "react";
import { convertPrice } from "../../../services/currencyService";

const TotalPayment = ({totalPrice}) => {
    return (
        <span>
            {convertPrice(parseFloat(totalPrice))}
        </span>
    );
};

export default TotalPayment;
