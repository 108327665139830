import React from "react";
import { useTranslation } from "react-i18next";
import OrdersFiltersTab from "./OrderFilterTab";
import "./OrdersFiltersTabs.css";
import FlightSvg from "../../../assets/images/filters/Flights.svg";
import flightAndHotel from "../../../assets/images/filters/flightAndHotel.svg";
import vacation from "../../../assets/images/filters/vacation.svg";
import hotel from "../../../assets/images/filters/hotel.svg";
const OrdersFiltersTabs = ({ handleChange, tabIndex }) => {
  const { t } = useTranslation();

  return (
    <div className={"filters-tabs"}>
      <OrdersFiltersTab
        tabIndex={tabIndex}
        index={1}
        handleChange={handleChange}
        img={hotel}
        title={t("orders.tabs.hotels")}
      />
      <OrdersFiltersTab
        tabIndex={tabIndex}
        index={3}
        handleChange={handleChange}
        img={vacation}
        title={t("orders.tabs.packages")}
      />
      <OrdersFiltersTab
        tabIndex={tabIndex}
        index={0}
        handleChange={handleChange}
        img={FlightSvg}
        title={t("orders.tabs.flights")}
        disabled={true}
      />
      <OrdersFiltersTab
        tabIndex={tabIndex}
        index={2}
        handleChange={handleChange}
        img={flightAndHotel}
        title={t("orders.tabs.hotels_and_flights")}
        disabled={true}
      />
    </div>
  );
};

export default OrdersFiltersTabs;
