import React from "react";
import { useNavigate } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./notification.css";

// Images
import doneImg from "../../../../../../../assets/images/passangerData/done.png";
import { useOrder } from "../../../../../../../contexts/OrderContext";

const NotificationPass = ({ title, subtitle }) => {
  // Translator
  const { currentHotelOrder } = useOrder();

  const { t } = useTranslation();

  // Navigator
  const navigate = useNavigate();

  // Go back to the searched hotel
  const returnToHotelProfile = () => {
    if (currentHotelOrder) {
      const hotelID = currentHotelOrder.searchPhrase.hotelID;

      navigate("/hotelprofile/" + hotelID);
    } else {
      navigate("/p/dashboard/home");
    }
  };

  return (
    <div className="notificationPass-component">
      <div className="notificationPass-message">
        {/* Image */}
        <div className="notificationPass-img-wrapper">
          <img className="notificationPass-img" src={doneImg} alt="Done"></img>
        </div>

        {/* Title */}
        <div className="notificationPass-title-wrapper">
          <label className="notificationPass-title"> {title} </label>
          <label className="notificationPass-sub-title"> {subtitle} </label>
        </div>
      </div>

      <div className="notificationPass-return-wrapper">
        <label
          onClick={returnToHotelProfile}
          className="notificationPass-return"
        >
          {t("completepassengers.return")} &gt;
        </label>
      </div>
    </div>
  );
};

export default NotificationPass;
