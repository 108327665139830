import React, { useState, useEffect } from "react";
// import { saveCreditCardFee } from "../../services/fhpService";
import { useAppContext } from "../../contexts/AppContext";
import { saveGlobalConstant } from "../../services/fhpService";

const GlobalConstantAdmin = () => {
  const {
    gcKey,
    getCreditCardFeePercent,
    getAgentCommissionPercent,
    getHrCommissionPercent,
    updateGlobalConstant,
    globalConstant,
  } = useAppContext();

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = async () => {
    setIsSaving(true);
    setErrorMessage(""); // Clear previous error message
    try {
      const response = await saveGlobalConstant({
        globalConstant: Object.entries(globalConstant),
      });
      if (response.data.status === "error") {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSaving(false);
    }
  };
  if (!getCreditCardFeePercent()) {
    return "Loading...";
  }
  return (
    <div>
      <h2>Global Constant</h2>
      <div>
        <h3>Credit Card Fee (%)</h3>
        <div>
          <input
            type="number"
            value={getCreditCardFeePercent()}
            onChange={(e) =>
              updateGlobalConstant({
                [gcKey.CREDIT_CARD_FEE]: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <h3>Hotel River Commission</h3>

        <div>
          <input
            type="number"
            value={getHrCommissionPercent()}
            onChange={(e) =>
              updateGlobalConstant({
                [gcKey.HR_COMMISSION]: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <h3>Agent Commission</h3>
        <div>
          <input
            type="number"
            value={getAgentCommissionPercent()}
            onChange={(e) =>
              updateGlobalConstant({
                [gcKey.AGENT_COMMISSION]: parseFloat(e.target.value),
              })
            }
          />
        </div>
      </div>
      <button onClick={handleSave} disabled={isSaving}>
        {isSaving ? "Saving..." : "Save"}
      </button>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default GlobalConstantAdmin;
