import React from "react";
import { ROUTES } from "../../Routes/Routes";

export const TermsAndConditionsLink = () => (
  <a href={ROUTES.TERMS_AND_CONDITIONS} target={"_blank"}>
    תקנון האתר
  </a>
);
export const AgentTermsLink = () => (
  <a href={ROUTES.AGENT_TERMS} target={"_blank"}>
    תקנון
  </a>
);
