import React, { useState, useEffect } from "react";
import { saveInterestTable } from "../../services/fhpService";

import { useAppContext } from "../../contexts/AppContext";

const InterestTableAdmin = () => {
  const { interestTable, setInterestTable } = useAppContext();
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (month, value) => {
    setInterestTable((prevTable) => ({
      ...prevTable,
      [month]: parseFloat(value),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    setErrorMessage(""); // Clear previous error message
    try {
      const response = await saveInterestTable({ interestTable });
      if (response.data.status === "error") {
        throw new Error(response.data.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <h2>Interest Table</h2>
      {interestTable ? (
        <>
          <table>
            <thead>
              <tr>
                <th>Month</th>
                <th>Interest Rate (%)</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(interestTable).map(([month, rate]) => (
                <tr key={month}>
                  <td>{month}</td>
                  <td>
                    <input
                      type="number"
                      value={rate}
                      onChange={(e) => handleInputChange(month, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleSave} disabled={isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </button>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default InterestTableAdmin;
