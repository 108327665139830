import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import {
  logout,
  getUserDetails,
  isAdminUser,
} from "../../services/authService";
import { useNavigate } from "react-router-dom";

import homeIcon from "../../assets/images/navbar/home.png";
import bankNoteIcon from "../../assets/images/navbar/banknotes.png";
import customerIcon from "../../assets/images/navbar/customer.png";
import shoppingcartIcon from "../../assets/images/navbar/shopping-cart.png";
import reportIcon from "../../assets/images/navbar/report.png";
import graphIcon from "../../assets/images/navbar/graph.png";
import academyIcon from "../../assets/images/navbar/mortarboard.png";
import searchIcon from "../../assets/images/navbar/search.png";
import settingsIcon from "../../assets/images/navbar/settings.svg";
import ordersIcon from "../../assets/images/navbar/orders.png";

import "./SideNav.css";
import {
  getSideBarStatus,
  getUserInfo,
  setSideBarStatus,
} from "../../services/localStorage";
import ProfileImageUploader from "../ProfileImageUploader/ProfileImageUploader";
import { Title } from "../Text";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNav = ({ setSidebarOpen }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const user = getUserDetails();
  const navigate = useNavigate();
  const { imageprofilebase65 } = getUserInfo() || {};
  const isAdmin = isAdminUser();

  const [open, setOpen] = React.useState(getSideBarStatus());

  const adminMenuItems = [
    { text: t("sidenav.admin"), img: graphIcon, path: "admin" },
  ];
  const menuItems = [
    { text: t("sidenav.home"), img: homeIcon, path: "home" },
    { text: t("sidenav.searchoffer"), img: searchIcon, path: "searchoffer" },
    { text: t("sidenav.orders"), img: ordersIcon, path: "orders" },
    { text: t("sidenav.finance"), img: bankNoteIcon, path: "finance" },
    { text: t("sidenav.management"), img: settingsIcon, path: "management" },

    {
      text: t("sidenav.customers"),
      img: customerIcon,
      path: "customers",
      disabled: true,
    },
    {
      text: t("sidenav.reports"),
      img: reportIcon,
      path: "reports",
      disabled: true,
    },
    {
      text: t("sidenav.academy"),
      img: academyIcon,
      path: "academy",
      disabled: true,
    },
    ...(isAdmin ? adminMenuItems : []),
  ];

  const logoutAgent = () => {
    logout();
    window.location.href = "/login";
  };

  const toggleSidebar = () => {
    const newState = !open;
    setSidebarOpen(newState);
    setOpen(newState);
    setSideBarStatus(newState);
  };

  const handleLinkClick = (path) => {
    navigate(`/p/dashboard/${path}`);
  };

  const renderUserType = () => {
    return isAdminUser() ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "black",
          fontWeight: 600,
          fontSize: 20,
        }}
      >
        Admin
      </div>
    ) : null;
  };

  return (
    <div className={"SideNav-wrapper"}>
      <Drawer
        anchor="right"
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            width: 240,
            // marginTop: "65px",
            // backgroundColor: "#25ac03",
          },
        }}
      >
        <DrawerHeader className="side-nav-login-user">
          {user && open && (
            <div className="user-details">
              <ProfileImageUploader />
              <div>
                <div className="side-nav-login-user-item">
                  {t("sidenav.greetings")},<br />
                  {user.firstname} {user.lastname}
                </div>
                {/* <div className="side-nav-login-user-item">{user.email}</div> */}
                {renderUserType()}
              </div>
            </div>
          )}
          <div
            onClick={toggleSidebar}
            className={`arrow-wrapper arrow-wrapper-${
              open ? "open" : "closed"
            }`}
          >
            {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </div>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map(({ text, img, path, disabled }) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => (disabled ? null : handleLinkClick(path))}
            >
              <Tooltip
                title={disabled ? "בקרוב!" : undefined}
                sx={{
                  fontSize: "6.2rem", // Bigger font size
                  "& .MuiTooltip-tooltip": {
                    padding: "10px 15px", // Bigger padding for larger area
                  },
                }}
              >
                <span>
                  <ListItemButton
                    disabled={disabled}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                        textAlign: "right",
                      }}
                    />
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        // padding: "5px",
                      }}
                    >
                      <img className="side-nav-icon" src={img} alt={img} />
                    </ListItemIcon>
                  </ListItemButton>
                </span>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Divider />
        {open && (
          <ListItem
            onClick={logoutAgent}
            key={t("sidenav.logout")}
            disablePadding
            sx={{ display: "block", marginTop: "30px" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemText
                primary={t("sidenav.logout")}
                sx={{
                  opacity: open ? 1 : 0,
                  textAlign: "right",
                  padding: "5px 10px",
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </Drawer>
    </div>
  );
};

export default SideNav;
