import React from "react";
import { useSearch } from "../../../../contexts/SearchContext";
import DestinationInput from "../SubComponents/DestinationInput/DestinationInput";
import DatesInput from "../SubComponents/DatesInput/DatesInput";
import PeopleCounterInput from "../SubComponents/PeopleCounterInput/PeopleCounterInput";
import "./packagesearchbar.css";
import {
  getAllDepartureDates,
  getReturnDatesForDeparture,
} from "../../../../contexts/SearchContextHelpers";
import { Loader } from "../../../Loader/Loader";

const PackageSearchBar = () => {
  const {
    calendarDateArrival,
    setCalendarDateArrival,
    calendarDateLeave,
    setCalendarDateLeave,
    roomInfo,
    setRoomInfo,
    location,
    setLocation,
    ldsDates,
    datesError,
    loadingDates,
  } = useSearch();

  function renderInput() {
    const defaultProps = {
      width: 120,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    if (datesError) {
      return <div style={{ ...defaultProps }}>{datesError}</div>;
    }
    if ((!location || location.length === 0) && !ldsDates) {
      return <div style={{ ...defaultProps }}>יש לבחור יעד</div>;
    }
    if (loadingDates) {
      return (
        <div
          style={{
            ...defaultProps,
          }}
        >
          <Loader size="small" />
        </div>
      );
    }
    return (
      <DatesInput
        calendarDateArrival={calendarDateArrival}
        calendarDateLeave={calendarDateLeave}
        setCalendarDateArrival={setCalendarDateArrival}
        setCalendarDateLeave={setCalendarDateLeave}
        possibleArrivalDates={getAllDepartureDates(ldsDates)}
        possibleDepartureDates={getReturnDatesForDeparture(
          ldsDates,
          calendarDateArrival,
        )}
      />
    );
  }

  return (
    <>
      <DestinationInput
        location={location}
        setLocation={setLocation}
        isLdsInput
      />

      {renderInput()}

      <PeopleCounterInput roomInfo={roomInfo} setRoomInfo={setRoomInfo} />
      {/*<div>שים לב, החיפוש יופעל עם טווח של -+2 ימים</div>*/}
    </>
  );
};

export default PackageSearchBar;
