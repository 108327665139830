import React from "react";
import "./CardPaidOverlay.scss";

const CardPaidOverlay = ({}) => {
  return (
    <div className={"card-paid-overlay"}>
      <div className="text"></div>
    </div>
  );
};

export default CardPaidOverlay;
