import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./roompassinfo.css";
import roomImg from "../../../../../../../assets/images/passangerData/room.png";
import { useSearch } from "../../../../../../../contexts/SearchContext";
import InputPassenger from "./subComponents/Input/input";

const RoomPassInfo = ({ roomInfo, roomNumber, roomName }) => {
  // Translator
  const { t } = useTranslation();
  const { passengerInfo, setPassengerInfo } = useSearch();

  // console.log({ passengerInfo });

  // Auto insert the email and phone of the inserted client to inputs
  useEffect(() => {
    // Email auto insert visually
    const emailInput = document.getElementById("email-1-0");

    if (emailInput) {
      // Get the email of the user
      const savedClientInfoData = localStorage.getItem(
        "agent-saved-client-info"
      );
      var savedClientInfoEmail = savedClientInfoData
        ? JSON.parse(savedClientInfoData).email
        : "";

      // Insert the email
      emailInput.value = savedClientInfoEmail;
    }

    // Cell auto insert visually
    const cellInput = document.getElementById("cell-1-0");

    if (cellInput) {
      // Get the cell of the user
      const savedClientInfoData = localStorage.getItem(
        "agent-saved-client-info"
      );
      var savedClientInfoCell = savedClientInfoData
        ? JSON.parse(savedClientInfoData).phone
        : "";

      // Insert the cell
      cellInput.value = savedClientInfoCell;
    }

    // - Set the data in the logic

    const setCellAndEmail = () => {
      console.log("setCellAndEmail");
      // Check if passengerInfo is not empty and has good structure
      if (
        passengerInfo.length > 0 &&
        Array.isArray(passengerInfo[0]) &&
        passengerInfo[0].length > 0
      ) {
        // get the first adult
        const mainAdult = passengerInfo[0][0];

        if (mainAdult) {
          // Set cell and email for the main adult
          mainAdult.cell = savedClientInfoCell;
          mainAdult.email = savedClientInfoEmail;

          // Update the state with the modified passengerInfo
          setPassengerInfo([...passengerInfo]);
        }
      }
    };

    // Call the function to set cell and email
    setCellAndEmail();
  }, []);

  // --- Functions

  // Duplicate lastname to the entire room
  const duplicateFamilyName = (e) => {
    e.preventDefault();

    // Search in the right room
    const rootRoom = document.getElementById("room-number-" + roomNumber);

    // Get the wanted family name
    const dupLastName = document.getElementById(
      "lastname-1-" + roomNumber
    ).value;

    // Get all the family names
    const inputElements = rootRoom.querySelectorAll("input");

    // Loop through the input elements and set lastname visually
    inputElements.forEach((input) => {
      // Check if the id attribute starts with "lastname"
      if (input.id.startsWith("lastname")) {
        // Set the value to the default lastname
        input.value = dupLastName;
      }
    });

    // - Set the lastname in the Logic for the entire room
    const updateLastNamesInRoom = (roomNumber, newLastName) => {
      console.log("updateLastNamesInRoom");

      // Check if passengerInfo is not empty and has good structure
      if (
        passengerInfo.length > roomNumber &&
        Array.isArray(passengerInfo[roomNumber]) &&
        passengerInfo[roomNumber].length > 0
      ) {
        // Update last names for each passenger in the specified room
        passengerInfo[roomNumber].forEach((passenger) => {
          passenger.lastname = newLastName;
        });

        // Update the state with the modified passengerInfo
        setPassengerInfo([...passengerInfo]);
      }
    };

    // Apply the function
    updateLastNamesInRoom(roomNumber, dupLastName);
  };

  return (
    <div className="roomPassInfo-component">
      <div className="roomPassInfo-component-item">
        {/* Room Header */}
        <div className="roomPassInfo-main-title-wrapper">
          <div className="roomPassInfo-title-img-wrapper">
            <img
              className="roomPassInfo-title-img"
              src={roomImg}
              alt="Room"
            ></img>
          </div>

          <div className="roomPassInfo-hotel-name-wrapper">
            <label className="roomPassInfo-hotel-name">{roomName}</label>
          </div>
        </div>

        {/* Title */}
        <div className="roomPassInfo-title-wrapper">
          <div className="roomPassInfo-border"></div>
          <label className="roomPassInfo-title">
            {t("completepassengers.room")} {roomNumber + 1}
          </label>
          <div className="roomPassInfo-border"></div>
        </div>

        {/* All passengers */}
        <div id={`room-number-${roomNumber}`}>
          {/* First Passanger - Main Passanger */}
          {roomNumber + 1 === 1 && (
            <div>
              <div className="roomPassInfo-passenger">
                <div className="roomPassInfo-passenger-info-button-wrapper">
                  <label className="roomPassInfo-passenger-info-title">
                    {t("completepassengers.passangernum")} 1 |{" "}
                    {t("completepassengers.contanctman")}
                  </label>

                  {/* Duplicate Family Name */}
                  <button
                    onClick={duplicateFamilyName}
                    className="roomPassInfo-passenger-info-button"
                  >
                    {t("completepassengers.duplicatelastname")}
                  </button>
                </div>

                <div className="roomPassInfo-passenger-info-wrapper">
                  <div className="roomPassInfo-passenger-info-item">
                    <InputPassenger
                      id={"firstname"}
                      title={t("completepassengers.firstname")}
                      type={"text"}
                      passengerNum={1}
                      roomNumber={roomNumber}
                    />
                    <InputPassenger
                      id={"lastname"}
                      title={t("completepassengers.lastname")}
                      type={"text"}
                      passengerNum={1}
                      roomNumber={roomNumber}
                    />
                  </div>

                  <div className="roomPassInfo-passenger-info-item">
                    <InputPassenger
                      id={"cell"}
                      title={t("completepassengers.cell")}
                      type={"text"}
                      passengerNum={1}
                      roomNumber={roomNumber}
                    />
                    <InputPassenger
                      id={"email"}
                      title={t("completepassengers.email")}
                      type={"email"}
                      passengerNum={1}
                      roomNumber={roomNumber}
                    />
                  </div>
                </div>
              </div>

              <label className="roomPassInfo-main-passenger-info-note">
                {t("completepassengers.contanctmannote")}
              </label>
            </div>
          )}

          {/* Rest Of passengers */}
          <div className="roomPassInfo-rest-of-passengers">
            {/* Rest Of the Adults */}
            {roomInfo &&
              roomInfo.AdultsCount &&
              Array.from(
                {
                  length:
                    roomNumber + 1 === 1
                      ? roomInfo.AdultsCount - 1
                      : roomInfo.AdultsCount,
                },
                (
                  _,
                  index // minus 1 because we already rendered the main adult
                ) => (
                  <div key={roomNumber + 1 === 1 ? index + 2 : index + 1}>
                    <div className={"roomPassInfo-duplicate-lastname-room2etc"}>
                      <label className="roomPassInfo-passenger-info-title">
                        {t("completepassengers.passangernum")}{" "}
                        {roomNumber + 1 === 1 ? index + 2 : index + 1}
                      </label>

                      {/* Duplicate Family Name - for first passenger only and for the second room and forward */}
                      {roomNumber + 1 > 1 && index + 1 === 1 && (
                        <button
                          onClick={duplicateFamilyName}
                          className="roomPassInfo-passenger-info-button"
                        >
                          {t("completepassengers.duplicatelastname")}
                        </button>
                      )}
                    </div>

                    <div className="roomPassInfo-passenger-info-wrapper">
                      <div className="roomPassInfo-passenger-info-item">
                        <InputPassenger
                          id={"firstname"}
                          title={t("completepassengers.firstname")}
                          type={"text"}
                          passengerNum={
                            roomNumber + 1 === 1 ? index + 2 : index + 1
                          }
                          roomNumber={roomNumber}
                        />
                      </div>

                      <div className="roomPassInfo-passenger-info-item">
                        <InputPassenger
                          id={"lastname"}
                          title={t("completepassengers.lastname")}
                          type={"text"}
                          passengerNum={
                            roomNumber + 1 === 1 ? index + 2 : index + 1
                          }
                          roomNumber={roomNumber}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}

            {/* Kids */}
            {roomInfo &&
              roomInfo.KidsAges &&
              roomInfo.KidsAges.map((kid, index) => (
                <div key={index + 1 + roomInfo.AdultsCount}>
                  <label className="roomPassInfo-passenger-info-title">
                    {t("completepassengers.passangernum")}{" "}
                    {index + 1 + roomInfo.AdultsCount} -{" "}
                    {t("completepassengers.kid")} ({kid})
                  </label>

                  <div className="roomPassInfo-passenger-info-wrapper">
                    <div className="roomPassInfo-passenger-info-item">
                      <InputPassenger
                        id={"firstname"}
                        title={t("completepassengers.firstname")}
                        type={"text"}
                        passengerNum={index + 1 + roomInfo.AdultsCount}
                        roomNumber={roomNumber}
                      />
                    </div>

                    <div className="roomPassInfo-passenger-info-item">
                      <InputPassenger
                        id={"lastname"}
                        title={t("completepassengers.lastname")}
                        type={"text"}
                        passengerNum={index + 1 + roomInfo.AdultsCount}
                        roomNumber={roomNumber}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomPassInfo;
