import React, { useState, useEffect } from "react";

//MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

// Languages
import { useTranslation } from "react-i18next";

// Services
import { convertPrice } from "../../../../services/currencyService";
import { getHotelsImage } from "../../../../services/fhpService";

// React Icons
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { RiHotelFill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { FaChildren } from "react-icons/fa6";
import { IoBedSharp } from "react-icons/io5";
import { FaRegClock } from "react-icons/fa";
import { FaCalendar } from "react-icons/fa";
import { FaBed } from "react-icons/fa6";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoMdAddCircleOutline } from "react-icons/io";

// Images
import NoHotels from "../../../../assets/images/icons/hotel.png";

// Components
import StarRating from "../../../StarRating/starrating";
import ImagesCarousel from "../../../ImagesCrousel/ImagesCarousel";
import { useOrder } from "../../../../contexts/OrderContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const HotelDisplay = ({
  hotelName,
  hotelID,
  hotelAddress,
  date,
  numberOfNights,
  totalRooms,
  roomName,
  rating,
  passengers,
  rooms,
  editInfo,
  basis,
  freeCancelation,
  dateOfCancelation,
  includestaxes,
  images,
  policies,
  policyRemarks,
  price,
}) => {
  const { currentHotelOrder } = useOrder();

  // Translator
  const { t } = useTranslation();

  // State
  const [isExpend, setIsExpend] = useState(true);
  const [hotelImage, setHotelImage] = useState(false);
  const [isImportantDataOpen, setIsImportantDataOpen] = useState(false);
  const [adults, setAdults] = useState(0);
  const [kids, setKids] = useState(0);

  // Get hotel image
  useEffect(() => {
    const getHotel = async () => {
      // Get hotel image
      const dataImg = {
        hotelID: hotelID,
      };

      const resImage = await getHotelsImage(dataImg);

      if (resImage && resImage.data) {
        setHotelImage(resImage.data.image);
      }
    };

    if (hotelID) {
      getHotel();
    }
  }, [hotelID]);

  // Get number of adults and kids
  useEffect(() => {
    let sortedArray = [];

    // If its info comes form back alter it if not alter it diffrently
    if (editInfo) {
      const transformedData = passengers.map((item) => {
        const { PersonDetails, Id } = item;
        const { Age } = PersonDetails;
        const { GivenName, Surname } = PersonDetails.Name;

        return {
          firstname: GivenName,
          lastname: Surname,
          isKid: Age ? true : false,
        };
      });

      // Sort the array so that kids come last
      sortedArray = transformedData.sort((a, b) =>
        a.isKid && !b.isKid ? 1 : -1
      );
    } else {
      const transformedArray = passengers.flatMap((room, roomIndex) => {
        return room.map((passenger) => {
          return {
            firstname: passenger.firstname,
            lastname: passenger.lastname,
            isKid: passenger.isKid,
          };
        });
      });

      // Sort the array so that kids come last
      sortedArray = transformedArray.sort((a, b) =>
        a.isKid && !b.isKid ? 1 : -1
      );
    }

    // Count adult and kids
    let adultsCount = 0;
    let kidsCount = 0;

    sortedArray.forEach((passenger) => {
      if (passenger.isKid) {
        kidsCount++;
      } else {
        adultsCount++;
      }
    });

    setAdults(adultsCount);
    setKids(kidsCount);
  }, [passengers, editInfo]);

  // Triggers
  const handleExpend = () => {
    setIsExpend(!isExpend);
  };

  return (
    <>
      <div className="hotelDisplay-wrapper">
        <div className="hotelDisplay-title-wrapper">
          <label className="hotelDisplay-title">
            {t("ordersummery.hotel")}
          </label>
          <RiHotelFill className="hotelDisplay-title-icon" />
        </div>

        <div className="hotelDisplay-info-wrapper">
          {/* Img */}
          <div className="hotelDisplay-info-img-wrapper">
            {hotelImage ? (
              <img
                src={hotelImage}
                alt="Booked Hotel Image"
                className="hotelDisplay-info-img"
              ></img>
            ) : (
              <div className="hotelDisplay-">loading</div>
            )}

            {!hotelImage && (
              <img
                src={NoHotels}
                alt="No Hotel Image"
                className="hotelDisplay-info-img"
              ></img>
            )}
          </div>

          {/* Info */}
          <div className="hotelDisplay-info-content-wrapper">
            <div className="hotelDisplay-info-content">
              {/* Star Rating */}
              <StarRating rating={rating} />

              <label className="hotelDisplay-info-content-title">
                {hotelName}
              </label>
              <label className="hotelDisplay-info-content-sub-title">
                {hotelAddress}
              </label>

              <div
                className={`ci-summery-hotel-info-wrapper hotelDisplay-info-content-date-wrapper ${
                  document.documentElement.dir === "rtl"
                    ? "direction-rtl"
                    : "direction-ltr"
                } `}
              >
                <div className="ci-summery-hotel-info-nights">
                  <label className="ci-summery-hotel-nights-title">
                    {t("hotelprofile.nights")}:
                  </label>
                  <label className="ci-summery-hotel-nights-value">
                    {numberOfNights}
                  </label>
                </div>

                <label className="ci-summery-hotel-dates">{date}</label>
              </div>
            </div>

            <div className="hotelDisplay-info-content-expend-wrapper">
              {!isExpend ? (
                <IoIosArrowUp
                  onClick={handleExpend}
                  className="hotelDisplay-info-content-expend-button"
                />
              ) : (
                <IoIosArrowDown
                  onClick={handleExpend}
                  className="hotelDisplay-info-content-expend-button"
                />
              )}
            </div>
          </div>
        </div>

        {isExpend && (
          <div className="hotelDisplay-expend">
            {/* Content */}
            <div className="hotelDisplay-expend-wrapper">
              {/* Check In | Check Out */}
              <div className="hotelDisplay-expend-check-wrapper">
                {/* Check In */}
                <div className="hotelDisplay-expend-check-item">
                  <div className="hotelDisplay-expend-check-item-1">
                    <label className="hotelDisplay-expend-check-item-1-title">
                      {t("successorder.checkin")}
                    </label>
                    <IoBedSharp className="hotelDisplay-expend-room-item-icon hotelDisplay-expend-check-item-icon" />
                  </div>

                  <div className="hotelDisplay-expend-check-item-2">
                    <label className="hotelDisplay-expend-check-item-2-title">
                      {date.split("-")[0]}
                    </label>
                    <FaCalendar className="hotelDisplay-expend-check-item-icon" />
                  </div>

                  <div className="hotelDisplay-expend-check-item-3">
                    <label className="hotelDisplay-expend-check-item-3-title">
                      {t("hotelprofile.startsat")} {"14:00"}
                    </label>
                    <FaRegClock className="hotelDisplay-expend-room-item-icon hotelDisplay-expend-check-item-icon" />
                  </div>
                </div>

                <div className="hotelDisplay-expend-border-v"></div>

                {/* Check Out */}
                <div className="hotelDisplay-expend-check-item">
                  <div className="hotelDisplay-expend-check-item-1">
                    <label className="hotelDisplay-expend-check-item-1-title">
                      {t("successorder.checkout")}
                    </label>
                    <IoBedSharp className="hotelDisplay-expend-room-item-icon hotelDisplay-expend-check-item-icon" />
                  </div>

                  <div className="hotelDisplay-expend-check-item-2">
                    <label className="hotelDisplay-expend-check-item-2-title">
                      {date.split("-")[1]}
                    </label>
                    <FaCalendar className="hotelDisplay-expend-check-item-icon" />
                  </div>

                  <div className="hotelDisplay-expend-check-item-3">
                    <label className="hotelDisplay-expend-check-item-3-title">
                      {t("completepassengers.endsat")} {"12:00"}
                    </label>
                    <FaRegClock className="hotelDisplay-expend-room-item-icon hotelDisplay-expend-check-item-icon" />
                  </div>
                </div>
              </div>

              <div className="hotelDisplay-expend-border-h"></div>

              {/* Room Basis */}
              <div className="hotelDisplay-expend-basis-wrapper">
                <div className="hotelDisplay-expend-basis">
                  <label className="hotelDisplay-expend-basis-title">
                    {basis}
                  </label>
                  <FaBed className="hotelDisplay-expend-basis-icon" />
                </div>

                <div className="hotelDisplay-expend-cancel">
                  <label>
                    {freeCancelation ? (
                      <span className="hotelDisplay-expend-cancel-title">{`${t(
                        "completepassengers.freecancelation"
                      )} ${dateOfCancelation}`}</span>
                    ) : (
                      <span className="hotelDisplay-expend-cancel-title-red">
                        {t("completepassengers.nofreecancelation")}
                      </span>
                    )}
                  </label>
                  <IoMdCloseCircleOutline className="hotelDisplay-expend-cancel-icon" />
                </div>

                <div
                  className="hotelDisplay-expend-important-info"
                  onClick={() => {
                    setIsImportantDataOpen(true);
                  }}
                >
                  <label className="hotelDisplay-expend-important-info-title">
                    {t("completepassengers.importantinfo")}
                  </label>
                  <IoMdAddCircleOutline className="hotelDisplay-expend-important-info-icon" />
                </div>
              </div>
              <Modal
                open={isImportantDataOpen}
                onClose={() => {
                  setIsImportantDataOpen(false);
                }}
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {t("completepassengers.policycanclationhotel")}
                  </Typography>
                  <Typography>
                    <div
                      className="modal-cancelation-policy-remarks"
                      style={{ maxHeight: "400px", overflow: "auto" }}
                      dangerouslySetInnerHTML={{
                        __html: policyRemarks,
                      }}
                    />
                    {policies?.length > 0 ? (
                      <div className="modal-cancelation-entrys">
                        {policies.map((cancelRowData, index) => (
                          <div key={index}>
                            <label className="passangerData-cancelation-entry">
                              {t("completepassengers.startsat")}{" "}
                              {cancelRowData.from}{" "}
                              {t("completepassengers.feesare")}{" "}
                              {
                                <label id="price-fee">
                                  {convertPrice(
                                    price.priceWithCommissionAndCreditCardFee
                                  )}
                                </label>
                              }
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="passangerData-cancelation-empty">
                        No Canclation Policys Available
                      </div>
                    )}
                  </Typography>
                </Box>
              </Modal>

              <div className="hotelDisplay-expend-border-h"></div>

              {/* Rooms Data */}
              <div className="hotelDisplay-expend-room-wrapper">
                <div className="hotelDisplay-expend-room-content">
                  <div className="hotelDisplay-expend-room-item">
                    <label className="ci-summery-hotel-room-name">
                      {roomName}
                      {totalRooms > 1 ? (
                        <span>
                          (
                          <span className="ci-summery-hotel-room-name-multiple">
                            X{totalRooms}
                          </span>
                          )
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <IoBedSharp className="hotelDisplay-expend-room-item-icon" />
                  </div>

                  <div className="hotelDisplay-expend-room-item">
                    <label style={{ display: "flex", gap: "2px" }}>
                      <span>
                        (
                        <span className="ci-summery-hotel-room-name-multiple">
                          {adults ? adults : rooms ? rooms[0].AdultsCount : 0}
                        </span>
                        )
                      </span>
                      {t("hotelsearchbar.adults")}
                    </label>
                    <FaUsers className="hotelDisplay-expend-room-item-icon" />
                  </div>

                  <div className="hotelDisplay-expend-room-item">
                    <label style={{ display: "flex", gap: "2px" }}>
                      <span>
                        (
                        <span className="ci-summery-hotel-room-name-multiple">
                          {kids}
                        </span>
                        )
                      </span>
                      {t("hotelsearchbar.kids")}
                    </label>
                    <FaChildren className="hotelDisplay-expend-room-item-icon" />
                  </div>
                </div>
              </div>
              <div className="hotelDisplay-expend-room-img-wrapper">
                <ImagesCarousel images={images} />
              </div>
            </div>

            <div className="hotelDisplay-expend-border-h"></div>

            {/* Pricing */}
            <div className="hotelDisplay-expend-pricing-wrapper">
              <div className="hotelDisplay-expend-pricing-text">
                <label className="hotelDisplay-expend-pricing-title">
                  {t("filters.price")} {t("hotelprofile.for")} {totalRooms}
                  {t("hotelprofile.rooms")}, {numberOfNights}
                  {t("hotelprofile.nights")}
                </label>
                <label className="hotelDisplay-expend-pricing-sub-title">
                  {includestaxes
                    ? t("hotelprofile.includetaxes")
                    : t("hotelprofile.notincludetaxes")}
                </label>
              </div>

              <label
                id="pri-hotel"
                className="hotelDisplay-expend-pricing-price"
              >
                {convertPrice(
                  parseFloat(currentHotelOrder.ourPrice.priceWithCreditCardFee)
                )}
              </label>
            </div>

            <div className="hotelDisplay-expend-border-h"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default HotelDisplay;
