import React, { useState, useEffect } from "react";

// Icons
import { TfiMore } from "react-icons/tfi";
import { IoIosCloudyNight, IoIosBed } from "react-icons/io";
import { FaPeopleGroup } from "react-icons/fa6";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./hotelinfo.css";

// Services
import { checkInInfo, getHotelsImage } from "../../../../services/fhpService";
import { convertPrice } from "../../../../services/currencyService";
import { parsePriceToShow } from "../../../../utils/OrderService";
import { useOrder } from "../../../../contexts/OrderContext";

const HotelInfo = ({ hotelInfo }) => {
  const { currentHotelOrder } = useOrder();
  const { t } = useTranslation();

  const [hotelDetails, setHotelDetails] = useState(null);
  const [hotelImages, setHotelImages] = useState(null);
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [totalAdults, setTotalAdults] = useState("");
  const [totalKids, setTotalKids] = useState("");
  const [numberOfNights, setNumberOfNights] = useState("");
  const [showFullRoom, setShowFullRoom] = useState(false);

  useEffect(() => {
    const searchData = localStorage.getItem("currentSearchPhrase");
    // Incase from original ordersummery
    if (
      currentHotelOrder &&
      searchData &&
      searchData.length > 0 &&
      (hotelInfo === null || hotelInfo === undefined)
    ) {
      saveCurrentHotel(currentHotelOrder, JSON.parse(searchData));
    }

    // Incase from upcomingorders - Rearrange data so it fits the functions
    if (hotelInfo) {
      // Create the selectedHotel object
      const selectedHotel = {
        HotelName: hotelInfo.hotelname,
      };

      // Initialize an object to store room names and their counts
      const roomCounts = {};

      // Iterate through the rooms and count duplicates
      hotelInfo.rooms.forEach((room) => {
        const roomName = room.roomname;
        if (roomCounts[roomName]) {
          roomCounts[roomName]++;
        } else {
          roomCounts[roomName] = 1;
        }
      });

      // Create the selectedHotelPackage object
      const roomNames = [];

      // Populate the selectedHotelPackage array with [name, count] arrays
      for (const roomName in roomCounts) {
        const count = roomCounts[roomName];
        roomNames.push([roomName, count]);
      }

      // Create the selectedHotelPackage object
      const selectedHotelPackage = {
        roomNames: roomNames,
      };

      // Create the searchPhrase object
      const searchPhrase = {
        hotelID: hotelInfo.hotelid,
      };

      // Combine everything together
      const hotelInfoFixed = {
        selectedHotelPackage,
        selectedHotel,
        searchPhrase,
      };

      // Save the rearraged data
      setHotelDetails(hotelInfoFixed);

      // Extract order info
      extractDataFromOrderInfo(hotelInfo);

      // Get hotel image
      setHotelImage(hotelInfoFixed);
    }
  }, []);

  // useEffect(() => {
  //   console.log({selectedRoomInfo})
  //   if (selectedRoomInfo.length === 0) {
  //     setHotelDetails(null);
  //   }
  // }, [selectedRoomInfo]);

  // Check if storage changed to refresh data
  useEffect(() => {
    function handleStorageChange() {
      const searchData = localStorage.getItem("currentSearchPhrase");
      if (currentHotelOrder !== hotelDetails) {
        saveCurrentHotel(currentHotelOrder, JSON.parse(searchData));
      }
    }

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // --- Functions

  const saveCurrentHotel = (hotelData, searchData) => {
    setHotelDetails(hotelData);
    extractDataFromSearch(searchData);
    setHotelImage(hotelData);
  };

  // Get Rooms | Adults Amount | Kids Amount | Nights Amount - current Search Order
  const extractDataFromSearch = (data) => {
    const { rooms } = data;
    if (rooms && rooms.length > 0) {
      let totalAdults = 0;
      let totalKids = 0;
      let totalRooms = rooms.length;

      rooms.forEach((room) => {
        totalAdults += room.AdultsCount;
        if (room.KidsAges && room.KidsAges.length > 0) {
          totalKids += room.KidsAges.length;
        }
      });

      // Get number of nights
      const checkInParts = data.checkIn.split("/");
      const checkOutParts = data.checkOut.split("/");

      // Create Date objects using parts in the format: year, month - 1, day
      const checkInDate = new Date(
        checkInParts[2],
        checkInParts[1] - 1,
        checkInParts[0]
      );
      const checkOutDate = new Date(
        checkOutParts[2],
        checkOutParts[1] - 1,
        checkOutParts[0]
      );

      // Calculate the time difference in milliseconds
      const timeDifference = checkOutDate - checkInDate;

      // Convert milliseconds to days
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      setCheckIn(data.checkIn);
      setCheckOut(data.checkOut);
      setTotalAdults(totalAdults);
      setTotalKids(totalKids);
      setNumberOfNights(numberOfNights);
    }
  };

  // Get Rooms | Adults Amount | Kids Amount | Nights Amount - current order in upComingOrders
  const extractDataFromOrderInfo = (data) => {
    const { rooms } = data;

    if (rooms && rooms.length > 0) {
      let totalAdults = 0;
      let totalKids = 0;
      let totalRooms = rooms.length;

      rooms.forEach((room) => {
        totalAdults += room.adultscount;
        if (room.kidsages && room.kidsages.length > 0) {
          totalKids += room.kidsages.length;
        }
      });

      // Get number of nights
      const checkInParts = data.checkin.split("/");
      const checkOutParts = data.checkout.split("/");

      // Create Date objects using parts in the format: year, month - 1, day
      const checkInDate = new Date(
        checkInParts[2],
        checkInParts[1] - 1,
        checkInParts[0]
      );
      const checkOutDate = new Date(
        checkOutParts[2],
        checkOutParts[1] - 1,
        checkOutParts[0]
      );

      // Calculate the time difference in milliseconds
      const timeDifference = checkOutDate - checkInDate;

      // Convert milliseconds to days
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      setCheckIn(data.checkin);
      setCheckOut(data.checkout);
      setTotalAdults(totalAdults);
      setTotalKids(totalKids);
      setNumberOfNights(numberOfNights);
    }
  };

  // Get main hotel image
  const setHotelImage = async (hotelData) => {
    // Get hotel image
    const dataImg = {
      hotelID: hotelData.searchPhrase.hotelID,
    };

    const image = await getHotelsImage(dataImg);
    setHotelImages(image.data.image);
  };

  const showFullName = () => {
    setShowFullRoom(!showFullRoom);
  };

  // Remove any duplicates and add the adults counter and kids counter to the origin room
  const combineSimilarRooms = (inputJSON) => {
    if (inputJSON.Rooms && Array.isArray(inputJSON.Rooms)) {
      const roomInfoCount = {};
      const updatedRooms = {};

      // Count room info
      for (const room of inputJSON.Rooms) {
        const roomInfoKey = `${room.RoomName}_${inputJSON.PackagePrice.FinalPrice}`;
        roomInfoCount[roomInfoKey] = roomInfoCount[roomInfoKey] || {
          count: 0,
          adultscount: 0,
          kidcount: 0,
        };
        roomInfoCount[roomInfoKey].count++;
        roomInfoCount[roomInfoKey].adultscount += room.adultscount;
        roomInfoCount[roomInfoKey].kidcount += room.kidsages.length;
      }

      // Create an array to keep track of unique rooms
      const uniqueRooms = [];

      // Loop through the rooms again to find unique ones and combine counts
      for (const room of inputJSON.Rooms) {
        const roomInfoKey = `${room.RoomName}_${inputJSON.PackagePrice.FinalPrice}`;
        const roomInfo = roomInfoCount[roomInfoKey];

        if (roomInfo.count === 1) {
          // Unique room, add it to uniqueRooms
          uniqueRooms.push({
            ...room,
            RoomContent: {
              HotelName: inputJSON.hotelname,
            },
            SimilarRooms: undefined,
          });
        } else if (!roomInfo.combined) {
          // First occurrence of a duplicate room
          uniqueRooms.push({
            ...room,
            RoomContent: {
              HotelName: inputJSON.hotelname,
            },
            SimilarRooms: roomInfo.count,
            adultscount: roomInfo.adultscount,
            kidsages: new Array(roomInfo.kidcount).fill(0),
          });
          roomInfo.combined = true;
        }
      }

      // Save the changes
      inputJSON.Rooms = uniqueRooms;
    }

    return inputJSON;
  };
  return (
    <>
      <div className="hotelInfo-component">
        {hotelDetails ? (
          <div className="hotelInfo-wrapper">
            {/* Title */}
            <label className="hotelInfo-title">
              <span className="hotelInfo-title-text">
                {t("hotelinfo.hotel")}:
              </span>
              <span className="hotelInfo-title-value">
                {hotelDetails.selectedHotel.HotelName}
              </span>
            </label>

            {/* Content */}
            <div className="hotelInfo-content">
              {/* Hotel Image */}
              <div className="hotelInfo-img-wrapper">
                <img
                  className="hotelInfo-img"
                  src={hotelImages}
                  alt="Hotel image"
                />
              </div>

              {/* Hotel Info */}
              <div className="hotelInfo-info-wrapper ">
                <div className="hotelInfo-info-wrapper-item">
                  <div className="hotelInfo-info-wrapper-item-item">
                    <div className="hotelInfo-info-wrapper-item-item-title-wrapper">
                      <IoIosBed />
                      <label className="hotelInfo-info-wrapper-item-item-title">
                        {t("hotelinfo.rooms")}
                      </label>
                    </div>

                    {/* <label className='hotelInfo-more-rooms' onClick={showFullName}>
                                            <TfiMore />
                                        </label> */}

                    {/* {showFullRoom && ( */}
                    {/* <div className='hotelInfo-content-room-name' onClick={showFullName}> */}
                    {/*<div>*/}
                    {/* <span className='hotelInfo-info-wrapper-item-item-title'>{t('hotelinfo.rooms')}</span> */}

                    {hotelDetails.selectedHotelPackage.roomNames.map(
                      (room, index) => (
                        // <div key={index} className="">
                        <span
                          key={index}
                          className="hotelInfo-info-wrapper-item-item-value hotelInfo-info-wrapper-item-item-value-room-name"
                        >
                          {/* <span> {index + 1}. &nbsp;</span> */}
                          <span className="direction-ltr">
                            {room.SimilarRooms && (
                              <span className="hotelInfo-info-wrapper-item-similar">
                                {
                                  hotelDetails.selectedHotelPackage.roomNames
                                    .length
                                }
                                x
                              </span>
                            )}
                            <span> {room} </span>
                          </span>
                        </span>
                        // </div>
                      )
                    )}
                  </div>
                  {/* )} */}
                  {/*</div>*/}

                  <div className="hotelInfo-info-wrapper-item-item">
                    <div className="hotelInfo-info-wrapper-item-item-title-wrapper">
                      <FaPeopleGroup />
                      <label className="hotelInfo-info-wrapper-item-item-title">
                        {t("hotelinfo.guests")}
                      </label>
                    </div>

                    <label className="hotelInfo-info-wrapper-item-item-value">
                      {totalAdults} {t("hotelsearchbar.adults")}
                      {totalKids > 0
                        ? `${totalKids} ${t("hotelsearchbar.kids")}`
                        : ""}
                    </label>
                  </div>
                  <div className="hotelInfo-info-wrapper-item-item">
                    <div className="hotelInfo-info-wrapper-item-item-title-wrapper">
                      <label className="hotelInfo-info-wrapper-item-item-title">
                        {t("hotelinfo.checkin")}
                      </label>
                    </div>

                    <label className="hotelInfo-info-wrapper-item-item-value">
                      {checkIn}
                    </label>
                  </div>
                  <div className="hotelInfo-info-wrapper-item-item">
                    <div className="hotelInfo-info-wrapper-item-item-title-wrapper">
                      <label className="hotelInfo-info-wrapper-item-item-title">
                        {t("hotelinfo.checkout")}
                      </label>
                    </div>

                    <label className="hotelInfo-info-wrapper-item-item-value">
                      {checkOut}
                    </label>
                  </div>
                </div>

                <div className="hotelInfo-info-wrapper-item">
                  <div className="hotelInfo-info-wrapper-item-item">
                    <div className="hotelInfo-info-wrapper-item-item-title-wrapper">
                      <IoIosCloudyNight />
                      <label className="hotelInfo-info-wrapper-item-item-title">
                        {t("hotelinfo.numberofnights")}
                      </label>
                    </div>

                    <label className="hotelInfo-info-wrapper-item-item-value  hotelInfo-info-wrapper-item-item-title-numOfNights">
                      {numberOfNights}
                    </label>
                  </div>

                  {/* Hotel Pricing */}
                  <div className="hotelInfo-pricing-wrapper">
                    <label className="hotelInfo-pricing-title">
                      {t("hotelinfo.paymentsummery")}
                    </label>
                    <label
                      id="price-hotel-info"
                      className="hotelInfo-pricing-sub-title"
                    >
                      {hotelDetails
                        ? convertPrice(
                            parsePriceToShow(
                              hotelDetails.selectedHotelPackage.finalPrice
                            )
                          )
                        : "err"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="hotelInfo-empty-order">
            {t("hotelinfo.nohotelselected")}
          </div>
        )}
      </div>
    </>
  );
};

export default HotelInfo;
