import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./login.scss";

// Login
import GoogleLogin from "../../../Components/GoogleLogin/googlelogin";
import FacebookLogin from "../../../Components/FacebookLogin/facebooklogin";

// Services
import {
  login,
  getUserDetails,
} from "../../../services/authService";
import { ROUTES } from "../../../Routes/Routes";
import { useAppContext } from "../../../contexts/AppContext";

function Login(props) {
  // States
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const { appLogin } = useAppContext();

  // Translator
  const { t } = useTranslation();

  // Navigator
  const navigate = useNavigate();

  // Save data recived from Google
  const [googleData, setGoogleData] = useState("");
  const handleGoogleDataChange = (googleData) => {
    setGoogleData(googleData);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  // Add event listner to the enter key
  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        document.getElementById("login-submit").click();
      }
    };

    document.addEventListener("keyup", handleEnterKey);

    return () => {
      document.removeEventListener("keyup", handleEnterKey);
    };
  }, []);

  // Handle submit - send api request to the backend
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.length === 0 || password.length === 0) {
      setError(t("login.error1"));
    } else {
      // Create data
      let data = {
        email: email,
        password: password,
        externalID: "",
      };

      // Authonticate
      let res = await login(data);

      if (res.status === "ok") {
        const user = getUserDetails();
        appLogin(user);
        let userType = user.usertype;

        // Redirect user accordnigly to his type
        if (userType === "clients") {
          navigate(`/p`);
        } else if (userType === "agents") {
          navigate(`/p/dashboard/home`);
        } else if (userType === "administrators") {
          navigate(`/p/administration`);
        } else {
          // pass
        }
      } else {
        // Error handler
        setError(t("login.error2"));
      }
    }
  };

  return (
    <>
      <div className=" ">
        <form className="login-form">
          <div className="login-form-title">{t("login.title")}</div>

          {/* Inputs */}
          <div className="login-input-wrapper">
            <div className="login-input-wrapper login-input-padding-wrapper">
              <label className="login-input-label">
                {t("login.emailLabel")}:{" "}
              </label>
              <input
                name="email"
                type="email"
                className="login-input"
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
            </div>

            <div className="login-input-wrapper">
              <label className="login-input-label">
                {t("login.passwordLabel")}:
              </label>

              <div className="login-password-input-container">
                <input
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="login-input"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></input>

                <button
                  type="button"
                  onClick={handleTogglePassword}
                  className="login-password-toggle-button"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
          </div>

          {/* Info */}
          <div className="login-info-wrapper">
            <div className="login-text">
              {t("login.noAccountText")}{" "}
              <Link className="link-login" to="/registeragent">
                {t("login.signUpLinkText")}
              </Link>
            </div>
          </div>

          {/* Submit button  */}
          <div className="login-submit-wrapper">
            <button
              type="submit"
              id="login-submit"
              className="login-submit-button"
              onClick={handleSubmit}
            >
              {t("login.loginButton")}
            </button>
          </div>

          {/* Error handler */}
          {error ? (
            <div id="login-error" className="login-error">
              {error}
            </div>
          ) : null}

          <div style={{ marginTop: 15 }}>
            <div className="login-text">{t("login.orLoginWithText")}</div>
            <div className="google-facebook-login">
              <GoogleLogin
                googleData={googleData}
                onGoogleDataChange={handleGoogleDataChange}
              />
              <FacebookLogin />
            </div>
          </div>
          {/* Agree Terms */}
          <div className="login-terms">
            {t("login.agreeTermsText")}{" "}
            <Link className="link-login" to={`/${ROUTES.TERMS_AND_CONDITIONS}`}>
              {t("login.termsLinkText")}
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
