import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import "./Filters.scss";

const TextFilter = ({ onFilterChange, filterName, placeHolder, value }) => {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const changeExpand = () => {
    setIsExpand((prevState) => !prevState);
    onFilterChange(internalValue);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInternalValue(newValue);
    onFilterChange(newValue);
  };

  const inputValue = internalValue !== undefined ? internalValue : "";

  return (
    <div className="hotelFilters-filter-wrapper">
      <div
        onClick={changeExpand}
        className={`hotelFilters-filters-title-wrapper ${
          document.documentElement.dir === "ltr"
            ? "direction-rtl"
            : "direction-ltr"
        }`}
      >
        <span className="hotelFilters-filters-title">{filterName}</span>
        {isExpand ? (
          <IoIosArrowUp className="hotelFilters-filters-arrow" />
        ) : (
          <IoIosArrowDown className="hotelFilters-filters-arrow" />
        )}
      </div>

      {isExpand && (
        <div className="hotelfilters-price-wrapper">
          <input
            id="hotelName"
            className={`hotelfilters-name-filter-input ${
              document.documentElement.dir === "rtl"
                ? "direction-rtl"
                : "direction-ltr"
            }`}
            onChange={handleInputChange}
            placeholder={t("filters.hotelname")}
            value={inputValue}
          />
        </div>
      )}
    </div>
  );
};

export default TextFilter;
