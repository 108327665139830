import React from "react";
import "./Buttons.scss";
import classnames from "classnames";

export const Button = ({ children, ...props }) => {
  return (
    <button className={"button"} {...props}>
      {children}
    </button>
  );
};

export const OrangeButton = ({
  className = "",
  children,
  disabled,
  onClick,
  ...props
}) => {
  if (disabled) {
    className += " disabled";
  }
  return (
    <button
      className={classnames(
        "button1 tw-bg-[#fe3702] tw-text-center tw-mx-auto",
        className
      )}
      onClick={disabled ? null : onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export const OrangeButtonNoWidth = ({
  className = "",
  children,
  disabled,
  onClick,
  ...props
}) => {
  if (disabled) {
    className += " disabled";
  }
  return (
    <button
      className={classnames(
        "button1 tw-bg-[#fe3702]  tw-text-center tw-mx-auto",
        className
      )}
      onClick={disabled ? null : onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export const BlueButton = ({ children, disabled, onClick, ...props }) => {
  return (
    <button
      className={classnames("button blue-button", { disabled })}
      onClick={disabled ? null : onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export const TransparentButton = ({
  children,
  disabled,
  onClick,
  ...props
}) => {
  return (
    <button
      className={classnames("button transparent-button", { disabled })}
      onClick={disabled ? null : onClick}
      {...props}
    >
      {children}
    </button>
  );
};
