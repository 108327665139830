import React from 'react';
import "./Titles.scss"

export const RegistrationTitle = ({title}) => {
    return (
        <div className={"registration-title-container"}>
            <div className={"registration-title"}>{title}</div>
            {title ? <div className={"registration-title-line"}/> : null}
        </div>
    );
};