import React from 'react';
import RegistrationBox from "../RegistrationBox";
import {FormFields} from "../../../contexts/RegistrationContext";
import { OrangeRegistrationInput} from "../Inputs";

const PrivateDetails = ({missingFields}) => {

    const inputs = [
        FormFields.PERSONAL_INFO.FIRST_NAME,
        FormFields.PERSONAL_INFO.LAST_NAME,
        FormFields.PERSONAL_INFO.EMAIL,
        FormFields.PERSONAL_INFO.CELL,
        FormFields.PERSONAL_INFO.COUNTRY,
    ]

    return (
        <RegistrationBox title="פרטים אישיים">
            {inputs.map((key) => <OrangeRegistrationInput registrationKey={key}/>)}
        </RegistrationBox>
    );
};

export default PrivateDetails;