import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// Routes Info
import routes from "../../../../locales/routes.json";

const RouteLocationDisplayer = () => {
  // Translator
  const { t } = useTranslation();

  // States
  const [routeLabel, setRouteLabel] = useState("");
  const [routeLink, setRouteLink] = useState("");

  // Useffects
  useEffect(() => {
    const currentRoute = window.location.pathname.split("/");
    const currentLanguage = localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "he";

    recognaizeRoute(currentRoute[1], currentLanguage);
  });

  // --- Functions
  const recognaizeRoute = (currentRoute, currentLanguage) => {
    // Get all routes
    const allRoutes = routes;

    for (let route of allRoutes) {
      if (route.name === currentRoute) {
        setRouteLabel(route.langauge[currentLanguage]);
        setRouteLink("/" + route.name);
      }
    }
  };

  return (
    <>
      <div className="routeLocationDisplayer-wrapper">
        {t("navbar.main")}/
        {routeLabel?.split("/").length > 1 ? (
          <span>
            <span> {routeLabel.split("/")[0]}/ </span>
            <a className="routeLocationDisplayer-link" href={routeLink}>
              {" "}
              {routeLabel.split("/")[1]}{" "}
            </a>
          </span>
        ) : (
          <a className="routeLocationDisplayer-link" href={routeLink}>
            {" "}
            {routeLabel}{" "}
          </a>
        )}
      </div>
    </>
  );
};

export default RouteLocationDisplayer;
