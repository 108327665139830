import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./ordersummery.css";

// Components
import ClientsInfo from "./ClientsInfo/clientsinfo";
import FlightsInfo from "./FlightsInfo/flightsinfo";
import HotelInfo from "./HotelInfo/hotelinfo";
import Summary from "./SubComponents/summary";
import HotelDisplay from "../PaymentAgent/SubComponents/hotelDisplay";
import HandlingFeePicker from "../PaymentAgent/HandlingFeePicker/HandlingFeePicker";
import MoreToPay from "../PaymentAgent/SubComponents/moreToPay";

import { cancellationPolicies } from "../../../services/fhpService";

// Services
import { useOrder } from "../../../contexts/OrderContext";

const OrderSummery = ({ clientsInfo }) => {
  // Params
  const { currentHotelOrder, setHandleFeeData } = useOrder();
  const { selectedObject } = useParams(); // Checks if picked a room / flight ...
  const searchPhrase = JSON.parse(localStorage.getItem("currentSearchPhrase"));

  // States
  const [selectOption, setSelectOption] = useState(selectedObject ? 3 : 1);
  const [clientsDetails, setClientsDetails] = useState(clientsInfo);

  const [rooms, setRooms] = useState(null);

  const [hotelPolicies, setHotelPolicies] = useState([]);
  const [hotelPoliciesRemarks, setHotelPoliciesRemarks] = useState([]);

  // Translator
  const { t } = useTranslation();

  // Navigator
  const navigate = useNavigate();

  // Contexts

  const [totalRooms, setTotalRooms] = useState("");

  const [numberOfNights, setNumberOfNights] = useState("");

  useEffect(() => {
    if (!clientsInfo.fullname) {
      setClientsDetails(
        JSON.parse(localStorage.getItem("agent-saved-client-info"))
      );
    }
  }, []);

  // Handle payment
  const handleSummeryAndPayment = () => {
    // Save in local storage the order

    if (currentHotelOrder) {
      navigate(
        "/p/dashboard/searchoffer/" +
          currentHotelOrder.selectedHotelPackage.packageID
      );
    }
  };

  // Get Rooms | Nights Amount - current Search Order
  const extractDataFromSearch = (data) => {
    const { rooms } = data;
    setRooms(rooms);

    if (rooms && rooms.length > 0) {
      const totalRooms = rooms.length;

      // Get number of nights
      const checkInParts = data?.checkIn.split("/");
      const checkOutParts = data?.checkOut.split("/");

      // Create Date objects using parts in the format: year, month - 1, day
      const checkInDate = new Date(
        checkInParts[2],
        checkInParts[1] - 1,
        checkInParts[0]
      );
      const checkOutDate = new Date(
        checkOutParts[2],
        checkOutParts[1] - 1,
        checkOutParts[0]
      );

      // Calculate the time difference in milliseconds
      const timeDifference = checkOutDate - checkInDate;

      // Convert milliseconds to days
      const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

      getCancellationDataForSearch();
      setTotalRooms(totalRooms);
      setNumberOfNights(numberOfNights);
    }
  };
  // Get Basic info about hotel
  useEffect(() => {
    extractDataFromSearch(searchPhrase);
  }, []);

  function renderSummary() {
    const params = {
      hotelName: "", //currentOrder.selectedHotel.HotelName,
      date: "",
      numberOfNights: numberOfNights,
      totalRooms: totalRooms,
      roomName: "",
    };
    if (currentHotelOrder) {
      params.hotelName = currentHotelOrder.selectedHotel.HotelName;
      params.date =
        currentHotelOrder.searchPhrase.checkIn +
        "-" +
        currentHotelOrder.searchPhrase.checkOut;
      params.roomName = currentHotelOrder.selectedRoom.roomNames[0];
    }

    return <Summary {...params} />;
  }

  const getTaxesAndFeesFromSearch = () => {
    const currentPackage = currentHotelOrder?.selectedHotel.Result.filter(
      (room) => {
        if (
          room.PackageId === currentHotelOrder?.selectedHotelPackage.packageID
        ) {
          return room;
        }
      }
    )[0];

    return currentPackage?.PackagePrice?.TaxesAndFees?.filter(
      (tax) => !tax.IsIncludedInPrice
    );
  };
  const getCancellationDataForSearch = async () => {
    const data = {
      session: searchPhrase?.session,
      hotelID: parseInt(searchPhrase?.hotelID),
      packageID: currentHotelOrder.selectedHotelPackage.packageID,
    };

    const res = await cancellationPolicies(data);

    setHotelPolicies(res.data.policies);
    setHotelPoliciesRemarks(res.data.remarks);
  };

  const additionalTaxes = getTaxesAndFeesFromSearch();

  return (
    <>
      <div
        id="order-summery-wrapper"
        className={`orderSummery-wrapper-wrapper`}
      >
        <div id="order-summery" className="orderSummery-wrapper">
          {/* Summery Content */}
          <div className="orderSummery-content">
            {/* Order Details */}
            {renderSummary()}
            <HandlingFeePicker
              updateHandleFeeData={setHandleFeeData}
              handleFeeData={currentHotelOrder.ourPrice.handleFeeData}
            />

            {selectOption === 1 && (
              <div className="orderSummery-content-item-view">
                <ClientsInfo
                  clientName={clientsDetails.fullname}
                  clientPhone={clientsDetails.phone}
                  clientEmail={clientsDetails.email}
                ></ClientsInfo>
              </div>
            )}

            {selectOption === 2 && (
              <div className="orderSummery-content-item-view">
                <ClientsInfo
                  clientName={clientsDetails.fullname}
                  clientPhone={clientsDetails.phone}
                  clientEmail={clientsDetails.email}
                ></ClientsInfo>
                <FlightsInfo />
              </div>
            )}
            {/* <HotelInfo hotelInfo={null} /> */}

            {selectOption === 3 && (
              <div>
                <HotelDisplay
                  price={currentHotelOrder.ourPrice}
                  commission={currentHotelOrder.ourPrice.handlingFee}
                  date={
                    currentHotelOrder.searchPhrase?.checkIn +
                    " - " +
                    currentHotelOrder.searchPhrase?.checkOut
                  }
                  hotelName={currentHotelOrder.selectedHotel.HotelName}
                  hotelID={currentHotelOrder.searchPhrase.hotelID}
                  hotelAddress={currentHotelOrder.selectedHotel.Address}
                  numberOfNights={numberOfNights}
                  totalRooms={totalRooms}
                  roomName={currentHotelOrder.selectedHotelPackage.roomNames[0]}
                  rating={currentHotelOrder.selectedHotel.Rating}
                  passengers={[]}
                  rooms={rooms}
                  editInfo={false}
                  basis={currentHotelOrder.selectedHotelPackage.roomBasis}
                  freeCancelation={
                    currentHotelOrder.selectedHotelPackage.refundability
                  }
                  dateOfCancelation={
                    currentHotelOrder.selectedHotelPackage.refundableUntil
                  }
                  includestaxes={
                    currentHotelOrder.selectedHotelPackage.includesTaxes
                  }
                  packageID={currentHotelOrder.selectedHotelPackage.packageID}
                  images={currentHotelOrder?.selectedRoom?.images}
                  policies={hotelPolicies}
                  policyRemarks={hotelPoliciesRemarks}
                />
                <MoreToPay additionalTaxes={additionalTaxes} />
              </div>
            )}

            {selectOption === 4 && (
              <div className="orderSummery-content-item-view">
                <ClientsInfo
                  clientName={clientsDetails.fullname}
                  clientPhone={clientsDetails.phone}
                  clientEmail={clientsDetails.email}
                ></ClientsInfo>

                <div className="orderSummery-content-infos">
                  {/* <FlightsInfo /> */}
                  <HotelInfo hotelInfo={null} />
                </div>

                <div>
                  {/* Order Summery Price */}
                  <div className="orderSummery-price-wrapper">
                    <label>{t("ordersummery.ordersummery")}:</label>
                    <label id="price-order-summery"></label>
                  </div>

                  {/* Order Summery button */}
                  <div className="orderSummery-payment-btn-wrapper">
                    <button
                      onClick={handleSummeryAndPayment}
                      className="orderSummery-payment-btn"
                    >
                      {t("ordersummery.payment")}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/*{document.documentElement.dir === "rtl" ? (*/}
            {/*  <TbLayoutSidebarRightExpand*/}
            {/*    onClick={narrowOrderSummery}*/}
            {/*    className="orderSummery-narrow-button"*/}
            {/*  />*/}
            {/*) : (*/}
            {/*  <TbLayoutSidebarLeftExpand*/}
            {/*    onClick={narrowOrderSummery}*/}
            {/*    className="orderSummery-narrow-button"*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummery;
