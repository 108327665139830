import React, { useState } from 'react';
import userIcon from "../../assets/images/navbar/user.png";
import {getUserInfo, setUserInfoImage} from "../../services/localStorage";
import BaseImageUploader from "./BaseImageUploader";
import {changeUserImage} from "../../services/authService";

const ProfileImageUploader = () => {
    const { imageprofilebase65, id } = getUserInfo() || {}
    const [base64Image, setBase64Image] = useState(imageprofilebase65 || userIcon);

    function onImageChange(newImage){
        changeUserImage(id, newImage)
            .then(res => {
                setBase64Image(newImage)
                setUserInfoImage(newImage)
            })
            .catch(err => console.error(err))
    }

    return (
        <BaseImageUploader imageSrc={base64Image} divStyle={{height: 40, width: 40}} onImageChange={onImageChange}/>

    );
};

export default ProfileImageUploader;