import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TextField } from "@mui/material";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          boxShadow: "0 2px 5px 0 #00000047 inset",
          padding: "5px 12px",
          borderRadius: "1000px",
          height: "32px",
        },
        input: {
          textAlign: "center",
        },
      },
    },
  },
});

function DatePicker({ value, setValue, setDatePickerOpenStatus, ...params }) {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="he">
        <MuiDatePicker
          {...params}
          slotProps={{
            textField: {
              size: "small",
              error: false,
            },
          }}
          onClose={
            setDatePickerOpenStatus
              ? () => setDatePickerOpenStatus(false)
              : null
          }
          onOpen={
            setDatePickerOpenStatus ? () => setDatePickerOpenStatus(true) : null
          }
          value={value}
          onChange={(newValue) => {
            newValue?.toDate() ? setValue(newValue.toDate()) : setValue(null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                style: { borderRadius: "10px", border: "none" },
              }}
            />
          )}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default DatePicker;
