import React from 'react';
import './Back.css';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate('/p/dashboard/searchoffer');
    };

    return (
        <button className="back-btn" onClick={goBack}>
            חזור לתוצאות החיפוש
        </button>
    );
};

export default BackButton;