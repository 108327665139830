import React from "react";
import { useTranslation } from "react-i18next";
import "./TermsAndConditions.css";

const TermsAndConditions = ({ OrderData }) => {
  const { t } = useTranslation();

  return (
    <div className="passangerData-popup-frame-content">
      <h2 style={{ textAlign: "center" }}>
        תקנון, תנאי שימוש ומדיניות פרטיות לאתר "hotelsriver.com"
      </h2>

      <p
        style={{
          fontSize: "80%",
          color: "#808080",
          textAlign: "center",
        }}
      >
        עדכון אחרון: פברואר 2023
      </p>
      <p>
        ריבר אנ מור בע"מ, ח.פ. 516601556 (<b>"החברה"</b>) מודה על בחירתכם לגלוש
        באתר האינטרנט המופעל בכתובת <a href="/">www.hotelsriver.com</a> (
        <b>"האתר"</b>).
      </p>
      <p>
        <b>HOTELSRIVER</b> הינה סוכנות נסיעות. האתר מאפשר רכישה מקוונת של מגוון
        חבילות תיירות, אירוח ונופש, הכוללות מלונות, טיסות ועוד (
        <b>"השירותים"</b>). כמו כן, ניתן למצוא באתר מידע אודות השירותים המוצעים
        לרכישה באתר.
      </p>

      <h4>1. כללי:</h4>
      <p>
        1.1. תקנון ותנאי שימוש אלה מהווים הסכם מחייב בינך, בין אם באופן פרטי
        ובין באמצעות תאגיד (<b>"אתה"</b>) לבין האתר בנוגע לגלישה ושימוש באתר בכל
        מחשב או מכשיר תקשורת אחר כדוגמת טלפון סלולרי, מחשבי טאבלט וכיו"ב. כמו כן
        הם חלים על השימוש באתר ו/או באתרים קשורים באמצעות אפליקציה או כל אמצעי
        אחר. קרא את התקנון ותנאי השימוש בקפידה, שכן הגלישה באתר וביצוע פעולות בו
        מעידים על הסכמתך לתנאים הכלולים בתקנון ותנאי שימוש אלו (<b>״התקנון״</b>
        ).
      </p>
      <p>
        1.2. השירותים המוענקים ללקוח הינם "שירותי סוכנות נסיעות", על פי ההגדרה
        המוצגת בסעיף 1 לחוק שירותי תיירות, התשל"ו-1976.
      </p>
      <p>
        1.3. האתר, כסוכנות הנסיעות, ישמש כמתווך בין הלקוח לבין הספקים ונותני
        השירות, כגון ספקי לינה והארחה, חברות תעופה וכיו"ב (<b>"הספקים"</b>)
        בהתאם להזמנה שבוצעה על ידי הלקוח.
      </p>
      <p>
        1.4. האתר יבצע הזמנה ורכישה של שירותים מהספקים, בהתאם להזמנה שתבוצע על
        ידי הלקוח באתר. השירותים יסופקו על ידי הספקים במישרין ללקוח. השירותים
        אינם מסופקים על ידי האתר, והאתר ולא יהיה אחראי על אופן אספקת השירותים,
        טיבם ואיכותם, ולא תהיה ללקוח כל טענה ו/או דרישה ו/או תביעה כלפי האתר
        בגין שירותי הספקים. לכל תלונה כלפי שירותי הספק יש לפנות לאותו הספק בלבד.
      </p>
      <p>
        1.5. האמור בתקנון מתייחס באופן שווה לבני שני המינים והשימוש בלשון זכר
        ו/או נקבה הוא מטעמי נוחות בלבד.
      </p>
      <p>
        1.6. הגלישה באתר מותרת בכל גיל. הרכישה באתר מיועדת לבעלי כשירות משפטית
        מגיל 18 בלבד. רכישה של קטין מתחת לגיל 18 באתר מחייבת אישור הורה או
        אפוטרופוס.
      </p>
      <p>
        1.7. ככלל, הגלישה באתר והעיון בו אינם דורשים הרשמה, והוא פתוח לכל גולש.
        מבלי לגרוע מן האמור לעיל, האתר שומר על זכותו למנוע מגולש אשר לא נרשם
        לאתר גישה לשירותים מסוימים באתר, מכל סיבה או נימוק שהוא, בשיקול דעתו
        הבלעדי, ללא צורך בהודעה מוקדמת או התראה ומבלי שיהיה אחראי לנזק כלשהו עקב
        החלטתו.
      </p>
      <p>
        1.8. במקרה שייקבע כי הוראה בתקנון זה אינה ניתנת לאכיפה או שהינה חסרת
        תוקף מטעם כלשהו, לא יהא בכך כדי להשפיע או לפגוע בחוקיותן, תקפותן ואכיפתן
        של שאר הוראות התקנון.
      </p>

      <h4>2. הרכישה באתר:</h4>
      <p>2.1. ניתן לרכוש את השירותים באופן נוח מאובטח באמצעות האתר.</p>
      <p>
        2.2. מחירים, זמינות ותנאי רכישה אחרים מופיעים בדפי האתר ועשויים להשתנות
        מעת לעת. האתר שומר לעצמו את הזכות לשנות את מגוון השירותים או להפסיקם או
        לשנות את מחיר השירותים המוצעים באתר ומחוצה לו, ללא הודעה מוקדמת.
      </p>
      <p>
        2.3. המחירים המוצגים באתר מיועדים לתושבי ישראל בעלי אזרחות ישראלית בלבד,
        ואינם מיועדים עבור תיירים שאינם אזרחי מדינת ישראל.
      </p>
      <p>
        2.4. המחירים המוצגים בתוצאות החיפוש באתר הינם המחירים המעודכנים ביותר
        למועד החיפוש. המחיר והזמינות המוצגים בעת החיפוש הינם נכונים למועד הפרסום
        באתר ועשויים להשתנות במהלך ביצוע ההזמנה ועד לרגע התשלום. המחירים המוצגים
        עשויים להשתנות בהתאם לשינויים שמבוצעים על ידי הספקים.
      </p>
      <p>
        2.5. מחירי השירותים המוצגים באתר כוללים את המיסים וההיטלים הנדרשים
        לתשלום, אלא אם צוין אחרת בעת ההזמנה.
      </p>
      <p>
        2.6. בעת הזמנה באתר, אתה אחראי לספק מידע נכון, מדויק, עדכני ומלא. האתר
        רשאי לאמת את הפרטים שהוזנו לאחר ביצוע הזמנה, והוא רשאי לבטל או להגביל את
        ההזמנה בכל עת. במידה ובוצע תשלום עבור הזמנה שבוטלה, האתר יחזיר את סכום
        התשלום לאותו אמצעי התשלום.
      </p>
      <p>
        2.7. אם, מכל סיבה שהיא, חברת כרטיס האשראי תסרב להעביר את סכום התשלום
        בגין רכישה באתר, האתר יהיה רשאי להשהות או להפסיק לאלתר את מתן השירות
        והוא רשאי לדרוש את התשלום בתוספת עלות שנגרמה לאתר עקב הסירוב (לרבות
        קנסות מצד צד שלישי) באמצעים אחרים, לשיקול דעתו של האתר. במידה והאתר יאלץ
        לנקוט בהליכים משפטיים לגביית יתרות תשלום, אתה מתחייב לשפות את האתר בכל
        ההוצאות שיגרמו לו, לרבות שכר טרחת עורך דין והוצאות משפט נוספות.
      </p>
      <p>
        2.8. רכישה באתר כפופה לתנאים ולהגבלות נוספות של חברות האשראי ו/או כל
        אמצעי תשלום אחר המופיע באתר.
      </p>
      <p>
        2.9. החיובים בגין רכישות באתר יבוצעו בשקלים חדשים בהתאם לשער ההעברות
        וההמחאות (מכירה) כפי שפורסם ביום העסקים שקדם ליום ביצוע ההזמנה.
      </p>
      <p>
        2.10. אישור הזמנת הלקוח באתר כפוף לאישור הספקים, בהתאם לשירותים שהוזמנו
        על ידי הלקוח.
      </p>
      <p>
        2.11. הנחה ו/או הטבה ו/או שובר מתנה ו/או זיכוי יהיו תקפים עבור הזמנות
        במסגרת המבצע או ההטבה הייעודית בלבד.
      </p>

      <h4>3. מדיניות ביטול ושינוי הזמנות:</h4>
      <p>
        3.1. בקשה להחזר, שינוי או ביטול עסקה יועברו לאתר באחד מאמצעי ההתקשרות
        המופיעים בתחתית התקנון.
      </p>
      <p>
        3.2. מדיניות הביטול והשינוי שיחולו על ההזמנות יהיו בהתאם לתעריפי הספקים
        השונים (בתי המלון, חברות התעופה וכיו"ב), וישתנו משירות לשירות, כמצוין
        בפרטי ההזמנה, ובכפוף להוראות חוק הגנת הצרכן, התשמ"א-1981 ("חוק הגנת
        הצרכן").
      </p>
      <p>
        3.3. בהתאם להוראות חוק הגנת הצרכן הלקוח לא יהיה זכאי לביטול הזמנה במסגרת
        חוק הגנת הצרכן בהזמנת שירותי תיירות המסופקים במלואם מחוץ לישראל, כגון
        בתי מלון, אירועי ספורט, הופעות וכד'.
      </p>
      <p>
        3.4. ביטול עסקה וקבלת החזר כספי יתאפשרו עד 14 ימים מיום ביצוע הרכישה,
        ובתנאי שישנם 7 ימים שאינם ימי מנוחה בטרם מועד החופשה. במקרה של ביטול
        כאמור, יחויב הלקוח בדמי ביטול בסך של 100 ש"ח או בסך של 5% משווי כל שירות
        בהזמנה, לפי הנמוך מביניהם.
      </p>
      <p>
        3.5. בהזמנות הכוללות מספר שירותים שונים מספקים שונים, כל שירות ייחשב
        כעסקה נפרדת לצורך גביית דמי הביטול. כל שירות עבור כל נוסע ייחשב כעסקה
        נפרדת, ממנה ייגבו דמי הביטול.
      </p>
      <p>
        3.6. לקוח שהוא אזרח ותיק (מעל גיל 65) או בעל מוגבלות או עולה חדש (עד 5
        שנים) רשאי לבטל הזמנה בתוך ארבעה חודשים מעת ביצוע הרכישה, ובתנאי שישנם 7
        ימים שאינם ימי מנוחה בטרם מועד החופשה, בעבור דמי ביטול בסך של 100 ש"ח או
        בסך של 5% מכל שירות בהזמנה, לפי הנמוך מביניהם.
      </p>
      <p>
        3.7. במקרה של בקשה לביטול או שינוי הזמנה שאינה במסגרת חוק הגנת הצרכן,
        יחולו דמי ביטול או שינוי לפי מדיניות הביטול והשינוי שנקבעה על הספקים.
      </p>
      <p>
        3.8. שינוי הזמנה או ביטול הזמנה יהיו כרוכים בדמי טיפול של 40$ עבור כל
        אדם. יובהר כי דמי הטיפול הינם בנוסף לדמי הביטול ולדמי השינוי שנקבעו
        בהתאם למדיניות הביטול והשינוי של הספקים.
      </p>
      <p>
        3.9. שינויים בהזמנה, לרבות שינוי מועדים, שינוי יעד, שינויים בשמות
        המוזמנים וכיו"ב יתבצעו בכפוף לאישור הספקים. האתר אינו מתחייב לעריכת
        השינויים ואישורם על ידי הספקים.
      </p>
      <p>
        3.10. החזר כספי בגין ביטול הזמנה בנסיבות רפואיות או חו"ח מוות של קרוב
        משפחה מדרגה ראשונה יהיו בכפוף למדיניות הספקים ואישורם. על הלקוח להעביר
        בקשה מפורטת ומסודרת הכוללת מסמכים רפואיים במקרה של בקשה לביטול והחזר
        כספי כאמור.
      </p>
      <p>
        3.11. כל שינוי או ביטול או החזר כספי יאושרו לאחר שהלקוח יקבל הודעה בכתב
        מהאתר המאשרת זאת.
      </p>
      <p>
        3.12. ככל והלקוח יהיה זכאי להחזר כספי בגין הזמנתו, ההחזר הכספי יעשה רק
        לאחר העברת הכספים על ידי הספקים אל החברה, באמצעות אמצעי התשלום שבו ביצע
        הלקוח את ההזמנה.
      </p>
      <p>
        3.13. הלקוח לא יהיה זכאי להחזר כספי או שובר או הארכת הטבה בגין ביטול
        הזמנה שנעשה בה שימוש בשובר או הטבה.
      </p>
      <p>
        3.14. האמור בסעיף זה הינו בכפוף להוראות חוק הגנת הצרכן, ומובא לשם נוחות
        הלקוחות בלבד. הנוסח וההוראות המחייבות הם המפורטים בחוק הגנת הצרכן כאמור.
        יובהר כי אין בתנאים המוצגים בסעיף זה על מנת לגרוע מהוראות חוק הגנת
        הצרכן.
      </p>

      <h4>4. ביטול הזמנות ושירותים על ידי האתר:</h4>
      <p>
        4.1. האתר יהיה רשאי לבטל הזמנה ו/או טיסה ו/או שהות במלון ו/או חבילת נופש
        ו/או כל שירות אחר מכל סיבה אשר אינה בשליטתו. במקרה של ביטול כאמור על ידי
        האתר, יוצעו ללקוח חלופות לבחירתו. במקרה שהלקוח לא יקבל את החלופות, האתר
        יחזיר ללקוח כל תשלום ששולם על ידי הלקוח. ללקוח לא תהיה כל טענה ו/או
        דרישה ו/או תביעה כלפי האתר בגין ביטול כאמור.
      </p>
      <p>
        4.2. האתר יהא רשאי לבטל הזמנה של לקוח גם במקרה בו קיבל הלקוח אישור
        הזמנה, במקרה של שגיאה שחלה אצל הספק או אצל האתר בנוגע לאחד מהנתונים
        המפורטים באתר לגבי השירות, לרבות מחיר השירות או פירוט השירות או במקרה של
        שיבושים ו/או תקלות בלתי צפויות ו/או בעיות תקשורת ו/או השבתה ו/או במקרים
        אחרים שאינם בשליטתו של האתר. ללקוח לא תהיה כל טענה ו/או דרישה ו/או תביעה
        כלפי האתר בגין ביטול הזמנה כאמור.
      </p>
      <p>
        4.3. כמו כן, יובהר כי ייתכנו שינויים או ביטולים בהזמנות או בשירותים
        מסוימים בהזמנות כתוצאה מנסיבות הקשורות לספקים או כתוצאה מכוח עליון ובכלל
        זה מלחמה, שביתה או השבתה, פעולות חבלה, הפרעות לסדר הציבורי, מעשה או מחדל
        של צד שלישי או מגבלות שהוטלו על ידו, חוקים, תקנות, צווים או הוראות
        ממשלתיות אחרות, מגבלות ביטחוניות, מגיפה, סגר או נסיבות אחרות שאינן
        בשליטתו. האתר לא יהיה אחראי בגין ביטול במקרים המצוינים לעיל, והחזר כספי
        בגין שינוי או ביטול כאמור יהיה בכפוף למדיניות הספקים.
      </p>

      <h4>5. אחריות האתר:</h4>
      <p>
        5.1. אין לראות במידע המופיע באתר משום הבטחה לתוצאה כלשהי ו/או אחריות
        לאופן מתן השירותים המסופקים בו. האתר לא יהיה אחראי לשום נזק, ישיר או
        עקיף, אשר ייגרם לגולש כתוצאה מהסתמכות על מידע המופיע באתר ו/או בקישורים
        לאתרים אחרים ו/או כל מקור מידע פנימי ו/או חיצוני אחר ו/או שימוש בשירותים
        אשר מוצגים על ידו.
      </p>
      <p>
        5.2. ביצוע הזמנת השירותים תבוצע בכפוף לזמינות השירותים של הספקים ובהתאם
        למידע שנמסר לאתר על ידי הספקים.
      </p>
      <p>
        5.3. הלקוח יהיה האחראי הבלעדי לוודא כי בבעלותו מסמכי נסיעה בתוקף
        המתאימים לשירותים שהוזמנו (כגון דרכון, אשרות כניסה ויציאה מישראל וליעד
        שהוזמן ועוד). האתר לא יהיה אחראי לכל עניין הקשור באשרות כניסה ויציאה,
        דרכונים שאינם בתוקף וכיו"ב, והאחריות בגין כך תחול על הלקוח בלבד.
      </p>
      <p>
        5.4. על הלקוח לרכוש ו/או לערוך את הביטוחים הרלוונטיים הנדרשים, לגוף
        ולרכוש. האתר לא יהיה אחראי לכל נזק, ישיר ו/או עקיף, לגוף ו/או לרכוש,
        שייגרם ללקוח במהלך החופשה, מכל סיבה שהיא, לרבות בגין תאונה, גנבה, אובדן
        כבודה או מסמכים או הוצאות שיהיה על הלקוח להוציא בגינם. אם הוציא האתר
        הוצאה כלשהי בעבור הלקוח, יהא הלקוח חייב בהשבה מלאה לאתר.
      </p>
      <p>
        5.5. האתר לא יהיה אחראי בשום אופן כלפי רוכש השירות במקרה של הרחקתו
        ממתחמי האירוח והבילוי עקב אלימות ו/או החזקת חומרים מסוכנים ו/או עישון
        ו/או החזקת נשק ו/או התנהגות שלא בהתאם לנהלים. לרוכש השירות לא תהיה כל
        טענה כלפי האתר במקרה שבו יורחק ממתחם האירוח ו/או הבילוי בגין התנהגות
        שאינה נאותה והוא אינו זכאי להחזר כלשהו.
      </p>
      <p>
        5.6. ללקוחות האתר לא תהיה כל טענה כלפי האתר, מנהליו, עובדיו או מי מטעמו
        והם לא יהיו אחראים, באופן ישיר או עקיף, לכל נזק לרבות, לגוף, לרכוש או
        לכל נזק אחר או לכל הפסד אחר, אשר יגרם כתוצאה מקבלת שירותים מהאתר, אפילו
        אם האתר הזהיר, המליץ או ייעץ לעניין מסוים או על שירות מסוים ובשום מקרה
        האתר לא יהווה תחליף או יישא באחריות הלקוח. על הלקוח חלה האחריות וכל
        סיכון חובה עבור נזק לרכושו ו/או לרכושם של צדדים שלישיים, הנובעים מהשימוש
        בשירותי האתר.
      </p>
      <p>
        5.7. התמונות באתר מוצגות לצורכי המחשה בלבד. ייתכנו הבדלים במראה, בגוון,
        בגודל, וכיו"ב בין השירותים, כפי שמוצגים באתר, לבין השירות שיסופק ללקוח.
        טעות סופר בתיאור שירות ו/או מחירו לא תחייב את האתר.
      </p>
      <p>
        5.8. מידע ומצגים אודות שירותים המוצגים באתר, שמקורם בספקים ו/או בשותפיה
        העסקיים של האתר ו/או צדדים שלישיים ששירותיהם מופיעים באתר וכל תוכן ביחס
        לשירותים נמצאים באחריותם הבלעדית של השותפים העסקיים כאמור, ועל כן מובן
        שלאתר אין כל אחריות בגין מידע מעין זה, ואין האתר ערב למידת הדיוק של מידע
        זה.
      </p>
      <p>
        5.9. האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש והוצאות
        שייגרמו לגולשים ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות על כל
        תוכן, מידע, נתון, מצג, תמונה, וידאו, אודיו, פרסומת, מוצר, שירות וכו'
        המופעים באתר. כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית
        של הגולש באתר.
      </p>
      <p>
        5.10. האתר בשום מקרה לא יהיה אחראי לנזק שנגרם לגולש האתר באמצעות יצירת
        קשר עם שותפיו העסקיים של האתר.
      </p>
      <p>
        5.11. האתר ממליץ לגולשים באתר לנהוג כצרכנים נבונים וזהירים, ולקרוא בעיון
        את המידע המוצג באתר ובכלל זה את המידע ביחס למוצר ו/או לשירות עצמו,
        תיאורו והתאמתו, כמתואר באתר.
      </p>
      <p>
        5.12. התכנים באתר ניתנים לשימוש כמות שהם ("AS IS"). לא ניתן להתאימם
        לצרכיו של כל אדם ואדם. לא תהיה לגולש כל טענה, תביעה או דרישה כלפי האתר
        בגין תכונות של התכנים, יכולותיהם, מגבלותיהם ו/או התאמתם לצרכיו.
      </p>
      <p>
        5.13. האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמות שהוא,
        ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין,
        יתכן ונפלו טעויות טכניות או אחרות במידע.
      </p>
      <p>
        5.14. השימוש באתר ייעשה על אחריותו הבלעדית והמלאה של כל גולש. כל החלטה
        שתתקבל ביחס לתכנים שיתפרסמו באתר הינה באחריותו המלאה.
      </p>

      <h4>6. קניין רוחני:</h4>
      <p>
        6.1. אלא אם צוין במפורש אחרת, כל זכויות היוצרים והקניין הרוחני באתר,
        לרבות, עיצובים, תמונות, שמע, וידאו, טקסט, מאגרי מידע, תוכנות, קוד
        (״התוכן״) וכן סמלילים, סימני מסחר וכיו״ב (״הסימנים״) הם בבעלות האתר
        בלבד, או בבעלות צד שלישי, שהרשה לאתר או נתן רישיון לאתר לעשות שימוש על
        פי דין בתכנים או בסימנים ובכלל זה שותפיו העסקיים של האתר.
      </p>
      <p>
        6.2. התוכן והסימנים ניתנים כמו שהם ״AS IS״ לשימוש אישי בלבד. אלא אם צוין
        במפורש אחרת, אין להעתיק, להפיץ, להציג בפומבי, לבצע בפומבי, להעביר
        לציבור, לשנות, לעבד, ליצור יצירות נגזרות, למכור או להשכיר כל חלק מן
        הנ"ל, בין ישירות ובין באמצעות או בשיתוף צד שלישי, בכל דרך או אמצעי בין
        אם אלקטרוניים, מכאניים, אופטיים, אמצעי צילום או הקלטה, או בכל אמצעי ודרך
        אחרת, בלא קבלת הסכמה בכתב ומראש מהאתר או מבעלי הזכויות האחרים, לפי
        העניין, ובכפוף לתנאי ההסכמה.
      </p>
      <p>
        6.3. אם וככל שניתנה הסכמה כאמור, יש להימנע מלהסיר, למחוק או לשבש כל
        הודעה או סימן בעניין זכויות קניין רוחני, לדוגמה: סימון זכויות היוצרים
        ,© או סימן מסחר ®, הנלווים לתכנים שיעשה בהם שימוש.
      </p>
      <p>
        6.4. בתנאי שאתה זכאי לגלוש באתר, האתר מקנה לך רישיון מוגבל לשימוש בו
        ולהורדת או הדפסת עותק של כל חלק מהתוכן אליו ניתנה גישה ראויה לשימוש אישי
        בלבד, שאינו מסחרי.
      </p>

      <h4>7. הפרת זכויות יוצרים וקניין רוחני:</h4>
      <p>
        7.1. אנו מכבדים את זכויות היוצרים והקניין של אחרים. אם אתה מאמין כי מידע
        או תוכן באתר מפר את זכויות קנייניות השייכות לך, אנא צור קשר באמצעות פרטי
        ההתקשרות שבתחתית תקנון זה.
      </p>

      <h4>8. הצהרות משתמש:</h4>
      <p>
        8.1. בעת גלישה באתר, אתה מתחייב ומצהיר כדלקמן: (1) הגלישה באתר והשימוש
        הם באחריותך הבלעדית; (2) הפרטים שהזנת בעת השארת הפרטים ו/או רכישה באתר
        הם נכונים, עדכניים, מדיוקים ומלאים; (3) ככל ויהיה בכך צורך, במקרה של
        שינוי פרטים תעדכנם בפנייה לאתר; (4) הנך בעל כשירות משפטית ואתה מסכים
        לתנאי התקנון; (5) לא תעשה שימוש באתר באמצעים אוטומטיים או לא אנושיים,
        בין אם באמצעות BOT, סקריפט או בכל דרך אחרת; (6) לא תעשה שימוש בלתי חוקי
        באתר; (7) השימוש שלך באתר לא יפר כל חוק או תקנה רלוונטיים.
      </p>
      <p>
        8.2. האתר רשאי, למנוע מכל גולש שימוש באתר לפי שיקול דעתו המוחלט. מבלי
        לגרוע מהאמור לעיל, האתר רשאי לחסום גישתו אליו או חלקה אם בעת השארת פרטים
        באתר נמסרו במתכוון פרטים שגויים, לא עדכניים או לא מלאים.
      </p>

      <h4>9. פעילות אסורה באתר:</h4>
      <p>
        9.1. אינך רשאי להשתמש באתר אלא למטרות שלשמן הוא נועד. השימוש באתר מותר
        למטרות פרטיות ואישיות בלבד ואין לעשות בו שימוש למטרות מסחריות למעט כאלו
        שיאושרו על ידי האתר באופן ספציפי.
      </p>
      <p>9.2. כמשתמש האתר, אתה מסכים שלא:</p>
      <p>
        9.2.1. לאחזר נתונים או תוכן אחר מהאתר כדי ליצור או להרכיב אוסף, מסד
        נתונים או מדריך ללא אישור מראש ובכתב מהאתר;
      </p>
      <p>9.2.2. לעשות כל שימוש בעיצובי האתר;</p>
      <p>
        9.2.3. לעשות שימוש לא מורשה באתר, לרבות איסוף כתובות דוא"ל וכיו״ב
        באמצעים אלקטרוניים או אחרים לצורך שליחת דוא"ל באמצעים אוטומטיים;
      </p>
      <p>
        9.2.4. לעקוף, להשבית או להפריע בדרך אחרת לאבטחת האתר, לרבות שימוש
        ביישומים המונעים או מגבילים את השימוש או ההעתקה של תוכן כלשהו;
      </p>
      <p>9.2.5. להונות או להטעות את האתר ו/או את משתמשיו;</p>
      <p>
        9.2.6. לעשות שימוש לא נכון בשירותי התמיכה של האתר או להגיש דוחות כוזבים
        בנוגע לשימוש באתר;
      </p>
      <p>
        9.2.7. לעשות שימוש אוטומטי במערכת, כגון שימוש בסקריפטים לשליחת הערות או
        הודעות, או שימוש בכריית נתונים, רובוטים או כלי איסוף וחילוץ נתונים
        דומים;
      </p>
      <p>
        9.2.8. לנסות להתחזות לאדם אחר ו/או להעביר את פרטי הגישה לאתר לאדם אחר;
      </p>
      <p>
        9.2.9. להשתמש במידע שהתקבל באתר על מנת להטריד, להתעלל או לפגוע באדם אחר;
      </p>
      <p>9.2.10. להשתמש באתר כחלק מכל מאמץ להתחרות באתר;</p>
      <p>
        9.2.11. לאחזר, לפענח או להנדס לאחור חלק מהאתר, אפשרות באתר או יישום
        באתר;
      </p>
      <p>9.2.12. להטריד, להפחיד או לאיים על כל אחד מעובדי או סוכני האתר;</p>
      <p>
        9.2.13. למחוק את זכויות היוצרים או את הודעת הזכויות הקנייניות מכל תוכן
        או סימן;
      </p>
      <p>
        9.2.14. להעתיק או להתאים את קוד האתר או חלק ממנו, כולל אך לא רק HTML
        ,Java Script PHP ,CSS , JSON או קוד אחר;
      </p>
      <p>
        9.2.15. להעלות או להעביר (או לנסות להעלות או להעביר) וירוסים, סוסים
        טרויאניים, או חומר אחר, כולל שימוש בדואר זבל, אשר יפריע לשימוש באתר;
      </p>
      <p>9.2.16. לבצע פעולה שתפגע או תזיק לאתר, בהתאם לשיקול דעתו של האתר;</p>
      <p>9.2.17. להשתמש באתר באופן שאינו עולה בקנה אחד החוק, התקנות והפסיקה.</p>
      <p>
        9.3. מבלי לגרוע מכל זכות נוספת, כל שימוש באתר תוך הפרה של האמור לעיל
        עלול לגרום, בין היתר, לסיום או השעיית זכויותיך לשימוש באתר.
      </p>

      <h4>10. תוכן צדדים שלישיים:</h4>
      <p>
        10.1. האתר רשאי להשתמש בקישורים לאתרים אחרים (<b>"אתרי צד שלישי"</b>)
        וכן במאמרים, תמונות, טקסט, גרפיקה, תמונות, עיצובים, מוסיקה, סאונד,
        וידאו, מידע, אפליקציות, תוכנה ותכנים או פריטים אחרים השייכים או שמקורם
        באתרי צד שלישי (<b>"תוכן של צד שלישי"</b>). אתרי צד שלישי ותוכן של צד
        שלישי אינם נחקרים, מנוטרים, או נבדקים על ידי האתר והאתר לא אחראי לתוכן
        של צד שלישי שפורסם דרך האתר, שהגעת אליו דרך האתר, שזמין דרך האתר או
        מותקן בו, כולל תוכן, חוות דעת, אמינות, נוהלי פרטיות או מדיניות אחרת של
        או כלולים באתרי שלישי או בתוכן של צד שלישי. שימוש בקישור או התרת שימוש
        או התקנה של אתרים של צד שלישי או כל תוכן של צד שלישי כלשהו אינה מרמזת על
        אישור או אישור לכך על ידינו, והאחריות על כניסה לקישור זה היא על הגולש
        בלבד, ועליו לבדוק אותו טרם הכניסה אליו.
      </p>
      <p>
        10.2. האתר לא לוקח אחריות על כל רכישה שתבצע מאתרי צד שלישי או מחברות
        אחרות אשר יבוצעו ויהיו בתוקף באופן בלעדי בינך לבין הצד השלישי הרלוונטי.
      </p>

      <h4>11. ניהול האתר:</h4>
      <p>
        11.1. אנו שומרים לעצמנו את הזכות: (1) לפקח על הפרות של תקנון זה; (2)
        לנקוט בפעולה משפטית כנגד כל מי שמפר את הוראות החוק או הוראות תקנון זה,
        לשיקול דעתו הבלעדי של האתר, לרבות, ללא הגבלה, דיווח על המשתמש לרשויות
        אכיפת החוק; (3) לסרב, להגביל גישה, להגביל זמינות, או להשבית (ככל שניתן
        מבחינה טכנולוגית) כל תרומה שלך לאתר או כל חלק ממנה, לפי שיקול דעתו
        הבלעדי של האתר וללא הגבלה; וכן (4) לנהל את האתר באופן שיגן על זכויותיו
        ורכושו ויקל על תפקודו התקין.
      </p>

      <h4>12. הרשאה לדיוור, פרסומים ופרסומת:</h4>
      <p>
        12.1. גולש שהשאיר פרטים באתר ומצורף לרשימת הדיוור של האתר, מאשר שימוש
        בפרטיו לצורך קבלת מידע שיווקי, עדכונים ופרסומות שיבצע האתר מעת לעת.
      </p>
      <p>
        12.2. על גולש שהשאיר פרטים כאמור תחולנה הוראות הדיוור המפורטות בתקנון
        להלן.
      </p>
      <p>
        12.3. אין להשאיר פרטים של אדם אחר באתר שלא בהסכמתו ו/או ללא נוכחותו מול
        המסך בשעת השארת הפרטים ולאחר שהוסברו לו כל תנאי התקנון.
      </p>
      <p>
        12.4. בעת השארת הפרטים יתבקש הגולש למסור פרטים אישיים כגון: שם פרטי, שם
        משפחה, טלפון וכן כתובת דואר אלקטרוני פעילה (לשיקול דעתו הבלעדי של האתר).
        מסירת פרטים חלקיים או שגויים עלולים למנוע את האפשרות להשתמש בשירות ולסכל
        יצירת קשר במקרה הצורך. במקרה של שינוי פרטים יש לעדכנם באתר.
      </p>
      <p>
        12.5. מובהר כי אין חובה על-פי חוק למסור פרטים באתר, אולם בלא למוסרם לא
        ניתן יהיה לקבל תוכן שיווקי ועדכונים מהאתר.
      </p>
      <p>
        12.6. האתר לא יעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות הפרטיות של
        האתר המהווה חלק בלתי נפרד מהתקנון.
      </p>
      <p>
        12.7. השארת פרטים באתר ואישור לקבלת תוכן שיווקי כוללת, בין היתר, קבלת
        תוכן שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים למשתמשים רשומים.
      </p>
      <p>
        12.8. אישור דיוור (קבלת תוכן שיווקי) כאמור, מהווה את הסכמתו של הגולש
        למשלוח דברי פרסומת על-פי חוק התקשורת (בזק ושידורים) (תיקון מס' 40)
        התשס"ח – 2008 (<b>"חוק התקשורת"</b>).
      </p>
      <p>
        12.9. מובהר כי באפשרות משאיר הפרטים להסיר עצמו בכל עת מהדיוור באמצעות
        לחיצה על "להסרה מרשימת התפוצה" או כל מלל דומה שמופיע בתחתית כל דיוור
        שנשלח או באמצעות פנייה לאתר בדוא"ל. כל עוד לא הסיר עצמו הנרשם מרשימת
        הדיוור כאמור, רשאי האתר, בכפוף לחוק התקשורת, להעביר לנרשם דיוור ישיר
        כאמור.
      </p>
      <p>
        12.10. האתר רשאי לבטל את רישומו של גולש לרשימת הדיוור לפי שיקול דעתו
        המוחלט. מבלי לגרוע מהאמור לעיל, רשאי האתר למנוע גלישת גולש ו/או לבטל
        רישום לרשימת הדיוור, או לחסום גישה אליו בכל אחד מהמקרים הבאים:
      </p>
      <p>
        12.10.1. אם בעת השארת הפרטים ו/או הרכישה באתר נמסרו במתכוון פרטים
        שגויים;
      </p>
      <p>
        12.10.2. במקרה שנעשה שימוש באתר לביצוע או כדי לנסות לבצע מעשה בלתי חוקי
        על-פי דיני מדינת ישראל, או מעשה הנחזה על פניו כבלתי חוקי כאמור, או כדי
        לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה;
      </p>
      <p>12.10.3. אם הופרו תנאי התקנון;</p>
      <p>
        12.10.4. אם נעשתה על ידי גולש כל פעולה שתמנע מאחרים להמשיך ולהשתמש באתר
        בכל דרך שהיא.
      </p>
      <p>
        12.10.5. אין לראות במידע בדיוור משום הבטחה לתוצאה כלשהי ו/או אחריות
        לשירות המוצע בו.
      </p>
      <p>
        12.11. האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש והוצאות
        שייגרמו למשאיר פרטים ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות
        על כל תוכן, מידע, נתון, מצג, פרסומת, מוצר, שירות וכד' המופיעים בדיוור.
        כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית של משאיר
        הפרטים.
      </p>
      <p>
        12.12. הדיוור בכללותו, לרבות כל המידע המופיע בו, מוצע כמות שהוא, ויהיה
        מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן
        ונפלו טעויות טכניות או אחרות במידע
      </p>
      <p>
        12.13. הגולש מאשר כי לא תהיה לו כל טענה בקשר למודעות פרסום ו/או פרסומות
        המוצגות באתר, לרבות בקשר למיקומן באתר. מובהר כי בכל הנוגע למודעות פרסום
        המוצגות בחסות צד שלישי, לאתר אין כל התערבות בבחירת הפרסומות המוצגות,
        אמיתות תוכנן וסדר הופעתן.
      </p>

      <h4>13. סיום:</h4>
      <p>
        13.1. תנאי תקנון זה יישארו בתוקפם בעת השימוש באתר. מבלי לגרוע מכל הוראה
        אחרת בתקנון זה, האתר שומר על זכותו למנוע מכל גולש את השימוש באתר (לרבות
        חסימת כתובות IP מסוימות), מכל סיבה או נימוק שהוא (מבלי שיצטרך לספק סיבה
        או נימוק), בשיקול דעתו הבלעדי, ללא צורך בהודעה מוקדמת או התראה ומבלי
        שיהיה אחראי לנזק כלשהו עקב החלטתו. כמו כן, האתר רשאי להפסיק את השימוש
        שלך בו וכל תוכן או מידע שפרסמת בכל עת, ללא אזהרה מראש.
      </p>

      <h4>14. שינויים באתר, תקלות והפסקות שירות:</h4>
      <p>
        14.1. האתר שומר את הזכות לשנות מעת לעת או להסיר את תוכן האתר מכל סיבה
        שהיא לפי שיקול דעתו הבלעדית וללא הודעה מוקדמת. האתר אינו מחויב לעדכן
        מידע או תוכן כלשהו באתר.
      </p>
      <p>
        14.2. האתר לא יהיה אחראי כלפיך או כלפי צד שלישי כלשהו עבור שינוי, השעיה
        או הפסקת שירות כאמור.
      </p>
      <p>
        14.3. האתר אינו מתחייב ששירותי האתר לא יופרעו, יינתנו כסדרם או בלא
        הפסקות, יתקיימו בבטחה וללא טעויות ויהיו חסינים מפני גישה בלתי מורשית
        למחשבי האתר או מפני נזקים, קלקולים, תקלות או כשלים - והכל, בחומרה,
        בתוכנה, בקווי ובמערכות תקשורת אצל האתר או אצל מי מספקיו.
      </p>
      <p>
        14.4. האתר שומר על זכותו לשנות, בכל עת וללא הודעה מוקדמת, את התנאים
        וההתניות בתקנון זה החלים על השימוש באתר ושינויים אלו ייכנסו לתוקפם מיד
        עם הכנסתם לתקנון.
      </p>

      <h4>15. סמכות שיפוט:</h4>
      <p>
        15.1. על תקנון זה והשימוש באתר יחולו אך ורק דיני מדינת ישראל, אולם לא
        תהיה תחולה לכללי ברירת הדין הבינלאומי הקבועים בהם.
      </p>
      <p>
        15.2. לבתי המשפט במחוז המרכז תהיה סמכות שיפוט ייחודית בכל עניין הנובע
        ו/או הקשור לתקנון ו/או למחלוקות משפטיות שיתגלו בינך לבין האתר.
      </p>

      <h4>16. דיוק/ תיקונים טכניים ואחרים:</h4>
      <p>
        16.1. האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמות שהוא,
        ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין,
        יתכן ונפלו טעויות טכניות או אחרות במידע. האתר שומר על זכותו לתקן טעויות
        ו/או שגיאות ו/או אי דיוקים כאמור ולעדכן את המידע באתר בכל עת וללא הודעה
        מוקדמת.
      </p>

      <h4>17. מדיניות פרטיות:</h4>
      <p>17.1. האתר מכבד את פרטיות הלקוחות.</p>
      <p>
        17.2. בנוסף למידע אותו הנך מוסר בעת הרשמתך לאתר, האתר אוסף מידע מסוים
        אודות המחשב שלך, אשר באמצעותו הנך מבקר ומבצע פעולות באתר. המידע נאסף
        באופן אוטומטי (לרבות באמצעות שימוש ב "Cookies") והוא עשוי לכלול כתובות
        IP, סוג דפדפן, זמני גלישה וכניסה לאתר, אופן הגלישה והכלי בו הנך משתמש
        למטרת הגלישה, פרטים לגבי ספק האינטרנט שלך וכתובות אתרי האינטרנט מהם הגעת
        (המידע הנמסר בעת ההרשמה והמידע הנאסף על ידי האתר כאמור בפסקה זו יכונו
        יחד: "המידע הנאסף באתר"). המידע הנאסף באתר עשוי לשמש את האתר לצרכים
        הבאים:
      </p>
      <p>17.2.1. לספק לך שירותים ולשפר את האתר ו/או את השירותים;</p>
      <p>17.2.2. תפעולו התקין של האתר;</p>
      <p>17.2.3. לנתח את ולנהל את האתר באופן תקין;</p>
      <p>17.2.4. שיפור שירות הלקוחות של האתר;</p>
      <p>17.2.5. ליצירת קשר או לספק לך נתונים בקשר לאתר או לשירות;</p>
      <p>
        17.2.6. בכדי לספק לך את המידע שביקשת או מידע נוסף אשר באתר מאמינים שהוא
        עשוי לעניין אותך, מעת לעת;
      </p>
      <p>17.2.7. בכדי להתאים פרסומות ומידע מסחרי על פי העדפותיך האישיות;</p>
      <p>
        17.2.8. בכדי ליצור עמך קשר על ידי נציגי האתר בנוגע לשירותים אותם האתר
        מספק;
      </p>
      <p>
        17.2.9. בכדי לבצע סקרי לקוחות ו/או מחקרי שיווק אשר האתר עורך מעת לעת.
      </p>
      <p>
        17.3. האתר ו/או מי מטעמו לא יגלו ו/או ימכרו כל מידע הנאסף באתר לכל צד
        שלישי כלשהו, וזאת למעט למי מטעמם להם הם מספקים את המידע הנאסף באתר אך
        ורק לצורך מתן שירות והשלמת הליך רכישה שבוצע באתר, ואשר אף הם מחויבים
        להגנה מלאה על סודיות המידע הנאסף באתר.
      </p>
      <p>
        17.4. יחד עם זאת, האתר ו/או מי מטעמו יהיו רשאים להעביר מידע הנאסף באתר
        לצדדים שלישיים (למעט מידע רגיש כגון פרטי אשראי, מספרי תעודות זהות
        וכיו״ב) בכל אחד מהמקרים הבאים:
      </p>
      <p>17.4.1. ניתנה לכך הסכמתך מראש ובכתב;</p>
      <p>
        17.4.2. האתר ו/או מי מטעמו נדרשים על פי חוק להעביר את המידע האמור, למשל,
        מכוח צו בית משפט;
      </p>
      <p>
        17.4.3. על מנת להגן על זכויותיהם המשפטיות והקנייניות של האתר ו/או מי
        מטעמו בכל הנוגע לאתר זה;
      </p>
      <p>
        17.4.4. במקרה של מחלוקת משפטית בינך לבין האתר אשר תחייב חשיפת הפרטים;
      </p>
      <p>
        17.4.5. אם תפר את התקנון של האתר או אם תבצע באמצעות האתר, או בקשר כלשהו
        עם האתר, פעולות מנוגדות או הנחזות כמנוגדות לדין, או כל ניסיון לבצע
        פעולות כאלה;
      </p>
      <p>
        17.4.6. בכל מקרה שהאתר יסבור, כי מסירת המידע הנאסף נחוצה על מנת למנוע
        נזק חמור לגוף המשתמש או לרכושו או לגופו או לרכושו של צד שלישי;
      </p>
      <p>
        17.4.7. שיתוף רשתות חברתיות כגון פייסבוק, אינסטגרם ואחרות ומפרסמים
        נוספים ברשת כגון גוגל, טאבולה ואחרים.
      </p>
      <p>
        17.4.8. אם האתר ימוזג לתוך פעילות גוף אחר או אם האתר יעבור לבעלות תאגיד
        אחר ניתן יהיה להעביר לתאגיד החדש את המידע הקיים באתר, אבל רק במקרה
        שהתאגיד יתחייב לשמור על מדיניות פרטיות זו.
      </p>
      <p>
        17.5. חשוב לזכור שלא ניתן לערוב במאת האחוזים מפני פעילות עוינת ונחושה
        מצד גורמים זרים ולכן אין בפעולות אלה בטחון מוחלט והאתר לא מתחייב
        שהשירותים באתר יהיו חסינים באופן מוחלט מפני גישה בלתי מורשית למידע הנאסף
        בו.
      </p>

      <h4>18. שיפוי:</h4>
      <p>
        18.1. על הגולש לשפות את האתר, עובדיו, מנהליו, שותפיו העסקיים או מי מטעמם
        בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם - ובכלל זה שכ"ט
        עו"ד והוצאות משפט עקב הפרת התקנון.
      </p>

      <h4>19. שונות:</h4>
      <p>
        19.1. תנאי תקנון זה מהווים את כל ההסכמות וההבנות בנוגע לשימוש באתר. אי
        מימוש או אכיפה של זכות או הוראה בתקנון זה, לא תחשב כוויתור מצד האתר על
        מימוש הזכות או אכיפת ההוראה. האתר יהיה רשאי להמחות לאחרים את כל או חלק
        מזכויותיו ו/או חובותיו בתקנון זה.
      </p>

      <h4>20. צרו קשר:</h4>
      <p>
        20.1. האתר מקפיד על קיום הוראות החוק ומכבד את זכותם של משתמשי האתר
        ואחרים לפרטיות ולשם טוב. אם אתה סבור כי פורסם באתר תוכן הפוגע בך מטעם
        כלשהו, נא פנה אלינו לפי הפרטים שלהלן ואנו נשתדל לטפל בפנייתך בכל ההקדם.
        פניות כאמור ניתן להעביר באמצעים הבאים:
      </p>
      <p>כתובת:</p>
      <p>________________;</p>
      <p>טלפון:</p>
      <p>_________________;</p>
      <p>דוא"ל:</p>
      <p>_________________</p>

      <p>
        כל הזכויות בתקנון זה שמורות לדניאל בחרי עורכי דין ואין להעתיק, לשכפל או
        להפיץ אותו.
      </p>
      <p>עדכון אחרון: פברואר 2023</p>
    </div>
  );
};

export default TermsAndConditions;
