import { round2 } from "../contexts/AppContext";
// import { parseNumber } from "../services/currencyService";

export function handleInputChange(e, cards, setCards, index) {
  let { name, value } = e.target;

  // Change caret color to default when the user starts typing
  if (value.length > 0) {
    e.target.style.caretColor = "auto";
  } else {
    e.target.style.caretColor = "transparent";
  }

  if (name === "cardnumber") {
    // Make spacing
    value = formatCreditCardNumber(value);
  }

  if (name === "expdate") {
    // Make automatic '/'
    if (value.length === 2 && value.indexOf("/") === -1) {
      value += "/";
    }
  }

  const updatedCards = [...cards];
  const updatedCard = { ...cards[index], [name]: value };
  updatedCards[index] = updatedCard;

  setCards(updatedCards);
}

const formatCreditCardNumber = (value) => {
  const regex = /(\d{1,4})/g;
  const parts = value.match(regex);

  if (parts) {
    const formattedValue = parts.join(" ");

    return formattedValue;
  }
};

// const blockInput = (index) => {
//   const currentCardWrapper = document.getElementById(
//     `new-card-number-${index}`
//   );

//   let inputs = currentCardWrapper.getElementsByTagName("input");

//   for (let i = 0; i < inputs.length; i++) {
//     // Block all inputs except amount for repayment from the same card
//     if (inputs[i].name !== "amount") {
//       inputs[i].readOnly = true;
//     }
//   }
// };

export const getTotalFeesCount = (tashFees) =>
  tashFees?.reduce((total, entry) => total + entry.tashFee, 0) || 0;

export const getTotalSumPaid = (sumpaid, tashfeesCount) => {
  return round2(sumpaid ? sumpaid - tashfeesCount : 0);
};
