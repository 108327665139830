import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InnerRoutes from "./Routes/Routes";
import "./App.css";
import { SearchProvider } from "./contexts/SearchContext";
import { SelectedResultProvider } from "./contexts/SelectedResultContext";
import { AppProvider } from "./contexts/AppContext";
import { TimerProvider } from "./contexts/TimerContext";
import { OrderProvider } from "./contexts/OrderContext";

const App = () => {
  const { i18n } = useTranslation();

  // Change direction of website accourdingly to current langugae
  useEffect(() => {
    // Set the initial direction based on the current language
    document.documentElement.setAttribute("dir", i18n.dir());

    // Update the direction on language change
    i18n.on("languageChanged", (lng) => {
      document.documentElement.setAttribute("dir", i18n.dir());
    });
  }, [i18n]);

  // WARNING: KEEP SelectedResultProvider INSIDE SearchProvider
  return (
    <TimerProvider>
      <SearchProvider>
        <SelectedResultProvider>
          <OrderProvider>
            <InnerRoutes />
          </OrderProvider>
        </SelectedResultProvider>
      </SearchProvider>
    </TimerProvider>
  );
};

export default App;
