import React from "react";
import MasterCardIcon from "../../../assets/images/payment/card.png";
import AmericanExpressIcon from "../../../assets/images/payment/american-express.png";
import visa1Icon from "../../../assets/images/payment/visa1.png";
import visaIcon from "../../../assets/images/payment/visa.png";

const PaymentIcons = () => {
  return (
    <div className="ci-pics">
      <div className="payment-agent-icons-wrapper">
        <img
          src={MasterCardIcon}
          className="payment-agent-icons"
          alt="Master Card Icon"
        ></img>
        <img
          src={AmericanExpressIcon}
          className="payment-agent-icons"
          alt="American Express icon"
        ></img>
        <img
          src={visa1Icon}
          className="payment-agent-icons"
          alt="visa icon"
        ></img>
        <img
          src={visaIcon}
          className="payment-agent-icons"
          alt="visa icon"
        ></img>
      </div>

      <label className="ci-pics-title">תשלום מאובטח</label>
    </div>
  );
};

export default PaymentIcons;
