import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoading from "../../../../GeneralComponents/CustomLoading/customloading";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import "./passangerdata.css";
import NotificationPass from "./subComponents/Notifications/notification";
import RoomPassInfo from "./subComponents/RoomPassInfo/roompassinfo";
import { convertPrice } from "../../../../../services/currencyService";
import {
  bookHotel,
  cancellationPolicies,
} from "../../../../../services/fhpService";
import CustomAlertAgent from "../../../../GeneralComponents/CustomAlertAgent/customalertagent";
import TermsAndConditions from "../../../../../Pages/TermsAndConditions/TermsAndConditions";
import { useSearch } from "../../../../../contexts/SearchContext";
import { Loader } from "../../../../Loader/Loader";
import { useOrder } from "../../../../../contexts/OrderContext";
import { useAppContext } from "../../../../../contexts/AppContext";

const PassangerData = () => {
  // Translator
  const { getAgentCommissionPercent } = useAppContext();
  const { currentHotelOrder, setCurrentHotelOrder, removeCurrentHotelOrder } =
    useOrder();
  const { t } = useTranslation();
  const { passengerInfo, setPassengerInfo } = useSearch();
  // Navigator
  const navigate = useNavigate();
  const modalRef = React.createRef();
  const inputRef = React.createRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) // Check if click is not on the model
      ) {
        setIsPopCancelationConditions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, modalRef]);

  // Constats
  const Rooms = currentHotelOrder.searchPhrase.rooms; // Rooms & passengers ( Adults and Kids)

  let RoomData = [];

  if (Rooms) {
    const numberOfRooms = Rooms.length;
    const adultsCountPerRoom = Rooms.map((room) => room.AdultsCount); //Amount of adults minus the two existing ones
    const kidsCountPerRoom = Rooms.map((room) =>
      room.KidsAges ? room.KidsAges.length : 0
    );

    // Create for each room basic stractre with the right amount of adults and kids
    for (let i = 0; i < numberOfRooms; i++) {
      let roomArray = [];

      // Add 2 Main adults (For the first room Create a 1 Contact Person)
      if (i === 0) {
        roomArray.push(
          {
            firstname: null,
            lastname: null,
            cell: null,
            email: null,
            isKid: false,
          },
          { firstname: null, lastname: null, isKid: false }
        );
      } else {
        roomArray.push(
          { firstname: null, lastname: null, isKid: false },
          { firstname: null, lastname: null, isKid: false }
        );
      }

      // Add additonal adults
      for (let j = 0; j < adultsCountPerRoom[i] - 2; j++) {
        roomArray.push({ firstname: null, lastname: null, isKid: false });
      }

      // Add kids
      for (let k = 0; k < kidsCountPerRoom[i]; k++) {
        roomArray.push({ firstname: null, lastname: null, isKid: true });
      }

      RoomData.push(roomArray);
    }
  }

  const initialPassangerInfo = RoomData;

  // Create an array of RoomData objects repeated Rooms.length times
  const [specialReq, setSpecialReq] = useState("");
  const [isConditionsConfirmed, setIsConditionsConfirmed] = useState(false);
  const [isCancelHotel, setIsCancelHotel] = useState(false);
  const [dataCancelHotel, setDataCancelHotel] = useState([]);
  const [dataCancelHotelRemarks, setDataCancelHotelRemarks] = useState([]);
  const [isCancelFlight, setIsCancelFlight] = useState(false);
  const [dataCancelFlight, setDataCancelFlight] = useState([]);
  const [isPopCancelationConditions, setIsPopCancelationConditions] =
    useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitLoadingPayment, setSubmitLoadingPayment] = useState(false);

  useEffect(() => {
    !passengerInfo && setPassengerInfo(initialPassangerInfo);
  }, [passengerInfo]);

  // Useeffect - get cancalation policys
  useEffect(() => {
    const getCancellationPolicies = async () => {
      const data = {
        session: currentHotelOrder.searchPhrase.session,
        hotelID: parseInt(currentHotelOrder.searchPhrase.hotelID),
        packageID: currentHotelOrder.selectedHotelPackage.packageID,
      };

      const res = await cancellationPolicies(data);
      if (!res.data) {
        return;
      }

      setDataCancelHotel(res.data.policies);

      setDataCancelHotelRemarks(res.data.remarks);
    };

    if (
      currentHotelOrder &&
      dataCancelHotel &&
      (!dataCancelHotel.length || !dataCancelHotelRemarks.length)
    ) {
      getCancellationPolicies();
    }
  }, [currentHotelOrder]);

  // --- Functions
  const handleSubmit = (e) => {
    e.preventDefault();

    // Set loading on
    setSubmitLoadingPayment(true);
    disableOtherSubmitBtn("submit2", true);

    if (currentHotelOrder) {
      // Add passenger data
      currentHotelOrder["passangerData"] = {
        passengerInfo: passengerInfo,
        specialReq: specialReq,
      };

      // Save the updates
      setCurrentHotelOrder(currentHotelOrder);

      // Navigate to payment page
      navigate(`/p/dashboard/payment/newOrder`);
    } else {
      CustomAlertAgent(t("warnings.somthingwentwrong"), t("warnings.tryagain"));
    }

    // Set loading on
    setSubmitLoadingPayment(false);
    disableOtherSubmitBtn("submit2", false);
  };

  // Triggers
  const showMoreHotelCancelation = () => {
    setIsCancelHotel(!isCancelHotel);
  };

  const showMoreFlightCancelation = () => {
    setIsCancelFlight(!isCancelFlight);
  };

  const openCancelationConditions = () => {
    setIsPopCancelationConditions(!isPopCancelationConditions);
  };

  // For pay later check if all inputs were answered
  const checkRequiredInputs = () => {
    // Check if all required inputs are filled
    const requiredInputs = document.querySelectorAll(
      ".passangerData-component input"
    );

    for (const input of requiredInputs) {
      // Check if it's a checkbox and is checked, or if it's a text input and has a value
      if (input.type === "checkbox" && !input.checked) {
        CustomAlertAgent(t("warnings.fillallinputs"), t("warnings.goback"));
        return false;
      } else if (input.type === "text" && input.value.trim() === "") {
        CustomAlertAgent(t("warnings.fillallinputs"), t("warnings.goback"));
        return false;
      }
    }
    return true;
  };

  // For new order
  const bookWithOutPay = async (e) => {
    e.preventDefault();

    const gatherPayMentData = (paid) => {
      // Get Data
      const loggedUser = JSON.parse(localStorage.getItem("UserInfo"));
      const clientData = JSON.parse(
        localStorage.getItem("agent-saved-client-info")
      );
      const { selectedRoom } = currentHotelOrder;

      // Arrange passsangers
      const passangerData = passengerInfo;

      let alteredPassangerDataAdults = [];
      let alteredPassangerDataKids = [];

      passangerData.forEach((room) => {
        room.forEach((passenger) => {
          let PersonDetails = {
            PersonDetails: {
              Name: {
                GivenName: passenger.firstname,
                NamePrefix: "Mr",
                Surname: passenger.lastname,
              },
            },
          };

          if (!passenger.isKid) {
            alteredPassangerDataAdults.push(PersonDetails);
          } else {
            alteredPassangerDataKids.push(PersonDetails);
          }
        });
      });

      const alteredPassangerData = [
        ...alteredPassangerDataAdults,
        ...alteredPassangerDataKids,
      ];

      const currentRoom = currentHotelOrder.selectedHotel.Result.find(
        (r) => r.PackageId === currentHotelOrder.selectedHotelPackage.packageID
      );

      let data = {
        session: currentHotelOrder.searchPhrase.session,
        checkIn: String(currentHotelOrder.searchPhrase.checkIn),
        checkOut: String(currentHotelOrder.searchPhrase.checkOut),
        package: { ...currentRoom, images: selectedRoom?.images },
        passengers: alteredPassangerData,
        commission: getAgentCommissionPercent(),
        agentID: loggedUser.id, // ?
        clientID: -1, // ?
        fullname: clientData.fullname,
        cell: clientData.phone,
        email: clientData.email,
        hotel: currentHotelOrder.selectedHotel,
        paid: paid,
        remarks: specialReq,
        includesTaxes: currentHotelOrder.selectedHotelPackage.includesTaxes,
        handleFee: currentHotelOrder.ourPrice.handleFeeData.value,
        handleFeeOption: currentHotelOrder.ourPrice.handleFeeData.type,
        totalPrice: currentHotelOrder.ourPrice.priceWithCreditCardFee,
        cardFee: 0, //cardFee,
        cards: [],
      };

      return data;
    };

    // Check required inputs
    if (!checkRequiredInputs()) {
      return;
    }

    // Set loading on
    setSubmitLoading(true);
    disableOtherSubmitBtn("submit1", true);

    const data = gatherPayMentData(false); // false stands for pay later

    // Book hotel
    const res = await bookHotel(data);

    if (res.data && res.data.status === "ok") {
      // Reset Order
      removeCurrentHotelOrder();
      localStorage.removeItem("agent-saved-client-info");

      navigate("/p/dashboard/success/" + res.data.id);
    } else {
      // Display alert
      CustomAlertAgent(t("warnings.somthingwentwrong"), t("warnings.tryagain"));
    }

    // Set loading off
    setSubmitLoading(false);
    disableOtherSubmitBtn("submit1", false);
  };

  const disableOtherSubmitBtn = (idOfButton, state) => {
    const button = document.getElementById(idOfButton);

    if (button) {
      button.disabled = state;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="passangerData-component">
      {/* Notification - For hotel Choosing */}
      <div className="tw-py-2">
        <NotificationPass
          title={t("completepassengers.greatchoice")}
          subtitle={t("completepassengers.greatchoicetext")}
        />
      </div>

      {/* Room Displayer */}
      {!passengerInfo ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="passangerData-room-pass-info-wrapper">
          {Rooms &&
            Rooms.map((room, index) => (
              <RoomPassInfo
                key={index}
                roomInfo={room}
                roomNumber={index}
                roomName={
                  currentHotelOrder.selectedHotelPackage.roomNames[index]
                }
              />
            ))}
        </div>
      )}

      {/* Special Requests */}
      <div className="passangerData-special-requests-wrapper">
        <label className="passangerData-special-requests-title">
          {t("completepassengers.specialrequests")}
        </label>

        <textarea
          onChange={(e) => {
            setSpecialReq(e.target.value);
          }}
          className="passangerData-special-requests-textarea"
          rows="4"
          cols="100%"
          placeholder={t("completepassengers.specialrequeststext")}
        ></textarea>
      </div>

      {/* Cancelation Policy - Hotel */}
      <div className={`passangerData-cancelation-policy-wrapper`}>
        <label className="passangerData-cancelation-policy-title">
          {t("completepassengers.policycanclationhotel")}
        </label>

        <label className="passangerData-cancelation-policy-sub-title">
          {currentHotelOrder.selectedHotel.HotelName}
        </label>

        <div
          className={`passangerData-cancelation-policy-cancel-wrapper  ${
            isCancelHotel ? "passangerData-cancelation-policy-wrapper-wide" : ""
          }`}
        >
          <div className="passangerData-cancelation-policy-cancel-heading-wrapper">
            {currentHotelOrder.selectedHotelPackage.refundability ? (
              <label className="passangerData-cancelation-policy-cancel-title">
                {t("completepassengers.freecancelation")}{" "}
                {currentHotelOrder.selectedHotelPackage.refundableUntil}
              </label>
            ) : (
              <>
                <label className="passangerData-cancelation-policy-cancel-title-not-free">
                  {t("completepassengers.nofreecancelation")}
                  <span style={{ marginRight: 15 }}>-</span>
                  <span style={{ marginRight: 15 }}>
                    {convertPrice(dataCancelHotel[0]?.fee)}
                  </span>
                </label>
              </>
            )}

            {!isCancelHotel && (
              <div
                onClick={showMoreHotelCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronDown className="assangerData-showmore-arrow"></BiChevronDown>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showmore")}
                </label>
              </div>
            )}
          </div>

          {dataCancelHotelRemarks && isCancelHotel && (
            <div className="passangerData-cancelation-entrys-button-wrapper">
              <div className="passangerData-cancelation-entrys-remark-wrapper">
                <div
                  className="passangerData-cancelation-policy-remarks"
                  dangerouslySetInnerHTML={{ __html: dataCancelHotelRemarks }}
                />

                {dataCancelHotel.length > 0 ? (
                  <div className="passangerData-cancelation-entrys">
                    {dataCancelHotel.map((cancelRowData, index) => (
                      <div key={index}>
                        <label className="passangerData-cancelation-entry">
                          {t("completepassengers.startsat")}
                          {cancelRowData.from} {t("completepassengers.feesare")}{" "}
                          {
                            <label id="price-fee">
                              {convertPrice(
                                currentHotelOrder.ourPrice
                                  .priceWithCreditCardFee
                              )}
                            </label>
                          }
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="passangerData-cancelation-empty">
                    No Canclation Policys Available
                  </div>
                )}
              </div>

              <div
                onClick={showMoreHotelCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronUp className="assangerData-showmore-arrow"></BiChevronUp>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showless")}
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Cancelation Policy - Flight */}
      {/* <div className="passangerData-cancelation-policy-wrapper">
        <label className="passangerData-cancelation-policy-title">
          {t("completepassengers.policycanclationflight")}
        </label>

        <label className="passangerData-cancelation-policy-sub-title">
          flight name...
        </label>

        <div
          className={`passangerData-cancelation-policy-cancel-wrapper  ${
            isCancelFlight
              ? "passangerData-cancelation-policy-wrapper-wide"
              : ""
          }`}
        >
          <div className="passangerData-cancelation-policy-cancel-heading-wrapper">
            <label className="passangerData-cancelation-policy-cancel-title">
              {t("completepassengers.freecancelation")}
            </label>

            {!isCancelFlight && (
              <div
                onClick={showMoreFlightCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronDown className="assangerData-showmore-arrow"></BiChevronDown>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showmore")}
                </label>
              </div>
            )}
          </div>

          {isCancelFlight && (
            <div className="passangerData-cancelation-entrys-button-wrapper">
              {dataCancelFlight.length > 0 ? (
                <div className="passangerData-cancelation-entrys">
                  {dataCancelFlight.map((cancelRowData, index) => (
                    <div key={index}>
                      <label className="passangerData-cancelation-entry">
                        {t("completepassengers.startsat")} {cancelRowData.from}{" "}
                        {t("completepassengers.startsat")} {cancelRowData.to}{" "}
                        {t("completepassengers.feesare")}{" "}
                        {
                          <label id="price-fee">
                            {convertPrice(cancelRowData.fee)}
                          </label>
                        }
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="passangerData-cancelation-empty">
                  No Canclation Policys Available
                </div>
              )}

              <div
                onClick={showMoreFlightCancelation}
                className="passangerData-showmore-wrapper"
              >
                <BiChevronUp className="assangerData-showmore-arrow"></BiChevronUp>
                <label className="passangerData-cancelation-policy-cancel-more">
                  {t("completepassengers.showless")}
                </label>
              </div>
            </div>
          )}
        </div>
      </div> */}

      {/* Payment */}
      <div className="passangerData-checkout-wrapper">
        <div className="passangerData-checkout-checkbox-wrapper">
          <input
            type="checkbox"
            className="passangerData-checkout-checkbox"
            required
            onChange={(e) => setIsConditionsConfirmed(e.target.checked)}
          ></input>
          <span className="passangerData-checkout-checkbox-text-black">
            {`${t("completepassengers.approve")}`}
          </span>
          <span
            className="passangerData-checkout-checkbox-text"
            onClick={openCancelationConditions}
          >
            {t("completepassengers.confirmconditions")}
          </span>

          {isPopCancelationConditions ? (
            <div className="passangerData-popup-wrapper">
              <div ref={modalRef} className="passangerData-popup-frame">
                <TermsAndConditions />

                <div className="passangerData-popup-agree-btn-wrapper">
                  <button
                    type="button"
                    className="passangerData-popup-agree-btn"
                    onClick={openCancelationConditions}
                  >
                    {t("completepassengers.close")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="passangerData-checkout-button-wrapper">
          <div className="passangerData-submit-button-wrapper">
            {!submitLoadingPayment ? (
              <button
                type="submit"
                id="submit1"
                className={
                  !isConditionsConfirmed
                    ? "button-disabled"
                    : "passangerData-checkout-button"
                }
                disabled={!isConditionsConfirmed}
              >
                {t("completepassengers.pay")}
              </button>
            ) : (
              <CustomLoading
                classLoading={""}
                labelLoading={t("completepassengers.pay")}
              />
            )}
          </div>

          <div className="passangerData-submit-button-wrapper">
            {currentHotelOrder.selectedHotelPackage.refundability &&
              (!submitLoading ? (
                <button
                  type="button"
                  id="submit2"
                  className={
                    !isConditionsConfirmed
                      ? "button-disabled"
                      : "passangerData-submit-button-pay-later"
                  }
                  onClick={bookWithOutPay}
                  disabled={!isConditionsConfirmed}
                >
                  {t("completepassengers.paylater")}
                </button>
              ) : (
                <CustomLoading
                  classLoading={""}
                  labelLoading={t("completepassengers.paylater")}
                />
              ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default PassangerData;

//{
//     "adults": [
//         {
//             "firstname": "sfdsdf",
//             "lastname": null,
//             "cell": "",
//             "email": ""
//         },
//         {
//             "firstname": "sfdsdf",
//             "lastname": null,
//             "cell": "",
//             "email": ""
//         }
//     ],
//     "kids": []
// }
