import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Slider } from "@mui/material";

import "./Filters.scss";

const PriceFilter = ({
  onFilterChange,
  filterName,
  placeHolder,
  lowValue = 1,
  highValue = 1000000,
  curreny = "",
  resultsRangePrice = {
    high: 10,
    low: 1,
  },
  setPriceSort,
  priceSort,
}) => {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);

  const changeExpand = () => {
    setIsExpand((prevState) => !prevState);
    onFilterChange();
    setPriceSort();
  };

  const handlePriceValueChange = (e, newPrice) => {
    onFilterChange(newPrice);
  };

  return (
    <div className="hotelFilters-filter-wrapper">
      <div
        onClick={changeExpand}
        className={`hotelFilters-filters-title-wrapper ${
          document.documentElement.dir === "ltr"
            ? "direction-rtl"
            : "direction-ltr"
        }  `}
      >
        <span className="hotelFilters-filters-title">{filterName}</span>

        {isExpand ? (
          <IoIosArrowUp className="hotelFilters-filters-arrow" />
        ) : (
          <IoIosArrowDown className="hotelFilters-filters-arrow" />
        )}
      </div>
      {isExpand && (
        <div className="hotelfilters-price-wrapper">
          <Slider
            getAriaLabel={() => "Price range"}
            value={[lowValue, highValue]}
            color="primary"
            style={{ color: "#ff3703" }}
            onChange={handlePriceValueChange}
            valueLabelDisplay="auto"
            step={10}
            min={resultsRangePrice.low}
            max={resultsRangePrice.high}
          />
          <div className="hotelfilters-price-values">
            <span className="hotelfilters-price-values-title">
              {
                <span id="price-filtration-from">
                  {resultsRangePrice.low}
                  {curreny}
                </span>
              }
            </span>
            <span className="hotelfilters-price-values-title">
              {
                <span id="price-filtration-until">
                  {resultsRangePrice.high}
                  {curreny}
                </span>
              }
            </span>
          </div>
          <form className="hotelfilters-price-sort-wrapper">
            <label
              className="hotelfilters-price-sort-radio"
              style={
                document.documentElement.dir === "ltr"
                  ? { direction: "rtl" }
                  : {}
              }
            >
              <input
                onChange={(e) => {
                  setPriceSort(true);
                }}
                checked={priceSort}
                type="radio"
                name="priceSorting"
                value="pricehightolow"
                className="hotelfilters-price-sort-radio-input"
              />
              <span className="hotelfilters-price-sort-radio-title">
                {t("filters.pricehightolow")}
              </span>
            </label>

            <label
              className="hotelfilters-price-sort-radio"
              style={
                document.documentElement.dir === "ltr"
                  ? { direction: "rtl" }
                  : {}
              }
            >
              <input
                onChange={(e) => {
                  setPriceSort(false);
                }}
                checked={!priceSort}
                type="radio"
                name="priceSorting"
                value="pricelowtohigh"
                className="hotelfilters-price-sort-radio-input"
              />
              <span className="hotelfilters-price-sort-radio-title">
                {t("filters.pricelowtohigh")}
              </span>
            </label>
          </form>
        </div>
      )}
    </div>
  );
};

export default PriceFilter;
