import React from "react";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

const TextOrLoader = ({ children, loading = false, ...params }) => {
  return (
    <>{!children || loading ? <SkeletonLoader {...params} /> : children}</>
  );
};

export default TextOrLoader;
