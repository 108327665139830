import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";
import {getUserInfo} from "../../../../services/localStorage";

const Crubms = ({ agent, client, orderId }) => {
  // Translator
  const { t } = useTranslation();

  return (
    <>
      <div className="crumbs-wrapper">
        <div className="orderSummery-header crumbs-header">
          <label id="option-1" className="orderSummery-header-item">
            {t("ordersummery.filluserdetails")}
          </label>
          <label id="option-2" className="orderSummery-header-item">
            {t("ordersummery.flights")}
          </label>
          <label id="option-3" className="orderSummery-header-item">
            {t("ordersummery.hotel")}
          </label>
          <label
            id="option-4"
            className="orderSummery-header-item orderSummery-header-item-selected"
          >
            {t("ordersummery.ordersummery")}
          </label>
        </div>

        <div className="crumbs-details-wrapper">
          <label className="crumbs-details-title">
            {t("ordersummery.agent")}:{" "}
            <span className="crumbs-details-value">{agent}</span>
          </label>
          <label className="crumbs-details-title">
            {t("ordersummery.client")}:{" "}
            <span className="crumbs-details-value">{client}</span>
          </label>
          <label className="crumbs-details-title">
            {t("ordersummery.orderNumber")}:{" "}
            <span className="crumbs-details-value">{orderId}</span>
          </label>
        </div>
      </div>
    </>
  );
};

export default Crubms;
