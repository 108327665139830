import { createContext, useContext, useState, useEffect } from "react";

const TimerContext = createContext();

const TWENTY_MIN = 1800;

export const TimerProvider = ({ children }) => {
  const [timeLeft, setTimeLeft] = useState(TWENTY_MIN);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <TimerContext.Provider value={{ timeLeft, setTimeLeft }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (context === undefined) {
    throw new Error("useTimer must be used within a TimerProvider");
  }
  return context;
};

export default useTimer;
