import React from "react";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";
import PackagesProfile from "./PackagesProfile";
import { useParams } from "react-router-dom";
import { findHotelDealsById, getLdsOrder } from "../../../services/fhpService";
import { Loader } from "../../../Components/Loader/Loader";
import { countAdultsAndKids } from "../../../utils/LDSHelpers";

const SavedPackagesProfile = () => {
  const { id } = useParams();
  const {
    setRoomsAndPaxInfo,
    setSelectedResult,
    setOurSavedLDSOrder,
    setLdsSupplement,
    setHandleFeeData,
    setAgentData,
  } = useSelectedResult();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    getLdsOrder({ id })
      .then((res) => {
        setOurSavedLDSOrder(res.data);
        setAgentData(res.data.agent);

        const { passengersinfo, dealid, dep_date, ret_date, supplement } =
          res.data;
        setRoomsAndPaxInfo(passengersinfo);
        setLdsSupplement(supplement);
        getLdsData(dealid, dep_date, ret_date, passengersinfo);
        if (res.data.ourprice?.handleFeeData) {
          setHandleFeeData(res.data.ourprice.handleFeeData);
        }
      })
      .catch((err) => {
        console.log("ERROR: " + err);

        setError(true);
      });
  }, []);

  const getLdsData = (dealid, dep_date, ret_date, passengersInfo) => {
    const { totalAdults, totalKids, totalInfant } =
      countAdultsAndKids(passengersInfo);

    findHotelDealsById({
      ldsDealId: dealid,
      depDate: dep_date,
      retDate: ret_date,
      numOfAdults: totalAdults,
      numOfChildren: totalKids,
      numOfInfants: totalInfant,
    }).then((res) => {
      const status = res.data?.opStatus?.status;
      if (status == "NO_RESULTS") {
        console.log("ERROR: No results");
        setError(true);
        return;
      }
      const deal = res.data.deal;
      setSelectedResult(deal);
      setLoading(false);
    });
  };

  if (error) {
    return (
      <div style={{ color: "red", fontSize: 30 }}>
        אירעה שגיאה, אנה נסה שנית מאוחר יותר
      </div>
    );
  }
  if (loading) {
    return <Loader size="large" />;
  }

  return <PackagesProfile orderSaved={true} ourLdsId={id} />;
};

export default SavedPackagesProfile;
