import React, { useEffect, useState } from "react";
import isArray from "lodash/isArray";

import { useParams } from "react-router-dom";
import { getLdsOrder } from "../../../../services/fhpService";
import translateLDSDestinations from "../../../../services/translateLDSDestinations";

import { Loader } from "../../../../Components/Loader/Loader";
import OrderSummary from "../OrderSummary";
import { useSelectedResult } from "../../../../contexts/SelectedResultContext";

const PackageOrderSummary = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { ourPrice } = useSelectedResult();

  useEffect(() => {
    getLdsOrder({ id })
      .then((res) => {
        setOrder(res.data);
        setLoading(false);
        const { order_response } = res.data;

        if (!order_response) {
          setError(true);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
        setError(true);
      });
  }, []);

  if (error) {
    let errMsg = "אירעה שגיאה בתהליך ההזמנה, אנא פנה לתמיכה";
    const opStatus = order?.order_response?.opStatus;
    if (opStatus.status === "INVALID_PARAM") {
      errMsg = opStatus.message;
    }
    return <div style={{ color: "red", fontSize: 30 }}>{errMsg}</div>;
  }
  if (loading) {
    return <Loader size="large" />;
  }

  const opStatus = order?.order_response?.opStatus;
  if (opStatus.status === "INVALID_PARAM") {
    setError(true);
    return <div>{opStatus.message}</div>;
  }

  const { hotel, pax } = order?.order_response?.reservation;

  const mainPassanger = isArray(pax) ? pax[0] : pax;
  const orderFullName = `${mainPassanger?.paxTitle}  ${mainPassanger?.firstName}  ${mainPassanger?.lastName}  `;

  const totalPeopleCounts = order?.passengersinfo?.reduce(
    (acc, curr) => {
      acc.totalAdults += curr.adults.length;
      acc.totalKids += curr.kids.length;
      return acc;
    },
    { totalAdults: 0, totalKids: 0 }
  );

  const orderProps = {
    loading,
    hotelCountry: translateLDSDestinations(hotel?.hotelCity).CITYNAME_HEB,
    hotelname: hotel?.hotelName,
    hotelMainImage: hotel?.resourceUrl,
    fullname: orderFullName,
    details: [
      { title: "סטטוס ההזמנה", value: opStatus?.status },
      { title: "שם המלון", value: hotel?.hotelName },
      { title: "מיקום המלון", value: hotel?.location },
      { title: "סה״כ מבוגרים", value: totalPeopleCounts?.totalAdults },
      { title: "סה״כ ילדים", value: totalPeopleCounts?.totalKids || "-" },
      { title: "מספר לילות", value: hotel?.nights },
      { title: "כניסה", value: hotel?.checkInDate },
      { title: "עזיבה", value: hotel?.checkOutDate },
      { title: "עלות ההזמנה", value: ourPrice.priceWithCreditCardFee },
    ],
  };

  return <OrderSummary {...orderProps} />;
};

export default PackageOrderSummary;
