export function getCurrentDate() {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = String(today.getFullYear());

  return { year, month, day };
}

export function getNextMonth(month, year, monthGap = 1) {
  const currentMonthNum = parseInt(month, 10);
  const gapMonths = currentMonthNum - 1 + monthGap;

  let nextMonthNum = (gapMonths % 12) + 1;
  const yearOffset = Math.floor(gapMonths / 12);

  const nextYear = parseInt(year) + yearOffset;
  return {
    month: String(nextMonthNum).padStart(2, "0"),
    year: String(nextYear),
  };
}

export function parseStringToDate(dd_mm_yyyy) {
  if (!dd_mm_yyyy || typeof dd_mm_yyyy !== "string") {
    return dd_mm_yyyy;
  }
  const parts = dd_mm_yyyy.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JavaScript
  const year = parseInt(parts[2], 10);
  return new Date(year, month, day);
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getAge(birthdateString) {
  const birthdate = new Date(birthdateString);
  const currentDate = new Date();
  const ageDifference = currentDate - birthdate;
  return Math.floor(ageDifference / (1000 * 60 * 60 * 24 * 365.25));
}
