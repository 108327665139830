import React from "react";
import visa1Icon from "../../../assets/images/payment/visa1.png";
import { useTranslation } from "react-i18next";
import { parseNumber } from "../../../services/currencyService";

export const CardHolderNameInput = ({
  handleInputChange,
  handleMarkEdit,
  handleUnMarkEdit,
  value,
  ...params
}) => {
  const { t } = useTranslation();
  return (
    <div className="ci-nd-item ci-nd-item-n">
      <input
        name="cardholdername"
        type="text"
        className="payment-agent-input"
        onChange={handleInputChange}
        onFocus={handleMarkEdit}
        onBlur={handleUnMarkEdit}
        placeholder={t("paymentagent.cardholdername")}
        pattern="[A-Za-z\s]+"
        required
        value={value}
        {...params}
      />
    </div>
  );
};

export const CvvInput = ({
  handleInputChange,
  handleMarkEdit,
  handleUnMarkEdit,
  value,
  ...params
}) => {
  return (
    <div className="ci-cvvid-item ci-cvvid-item-cvv">
      <input
        name="cvv"
        type="text"
        className="payment-agent-input credit-card-amount"
        onChange={handleInputChange}
        onFocus={handleMarkEdit}
        onBlur={handleUnMarkEdit}
        // placeholder='3 ספרות בגב הכרטיס-קוד CVV'
        placeholder="CVV"
        pattern="\d{3}"
        maxLength="3"
        required
        value={value}
        {...params}
      />

      <img
        src={visa1Icon}
        className="payment-agent-icons"
        alt="visa icon"
      ></img>
    </div>
  );
};

export const ExpDateInput = ({
  handleInputChange,
  handleMarkEdit,
  handleUnMarkEdit,
  value,
  ...params
}) => {
  return (
    <div className="ci-nd-item">
      <input
        name="expdate"
        id="expdate"
        type="text"
        className="payment-agent-input payment-agent-input-small"
        onChange={handleInputChange}
        onFocus={handleMarkEdit}
        onBlur={handleUnMarkEdit}
        placeholder="12/28"
        pattern="\d{2}/\d{2}"
        maxLength="5"
        required
        value={value}
        {...params}
      />
    </div>
  );
};

export const CardNumberInput = ({
  index,
  handleInputChange,
  handleMarkEdit,
  handleUnMarkEdit,
  value,
  ...params
}) => {
  const { t } = useTranslation();

  return (
    <div className="ci-cn">
      <input
        name="cardnumber"
        id={`cardnumber-${index}`}
        type="text"
        className="payment-agent-input"
        placeholder={t("paymentagent.cardnumber")}
        pattern="^\d{4}\s\d{4}\s\d{4}\s\d{4}$"
        maxLength="19"
        value={value}
        onChange={handleInputChange}
        onFocus={handleMarkEdit}
        onBlur={handleUnMarkEdit}
        required
        {...params}
      />
    </div>
  );
};

export const PaymentsAmountInput = ({
  handleInputChange,
  handleMarkEdit,
  handleUnMarkEdit,
  value,
  disabled,
  handlePaymentsChange,
  totalAmountWithPaymentsFee,
  numberofpayments,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="ci-a-nop">
        <div
          className="ci-a-nop-item"
          style={{ display: "row", flexWrap: "nowrap" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <input
              name="amount"
              type="number"
              id="price-amount"
              className="payment-agent-input payment-agent-input-amount"
              onChange={handleInputChange}
              onFocus={handleMarkEdit}
              onBlur={handleUnMarkEdit}
              placeholder={t("paymentagent.amount")}
              required
              value={value}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="ci-a-nop-item-nop">
          <label className="ci-a-nop-item-label">
            {t("paymentagent.numberofpayments")}:
          </label>
          <select
            name="splitPayment-card-amount-input"
            id="splitPayment-card-amount-input"
            disabled={disabled}
            onChange={handlePaymentsChange}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
      </div>
      {numberofpayments > 1 && value ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            whiteSpace: "nowrap",
            alignItems: "center",
            fontSize: 20,
            marginRight: 10,
          }}
        >
          {"תשלום סופי:"}
          <span style={{ fontWeight: "bold", marginRight: 5 }}>
            {totalAmountWithPaymentsFee}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export const CardHolderId = ({
  handleInputChange,
  handleMarkEdit,
  handleUnMarkEdit,
  value,
  ...params
}) => {
  const { t } = useTranslation();

  return (
    <div className="ci-cvvid-item ci-cvvid-item-id">
      <input
        name="cardholderid"
        type="text"
        className="payment-agent-input"
        onChange={handleInputChange}
        onFocus={handleMarkEdit}
        onBlur={handleUnMarkEdit}
        placeholder={t("paymentagent.cardholderid")}
        pattern="\d{9}"
        maxLength="9"
        value={value}
        {...params}
      ></input>
    </div>
  );
};
