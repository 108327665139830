import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import "./SummaryFooter.scss";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Loader } from "../Loader/Loader";
import { useSelectedResult } from "../../contexts/SelectedResultContext";

const SummaryFooter = ({ moveNextStage, nextStageDisabled, loading }) => {
  const { ourPrice } = useSelectedResult();

  const { t } = useTranslation();

  return (
    <Tooltip
      title={nextStageDisabled ? <h1>{nextStageDisabled}</h1> : undefined}
    >
      <div className="summary-footer">
        <div className="price-to-pay tw-text-sm">
          <div className="tw-hidden lg:tw-block">
            {t("packages.totalToPay")}:
          </div>
          {ourPrice?.priceWithCreditCardFee ? (
            ourPrice.priceWithCreditCardFee
          ) : (
            <SkeletonLoader
              style={{ width: 70, height: 20, marginRight: 10 }}
            />
          )}
          ₪
        </div>

        {loading ? (
          <Loader />
        ) : (
          <button
            onClick={nextStageDisabled ? null : moveNextStage}
            className={classNames(
              "order-package-btn tw-text-sm lg:tw-text-xl tw-w-[100px] lg:tw-w-[200px]",
              {
                disabled: nextStageDisabled,
              }
            )}
          >
            {t("packages.continue")}
          </button>
        )}
      </div>
    </Tooltip>
  );
};

export default SummaryFooter;
