import React from "react";
import { handleImageChange } from "./imageFunctions";
import "./BaseImageUploader.scss";

const BaseImageUploader = ({
  imageSrc,
  divStyle,
  imageStyle = {},
  onImageChange,
}) => {
  return (
    <div className="base-image-uploader-container" style={divStyle}>
      <img
        className="base-image-uploader-image"
        style={imageStyle}
        src={imageSrc}
        alt="Profile"
      />
      <input
        type="file"
        accept="image/*"
        className="base-image-uploader-input"
        onChange={(e) => handleImageChange({ e, onImageChange })}
      />
    </div>
  );
};

export default BaseImageUploader;
