import React, { useState, useEffect } from "react";
import uploadIcon from "../../assets/images/inputs/upload.png";
import "./UploadInput.css";
import { handlePDFupload } from "../Utils";

const UploadInput = ({
  pdfPath,
  setPdfPath,
  title,
  setFileStrBase64,
  width = "180px",
  accepting = "application/pdf",
}) => {
  const [srcShorten, setSrcShorten] = useState("");

  useEffect(() => {
    const splittedSrc = pdfPath.split("\\");
    let strPath = splittedSrc[splittedSrc.length - 1];
    if (strPath.length > 17) {
      strPath = strPath.slice(strPath.length - 17, strPath.length - 1) + "...";
    }
    setSrcShorten(strPath);
  }, [pdfPath]);

  const onFileSelected = async (e) => {
    const base64String = await handlePDFupload(e.target.files[0]);
    setFileStrBase64(base64String);
    setPdfPath(e?.target?.value);
  };

  return (
    <div
      className={"upload-input-wrapper"}
      style={{ width, position: "relative" }}
    >
      <input
        type="file"
        accept={accepting}
        className={"upload-input"}
        onChange={onFileSelected}
        style={{
          opacity: 0,
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "100%",
        }}
      />
      <div className={"upload-input-title"}>{title}</div>
      <div className={"upload-input-box input-upload-default"}>
        <img src={uploadIcon} alt="upload" className="upload-logo" />
        <div className="img-path">{srcShorten}</div>
      </div>
    </div>
  );
};

export default UploadInput;
