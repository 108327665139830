import React from "react";
import { useTranslation } from "react-i18next";
import CreditCardDetails from "../../Agent/PaymentAgent/SplitPayment/subComponent/CreditCardDetails/creditcarddetails";
import CardPayment from "../CardPayment/CardPayment";
import { useCards } from "../../../contexts/CardsContext";

const PaymentSplit = ({
  handlingFee,
  commission,
  priceToPay,
  setPriceToPay,
  setCardPaid,
  pay,
}) => {
  const { cards } = useCards();

  const { t } = useTranslation();

  function renderCards() {
    return cards.map(({ orderReqData }, index) => (
      <div key={index}>
        <CardPayment
          cardIndex={index}
          orderReqData={orderReqData}
          handlingFee={handlingFee}
          commission={commission}
          priceToPay={priceToPay}
          savePriceToPay={setPriceToPay}
          setCardPaid={setCardPaid}
          pay={pay}
        />
      </div>
    ));
  }

  return <div className="payment-split">{renderCards()}</div>;
};

export default PaymentSplit;
