import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./Loader.scss";

export const Loader = ({ size = "medium", showText = false, loaderText }) => {
  const [text, setText] = useState("טוען..."); // Initial Hebrew text
  const milisecondBetweenText = 3000;
  const texts = [
    "אנא המתן רגע...",
    "נא להמתין...",
    "מכין את התוכן...",
    "טוען מידע...",
    "אנו משדרגים את החוויה שלך...",
    "סבלנות, רק רגע ונסיים...",
    "מכין את המידע...",
    "המערכת בתהליך טעינה...",
    "אנו מכינים הכול בשבילך...",
  ];

  useEffect(() => {
    if (showText) {
      let index = 0;
      const intervalId = setInterval(() => {
        index = (index + 1) % texts.length;
        setText(texts[index]);
      }, milisecondBetweenText);
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <div className="loader-container">
      <div className={classNames("loader", size)} />
      {showText && <div className="loader-text">{text}</div>}
      {loaderText && <div className="loader-text">{loaderText}</div>}
    </div>
  );
};
