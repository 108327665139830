import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CalenderIcon from "../../../../../assets/images/searchbars/calendar.png";
import Calendar from "../../SubComponents/Calendar/calendar";
import "../../HotelSearchBar/hotelsearchbar.scss";
import { useOnClickOutside } from "../../../../../utils/ComponentsUtils";

const DatesInput = ({
  calendarDateArrival,
  setCalendarDateArrival,
  calendarDateLeave,
  setCalendarDateLeave,
  possibleArrivalDates,
  possibleDepartureDates,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { t } = useTranslation();
  const ref = useRef(null);

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);
  useOnClickOutside(ref, () => setIsCalendarOpen(false));

  return (
    <div
      className="hotelSearchBar-item cursor-pointer hotelSearchBar-item-calender tw-flex tw-flex-col"
      ref={ref}
    >
      <div
        className="hotelSearchBar-desc-wrapper cursor-pointer"
        onClick={toggleCalendar}
      >
        <div className="hotelSearchBar-icon-wrapper">
          <img
            className="hotelSearchBar-icon2"
            src={CalenderIcon}
            alt="Calendar"
          ></img>
        </div>

        <div className="hotelSearchBar-label-wrapper cursor-pointer">
          <label className="hotelSearchBar-label1 cursor-pointer">
            {t("hotelsearchbar.selectDate")}
          </label>

          {calendarDateLeave.length > 0 ? (
            <label className="hotelSearchBar-label2 cursor-pointer">
              {calendarDateArrival} - {calendarDateLeave}
            </label>
          ) : (
            <label className="hotelSearchBar-label2 hotelSearchBar-letter-spacing cursor-pointer">
              {t("hotelsearchbar.date")}
            </label>
          )}
        </div>
      </div>

      {/* Calendar */}
      <div>
        {isCalendarOpen && (
          <Calendar
            calendarDateLeave={calendarDateLeave}
            calendarDateArrival={calendarDateArrival}
            saveArrivalDate={setCalendarDateArrival}
            saveDepartureDate={setCalendarDateLeave}
            possibleArrivalDates={
              possibleArrivalDates?.length > 0 && possibleArrivalDates
            }
            possibleDepartureDates={
              possibleDepartureDates?.length > 0 && possibleDepartureDates
            }
          />
        )}

        <input
          defaultValue={calendarDateLeave}
          className="hotelSearchBar-calendar-required"
          required
        ></input>
      </div>
    </div>
  );
};

export default DatesInput;
