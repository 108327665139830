import React, { useState, useEffect } from "react";

import { SearchProvider } from "../../../contexts/SearchContext";
import ClientsInfoAgent from "../../../Components/Agent/ClientsInfoAgent/clientsinfoagent";
import PriceOfferComponent from "../../../Components/Agent/PriceOfferComponent/priceoffercomponent";

// CSS
import "./priceoffer.css";
import { Loader } from "../../../Components/Loader/Loader";

const PriceOffer = () => {
  // Checkers
  const [isDone, setIsDone] = useState(false);
  const [loading, setLodaing] = useState(true);

  const _setIsDone = (newIsDone) => {
    setIsDone(newIsDone);
    setLodaing(false);
  };
  // const isDone = false;

  // Save info between components
  const [clientsinfo, setClientsinfo] = useState({});

  // --- UseEffects
  useEffect(() => {
    // Add an event listener to the 'beforeunload' event
    window.addEventListener("load", handleRefresh);

    return () => {
      // Clean up by removing the event listener when the component is unmounted
      window.removeEventListener("load", handleRefresh);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("agent-saved-client-info")) {
      _setIsDone(true);
    } else {
      setLodaing(false);
    }
  }, []);

  // Check if current client details were deleted,
  // if so return to clientsinfo component
  useEffect(() => {
    const saveClient = "agent-saved-client-info";

    // Event listener function
    const storageChangeHandler = (event) => {
      if (event.key === saveClient && event.newValue === null) {
        _setIsDone(false);
      }
    };

    // Add the event listener
    window.addEventListener("storage", storageChangeHandler);
  }, []);

  // --- Functions
  const handleRefresh = (event) => {
    // Before the window is refreshed delete all session storage
    sessionStorage.clear();

    event.preventDefault();
    event.returnValue = ""; // Needed for Chrome and legacy browsers
  };

  // --- Actions
  const changeClient = () => {
    _setIsDone(false);

    // Delete search data
    sessionStorage.clear();
  };

  const renderComponent = () => {
    if (isDone) {
      return (
        <PriceOfferComponent
          clientsInfo={clientsinfo}
          changeClient={changeClient}
        />
      );
    }
    return (
      <ClientsInfoAgent
        clientsInfo={setClientsinfo}
        finishAction={_setIsDone}
      />
    );
  };

  return (
    <>
      <div className="priceOffer-wrapper">
        {/* Components */}
        <div className="priceOffer-component-wrapper">
          {loading ? <Loader /> : renderComponent()}
        </div>
      </div>
    </>
  );
};

export default PriceOffer;
