export const handlePDFupload = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject('No file selected');
            return;
        }
        const reader = new FileReader();
        reader.onload = function(event) {
            const base64String = event.target.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = function(event) {
            reject(event.target.error);
        };
        reader.readAsDataURL(file);
    });
}
