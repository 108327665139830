import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { isAdminUser } from "../../../services/authService";
import { useTranslation } from "react-i18next";
import ActiveInput from "../../Inputs/ActiveInput";

import "./FinanceTable.scss";

const DatesCellRender = ({ date }) => {
  const dateObj = new Date(date);

  const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}/${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj.getFullYear()}`;

  return <div>{formattedDate}</div>;
};

const SumCellRender = ({ sum, currency }) => {
  const className = sum > 0 ? "sum-positive" : "sum-negative";
  return (
    <div className={className}>
      {sum}
      {currency === "ILS" ? "₪" : currency}
    </div>
  );
};

const NameCellRender = ({ firstName, lastName }) => {
  return (
    <div>
      {firstName} {lastName}
    </div>
  );
};

const InvoiceCellRender = ({ invoice }) => {
  if (invoice === "" || !invoice) return <div>אין חשבונית</div>;
  return (
    <a href={invoice} className="table-row-cell">
      לינק להורדה
    </a>
  );
};

const ActionsCellRender = ({
  email,
  reqNumber,
  status,
  clickApproveRequest,
  clickDeclineRequest,
}) => {
  const { t } = useTranslation();

  return status === "waiting" ? (
    <div className="table-actions-cell">
      <button
        className="approve-requset"
        onClick={() => clickApproveRequest({ email, reqNumber })}
      >
        {t("finance.approveRequest")}
      </button>
      <button
        className="decline-requset"
        onClick={() => clickDeclineRequest({ email, reqNumber })}
      >
        {t("finance.declineRequest")}
      </button>
    </div>
  ) : (
    <></>
  );
};

const StatusCellRender = ({ status }) => {
  let className = "";
  let text = "";
  switch (status) {
    case "APPROVED":
      className = "status-aprroved";
      text = "מאושר";
      break;
    case "waiting":
      className = "status-waiting";
      text = "ממתין לאישור";
      break;
    case "applied":
      className = "status-applied";
      text = "קוזז";
    case "DECLINED":
      className = "status-applied";
      text = "נדחה";
      break;
    default:
  }
  return <div className={className}>{text}</div>;
};

const FinanceRow = ({
  row,
  index,
  clickApproveRequest,
  clickDeclineRequest,
  addRequestComments,
} = {}) => {
  const isAdmin = isAdminUser();

  const {
    comments,
    currency,
    date,
    email,
    invoice,
    reqnumber,
    status,
    sum,
    agentfirstname,
    agentlastname,
  } = row;

  const approved = status === "approved";

  const rowFields = [
    { value: email, isHidden: !isAdmin },
    {
      value: (
        <NameCellRender firstName={agentfirstname} lastName={agentlastname} />
      ),
      isHidden: !isAdmin,
    },
    {
      value: <InvoiceCellRender invoice={invoice} />,
    },
    {
      value: <DatesCellRender className="table-row-cell" date={date} />,
    },
    { value: reqnumber },
    {
      value: <SumCellRender sum={sum} currency={currency} />,
    },
    { value: <StatusCellRender status={status} /> },
    {
      value: isAdmin ? (
        <ActiveInput
          value={comments}
          saveFunc={(commentsText) =>
            addRequestComments({
              email,
              reqNumber: reqnumber,
              comments: commentsText,
            })
          }
        />
      ) : (
        comments
      ),
    },
    {
      value: (
        <ActionsCellRender
          status={status}
          email={email}
          reqNumber={reqnumber}
          clickApproveRequest={clickApproveRequest}
          clickDeclineRequest={clickDeclineRequest}
        />
      ),
      width: "10%",
      isHidden: !isAdmin,
    },
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={approved ? `table-row-paid` : `table-row`}
      >
        {rowFields.map(({ value, cellRenderer, isHidden }, index) =>
          isHidden ? null : (
            <TableCell key={index} className="table-row-cell">
              {cellRenderer ? cellRenderer : value}
            </TableCell>
          )
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default FinanceRow;
