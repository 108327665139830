import React, { useEffect, useState } from "react";

// Languages
import { useTranslation } from "react-i18next";

// Services
import { convertPrice } from "../../../../services/currencyService";

import TotalPayment from "../../PaymentAgent/TotalPayment";
import { useOrder } from "../../../../contexts/OrderContext";
import { useAppContext } from "../../../../contexts/AppContext";
import OrderDetail from "../../PaymentAgent/SuccessOrder/SubComponents/OrderDetail/orderdetail";

const Summary = ({
  hotelName,
  date,
  numberOfNights,
  totalRooms,
  roomName,
  orderReqData,
}) => {
  const { currentHotelOrder } = useOrder();
  const { getCreditCardFeePercent } = useAppContext();
  const [details, setDetails] = useState([]);

  const tableForScreenshot = React.useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    createDetails(orderReqData);
  }, [orderReqData]);

  const getNextDay = (dateString) => {
    // Step 1: Parse the date string into a Date object
    const [day, month, year] = dateString.split("/");
    const date = new Date(`${year}-${month}-${day}`);

    // Step 2: Add one day to the Date object
    date.setDate(date.getDate() + 1);

    // Step 3: Format the new date back into a string
    const newDay = String(date.getDate()).padStart(2, "0");
    const newMonth = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const newYear = date.getFullYear();

    const newDateString = `${newDay}/${newMonth}/${newYear}`;

    return newDateString;
  };

  const createDetails = (orderData) => {
    if (!orderData) return;

    // Gather data
    const roomsData = analyzeRooms(orderData.rooms);

    if (!roomsData) return;

    const noOfNights = calculateNumberOfNights(
      orderData.checkin,
      orderData.checkout
    );

    let cancelationDate = new Date(orderData.refundableuntil);
    // add 1 day to the cancelation date
    cancelationDate.setDate(cancelationDate.getDate() + 1);

    const detailClass = "successOrder-content-details-detail-value";

    // Create Details
    const data = [
      {
        title: t("successorder.confirmationnumber"),
        detailValue: orderData.orderid,
        classDetail: detailClass,
      },
      {
        title: t("successorder.hotelname"),
        detailValue: orderData.hotelname,
        classDetail: detailClass,
      },
      {
        title: t("successorder.address"),
        detailValue: orderData.hoteladdress,
        classDetail: detailClass,
      },
      {
        title: t("successorder.roomkind"),
        detailValue: roomsData.roomNames,
        classDetail: detailClass,
      },
      {
        title: t("successorder.totalpeople"),
        detailValue: `${roomsData.totalAdults} ${t("successorder.adults")} ${
          roomsData.totalKids > 0
            ? `${t("successorder.kids")}, ${roomsData.totalKids}`
            : ""
        }`,
        classDetail: detailClass,
      },
      {
        title: t("successorder.roomamount"),
        detailValue: roomsData.totalRooms,
        classDetail: detailClass,
      },
      {
        title: t("successorder.totalnights"),
        detailValue: noOfNights,
        classDetail: detailClass,
      },
      {
        title: t("successorder.checkin"),
        detailValue: orderData.checkin,
        classDetail: detailClass,
      },
      {
        title: t("successorder.checkout"),
        detailValue: orderData.checkout,
        classDetail: detailClass,
      },
      {
        title: t("successorder.totalcost"),
        detailValue: (
          <span id="price-cost">{convertPrice(orderData.pricetotal)}</span>
        ),
        classDetail: detailClass,
      },
      {
        title: t("successorder.canclationconditions"),
        detailValue: orderData.refundability ? (
          <div id="cancelPolicy">
            {t("completepassengers.startsat")}{" "}
            {getNextDay(orderData.refundableuntil)}{" "}
            {t("completepassengers.feesare")}{" "}
            {
              <label id="price-fee">
                {convertPrice(orderData?.pricetotal)}
              </label>
            }
          </div>
        ) : (
          t("successorder.fullpayment")
        ),
        classDetail: orderData.refundability
          ? "successOrder-content-details-detail-value-green"
          : "successOrder-content-details-detail-value-red",
      },
    ];

    setDetails(data);
  };

  const getPaymentsFees = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 4,
          }}
        >
          <div className={"ci-summery-fee1-title"}>
            עמלת כרטיס ({getCreditCardFeePercent()}%)
          </div>
          <div className={"ci-summery-fee2-value"}>
            {convertPrice(currentHotelOrder.ourPrice.creditCardFee)}
          </div>
        </div>
      </>
    );
  };

  function analyzeRooms(data) {
    let totalAdults = 0;
    let roomNames = [];
    let totalKids = 0;

    // Create a Set to store unique room names
    let uniqueRoomNames = new Set();

    console.log(data);

    if (!data) return;

    data.forEach((room) => {
      // Count adults
      totalAdults += room.adultscount;

      // Add room name to uniqueRoomNames set
      uniqueRoomNames.add(room.roomname);

      // Count kids
      totalKids += room.kidsages.length;
    });

    // Convert uniqueRoomNames Set to an array
    roomNames = Array.from(uniqueRoomNames);

    // Convert roomNames array to a comma-separated string
    const roomNamesString = roomNames.join(", ");

    return {
      totalAdults: totalAdults,
      totalRooms: data.length,
      roomNames: roomNamesString,
      totalKids: totalKids,
    };
  }

  function calculateNumberOfNights(checkin, checkout) {
    // Get number of nights
    if (!checkin || !checkout) return;

    const checkInParts = checkin.split("/");
    const checkOutParts = checkout.split("/");

    // Create Date objects using parts in the format: year, month - 1, day
    const checkInDate = new Date(
      checkInParts[2],
      checkInParts[1] - 1,
      checkInParts[0]
    );
    const checkOutDate = new Date(
      checkOutParts[2],
      checkOutParts[1] - 1,
      checkOutParts[0]
    );

    // Calculate the time difference in milliseconds
    const timeDifference = checkOutDate - checkInDate;

    // Convert milliseconds to days and round down
    const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return numberOfNights;
  }

  return (
    <>
      <div className="ci-summery-wrapper">
        <div className="payment-agent-price-payment-wrapper">
          <label>{t("ordersummery.ordersummery")}</label>
        </div>

        <div className="ci-summery-content-wrapper">
          {/* Hotel Info */}
          <div className="ci-summery-hotel-wrapper">
            <div
              className="successOrder-content-details-wrapper"
              ref={tableForScreenshot}
            >
              <label className="successOrder-content-details-title">
                {t("successorder.ordersummery")}
              </label>
              <div className="successOrder-content-details">
                {details.map((detail, index) => {
                  return (
                    <OrderDetail
                      key={index}
                      title={detail.title}
                      value={detail.detailValue}
                      classDetail={detail.classDetail}
                    />
                  );
                })}
              </div>
            </div>
          </div>

          <div className="ci-summery-border"></div>

          {/* Fees */}
          <div className="ci-summery-fee1-wrapper">
            <label className="ci-summery-fee1-title">
              {t("orders.myCommission")}
            </label>
            <label id="price-commission" className="ci-summery-fee1-value">
              {convertPrice(
                !!orderReqData
                  ? orderReqData.commission.agentCommission
                  : currentHotelOrder.ourPrice.agentCommission
              )}
            </label>
          </div>
          <div className="ci-summery-fee1-wrapper">
            <label className="ci-summery-fee1-title">
              {t("paymentagent.handlingFee")}
            </label>
            <label id="price-commission" className="ci-summery-fee2-value">
              {convertPrice(
                !!orderReqData
                  ? orderReqData.commission.handlingFee
                  : currentHotelOrder.ourPrice.handlingFee
              )}
            </label>
          </div>
          {getPaymentsFees()}
        </div>

        <div className="ci-summery-border"></div>

        <div className="splitPayment-card-amount-totalprice ci-summery-topay-wrapper">
          <label className="splitPayment-card-amount-price-title ci-summery-topay">
            {t("paymentagent.priceis")}:
            <span
              id="priproblem-total"
              className="splitPayment-card-amount-price-number"
            >
              <TotalPayment
                totalPrice={currentHotelOrder.ourPrice.priceWithCreditCardFee}
              />
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default Summary;
