import React from "react";

// Languages
import { useTranslation } from "react-i18next";

// Services
import { convertPrice } from "../../../../services/currencyService";

import { useOrder } from "../../../../contexts/OrderContext";
import { useCards } from "../../../../contexts/CardsContext";

const PaidCards = ({

  sumPaid = 0,
}) => {
  const { cards } = useCards();

  const { currentHotelOrder } = useOrder();


  const { t } = useTranslation();


  let leftToPay = currentHotelOrder.ourPrice.priceWithCreditCardFee - sumPaid;
  return (
    <>
      <div style={{ width: "100%", height: 1, backgroundColor: "grey" }} />

      <div className={"ci-summery-content-wrapper"} style={{ fontSize: 14 }}>
        <div className="ci-summery-fee1-wrapper">
          <label className="splitPayment-card-amount-price-title ci-summery-topay">
            שולם:
          </label>
        </div>
        {sumPaid > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 4,
            }}
          >
            <div className={"ci-summery-fee1-title"}>שולם</div>
            <div className={"ci-summery-fee1-value"}>
              {convertPrice(sumPaid)}
            </div>
          </div>
        )}
        {cards.map((c, i) => {
          if (!c.paid) {
            return null;
          }
          leftToPay -= c.amount;
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 4,
              }}
            >
              <div className={"ci-summery-fee1-title"}>כרטיס {i + 1}</div>
              <div className={"ci-summery-fee1-value"}>
                {convertPrice(c.amount)}
              </div>
            </div>
          );
        })}
        <div className="splitPayment-card-amount-totalprice ci-summery-topay-wrapper">
          <label
            className="splitPayment-card-amount-price-title ci-summery-topay"
            style={{ fontSize: 14, color: "purple" }}
          >
            יתרה לתשלום:
            <span
              id="priproblem-total"
              className="splitPayment-card-amount-price-number"
              style={{ fontSize: 14 }}
            >
              {convertPrice(leftToPay)}
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default PaidCards;
