import React from "react";
import isArray from "lodash/isArray";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./RoomsSelector.scss";
import { PASSENGER_KEYS, ROOM_KEYS } from "../PassengersData/PassengersData";
import { errorMessage } from "../../services/swalService";
// import { calculateLDSDealFare } from "../../utils/CommissionsUtil";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { useSelectedResult } from "../../contexts/SelectedResultContext";

const RoomsSelector = ({ isAutoApprove, disabled }) => {
  // const disabled = false;

  const { setRoomInfo, roomsAndPaxInfo, selectedResult, roomsPriceTable } =
    useSelectedResult();

  const board = selectedResult?.hotel?.board;

  const { t } = useTranslation();

  const getAges = (childFare) =>
    !childFare ? null : `(${childFare.ageFrom} - ${childFare.ageTo})`;

  const checkIfRoomDisabled = (childFare, roomInfo) => {
    const childFareToCheck = isArray(childFare) ? childFare[0] : childFare;

    const kidsAges = roomInfo?.kids?.map(({ kisSearchAge }) => kisSearchAge);
    if (childFareToCheck) {
      const isAllInAgeRange = kidsAges.every(
        (age) =>
          age >= parseInt(childFareToCheck.ageFrom) &&
          age <= parseInt(childFareToCheck.ageTo)
      );
      if (!isAllInAgeRange) {
        const ageRange = getAges(childFareToCheck);
        return `אחד הילדים בגיל מבוגר מדי , אין אפשרות לבחור את החדר. \n טווח הגילאים הוא ${ageRange}`;
      }
      return false;
    }
    return false;
  };

  const onRoomClick = (roomPriceData, roomAndPaxInfoIndex) => {
    if (disabled) {
      return;
    }
    const { roomSuggestion, ourFareDetails } = roomPriceData;
    const { childFare, ldsDealId } = roomSuggestion;
    const roomUnavailableMessage = checkIfRoomDisabled(
      childFare,
      roomsAndPaxInfo[roomAndPaxInfoIndex]
    );
    const roomDisabled = roomUnavailableMessage || disabled;

    if (roomUnavailableMessage) {
      errorMessage(roomDisabled);
    } else if (!disabled) {
      setRoomInfo(
        { ...roomSuggestion, ourFareDetails, ldsDealId },
        roomAndPaxInfoIndex
      );
    }
  };

  ////////////////////////////////////////////////////////////////////////
  function renderRoom(
    roomPriceData,
    roomPriceDataIndex,
    roomAndPaxInfo,
    roomAndPaxInfoIndex
  ) {
    if (!roomPriceData) return null;
    const { roomSuggestion, ourFareDetails } = roomPriceData;

    const isSelectedRoom =
      roomSuggestion["@seqNum"] === roomAndPaxInfo?.room[ROOM_KEYS.SEQ_NUM];

    return (
      <>
        <tr
          key={`${roomAndPaxInfoIndex}-${roomPriceDataIndex}`}
          className={classNames("room-selector-row " + roomAndPaxInfoIndex, {
            selected: isSelectedRoom,
            disabled,
          })}
          onClick={(e) => onRoomClick(roomPriceData, roomAndPaxInfoIndex)}
        >
          <td>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>{roomSuggestion.roomType}</div>
            </div>
          </td>
          <td>{`${board}`}</td>
          <td>
            {isAutoApprove ? (
              <div className="auto-approve"> {t("packages.autoApprove")}</div>
            ) : (
              <div style={{ color: "red" }}>נדרש אישור מלון</div>
            )}
          </td>

          <td>
            {ourFareDetails.ourTotalFare?.priceWithCommissionAndCreditCardFee}₪
          </td>
        </tr>
      </>
    );
  }

  const headers = [
    {
      text: t("packages.roomType"),
    },
    {
      text: t("packages.base"),
    },
    {
      text: t("packages.status"),
    },
    {
      text: t("packages.price"),
    },
  ];

  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      {!roomsAndPaxInfo || !roomsAndPaxInfo.length ? (
        <>
          <SkeletonLoader
            height={40}
            style={{ margin: 20, width: "calc(100% - 40px)" }}
          />
          <SkeletonLoader />
          <SkeletonLoader />
          <SkeletonLoader />
        </>
      ) : (
        <>
          {roomsAndPaxInfo.map((roomAndPaxInfo, roomAndPaxInfoIndex) => (
            <span key={`passenger-info-${roomAndPaxInfoIndex}`}>
              <div className="roomPassInfo-title-wrapper">
                <div className="roomPassInfo-border"></div>
                <label className="roomPassInfo-title">
                  {t("completepassengers.room")} {roomAndPaxInfoIndex + 1}
                </label>
                <div className="roomPassInfo-border"></div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <h4>מבוגרים: {roomAndPaxInfo?.adults.length}</h4>
                <h4>ילדים: {roomAndPaxInfo?.kids.length}</h4>
                <h4>
                  {roomAndPaxInfo?.kids &&
                    roomAndPaxInfo.kids.map((kid) => kid[PASSENGER_KEYS.AGE])}
                </h4>
                <h4>
                  {t("roomscounter.infant")}: {roomAndPaxInfo?.infants.length}
                </h4>
              </div>

              <table cellSpacing="0" className="room-selector-table">
                <thead>
                  {headers.map(({ text }, index) => {
                    return (
                      <th
                        className="room-selector-table-head"
                        key={`room-selector-table-head-${index}`}
                      >
                        {text}
                      </th>
                    );
                  })}
                </thead>
                <tbody>
                  {roomsPriceTable[roomAndPaxInfoIndex]?.map(
                    (roomPriceData, roomPriceDataIndex) =>
                      renderRoom(
                        roomPriceData,
                        roomPriceDataIndex,
                        roomAndPaxInfo,
                        roomAndPaxInfoIndex
                      )
                  ) || <SkeletonLoader />}
                </tbody>
              </table>
              {roomAndPaxInfo?.infants.length > 0 && (
                <div className="tw-flex tw-items-center">
                  <div className="tw-py-5 tw-pl-5">
                    מחיר לתינוק:{" "}
                    {
                      roomAndPaxInfo?.room?.ourFareDetails?.ourInfantFare
                        .priceWithCommissionAndCreditCardFee
                    }
                    שח
                  </div>
                </div>
              )}
            </span>
          ))}
        </>
      )}
    </div>
  );
};

export default RoomsSelector;
