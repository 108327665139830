import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { ApiReqBaseUrl } from "../assets/GlobalVariables";
import { countries } from "countries-list";
import { getAgents } from "../services/fhpService";
const RegistrationContext = createContext();
export const FormFields = {
  PERSONAL_INFO: {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    CELL: "cell",
    COUNTRY: "country",
  },
  ACCOUNT_INFO: {
    PASSWORD: "password",
    PASSWORD_AGAIN: "passwordAgain",
  },
  COMPANY_INFO: {
    COMPANY_COUNTRY: "companyCountry",
    COMPANY_NAME: "companyName",
    COMPANY_NUMBER: "companyNumber",
    COMPANY_TYPE: "companyType",
    IN_BUSINESS: "inBusiness",
    SALES_REVENUE: "salesRevenue",
  },
  ADDITIONAL_INFO: {
    PACKAGE: "package",
    RECOMMENDED_BY: "recommendedBy",
  },
  OTHERS: {
    TERMS_APPROVED: "terms_approved",
  },
};

export const getFormFields = (formField) =>
  Object.keys(formField).map((key) => formField[key]);

export const RegistrationProvider = ({ children }) => {
  const countriesOptions = Object.keys(countries).map((k) => ({
    name: countries[k].name,
    value: countries[k].name,
  }));
  const yesNoOptions = [
    { name: "כן", value: true },
    { name: "לא", value: false },
  ];
  const companyTypeOptions = [
    { name: "פטור", value: "פטור" },
    { name: "מורשה", value: "מורשה" },
    { name: 'חברה בע"מ', value: 'חברה בע"מ' },
  ];
  const salesRevenueOptions = [
    { name: "0 - 500,000 $", value: "0-500,000 $" },
    { name: "501,000 - 1,000,000 $", value: "501,000 - 1,000,000 $" },
    { name: "1,000,000 - 2,000,000 $", value: "1,000,000 - 2,000,000 $" },
    { name: "2,000,000+ $", value: "2,000,000+ $" },
  ];
  const [recommendedByOptions, setRecommendedByOptions] = React.useState([]);

  const [formData, setFormData] = useState({
    [FormFields.PERSONAL_INFO.FIRST_NAME]: "",
    [FormFields.PERSONAL_INFO.LAST_NAME]: "",
    [FormFields.PERSONAL_INFO.EMAIL]: "",
    [FormFields.PERSONAL_INFO.CELL]: "",
    [FormFields.PERSONAL_INFO.COUNTRY]: "Israel",
    [FormFields.ACCOUNT_INFO.PASSWORD]: "",
    [FormFields.ACCOUNT_INFO.PASSWORD_AGAIN]: "",
    [FormFields.COMPANY_INFO.COMPANY_COUNTRY]: "Israel",
    [FormFields.COMPANY_INFO.COMPANY_NAME]: "",
    [FormFields.COMPANY_INFO.COMPANY_NUMBER]: "",
    [FormFields.COMPANY_INFO.COMPANY_TYPE]: "",
    [FormFields.COMPANY_INFO.IN_BUSINESS]: false,
    [FormFields.COMPANY_INFO.SALES_REVENUE]: salesRevenueOptions[0],
    [FormFields.ADDITIONAL_INFO.PACKAGE]: "PRO",
    [FormFields.ADDITIONAL_INFO.RECOMMENDED_BY]: "",
    [FormFields.OTHERS.TERMS_APPROVED]: false,
  });
  const formTitles = {
    [FormFields.PERSONAL_INFO.FIRST_NAME]: "שם פרטי",
    [FormFields.PERSONAL_INFO.LAST_NAME]: "שם משפחה",
    [FormFields.PERSONAL_INFO.EMAIL]: "כתובת אימייל",
    [FormFields.PERSONAL_INFO.CELL]: "טלפון",
    [FormFields.PERSONAL_INFO.COUNTRY]: "מדינה",
    [FormFields.ACCOUNT_INFO.PASSWORD]: "סיסמא",
    [FormFields.ACCOUNT_INFO.PASSWORD_AGAIN]: "סיסמא שוב",
    [FormFields.COMPANY_INFO.COMPANY_COUNTRY]: "מדינה שבה החברה רשומה",
    [FormFields.COMPANY_INFO.COMPANY_NAME]: "שם החברה",
    [FormFields.COMPANY_INFO.COMPANY_NUMBER]: "ח.פ / עוסק מורשה",
    [FormFields.COMPANY_INFO.COMPANY_TYPE]: "סוג החברה",
    [FormFields.COMPANY_INFO.IN_BUSINESS]: "האם אתה סוכן נסיעות",
    [FormFields.COMPANY_INFO.SALES_REVENUE]: "הערכת מחזור מכירות שנתי",
    // [FormFields.ADDITIONAL_INFO.PACKAGE]: 'PRO',
    [FormFields.ADDITIONAL_INFO.RECOMMENDED_BY]: "מי המליץ עלי?",
    // [FormFields.OTHERS.TERMS_APPROVED]: false,
  };
  const formMessages = {
    [FormFields.ACCOUNT_INFO.PASSWORD]: "נא לבחור סיסמא חזקה עד 8 תווים",
    [FormFields.ADDITIONAL_INFO.RECOMMENDED_BY]: "הכניסו קוד סוכן",
  };
  const formOptions = {
    [FormFields.COMPANY_INFO.COMPANY_COUNTRY]: countriesOptions,
    [FormFields.PERSONAL_INFO.COUNTRY]: countriesOptions,
    [FormFields.COMPANY_INFO.IN_BUSINESS]: yesNoOptions,
    [FormFields.COMPANY_INFO.COMPANY_TYPE]: companyTypeOptions,
    [FormFields.COMPANY_INFO.SALES_REVENUE]: salesRevenueOptions,
    [FormFields.ADDITIONAL_INFO.RECOMMENDED_BY]: recommendedByOptions,
  };

  const requiredFields = {
    PrivateDetails: getFormFields(FormFields.PERSONAL_INFO),
    LoginDetails: getFormFields(FormFields.ACCOUNT_INFO),
    BusinessDetails: getFormFields(FormFields.COMPANY_INFO),
    PackageDetails: [
      FormFields.ADDITIONAL_INFO.PACKAGE,
      FormFields.OTHERS.TERMS_APPROVED,
    ],
  };
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    getAgents()
      .then((agents) => {
        setLoading(false);
        setRecommendedByOptions(
          agents.map(({ id, name }) => ({
            name: `${name} (${id})`,
            value: id,
          })),
        );
      })
      .catch((err) => {
        console.error(err);
        setRecommendedByOptions([]);
      });
  }, []);

  const setFormKey = (key, value) => setFormData({ ...formData, [key]: value });

  const getMissingFields = () => {
    const keysWithEmptyValues = [];
    for (const key in formData) {
      if (
        key === FormFields.OTHERS.TERMS_APPROVED &&
        formData[FormFields.OTHERS.TERMS_APPROVED] === false
      ) {
        keysWithEmptyValues.push(FormFields.OTHERS.TERMS_APPROVED);
      }
      if (
        key === FormFields.ACCOUNT_INFO.PASSWORD &&
        formData[FormFields.ACCOUNT_INFO.PASSWORD] !==
          formData[FormFields.ACCOUNT_INFO.PASSWORD_AGAIN]
      ) {
        keysWithEmptyValues.push(FormFields.OTHERS.PASSWORD);
      } else if (formData[key] === "") {
        keysWithEmptyValues.push(key);
      }
    }
    return keysWithEmptyValues;
  };

  const registerUser = async () => {
    setLoading(true);
    return axios
      .post(`${ApiReqBaseUrl}/registerAgent`, formData)
      .catch((err) => {
        throw err.response;
      })
      .finally(setLoading(false));
  };

  return (
    <RegistrationContext.Provider
      value={{
        formData,
        setFormData,
        setFormKey,
        formMessages,
        getMissingFields,
        registerUser,
        requiredFields,
        formOptions,
        loading,
        formTitles,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  if (context === undefined) {
    throw new Error(
      "useRegistration must be used within a RegistrationProvider",
    );
  }
  return context;
};

export default RegistrationContext;
