import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// React Icons
import { AiOutlineCheck } from "react-icons/ai";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./upcomingorders.css";

// Services
import { getOrders } from "../../../../services/fhpService";
import { convertPrice } from "../../../../services/currencyService";

// Components
import ClientsInfo from "../../OrderSummery/ClientsInfo/clientsinfo";
import FlightsInfo from "../../OrderSummery/FlightsInfo/flightsinfo";
import HotelInfo from "../../OrderSummery/HotelInfo/hotelinfo";

// Icons
import ZoomOutIcon from "../../../../assets/images/ordersummery/zoom-out.png";
import { getOrderPrice } from "../../../../utils/OrderService";
import { Loader } from "../../../Loader/Loader";

const UpComingOrders = ({ loading }) => {
  // Navigator
  const navigate = useNavigate();

  // Transelator
  const { t } = useTranslation();

  // States
  const [orders, setOrders] = useState();
  const [isShowOrder, setIsShowOrder] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(null);

  // Refs
  const excludedOrderDisplayerRef = useRef(null);

  // --- UseEffects

  useEffect(() => {
    const getOrdersFromServer = async () => {
      // Get agent's email
      let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

      // Build the data and get the orders
      let data = {
        email: agentEmail,
      };

      let ordersData = await getOrders(data);

      setOrders(ordersData?.data?.orders);
    };

    getOrdersFromServer();
  }, []);

  // Add eventer listeners that exits display shower
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isShowOrder &&
        !excludedOrderDisplayerRef.current.contains(event.target) &&
        event.target.id !== "button-show-order-summery"
      ) {
        setIsShowOrder(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isShowOrder]);

  // --- Functions

  const showOrder = (orderinfo) => {
    // Show the order displayer
    setIsShowOrder(true);

    // Save the order
    setShowOrderDetails(orderinfo);
  };

  const quitOrderShower = () => {
    setIsShowOrder(false);
  };

  // Handle payment
  const handlePayment = (showOrderDetails) => {
    let orderID = showOrderDetails.id;

    // Navigate to payment page
    navigate(`/p/dashboard/payment/${orderID}`);
  };

  function renderItems() {
    if (orders.length) {
      return orders?.map((orderinfo, index) => (
        <tr
          key={index}
          className={`upComingOrders-order ${
            orderinfo.paid
              ? "upComingOrders-order-paid"
              : "upComingOrders-order-not-paid"
          } ${
            document.documentElement.dir === "rtl"
              ? "direction-rtl"
              : "direction-ltr"
          }`}
        >
          <td className="upComingOrders-order-item0">
            {orderinfo.paid ? <AiOutlineCheck /> : ""}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item1">
            {orderinfo.fullname}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item2">
            {orderinfo.hotelname}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item3">
            {orderinfo.checkin}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item3">
            {orderinfo.checkout}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item3">
            {orderinfo.refundability
              ? orderinfo.refundableuntil
              : t("upcomingorders.notrefundable")}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item4">
            {orderinfo.paid ? (
              t("upcomingorders.paid")
            ) : (
              <span id="price-of-order">
                {convertPrice(getOrderPrice(orderinfo))}
              </span>
            )}
          </td>
          <td className="upComingOrders-order-item upComingOrders-order-item4">
            <button
              id="button-show-order-summery"
              onClick={() => showOrder(orderinfo)}
              className="upComingOrders-order-btn lg:tw-px-5 lg:tw-py-2"
            >
              {t("upcomingorders.displayorder")}
            </button>
          </td>
        </tr>
      ));
    }
    return <div className="no-data">אין הזמנות עתידיות </div>;
  }

  return (
    <>
      <div className="upComingOrders-wrapper">
        {/* Title */}
        <label className="upComingOrders-title">
          {t("upcomingorders.myupcomingorders")}
        </label>

        {/* Order Wrapper */}
        <div
          className={`upComingOrders-order-wrapper ${
            document.documentElement.dir === "ltr"
              ? "direction-rtl"
              : "direction-ltr"
          }`}
        >
          {loading || !orders ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : orders.length === 0 ? (
            <div className="no-data">אין הזמנות עתידיות </div>
          ) : (
            <table>
              <thead>
                <tr
                  className={`upComingOrders-header-wrapper ${
                    document.documentElement.dir === "rtl"
                      ? "direction-rtl"
                      : "direction-ltr"
                  }`}
                >
                  <th className="upComingOrders-header-item">
                    {t("upcomingorders.clientsname")}
                  </th>
                  <th className="upComingOrders-header-item">
                    {t("upcomingorders.hotelname")}
                  </th>
                  <th className="upComingOrders-header-item">
                    {t("upcomingorders.checkin")}
                  </th>
                  <th className="upComingOrders-header-item">
                    {t("upcomingorders.checkout")}
                  </th>
                  <th className="upComingOrders-header-item">
                    {t("upcomingorders.freecanceltion")}
                  </th>
                  <th className="upComingOrders-header-item">
                    {t("upcomingorders.overallpayment")}
                  </th>
                  <th className="upComingOrders-header-item"></th>
                </tr>
              </thead>
              <tbody className="upComingOrders-orders-wrapper">
                {renderItems()}
              </tbody>
            </table>
          )}
        </div>

        {/* Order Displayer */}
        {isShowOrder && (
          <div className="upComingOrders-order-displayer-wrapper">
            <div
              className="upComingOrders-order-displayer lg:tw-w-[30vw]"
              ref={excludedOrderDisplayerRef}
            >
              {/* Header */}
              <div className="upComingOrders-header">
                <label className="orderSummery-header-item orderSummery-header-item-selected">
                  {t("ordersummery.ordersummery")}
                </label>
              </div>

              {/* Summery Content */}
              <div className="upComingOrders-content">
                <ClientsInfo
                  clientName={showOrderDetails.fullname}
                  clientPhone={showOrderDetails.cell}
                  clientEmail={showOrderDetails.email}
                ></ClientsInfo>
                <FlightsInfo />
                <HotelInfo hotelInfo={showOrderDetails} />
              </div>

              {/* Pricing */}
              <div className="footer-wrapper">
                {/* Order Summery Price */}
                <div className="orderSummery-price-wrapper">
                  <label>{t("ordersummery.ordersummery")}:</label>
                  <label id="price-show-order-summery">
                    {convertPrice(getOrderPrice(showOrderDetails))}
                  </label>
                </div>

                {/* Order Summery button */}
                {!showOrderDetails.paid ? (
                  <div className="orderSummery-payment-btn-wrapper">
                    <button
                      onClick={() => handlePayment(showOrderDetails)}
                      className="orderSummery-payment-btn"
                    >
                      {t("ordersummery.payment")}
                    </button>
                  </div>
                ) : (
                  <div className="upComingOrders-paid-wrapper">
                    <label className="upComingOrders-paid-title">
                      {t("upcomingorders.paid")}
                    </label>
                  </div>
                )}
              </div>

              {/* Close Display Shower */}
              <div
                className={
                  document.documentElement.dir === "rtl"
                    ? "upComingOrders-wrapper-icon-he"
                    : "upComingOrders-wrapper-icon"
                }
              >
                <img
                  onClick={quitOrderShower}
                  className="orderSummery-icon"
                  src={ZoomOutIcon}
                  alt="Enhance order summery"
                ></img>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UpComingOrders;
