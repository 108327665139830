import LDS_AUTOCMPLETE_CITIES from "../assets/Data/LdsAirportsAutocomplete"

const translateLDSDestinations = ( destination = "" ) => {
  const translate =  LDS_AUTOCMPLETE_CITIES.find(({CITYCODE}) =>
    CITYCODE?.toLowerCase() === destination.toLowerCase()
  );
  return translate || ""
};

export default translateLDSDestinations;
