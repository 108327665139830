import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./searchagent.css";

// Components
import DataBox from "../../../Components/Agent/SearchAgent/DataBox/databox";
import UpComingOrders from "../../../Components/Agent/SearchAgent/UpComingOrders/upcomingorders";

// Icons
import AvailableProfit from "../../../assets/images/ordersummery/dollar-symbol-blue.png";
import FutureCommissionIcon from "../../../assets/images/ordersummery/money-bag-orange.png";
import OrdersPaymentsIcon from "../../../assets/images/ordersummery/shopping-list-green.png";

// Services
import { getFinanceData } from "../../../services/fhpService";
import { convertPrice } from "../../../services/currencyService";
// import { Title } from "../../../Components/Text";

const SearchAgent = () => {
  // Transelator
  const { t } = useTranslation();

  // States
  const [financeData, setFinanceData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getFinanceDataFromServer = async () => {
      // Get agent's email
      let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

      // Build the data and get the finance info
      let data = {
        email: agentEmail,
      };

      let financeData = await getFinanceData(data);
      setLoading(false);
      if (financeData?.data) {
        setFinanceData(financeData?.data);
      }
    };

    getFinanceDataFromServer();
  }, []);

  return (
    <>
      <div
        className="tw-flex tw-flex-col lg:tw-items-center lg:tw-px-[5%] lg:tw-mx-auto"
        style={{ height: "calc(100vh - 100px)" }}
      >
        {/* Agent info */}
        <div
          className="databox-container tw-flex tw-justify-between"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <DataBox
            idOfBox={"orderspayments"}
            title={t("searchagent.orderspayments")}
            value={financeData ? financeData.comingOrders : ""}
            iconSrc={OrdersPaymentsIcon}
            color={"green"}
          ></DataBox>
          <DataBox
            idOfBox={"price-futurecommission"}
            title={t("searchagent.futurecommission")}
            value={
              financeData ? convertPrice(financeData.futureCommission) : ""
            }
            iconSrc={FutureCommissionIcon}
            color={"orange"}
          ></DataBox>
          <DataBox
            idOfBox={"price-availableprofit"}
            title={t("searchagent.availableprofit")}
            value={financeData ? convertPrice(financeData.possibleProfit) : ""}
            iconSrc={AvailableProfit}
            color={"blue"}
          ></DataBox>
        </div>

        <div className="tw-flex tw-flex-row  tw-w-full tw-mx-auto tw-h-[65%] tw-mt-0  tw-mb-1">
          <div className="tw-w-full tw-h-full tw-bg-white tw-rounded-[5px] tw-shadow-[0px_2px_4px_#cbc8c8]">
            <div className="lg:tw-w-[80%] tw-mx-auto tw-h-[90%]">
              <UpComingOrders loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchAgent;
