const resizeAndCompressImage = (base64, maxWidth, quality) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Ensure canvas supports transparency
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw image onto canvas preserving transparency
      ctx.drawImage(img, 0, 0);

      // Resize canvas if needed
      if (img.width > maxWidth) {
        const scaleFactor = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleFactor;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }

      // Convert canvas to PNG base64
      const resizedBase64 = canvas.toDataURL("image/png", quality);
      resolve(resizedBase64);
    };
    img.onerror = (error) => {
      reject(error);
    };
  });
};

export const handleImageChange = ({ e, onImageChange }) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onload = (event) => {
    const base64 = event.target.result;
    // resizeAndCompressImage(base64, 100, 1)
    //   .then((resizedBase64) => {
    onImageChange && onImageChange(base64);
    // })
    // .catch((err) => console.error(err));
  };

  reader.onerror = (error) => {
    console.error("Error occurred while reading the file:", error);
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};
