import React, { useEffect, useRef, useState } from "react";
import CalenderIcon from "../../assets/images/searchbars/calendar.png";
import { useTranslation } from "react-i18next";
import Calendar from "../Agent/SearchBars/SubComponents/Calendar/calendar";
import "./DateRange.css";
import "./inputs.css";

const DateRange = ({ onChange, enablePast = false }) => {
  const { t } = useTranslation();
  const excludedCalendarRef = useRef(null);

  const [calendarOpen, setCalendarOpen] = useState(false);
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");

  React.useEffect(() => {
    if (firstDate && secondDate) {
      onChange(firstDate, secondDate);
    }
  }, [secondDate]);

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (
      excludedCalendarRef.current &&
      !excludedCalendarRef.current.contains(event.target)
    ) {
      setCalendarOpen(false);
    }
  };

  const renderDates = (firstDate, secondDate) => {
    return (
      <>
        <div className={"date-range-date first-date"}>{firstDate}</div>
        <div className={"date-range-date-separator"}>{"|"}</div>
        <div className={"date-range-date"}>{secondDate}</div>
      </>
    );
  };

  return (
    <div className="date-range-wrapper input-default" ref={excludedCalendarRef}>
      {calendarOpen && (
        <Calendar
          saveArrivalDate={setFirstDate}
          saveDepartureDate={setSecondDate}
          enablePast={enablePast}
        />
      )}
      <div
        className="date-range-inputs cursor-pointer"
        onClick={toggleCalendar}
      >
        <div className="date-range-dates cursor-pointer">
          {secondDate.length > 0
            ? renderDates(firstDate, secondDate)
            : renderDates(t("hotelsearchbar.date"), t("hotelsearchbar.date"))}
        </div>

        <input
          defaultValue={secondDate}
          className="dates-range-input"
          required
        ></input>
      </div>

      <div className="date-range-calendar-image-wrapper">
        <img
          className="date-range-calendar-image"
          src={CalenderIcon}
          alt="Calendar"
        ></img>
      </div>
    </div>
  );
};

export default DateRange;
