import React from "react";
import classNames from "classnames";
import "./searchoption.scss";
import Tooltip from "@mui/material/Tooltip";

const SearchOption = ({ id, title, action, selected, disabled }) => {
  return (
    <>
      <Tooltip title={disabled ? "בקרוב!" : undefined}>
        <div
          className={classNames("searchOption-wrapper", { selected, disabled })}
          onClick={disabled ? null : () => action(id)}
        >
          <label className="searchOption-title">{title}</label>
        </div>
      </Tooltip>
    </>
  );
};

export default SearchOption;
