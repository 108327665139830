import React from 'react';
import {FormFields} from "../../../contexts/RegistrationContext";
import RegistrationBox from "../RegistrationBox";
import { OrangeRegistrationInput} from "../Inputs";

const BusinessDetails = ({missingFields}) => {
    const inputs = [
        FormFields.COMPANY_INFO.COMPANY_COUNTRY,
        FormFields.COMPANY_INFO.COMPANY_NUMBER,
        FormFields.COMPANY_INFO.COMPANY_TYPE,
        FormFields.COMPANY_INFO.COMPANY_NAME,
        FormFields.COMPANY_INFO.IN_BUSINESS,
        FormFields.COMPANY_INFO.SALES_REVENUE,
    ]

    return (
        <RegistrationBox title="קצת על העסק">
            {inputs.map((key) => <OrangeRegistrationInput registrationKey={key}/>)}
        </RegistrationBox>
    );
};

export default BusinessDetails;