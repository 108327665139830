import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LDS_AUTOCMPLETE_CITIES from "../../../../../assets/Data/LdsAirportsAutocomplete";

export const parseLocationToString = (l) =>
  `(${l.AIRPORTCODE}) ${l.COUNTRYNAME_HEB} - ${l.CITYNAME_HEB}`;

const AutoCompleteInput = ({ saveLocationSelection, location = "" }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(
    location ? parseLocationToString(location) : "",
  );

  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const optionsRef = React.createRef();
  const inputRef = React.createRef();

  function getOptions(newValue) {
    if (newValue.length <= 2) {
      setFilteredOptions([]);
    } else {
      const filteredOptions = LDS_AUTOCMPLETE_CITIES.filter((user) =>
        Object.values(user).some((value) =>
          value
            ? value.toString().toLowerCase().includes(newValue.toLowerCase())
            : false,
        ),
      );
      setFilteredOptions(filteredOptions);
    }
  }

  function onChange(e) {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    getOptions(newValue);
  }

  function onOptionSelect(e, option) {
    e.preventDefault();
    setCurrentValue(parseLocationToString(option));
    saveLocationSelection(option);
    setIsOpen(false);
  }

  function getLdsOptionRow(option) {
    if (!option.AIRPORTCODE) {
      return null;
    }
    return (
      <div
        id={`lds-autocomplete-${option?.AIRPORTCODE}`}
        className="autoComplete-input-option-row"
        onClick={(e) => {
          onOptionSelect(e, option);
        }}
      >
        {option?.COUNTRYNAME_HEB && option?.CITYNAME_HEB ? (
          <div>
            {`(${option.AIRPORTCODE}) ${option.COUNTRYNAME_HEB} - ${option.CITYNAME_HEB}`}
          </div>
        ) : null}
        <div>{` ${option.CITYNAME} - ${option.COUNTRYNAME}`}</div>
      </div>
    );
  }

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) && // Check if click is outside input
        optionsRef.current &&
        !optionsRef.current.contains(event.target) // Check if click is not on the div
      ) {
        setIsOpen(false);
      }
    }

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, optionsRef]);

  return (
    <div className="autoComplete-input-box-wrapper">
      <input
        onChange={onChange}
        className="autoComplete-input-box"
        value={currentValue}
        placeholder={t("hotelsearchbar.selectdestination")}
        ref={inputRef}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen ? (
        <div className="autoComplete-input-options" ref={optionsRef}>
          {filteredOptions?.map((option) => getLdsOptionRow(option))}
        </div>
      ) : null}
    </div>
  );
};

export default AutoCompleteInput;
