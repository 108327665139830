import React from "react";
import Tooltip from "@mui/material/Tooltip";
import "./OrdersFiltersTab.css";

const OrdersFiltersTab = ({
  title,
  tabProps,
  handleChange,
  tabIndex,
  index,
  img,
  disabled,
}) => {
  const selected = index === tabIndex;
  return (
    <Tooltip title={disabled ? "בקרוב!" : undefined}>
      <div
        className={`filters-tab lg:tw-w-[150px]  ${selected ? "filters-tab-selected" : ""}${disabled ? "filters-tab-disabled" : ""}`}
        onClick={() => (disabled ? {} : handleChange(index))}
        {...tabProps}
      >
        <div className="filters-tab-image-wrapper">
          {img ? (
            <img
              className={`filters-tab-image ${
                selected ? "filters-tab-image-selected" : ""
              }`}
              src={img}
            />
          ) : null}
        </div>
        <div className="filters-tab-text">{title}</div>
      </div>
    </Tooltip>
  );
};

export default OrdersFiltersTab;
