import axios from "axios";

// Global Variables
import { ApiReqBaseUrl } from "../assets/GlobalVariables";
import { round2 } from "../contexts/AppContext";

// --- Functions

// Get currency - Incase changing the dropdown
const getCurrency = async (data) => {
  // Create API request for currency Calculator
  try {
    const res = await axios.post(`${ApiReqBaseUrl}/change`, data);

    return res.data;
  } catch (err) {
    console.log({ err });
  }
};

// Get currency - Incase a page load
const convertPrice = (price) => {
  try {
    // Get from local storage or use default ILS
    if (!price || price === "") {
      return "0₪";
    }
    const rate = localStorage.getItem("currentRate");
    const currencyInfo = localStorage.getItem("default-currency")
      ? localStorage.getItem("default-currency")
      : JSON.stringify({ symbol: "₪" });

    if (rate && rate !== "undefined" && currencyInfo) {
      return `${parseNumber(parseFloat(price) * parseFloat(rate))}${JSON.parse(currencyInfo).symbol
        }`;
    } else {
      if (currencyInfo) {
        return `${parseNumber(price)}${JSON.parse(currencyInfo).symbol}`;
      } else {
        return `${parseNumber(price)}₪`;
      }
    }
  } catch (err) {
    console.log({ err });
  }
};

const getCurrencyRate = async (currencyCode) => {
  // Create API request for getting Currency Rate
  try {
    const res = await axios.post(`${ApiReqBaseUrl}/getRate`, currencyCode);

    return res.data;
  } catch (err) {
    console.log({ err });
  }
};

export function parseNumber(num) {
  if (typeof num !== "number" && isNaN(parseFloat(num))) {
    console.error(`${num} is not a number!`);
    return NaN;
  }
  const fixedNum = round2(parseFloat(num));
  return fixedNum;
}

export { getCurrency, convertPrice, getCurrencyRate };
