// export const ApiReqBaseUrl = "https://api.hotelsriver.com";
export const ApiReqBaseUrl = process.env.REACT_APP_API_URL;
// console.log("ApiReqBaseUrl", ApiReqBaseUrl);

// export const ApiReqBaseUrl = "http://127.0.0.1:5000";
export const CLIENT_ID =
  "1064115497372-pdg05barjshr9dfbv3gqu0ceeohu7nrv.apps.googleusercontent.com";
export const GoogleMapSecretKey = "AIzaSyAWE8-GDf0heTxZavlj-jCZNu2zPk_QrE4"; // Google map Secret api key

export const IS_DEV = process.env.REACT_APP_IS_DEV === "1";
