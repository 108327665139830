import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// React Icons
import { FaUser } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";

const ClientInfo = ({ name, email, cell }) => {
  // Translator
  const { t } = useTranslation();

  return (
    <>
      <div className="ci-clientInfo-wrapper">
        <label className="ci-clientInfo-main-title">
          {t("ordersummery.clientsdetails")}
        </label>

        <div className="ci-clientInfo-info-wrapper">
          <div className="ci-clientInfo-info-box">
            <div className="ci-clientInfo-info-box-title-wrapper">
              <label className="ci-clientInfo-info-box-title">
                {t("ordersummery.clientsname")}
              </label>
              <FaUser className="ci-clientInfo-info-box-icon-smaller" />
            </div>

            <label className="ci-clientInfo-info-box-value">{name}</label>
          </div>

          <div className="ci-clientInfo-info-box-border"></div>

          <div className="ci-clientInfo-info-box">
            <div className="ci-clientInfo-info-box-title-wrapper">
              <label className="ci-clientInfo-info-box-title">
                {t("ordersummery.phone")}
              </label>
              <MdLocalPhone className="ci-clientInfo-info-box-icon" />
            </div>

            <label className="ci-clientInfo-info-box-value">{cell}</label>
          </div>

          <div className="ci-clientInfo-info-box-border"></div>

          <div className="ci-clientInfo-info-box">
            <div className="ci-clientInfo-info-box-title-wrapper">
              <label className="ci-clientInfo-info-box-title">
                {t("ordersummery.email")}
              </label>
              <IoIosMail className="ci-clientInfo-info-box-icon" />
            </div>

            <label className="ci-clientInfo-info-box-value">{email}</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientInfo;
