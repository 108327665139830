import React from "react";
import { useRegistration } from "../../contexts/RegistrationContext";
import PrivateDetails from "./stages/PrivateDetails";
import Stages from "../Stages/Stages";
import LoginDetails from "./stages/loginDetails";
import BusinessDetails from "./stages/BusinessDetails";
import { Button, OrangeButton } from "../buttons/Buttons";
import PackageDetails from "./stages/PackageDetails";
import FinalStage from "./stages/FinalStage";
import "./Registration.scss";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import RegistrationImage from "./RegistrationImage/RegistrationImage";
import { bigErrorMessage } from "../../services/swalService";

const Registration = () => {
  const navigate = useNavigate();
  const { getMissingFields, registerUser, requiredFields, loading } =
    useRegistration();
  const [currentStageIndex, setCurrentStageIndex] = React.useState(0);
  const [missingFields, setMissingFields] = React.useState([]);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const stages = [
    {
      title: "פרטים אישיים",
      component: <PrivateDetails missingFields={missingFields} />,
      neededFields: requiredFields.PrivateDetails,
    },
    {
      title: "פרטי התחברות",
      component: <LoginDetails missingFields={missingFields} />,
      neededFields: requiredFields.LoginDetails,
    },
    {
      title: "קצת על העסק",
      component: <BusinessDetails missingFields={missingFields} />,
      neededFields: requiredFields.BusinessDetails,
    },
    {
      title: "בחירת חבילה",
      component: <PackageDetails missingFields={missingFields} />,
      neededFields: requiredFields.PackageDetails,
    },
    {
      title: "אישור רישום",
      component: <FinalStage />,
    },
  ];
  const isFinished = currentStageIndex === stages.length - 1;

  function _setCurrentStageIndex(index) {
    const isLast = index === stages.length - 1;
    if (!isLast) {
      setCurrentStageIndex(index);
    }
  }

  function getButtons() {
    if (loading || buttonLoading) {
      return <Loader />;
    }
    if (isFinished) {
      return <Button onClick={() => navigate("/")}>עבור לדף הבית</Button>;
    }
    const showBackButton = currentStageIndex !== 0;
    const showNextButton = currentStageIndex !== stages.length - 1;

    const isLastStageButton = currentStageIndex === stages.length - 2;
    const nextText = isLastStageButton ? "שליחת פרטים" : "המשך לשלב הבא";

    return (
      <>
        {showBackButton && (
          <Button
            disabled={currentStageIndex === 0}
            onClick={() => setCurrentStageIndex(currentStageIndex + -1)}
          >
            חזור לשלב הקודם
          </Button>
        )}
        {showNextButton && (
          <OrangeButton
            onClick={() =>
              isLastStageButton
                ? handleSubmit()
                : setCurrentStageIndex(currentStageIndex + 1)
            }
          >
            {nextText}
          </OrangeButton>
        )}
      </>
    );
  }

  function handleSubmit() {
    setButtonLoading(true);
    const tempMissingFields = getMissingFields();
    if (tempMissingFields.length === 0) {
      registerUser()
        .then((res) => setCurrentStageIndex(stages.length - 1))
        .catch((err) => {
          bigErrorMessage(err.data.exception);
        })
        .finally(() => {
          setButtonLoading(false);
        });
    } else {
      setMissingFields(tempMissingFields);
      setTimeout(() => {
        setMissingFields([]);
      }, 4000);
      setButtonLoading(false);
    }
  }

  function renderInputs() {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Loader />
        </div>
      );
    }
    return (
      <>
        <div className="register-inputs-header">
          <div className="title">ברוך הבא להוטלס ריבר!</div>
          <div className="sub-title2">
            מערכת תיירות חכמה לסוכנים לפרילנסר ומשרדי נסיעות ממוקדת מכירות
          </div>
          <div className="sub-title">הרשמה למערכת</div>
        </div>

        <Stages
          stages={stages}
          currentIndex={currentStageIndex}
          setCurrentStageIndex={_setCurrentStageIndex}
          missingFields={missingFields}
        />
        <div className="register-inputs-wrapper">
          {stages[currentStageIndex].component}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: 5,
            height: 30,
          }}
        >
          {getButtons()}
        </div>
      </>
    );
  }

  return (
    <div className="register-container">
      <div className="register-inputs-container">{renderInputs()}</div>
      <div className="register-image-container">
        <RegistrationImage currentStageIndex={currentStageIndex} />
      </div>
    </div>
  );
};

export default Registration;
