import React from "react";
import financeLogo from "../../assets/images/finance/financeLogo.png";

import "./FinanceHeader.css";

const FinanceHeader = () => {
  return (
    <div className="finance-header-wrapper">
        <img src={financeLogo} alt="finance logo" className="finance-logo" />
        <div className="main-header">עמוד פיננסים</div>
    </div>
  );
};

export default FinanceHeader;
