import React from "react";

// CSS
import "./starrating.css";

const StarRating = ({ rating, starSize = 15 }) => {
  const getStarIcons = () => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const starIcons = [];

    for (let i = 0; i < fullStars; i++) {
      starIcons.push(
        <span key={i} className="star-icon">
          &#9733;
        </span>,
      );
    }

    if (hasHalfStar) {
      starIcons.push(
        <span key="half" className="star-icon">
          &#9733;
        </span>,
      );
    }

    const remainingStars = 5 - starIcons.length;
    for (let i = 0; i < remainingStars; i++) {
      starIcons.push(
        <span key={`empty-${i}`} className="star-icon empty">
          &#9734;
        </span>,
      );
    }

    return starIcons;
  };

  return (
    <div className="star-rating" style={{ fontSize: starSize }}>
      {getStarIcons()}
    </div>
  );
};

export default StarRating;
