import React, { useEffect } from "react";

// Global veriables
import { GoogleMapSecretKey } from "../../../../../assets/GlobalVariables";

const containerStyle = {
  width: "100%",
  height: "100%",
};

function LocationMap({ lat, lng, mapId, trigger }) {
  const center = {
    lat: lat,
    lng: lng,
  };

  const markerPosition = {
    lat: lat,
    lng: lng,
  };

  const language = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "en";

  // Get the google maps api
  useEffect(() => {
    // Check if the script already exists
    const existingScript = document.querySelector(
      `script[src^="https://maps.googleapis.com/maps/api/js?key=${GoogleMapSecretKey}&language=${language}"]`
    );

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapSecretKey}&language=${language}`;
      script.async = true;
      script.onload = () => {
        initMap(mapId);
      };
      document.head.appendChild(script);
    } else {
      initMap(mapId);
    }
  }, [language, mapId, trigger]);

  // Map configuration
  const initMap = (mapId) => {
    // Check if window.google.maps is defined
    if (window.google && window.google.maps) {
      const map = new window.google.maps.Map(document.getElementById(mapId), {
        center: center,
        zoom: 14,
      });

      // Add marker to the map
      new window.google.maps.Marker({
        position: markerPosition,
        map: map,
      });
    } else {
      console.error("Google Maps API is not available.");
    }
  };

  return (
    <div className={`hotelProfile-content-googlemaps`}>
      <div id={mapId} style={containerStyle}></div>
    </div>
  );
}

export default LocationMap;
