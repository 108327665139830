import React from "react";
import phoneLineSVG from "../../assets/images/navbar/phoneline.svg";
import "./PhoneLine.css";
import { Switch, Tooltip } from "@mui/material";

const PhoneLine = () => {
  return (
    <Tooltip title={"קו טלפון"}>
      <div className="phone-line">
        <Switch dir="rtl" />
        <img src={phoneLineSVG} alt="phone line" />
      </div>
    </Tooltip>
  );
};

export default PhoneLine;
