import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearch } from "./SearchContext";
import {
  PASSENGER_KEYS,
  ROOM_KEYS,
} from "../Components/PassengersData/PassengersData";
import { isNumber, round2, useAppContext } from "./AppContext";
import { getRoomSetByCount } from "../utils/LDSHelpers";
import { getUserDetails } from "../services/authService";

const SelectedResultContext = createContext();

const INITIAL_PASSENGER = {
  [PASSENGER_KEYS.TITLE]: "Mr",
  [PASSENGER_KEYS.FIRSTNAME]: "",
  [PASSENGER_KEYS.LASTNAME]: "",
  [PASSENGER_KEYS.CELL]: "",
  [PASSENGER_KEYS.EMAIL]: "",
  [PASSENGER_KEYS.AGE]: "01/01/1990",
};
const INITIAL_KID_PASSENGER = {
  [PASSENGER_KEYS.TITLE]: "CHD",
  [PASSENGER_KEYS.FIRSTNAME]: "",
  [PASSENGER_KEYS.LASTNAME]: "",
  [PASSENGER_KEYS.AGE]: "01/01/2020",
};
const INITIAL_INFANT_PASSENGER = {
  [PASSENGER_KEYS.TITLE]: "INFANT",
  [PASSENGER_KEYS.FIRSTNAME]: "",
  [PASSENGER_KEYS.LASTNAME]: "",
  [PASSENGER_KEYS.AGE]: "01/01/2024",
};

const INITIAL_ROOM = {
  [ROOM_KEYS.ROOM_SET]: "",
  [ROOM_KEYS.ROOM_TYPE]: "",
  [ROOM_KEYS.SEQ_NUM]: "",
};

function getFirstFare(childFare) {
  // Check if childFare is an array
  if (Array.isArray(childFare)) {
    // Return fare from the first object in the array
    return childFare.length > 0 ? childFare[0].fare : undefined;
  } else {
    // Return fare from the single object
    return childFare.fare;
  }
}
//////////////////////////////////////
export const SelectedResultProvider = ({ children }) => {
  const { currencyRate, getCreditCardFeePercent, getBaggageFeeFlat } =
    useAppContext();

  const [creditCardFee, setCreditCardFee] = useState(0);

  const calculateCreditCardFee = (priceToPay) => {
    const fee = round2(
      (parseFloat(priceToPay) * getCreditCardFeePercent()) / 100
    );
    setCreditCardFee(fee);
    return fee;
  };

  const { searchTab, roomInfo, calculateLDSDealFare } = useSearch();
  const { rooms: roomsComposition } = roomInfo;
  const [extendedDealApiData, setExtendedDealApiData] = useState([]);
  const [roomsPriceTable, setRoomsPriceTable] = useState([]);

  // init roomsPriceTable
  // we cannot use the lds total fare because it doesn't include infant and show only the sum of the first room
  // therefore we need to calc the total by a sum of each pax type (multiply by the number of pax)
  // this result in a roomsPriceTable where each row is a room composition and each col is a specific room (from extended deal api)
  useEffect(() => {
    if (!selectedResult || extendedDealApiData.length == 0) return;
    const { fareDetails } = selectedResult;

    const roomsPriceTable = roomsAndPaxInfo.map((roomPaxInfo) =>
      extendedDealApiData.map((roomSuggestion) => {
        const {
          roomSet,
          childFare = { fare: 0 },
          adultFare,
          infantFare = 0,
          ldsDealId,
        } = roomSuggestion;

        const neededRoomSet = getRoomSetByCount(
          roomPaxInfo?.adults.length,
          roomPaxInfo?.kids.length
        );

        if (roomSet !== neededRoomSet) {
          return null;
        }

        if (roomPaxInfo?.infants.length && !infantFare) {
          return null;
        }

        let totalFare = round2(
          Number(adultFare) * roomPaxInfo.adults.length +
            Number(getFirstFare(childFare)) * roomPaxInfo.kids.length +
            Number(infantFare) * roomPaxInfo.infants.length
        );

        const ourFareDetails = calculateLDSDealFare({
          dealFare: {
            adultFare,
            totalFare,
            childFareExt: childFare,
            infantFare,
          },
          fareDetails,
        });

        return { roomSuggestion, ourFareDetails, ldsDealId };
      })
    );
    setRoomsPriceTable(roomsPriceTable);

    // if the roomAndPaxInfoIndex has no selected room than select the first one which isn't null
    let tempPassengerInfo = JSON.parse(JSON.stringify(roomsAndPaxInfo));
    tempPassengerInfo.forEach((roomPaxInfo, roomAndPaxInfoIndex) => {
      if (!roomPaxInfo.room?.ourFareDetails?.ourTotalFare) {
        const currentRoomPrices = roomsPriceTable[roomAndPaxInfoIndex];
        let i = 0;
        for (i = 0; i < currentRoomPrices.length; i++) {
          if (currentRoomPrices[i]) {
            break;
          }
        }
        const { roomSuggestion, ourFareDetails, ldsDealId } =
          roomsPriceTable[roomAndPaxInfoIndex][i];

        setRoomInfo_helper(
          { ...roomSuggestion, ourFareDetails, ldsDealId },
          tempPassengerInfo,
          roomAndPaxInfoIndex
        );
      }
      setRoomsAndPaxInfo(tempPassengerInfo);
    });
  }, [extendedDealApiData]);

  const [hotelDetails, setHotelDetails] = useState(null);
  const [hotelResource, setHotelResource] = useState(null);
  const [ourSavedLDSOrder, setOurSavedLDSOrder] = useState(null);

  const [isConditionsConfirmed, setIsConditionsConfirmed] = useState(
    false || !!ourSavedLDSOrder
  );
  const [selectedResult, setSelectedResult] = useState();

  ////////////////////////////////
  // reset all fields
  const setSelectedResult_resetAllFields = (selectedResult) => {
    setRoomsAndPaxInfo(getInitialPassengersInfo());
    setRoomsSelected(false);
    setFieldsFilled(false);
    setRoomsPriceTable([]);
    setExtendedDealApiData([]);
    setSelectedResult(selectedResult);
    initLdsSupplements(selectedResult);
    setCurrentStageIndex(0);
    setOurSavedLDSOrder(null);
  };

  const [currentStageIndex, setCurrentStageIndex] = useState(0);
  const [roomsAndPaxInfo, setRoomsAndPaxInfo] = React.useState(
    getInitialPassengersInfo()
  );
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [agentData, setAgentData] = useState(getUserDetails());
  const [handleFeeData, setHandleFeeData] = useState({
    type: "",
    value: 0,
  });
  const updateHandleFeeData = (type, value) => {
    setHandleFeeData({ type, value });
  };
  const [ourPrice, setOurPrice] = useState({});

  const [fieldsFilled, setFieldsFilled] = React.useState(false);
  const [roomsSelected, setRoomsSelected] = React.useState(false);
  const [ldsSupplement, setLdsSupplement] = React.useState(
    getInitialLdsSupplements()
  );

  //////////////////////////////////////
  React.useEffect(() => {
    setFieldsFilled(areAllFieldsFilled());
    setRoomsSelected(areAllRoomsSelected());
    updateNameInSupplement(roomsAndPaxInfo);
  }, [roomsAndPaxInfo]);

  //////////////////////////////////////
  React.useEffect(() => {
    setRoomsAndPaxInfo(getInitialPassengersInfo());
  }, [roomsComposition]);

  const moveNextStage = () => setCurrentStageIndex(currentStageIndex + 1);

  ////////////////////////////////////////////////
  function getInitialLdsSupplements() {
    return {};
  }
  ////////////////////////////////////////////////
  function getInitialPassengersInfo() {
    let paxCounter = 0;
    ////////////////////////////////////////////////
    function getInitialPassengersByLength(length, params) {
      if (!length) {
        return [];
      }
      return Array.from({ length }, (_, i) => {
        paxCounter += 1;
        if (params.adults) {
          return {
            ...INITIAL_PASSENGER,
            "@seqNum": paxCounter,
          };
        }
        if (params.kids) {
          return {
            ...INITIAL_KID_PASSENGER,
            [PASSENGER_KEYS.KIS_SEARCH_AGE]: params.kidsAges[i],
            "@seqNum": paxCounter,
          };
        }
        if (params.infants) {
          return {
            ...INITIAL_INFANT_PASSENGER,
            "@seqNum": paxCounter,
          };
        }
      });
    }

    //////////////////////////////////////
    let roomCounter = 0;
    const roomsAndPaxInfoInit = roomsComposition?.map((room) => {
      roomCounter += 1;
      return {
        adults: getInitialPassengersByLength(room.adults, { adults: true }),
        kids: getInitialPassengersByLength(room.kids, {
          kids: true,
          kidsAges: room.kidsAges,
        }),
        infants: getInitialPassengersByLength(room.infants, { infants: true }),
        room: INITIAL_ROOM,
        "@seqNum": roomCounter,
      };
    });
    return roomsAndPaxInfoInit;
  }

  ////////////////////////////////////////////////
  function areAllFieldsFilled() {
    // Field definitions for adults and kids
    const adultFields = [
      PASSENGER_KEYS.CELL,
      PASSENGER_KEYS.EMAIL,
      PASSENGER_KEYS.LASTNAME,
      PASSENGER_KEYS.FIRSTNAME,
      PASSENGER_KEYS.AGE,
    ];
    const kidFields = [
      PASSENGER_KEYS.AGE,
      PASSENGER_KEYS.LASTNAME,
      PASSENGER_KEYS.FIRSTNAME,
    ];

    ////////////////////////////////////////////////
    // Helper function to check if all required fields are filled in an object
    ////////////////////////////////////////////////
    function isComplete(person, requiredFields) {
      return requiredFields.every(
        (field) =>
          person[field] !== undefined &&
          person[field] !== "" &&
          person[field] !== null
      );
    }

    // Check each group in the data array
    //////////////////////////////////////
    return roomsAndPaxInfo?.every((group) => {
      // Check all adults in the group

      const adultsComplete = group.adults.every((adult) =>
        isComplete(adult, adultFields)
      );
      // Check all kids in the group
      const kidsComplete = group.kids.every((kid) =>
        isComplete(kid, kidFields)
      );

      return adultsComplete && kidsComplete;
    });
  }

  ////////////////////////////////////////////////
  function areAllRoomsSelected() {
    return roomsAndPaxInfo?.every(
      (group) => !!group?.room[ROOM_KEYS.ROOM_TYPE]
    );
  }

  //////////////////////////////////////
  const setRoomInfo = (newRoom, roomIndex) => {
    let tempPassengerInfo = JSON.parse(JSON.stringify(roomsAndPaxInfo));
    setRoomInfo_helper(newRoom, tempPassengerInfo, roomIndex);
    setRoomsAndPaxInfo(tempPassengerInfo);
  };

  ////////////////////////////////################################
  const calculateLdsTotalPayment = () => {
    // this code sum the total of each key (hrCommission, agentCommission, total)
    // NOTE: lds don't include infant price in findExtendedDeal, but this is already calculated in
    // RoomSelector
    const ourPrice = {};
    for (let i = 0; i < roomsAndPaxInfo.length; i++) {
      const ourTotalFare =
        roomsAndPaxInfo[i].room?.ourFareDetails?.ourTotalFare;
      if (ourTotalFare) {
        for (let key in ourTotalFare) {
          if (!isNumber(ourTotalFare[key])) {
            continue;
          }
          if (ourPrice[key] === undefined) {
            ourPrice[key] = 0;
          }
          ourPrice[key] += ourTotalFare[key];
        }
      }
    }

    let baggageFare = 0;
    for (const suppKey in ldsSupplement) {
      const supp = ldsSupplement[suppKey];
      if (supp.checked) {
        baggageFare += supp.ourFare;
      }
    }
    ourPrice.baggagePrice = round2(baggageFare);

    ourPrice.priceWithBaggageFare = round2(
      ourPrice.priceWithCommission + baggageFare
    );
    const handleFee = calculateHandleFee(ourPrice.priceWithBaggageFare);
    ourPrice.handleFee = handleFee;

    ourPrice.priceWithHandleFee = ourPrice.priceWithBaggageFare + handleFee;
    ourPrice.creditCardFee = calculateCreditCardFee(
      ourPrice.priceWithHandleFee
    );
    ourPrice.priceWithCreditCardFee = round2(
      ourPrice.priceWithHandleFee + ourPrice.creditCardFee
    );
    ourPrice.handleFeeData = handleFeeData;

    setOurPrice(ourPrice);

    return ourPrice;
  };
  ////////////////////////////////

  const calculateHandleFee = (priceWithCommission) => {
    let handlingFee = handleFeeData.value;
    if (handleFeeData.type === "%")
      handlingFee = (priceWithCommission * handleFeeData.value) / 100;

    return round2(handlingFee);
  };

  useEffect(() => {
    calculateLdsTotalPayment();
  }, [handleFeeData]);

  ////////////////////////////////

  const initLdsSupplements = (selectedResult) => {
    const calculateBaggagePriceWithCommissions = (fare) => {
      const priceNIS = parseFloat(fare) * currencyRate.USD;
      return round2(priceNIS + getBaggageFeeFlat() * currencyRate.USD);
    };
    let { linkedFlights, supplement } = selectedResult;
    if (!supplement) return;
    if (typeof supplement === "object" && !Array.isArray(supplement)) {
      supplement = [supplement];
    }

    supplement = supplement.filter((supp) => {
      return supp.type === "TROLLEY" || supp.type === "LUGGAGE";
    });

    const parsedSupplement = supplement?.map((item) => ({
      "@seqNum": item["@seqNum"],
      id: item.id,
      type: item.type,
      description: item.desc,
      fare: parseFloat(item.fare),
      weight: item.params.find((param) => param.name === "weight")?.value
        ? parseFloat(item.params.find((param) => param.name === "weight").value)
        : null,
      unit: item.params.find((param) => param.name === "unit")?.value || null,
      fltRef: item.fltRef,
      paxRef: item.paxRef,
    }));

    ////////////////////////////////
    const ldsSupplement = {};
    let suppCount = 0;

    parsedSupplement.forEach((supplement, suppIndex) => {
      roomsAndPaxInfo.forEach((roomInfo, roomIndex) => {
        const paxList = [...roomInfo.adults, ...roomInfo.kids];
        paxList.forEach((pax, adultIndex) => {
          if (supplement.fltRef === "-3") {
            linkedFlights.flight.forEach((flight, fightIndex) => {
              suppCount += 1;
              const seqNum = suppCount;
              ldsSupplement[seqNum] = {
                "@seqNum": seqNum,
                orig: {
                  flight: flight.leg,
                  supp: supplement,
                },
                pax,

                type: supplement.type,
                checked: false,
                description: supplement.description,
                weightText: `${supplement.weight}${supplement.unit}`,
                flight: {
                  fltRef: supplement.fltRef,
                  depCity: flight.leg.depCity,
                  arrCity: flight.leg.arrCity,
                },
                ourFare: calculateBaggagePriceWithCommissions(supplement.fare),
              };
            });
          } else {
            suppCount += 1;
            const seqNum = suppCount;
            const flight = linkedFlights.flight[0];
            ldsSupplement[seqNum] = {
              "@seqNum": seqNum,
              orig: {
                flight: flight.leg,
                supp: supplement,
              },
              pax,
              type: supplement.type,
              checked: false,
              description: supplement.description,
              weightText: `${supplement.weight}${supplement.unit}`,
              flight: {
                fltRef: supplement.fltRef,
                depCity: flight.leg.depCity,
                arrCity: flight.leg.arrCity,
              },
              ourFare: calculateBaggagePriceWithCommissions(supplement.fare),
            };
          }
        });
      });
    });

    setLdsSupplement(ldsSupplement);
  };
  ////////////////////////////////

  const getPaxFullNameBySupplement = (supp) => {
    let fullName = "";
    roomsAndPaxInfo.forEach((roomInfo) => {
      const paxList = [...roomInfo.adults, ...roomInfo.kids];
      paxList.forEach((pax, adultIndex) => {
        if (pax["@seqNum"] === supp.pax["@seqNum"]) {
          fullName = `${pax.firstname} ${pax.lastname}`;
          supp.pax.firstname = pax.firstname;
          supp.pax.lastname = pax.lastname;
        }
      });
    });
    return fullName;
  };

  const updateNameInSupplement = (roomsAndPaxInfo) => {
    const tmpLdsSupplement = { ...ldsSupplement };
    for (let suppKey in tmpLdsSupplement) {
      const supp = tmpLdsSupplement[suppKey];
      getPaxFullNameBySupplement(supp);
    }
    setLdsSupplement(tmpLdsSupplement);
  };
  ////////////////////////////////

  const getSupplementsAsType = () => {
    const suppRes = {};
    for (const suppKey in ldsSupplement) {
      const supp = ldsSupplement[suppKey];
      if (suppRes[supp.type]) {
        suppRes[supp.type].push(supp);
      } else {
        suppRes[supp.type] = [supp];
      }
    }
    return suppRes;
  };
  ////////////////////////////////
  const hasLdsSupplements = () => {
    return Object.keys(ldsSupplement).length;
  };
  return (
    <SelectedResultContext.Provider
      value={{
        selectedResult,
        currentStageIndex,
        setCurrentStageIndex,
        searchTab,
        roomsAndPaxInfo,
        fieldsFilled,
        roomsSelected,
        ourSavedLDSOrder,
        // funcs
        moveNextStage,
        setRoomsAndPaxInfo,
        setSelectedResult,
        setOurSavedLDSOrder,
        setRoomInfo,
        setExtendedDealApiData,
        hotelDetails,
        setHotelDetails,
        hotelResource,
        setHotelResource,
        isConditionsConfirmed,
        setIsConditionsConfirmed,
        selectedRoom,
        setSelectedRoom,
        handleFeeData,
        updateHandleFeeData,
        ldsSupplement,
        setLdsSupplement,
        calculateLdsTotalPayment,
        creditCardFee,
        setSelectedResult_resetAllFields,
        getPaxFullNameBySupplement,
        getSupplementsAsType,
        ourPrice,
        roomsPriceTable,
        hasLdsSupplements,
        setHandleFeeData,
        agentData,
        setAgentData,
      }}
    >
      {children}
    </SelectedResultContext.Provider>
  );
};

//////////////////////////////////////
export const useSelectedResult = () => {
  const context = useContext(SelectedResultContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedResult must be used within a SelectedResultProvider"
    );
  }
  return context;
};

export default SelectedResultContext;
function setRoomInfo_helper(newRoom, tempPassengerInfo, roomIndex) {
  const {
    adultFare,
    totalFare,
    roomSet,
    roomType,
    kidFare,
    infantFare,
    ourFareDetails,
    ldsDealId,
    "@seqNum": seqNum,
  } = newRoom;
  tempPassengerInfo[roomIndex].room = {
    [ROOM_KEYS.ROOM_SET]: roomSet,
    [ROOM_KEYS.ROOM_TYPE]: roomType,
    [ROOM_KEYS.SEQ_NUM]: seqNum,
    [ROOM_KEYS.OUR_FARE_DETAILS]: ourFareDetails,
    [ROOM_KEYS.DEAL_FARE]: { adultFare, totalFare, kidFare, infantFare },
    [ROOM_KEYS.LDS_DEAL_ID]: ldsDealId,
  };
}
