import React from "react";

// React Icons
import { BsCheckLg } from "react-icons/bs";

const OrderDetail = ({ title, value, classDetail }) => {
  return (
    <div className="orderDetail-component successOrder-content-details-detail">
      {/* Title */}
      <div className="successOrder-content-details-detail-title-wrapper">
        <BsCheckLg className="successOrder-content-details-detail-title-icon" />
        <label className="successOrder-content-details-detail-title">
          {title}:
        </label>
      </div>

      {/* Value */}
      <div className={`${classDetail}`}>{value}</div>
    </div>
  );
};

export default OrderDetail;
