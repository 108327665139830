import axios from "axios";

// Global Variables
import { ApiReqBaseUrl } from "../assets/GlobalVariables";
import { setUserInfo } from "./localStorage";
import { performPostFetch } from "./fetchService";

// Authenticated User
const getAuthenticatedUser = () => {
  const userData = localStorage.getItem("UserInfo");
  const authenticatedUser = userData ? JSON.parse(userData) : null;

  return authenticatedUser;
};

// Functions

// Middleware to check if the user is authenticated
const isAuthenticated = async () => {
  // Check if session expired
  if (getAuthenticatedUser()) {
    try {
      const res = await axios.get(
        `${ApiReqBaseUrl}/isExpired?sessionID=${
          getAuthenticatedUser().sessionID
        }`,
      );

      if (res.data.active === true) {
        return true;
      } else {
        // Logout user
        logout();

        return false;
      }
    } catch (err) {
      console.log({ err });
    }

    // User is authenticated
    return true;
  } else {
    // User is not authenticated
    return false;
  }
};

// Login endpoint
const login = async (data) => {
  const res = await performPostFetch(`${ApiReqBaseUrl}/login`, data);
  if (res?.data?.status === "ok") {
    setUserInfo(res.data);
    return { status: "ok" };
  } else {
    return { status: "fail" };
  }
};

const changeUserImage = async (id, image) => {
  return performPostFetch(`${ApiReqBaseUrl}/changeProfileImage`, {
    id,
    base64Image: image,
  });
};
const changeUserLogo = async (id, image) => {
  return performPostFetch(`${ApiReqBaseUrl}/changeLogo`, {
    id,
    base64Image: image,
  });
};

// Logout endpoint
const logout = async () => {
  try {
    // Remove all data from the local storage and session storage
    // localStorage.removeItem('UserInfo');
    localStorage.clear();
    sessionStorage.clear();

    let data = {
      sessionID: getAuthenticatedUser().sessionID,
    };

    // Log user out
    return await axios.post(`${ApiReqBaseUrl}/logout`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Endpoint to get authenticated user details
const getUserDetails = () => {
  if (getAuthenticatedUser() !== null) {
    // Return user
    return getAuthenticatedUser();
  } else {
    return false;
  }
};

const isAdminUser = () => {
  const user = getAuthenticatedUser();
  const adminsMails = ["adir@hotelsriver.com"];
  if (user) {
    return adminsMails.includes(user.email);
    // return user.usertype === "Admin";
  } else {
    return false;
  }
};

const getSessionId = async () => {
  const user = getAuthenticatedUser();
  if (user) {
    return user.sessionID;
  } else {
    return false;
  }
};

export {
  isAuthenticated,
  login,
  logout,
  getUserDetails,
  isAdminUser,
  getSessionId,
  changeUserImage,
  changeUserLogo,
};
