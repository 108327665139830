import React from 'react';
import "./RegistrationImage.scss"

import stage1src from "../../../assets/images/register/stage-1.png"
import stage2src from "../../../assets/images/register/stage-2.png"
import stage3src from "../../../assets/images/register/stage-3.png"
import stage4src from "../../../assets/images/register/stage-4.png"
import stage5src from "../../../assets/images/register/stage-5.png"
const RegistrationImage = ({currentStageIndex}) => {
    function getImageSrc(){
        switch (currentStageIndex){
            case 0: return stage1src
            case 1: return stage2src
            case 2: return stage3src
            case 3: return stage4src
            case 4: return stage5src
            default: return stage1src

        }
    }

    return (<img src={getImageSrc()} className="registration-image"/> )
};

export default RegistrationImage;