import React, { useState, useEffect } from "react";
import { Loader } from "./Loader";
import Logo from "../../assets/images/hotelsriver-logo.jpg";
import "./LoaderModal.scss";

export const LoaderModal = ({}) => {
  return (
    <div className="loader-modal">
      <img
        style={{ width: 550, objectFit: "contain" }}
        src={Logo}
        alt="Hotels River Logo"
      />
      <Loader showText size={"large"} />
    </div>
  );
};
