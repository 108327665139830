import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// React Icons
import { BsFillPersonFill } from "react-icons/bs";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./successorder.css";

// Services
import { convertPrice } from "../../../../services/currencyService";
import {
  getDelayedPayOrder,
  getHotelsImage,
  cancellationPolicies,
} from "../../../../services/fhpService";

// Components
import OrderDetail from "./SubComponents/OrderDetail/orderdetail";
import LocationMap from "../../../../Pages/Client/HotelProfile/Components/LocationMap/locationmap";

// Gloabl Veriables
import { GoogleMapSecretKey } from "../../../../assets/GlobalVariables";
import ScreenCapture from "../../../ScreenCapture/ScreenCapture";
import { useOrder } from "../../../../contexts/OrderContext";

const SuccessOrder = () => {
  // Params
  const { currentOrderId } = useParams(); // Checks if delayed order visit accured
  // Constants

  const tableForScreenshot = React.useRef(null);

  // States
  const [orderReqData, setOrderReqData] = useState(null);
  const [details, setDetails] = useState([]);
  const [hotelMainImage, setHotelMainImage] = useState(null);
  const [hotelCountry, setHotelCountry] = useState("");
  const [hotelLongLat, setHotelLongLat] = useState(null);
  const [dataCancelHotel, setDataCancelHotel] = useState([]);
  const [dataCancelHotelDesc, setDataCancelHotelDesc] = useState([]);

  // Translator
  const { t } = useTranslation();

  // --- useEffects

  useEffect(() => {
    const GetOrderData = async () => {
      const data = localStorage.getItem("UserInfo");
      const agentId = data ? JSON.parse(data).id : 0;

      const res = await getDelayedPayOrder({
        id: parseInt(currentOrderId),
        agentID: agentId,
      });

      let orderData = res.data;

      if (orderData.status !== "perms" && orderData.status !== "fail") {
        // Save order
        setOrderReqData(orderData);

        // Create details for later use
        createDetails(orderData);
      }
    };

    GetOrderData();
  }, []);

  useEffect(() => {
    const getHotel = async () => {
      // Get hotel image
      const dataImg = {
        hotelID: parseInt(orderReqData.hotelid),
      };

      const resImage = await getHotelsImage(dataImg);

      if (resImage && resImage.data) {
        setHotelMainImage(resImage.data.image);
      }
    };

    if (orderReqData) {
      getHotel();
    }
  }, [orderReqData]);

  useEffect(() => {
    async function getGeolocation(address) {
      try {
        // const language = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'he';
        const language = "en";

        const encodedAddress = encodeURIComponent(address);
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${GoogleMapSecretKey}&language=${language}`;

        const response = await fetch(apiUrl);
        const data = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
          const result = data.results[0];
          const country = result.address_components.find((component) =>
            component.types.includes("country")
          ).long_name;

          const location = result.geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;

          return { country, latitude, longitude };
        } else {
          throw new Error("Unable to retrieve geolocation data");
        }
      } catch (error) {
        console.error("Error fetching geolocation:", error.message);
        throw error;
      }
    }

    if (orderReqData) {
      const address = orderReqData.hoteladdress;

      getGeolocation(address)
        .then((result) => {
          setHotelCountry(result.country);
          setHotelLongLat({ lat: result.latitude, lng: result.longitude });
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    }
  }, [orderReqData]);

  // Get cancalation policys
  useEffect(() => {
    const getCancellationPolicies = async () => {
      const policies = JSON.parse(orderReqData.policies);

      setDataCancelHotel(policies);
      setDataCancelHotelDesc(orderReqData.policyremarks);

      // Add to order summery cancelation policy
      const cancelPolicyObject = document.getElementById("cancelPolicy");

      if (cancelPolicyObject) {
        // Remove existing content
        while (cancelPolicyObject.firstChild) {
          cancelPolicyObject.removeChild(cancelPolicyObject.firstChild);
        }

        if (policies.length > 0) {
          const cancelationEntrys = document.createElement("div");
          cancelationEntrys.className = "passangerData-cancelation-entrys";

          policies.forEach((cancelRowData, index) => {
            const entry = document.createElement("div");
            entry.key = index;

            const label = document.createElement("label");
            label.className = "passangerData-cancelation-entry";
            label.textContent =
              t("completepassengers.startsat") +
              " " +
              cancelRowData.from +
              " " +
              t("completepassengers.feesare") +
              " ";

            const priceLabel = document.createElement("label");
            priceLabel.id = "price-fee";
            priceLabel.textContent = convertPrice(orderReqData?.pricetotal);

            label.appendChild(priceLabel);
            entry.appendChild(label);
            cancelationEntrys.appendChild(entry);
          });

          cancelPolicyObject.appendChild(cancelationEntrys);
        } else {
          const emptyMessage = document.createElement("div");
          emptyMessage.className = "successOrder-cancelation-empty";
          emptyMessage.textContent = "No Cancellation Policies Available";

          cancelPolicyObject.appendChild(emptyMessage);
        }
      }
    };

    if (orderReqData && orderReqData.policies) {
      getCancellationPolicies();
    }
  }, [orderReqData]);

  // --- Functions

  function analyzeRooms(data) {
    let totalAdults = 0;
    let roomNames = [];
    let totalKids = 0;

    // Create a Set to store unique room names
    let uniqueRoomNames = new Set();

    data.forEach((room) => {
      // Count adults
      totalAdults += room.adultscount;

      // Add room name to uniqueRoomNames set
      uniqueRoomNames.add(room.roomname);

      // Count kids
      totalKids += room.kidsages.length;
    });

    // Convert uniqueRoomNames Set to an array
    roomNames = Array.from(uniqueRoomNames);

    // Convert roomNames array to a comma-separated string
    const roomNamesString = roomNames.join(", ");

    return {
      totalAdults: totalAdults,
      totalRooms: data.length,
      roomNames: roomNamesString,
      totalKids: totalKids,
    };
  }

  function calculateNumberOfNights(checkin, checkout) {
    // Get number of nights
    const checkInParts = checkin.split("/");
    const checkOutParts = checkout.split("/");

    // Create Date objects using parts in the format: year, month - 1, day
    const checkInDate = new Date(
      checkInParts[2],
      checkInParts[1] - 1,
      checkInParts[0]
    );
    const checkOutDate = new Date(
      checkOutParts[2],
      checkOutParts[1] - 1,
      checkOutParts[0]
    );

    // Calculate the time difference in milliseconds
    const timeDifference = checkOutDate - checkInDate;

    // Convert milliseconds to days and round down
    const numberOfNights = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return numberOfNights;
  }

  const createDetails = (orderData) => {
    // Gather data
    const roomsData = analyzeRooms(orderData.rooms);
    const noOfNights = calculateNumberOfNights(
      orderData.checkin,
      orderData.checkout
    );

    const detailClass = "successOrder-content-details-detail-value";

    // Create Details
    const data = [
      {
        title: t("successorder.confirmationnumber"),
        detailValue: orderData.orderid,
        classDetail: detailClass,
      },
      {
        title: t("successorder.hotelname"),
        detailValue: orderData.hotelname,
        classDetail: detailClass,
      },
      {
        title: t("successorder.address"),
        detailValue: orderData.hoteladdress,
        classDetail: detailClass,
      },
      {
        title: t("successorder.roomkind"),
        detailValue: roomsData.roomNames,
        classDetail: detailClass,
      },
      {
        title: t("successorder.totalpeople"),
        detailValue: `${roomsData.totalAdults} ${t("successorder.adults")} ${
          roomsData.totalKids > 0
            ? `${t("successorder.kids")}, ${roomsData.totalKids}`
            : ""
        }`,
        classDetail: detailClass,
      },
      {
        title: t("successorder.roomamount"),
        detailValue: roomsData.totalRooms,
        classDetail: detailClass,
      },
      {
        title: t("successorder.totalnights"),
        detailValue: noOfNights,
        classDetail: detailClass,
      },
      {
        title: t("successorder.checkin"),
        detailValue: orderData.checkin,
        classDetail: detailClass,
      },
      {
        title: t("successorder.checkout"),
        detailValue: orderData.checkout,
        classDetail: detailClass,
      },
      {
        title: t("successorder.totalcost"),
        detailValue: (
          <span id="price-cost">{convertPrice(orderData.pricetotal)}</span>
        ),
        classDetail: detailClass,
      },
      {
        title: t("successorder.canclationconditions"),
        detailValue: orderData.refundability ? (
          <div id="cancelPolicy"></div>
        ) : (
          t("successorder.fullpayment")
        ),
        classDetail: orderData.refundability
          ? "successOrder-content-details-detail-value-green"
          : "successOrder-content-details-detail-value-red",
      },
    ];

    setDetails(data);
  };

  // -- Handle commition

  return (
    <>
      <div className="successOrder-crubms-wrapper">
        <div className="successOrder-crubms">
          <span className="successOrder-crubms-title">
            <a
              href="/p/dashboard/searchoffer"
              className="successOrder-crubms-title-link"
            >
              {t("navbar.main")}
            </a>{" "}
            /&nbsp;
            <a className="successOrder-crubms-title-link">
              {orderReqData ? orderReqData.hotelname : ""}
            </a>{" "}
            /&nbsp;
            <label>{t("ordersummery.ordersummery")}</label>
          </span>
        </div>
      </div>

      <div className="successOrder-component">
        {orderReqData && (
          <>
            <div className="successOrder-header-wrapper">
              <div className="successOrder-header-title">
                <label>
                  {t("successorder.yourorderto")} {hotelCountry}{" "}
                  {t("successorder.isapproved")}
                </label>
              </div>

              <div className="successOrder-header-hotelname">
                <label>{orderReqData.hotelname}</label>
              </div>
            </div>

            <div className="successOrder-body-wrapper lg:tw-flex">
              <div className="successOrder-body-item">
                {hotelMainImage ? (
                  <img
                    src={hotelMainImage}
                    className="successOrder-body-item-img"
                  ></img>
                ) : (
                  <div>Loading...</div>
                )}

                <div className="successOrder-body-item-gap"></div>

                {/* Order Content */}
                <div className="successOrder-content-wrapper">
                  {/* <ScreenCapture captureRef={tableForScreenshot} /> */}

                  {/* Details Header */}
                  <div className="successOrder-content-header-wrapper">
                    <div className="successOrder-content-header-title-wrapper">
                      <BsFillPersonFill className="successOrder-content-header-title-icon" />
                      <label className="successOrder-content-header-title">
                        {t("successorder.thankyou")}, {orderReqData.fullname}!
                      </label>
                    </div>

                    <label className="successOrder-content-header-sub-title">
                      {t("successorder.emailsent")} :{" "}
                      <span className="successOrder-content-header-sub-title-email">
                        {orderReqData.email}
                      </span>
                    </label>
                  </div>

                  {/* Details Summeration  */}
                  <div
                    className="successOrder-content-details-wrapper"
                    ref={tableForScreenshot}
                  >
                    <label className="successOrder-content-details-title">
                      {t("successorder.ordersummery")}
                    </label>

                    {/* Details */}
                    <div className="successOrder-content-details">
                      {details &&
                        details.map((detail, index) => (
                          <OrderDetail
                            key={index}
                            title={detail.title}
                            value={detail.detailValue}
                            classDetail={detail.classDetail}
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="successOrder-body-gap"></div>

              {/* Map | Functions */}
              <div className="successOrder-functions-wrapper lg:tw-w-[30%]">
                {/* Map */}
                {hotelLongLat && (
                  <div className="successOrder-functions-map-wrapper">
                    <LocationMap
                      lat={hotelLongLat.lat}
                      lng={hotelLongLat.lng}
                      mapId="map-1"
                    />
                  </div>
                )}

                <div className="successOrder-body-item-gap"></div>

                {/* Functions Displayer */}
                <div className="successOrder-functions">
                  <label className="successOrder-content-header-title">
                    {t("successorder.changeorder")}
                  </label>
                  <label className="successOrder-content-header-sub-title">
                    {t("successorder.changeorderonline")}
                  </label>

                  {/* Functions */}

                  {/* Cancaltion Policy */}
                  <div className="successOrder-functions-canclation-wrapper">
                    <label
                      className={`successOrder-functions-canclation-title ${
                        orderReqData.refundability
                          ? "successOrder-functions-canclation-title-green"
                          : "successOrder-functions-canclation-title-red"
                      }`}
                    >
                      {t("successorder.canclationpolicy")}
                    </label>

                    <div
                      className="successOrder-cancelation-desc"
                      dangerouslySetInnerHTML={{ __html: dataCancelHotelDesc }}
                    />

                    <label className="successOrder-functions-canclation-sub-title">
                      {dataCancelHotel.length > 0 ? (
                        <div className="passangerData-cancelation-entrys">
                          {dataCancelHotel.map((cancelRowData, index) => (
                            <div key={index}>
                              <label className="passangerData-cancelation-entry">
                                {t("completepassengers.startsat")}{" "}
                                {cancelRowData.from}{" "}
                                {t("completepassengers.feesare")}{" "}
                                {
                                  <label id="price-fee">
                                    {convertPrice(orderReqData?.pricetotal)}
                                  </label>
                                }
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="successOrder-cancelation-empty">
                          No Canclation Policys Available
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SuccessOrder;
