import React, { useState } from "react";
import isArray from "lodash/isArray";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NoBeds from "../../assets/images/icons/bed.png";
import { useTranslation } from "react-i18next";
import "./ImagesCrousel.css";

function ImagesCarousel({ images }) {
  const { t } = useTranslation();
  const [isZoom, setIsZoom] = useState(false);

  const imageArr = isArray(images) ? images : [images];

  const toggleZoom = () => setIsZoom(!isZoom);

  return (
    <div
      className={`roomDisplayer-object-room-img ${
        isZoom ? "roomDisplayer-object-room-img-zoom-wrapper" : ""
      }`}
      // onClick={() => (isZoom ? setIsZoom(false) : {})}
    >
      <div className={`${isZoom ? "roomDisplayer-object-room-img-zoom" : ""}`}>
        <Carousel
          showThumbs={false}
          // showStatus={false}
          showArrows={imageArr.length - 1}
          infiniteLoop={true}
        >
          {imageArr ? (
            imageArr.map((imageUrl, index) => (
              <div
                key={index}
                className={`roomDisplayer-room-image-wrapper ${
                  isZoom ? "roomDisplayer-object-room-img-zoom-cursor" : ""
                }`}
              >
                <img
                  className={`${
                    isZoom ? "roomPlacer-image-zoom" : "roomPlacer-image"
                  }`}
                  src={imageUrl}
                  alt={`Image ${index}`}
                />

                <div className="roomDisplayer-room-image" onClick={toggleZoom}>
                  {isZoom ? (
                    <span>{t("hotelprofile.smallimg")}</span>
                  ) : (
                    <span>{t("hotelprofile.bigimg")}</span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="roomPlacer-nobedimage-wrapper">
              <img
                className="roomPlacer-nobedimage-img"
                src={NoBeds}
                alt="No hotel room images"
              ></img>
              <label className="roomPlacer-nobedimage-title">
                {t("hotelprofile.noimages")}
              </label>
            </div>
          )}
        </Carousel>
      </div>
    </div>
  );
}

export default ImagesCarousel;
