import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { successMessage } from "../../../../services/swalService";

import "./HotelsTable.scss";
import { convertPrice } from "../../../../services/currencyService";

const PoliciesCellRender = ({ policies, policyremarks, price }) => {
  const { t } = useTranslation();

  const parsedPolicies = JSON.parse(policies)[0];
  const { fee, from, currency } = parsedPolicies;
  return (
    <div>
      {price ? price : fee}
      {currency === "ILS" ? "₪" : currency}
      {"   "} {t("orders.from")} {from}
    </div>
  );
};

const PassengersNamesRenderer = ({ passengers }) => {
  const parsedPassengers = JSON.parse(passengers);

  return parsedPassengers.map((passenger, index) => {
    const { NamePrefix, GivenName, Surname } = passenger?.PersonDetails?.Name;
    return (
      <div key={GivenName + "-" + index}>
        {NamePrefix} {GivenName} {Surname}
      </div>
    );
  });
};

const RoomsTypeRenderer = ({ rooms }) => {
  return rooms.map((room, index) => {
    const { roomname } = room;
    return (
      <div key={index} className="blue-name-cell">
        {roomname}
      </div>
    );
  });
};

const DatesCellRender = ({ checkIn, checkOut }) => {
  return (
    <div>
      {checkIn} - {checkOut}
    </div>
  );
};

const CollapsedHotelRow = ({ row, cancelOrder } = {}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isRemarkesOpen, setIsRemarkesOpen] = useState(false);
  const [isCancelOrderOpen, setIsCancelOrderOpen] = useState(false);

  const {
    agentName: agentInfo,
    passengers,
    hotelname,
    rooms,
    fullname,
    checkin,
    checkout,
    orderid,
    refundableuntil,
    pricetotal,
    policies,
    policyremarks,
    paid,
    id,
    segments,
    handlingfee,
    handle_fee_option,
    commission,
    paymentinterest,
    creationdate,
  } = row;

  const today = new Date()
    .toLocaleDateString("he-IL", { timeZone: "Asia/Jerusalem" })
    .replace(/\D/g, "/");

  const isCancelDiabled =
    new Date(refundableuntil).getTime() < new Date(today).getTime();

  const handlePayNow = () => {
    if (id) {
      navigate(`/p/dashboard/payment/${id}`);
    }
  };

  const cancelOrderClick = () => {
    cancelOrder({ orderId: id, segmentId: segments[0] }).then(() =>
      successMessage({
        title: "ההזמנה בוטלה בהצלחה",
        message: "",
        confirmButtonText: "אישור",
      })
    );
    setIsCancelOrderOpen(false);
  };

  const CancelOrderModal = ({ policies }) => {
    return (
      <div className="cancel-order-content">
        <div style={{ height: "200px", overflow: "auto" }}>
          <Typography id="modal-modal-description">
            <div
              className="modal-cancelation-policy-remarks"
              dangerouslySetInnerHTML={{
                __html: policyremarks,
              }}
            />
          </Typography>
        </div>
        <PoliciesCellRender policies={policies} />
        <button className="table-button-cancel-now" onClick={cancelOrderClick}>
          {t("orders.cancelSure")}
        </button>
      </div>
    );
  };

  const getFee = (totalPrice, fee, type) => {
    if (type === "%") {
      return (totalPrice * fee) / 100;
    } else {
      return fee;
    }
  };

  return (
    <div className="collapsed-wrapper">
      <div className="column-wrapper">
        {agentInfo ? (
          <>
            <div className="item-wrapper">
              <div className="item-header">{t("orders.agentName")}</div>
              <div>
                {agentInfo.firstName} {agentInfo.lastName}
              </div>
            </div>
            <div className="item-wrapper">
              <div className="item-header">{t("orders.agentEmail")}</div>
              <div>{agentInfo.email}</div>
            </div>
          </>
        ) : null}
        <div className="item-wrapper">
          <div className="item-header">{t("orders.hotelName")}</div>
          <div className="blue-name-cell">{hotelname}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.roomType")}</div>
          <RoomsTypeRenderer rooms={rooms} />
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">{t("orders.mainContact")}</div>
          <div>{fullname}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.passengersNames")}</div>
          <PassengersNamesRenderer passengers={passengers} />
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.stayDates")}</div>
          <DatesCellRender checkIn={checkin} checkOut={checkout} />
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.orderId")}</div>
          <div>{orderid}</div>
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <div className="item-header">{t("orders.createdAt")}</div>
          <div className="created-at-until-cell">{creationdate}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.toPay")}</div>
          <div>{convertPrice(pricetotal)}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">ריבית תשלומים</div>
          <div>{convertPrice(paymentinterest)}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.myCommission")}</div>
          <div className="commisions-cell">{convertPrice(commission)}</div>
        </div>
        <div className="item-wrapper">
          <div className="item-header">{t("orders.handlingFee")}</div>
          <div className="commisions-cell">
            {handlingfee && handle_fee_option
              ? convertPrice(getFee(pricetotal, handlingfee, handle_fee_option))
              : "אין"}
          </div>
        </div>
      </div>
      <div className="column-wrapper">
        <div className="item-wrapper">
          <button
            className={paid ? "table-button-disabled" : "table-button-pay-now"}
            onClick={handlePayNow}
            disabled={paid}
          >
            {t("orders.payNow")} {">"}
          </button>
        </div>
        <div className="item-wrapper">
          <button
            className={
              isCancelDiabled
                ? "table-button-disabled"
                : "table-button-cancel-now"
            }
            disabled={isCancelDiabled}
            onClick={() => setIsCancelOrderOpen(true)}
          >
            {t("orders.cancelOrder")} {">"}
          </button>
        </div>
        <div className="item-wrapper">
          <div className="item-header">
            {t("orders.cancelationPolicy")}
            <div className="info-icon" onClick={() => setIsRemarkesOpen(true)}>
              <InfoOutlined fontSize="small" />
            </div>
          </div>
          <PoliciesCellRender
            className="policies-cell"
            policies={policies}
            policyremarks={policyremarks}
            price={pricetotal}
          />
          <Modal
            open={isRemarkesOpen}
            onClose={() => {
              setIsRemarkesOpen(false);
            }}
          >
            <Box className="box-style">
              <div style={{ height: "400px", overflow: "auto" }}>
                <Typography id="modal-modal-description">
                  <div
                    className="modal-cancelation-policy-remarks"
                    dangerouslySetInnerHTML={{
                      __html: policyremarks,
                    }}
                  />
                </Typography>
              </div>
            </Box>
          </Modal>
          <Modal
            open={isCancelOrderOpen}
            onClose={() => {
              setIsCancelOrderOpen(false);
            }}
          >
            <Box className="modal-cancel-order">
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <CancelOrderModal policies={policies} />
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

//fullname

export default CollapsedHotelRow;
