import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

// React Icons
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./creditcarddetails.css";

// Images
import doneImg from "../../../../../../assets/images/passangerData/done.png";

// Services
import {
  bookHotel,
  pay,
  delayedPayment,
  updateOrderFinancials,
} from "../../../../../../services/fhpService";
import {
  convertPrice,
  parseNumber,
} from "../../../../../../services/currencyService";

// Custom Functions
import CustomAlertAgent from "../../../../../GeneralComponents/CustomAlertAgent/customalertagent";
import CustomLoading from "../../../../../GeneralComponents/CustomLoading/customloading";

// Icons
import visaIcon from "../../../../../../assets/images/payment/visa.png";
import visa1Icon from "../../../../../../assets/images/payment/visa1.png";
import MasterCardIcon from "../../../../../../assets/images/payment/card.png";
import AmericanExpressIcon from "../../../../../../assets/images/payment/american-express.png";
import { useCards } from "../../../../../../contexts/CardsContext";
import TotalPayment from "../../../TotalPayment";
import { useOrder } from "../../../../../../contexts/OrderContext";
import { round2 } from "../../../../../../contexts/AppContext";

const CreditCardDetails = ({
  cardNumber,
  orderReqData,
  handlingFee,
  baseCommissions,
  priceToPay,
  savePriceToPay,
  cardIndex,
  cardFee,
}) => {
  const { currentHotelOrder } = useOrder();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { currentOrderId } = useParams();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [expandCard, setExpandCard] = useState(true);
  const { cards, updateCards, setCardPaid } = useCards();
  const currentCard = cards[cardIndex];

  const currentCardPaid = currentCard.amount;

  // 1. Auto open first card
  useEffect(() => {
    if (cardNumber === 1) {
      setExpandCard(true);
    }
  }, []);

  // --- Functions
  const cardExpandor = () => {
    setExpandCard(!expandCard);
  };

  // Mark and un mark inputs
  const handleMarkEdit = (e) => {
    e.target?.classList.add("ci-input-editing");
  };

  const handleUnMarkEdit = (e) => {
    e.target?.classList.remove("ci-input-editing");
  };

  // Save changes for inputs
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    let newCardAmount = null;
    if (name === "amount") {
      newCardAmount = parseFloat(value);
      if (cards.length === 1) {
        return;
      }
      if (newCardAmount > priceToPay || newCardAmount < 0) {
        return false;
      }
    }

    // Change caret color to default when the user starts typing
    if (value.length > 0) {
      e.target.style.caretColor = "auto";
    } else {
      e.target.style.caretColor = "transparent";
    }

    if (name === "cardnumber") {
      // Make spacing
      value = formatCreditCardNumber(value);
    }

    if (name === "expdate") {
      // Make automatic '/'
      if (value.length === 2 && value.indexOf("/") === -1) {
        value += "/";
      }
    }

    const updatedCards = [...cards];
    const updatedCard = {
      ...cards[cardNumber - 1],
      [name]: newCardAmount ?? value,
    };
    updatedCards[cardNumber - 1] = updatedCard;
    if (name === "amount" && updatedCards.length === 2) {
      let amountDiff = newCardAmount - parseFloat(cards[cardNumber - 1].amount);
      for (let i = 0; i < updatedCards.length; i++) {
        if (i === cardNumber - 1) {
          continue;
        }
        let amountToRemove = Math.min(updatedCards[i].amount, amountDiff);
        updatedCards[i].amount -= amountToRemove;
        amountDiff -= amountToRemove;
      }
    }

    updateCards(updatedCards);
  };

  const formatCreditCardNumber = (value) => {
    const regex = /(\d{1,4})/g;
    const parts = value.match(regex);

    if (parts) {
      const formattedValue = parts.join(" ");

      return formattedValue;
    }
  };

  const handlePaymentsChange = (e) => {
    const newPaymentsNumber = e.target.value;
    let tempCards = JSON.parse(JSON.stringify(cards));
    const tmpCurCard = tempCards[cardNumber - 1];
    tmpCurCard.numberofpayments = newPaymentsNumber;

    updateCards(tempCards);
  };

  // Handle payment and booking
  const handlePayment = async (e) => {
    e.preventDefault();

    // Functions

    // Pay for new order
    const sendPaymentNewOrder = async () => {
      if (currentHotelOrder) {
        // Current Currency
        const currencyData = localStorage.getItem("default-currency");
        const currentCurrecy = currencyData
          ? JSON.parse(currencyData).code
          : "ILS";

        const transactionData = {
          info: `Order_By_${
            currentHotelOrder.passangerData
              ? currentHotelOrder.passangerData.passengerInfo[0][0].firstname.replace(
                  /\s/g,
                  ""
                )
              : ""
          }_${
            currentHotelOrder.passangerData
              ? currentHotelOrder.passangerData.passengerInfo[0][0].lastname.replace(
                  /\s/g,
                  ""
                )
              : ""
          }`, // HOTEL NAME HERE
          amount: currentCard.amountWithInterest, //currentCard.amount, //"7", // TOTAL TO PAY
          userid: currentCard.cardholderid, //"000000000", // TEUDAT ZEHOOT
          firstname: currentCard.cardholdername.split(" ")[0],
          lastname: currentCard.cardholdername.split(" ")[1],
          email: currentHotelOrder.passangerData
            ? currentHotelOrder.passangerData.passengerInfo[0][0].email
            : "",
          tash: currentCard.numberofpayments, //"7", // amount of tashloomim
          currency: currentCurrecy, // ILS | USD | EUR
          cc: currentCard.cardnumber.replace(/\s/g, ""), //"5326105300985614",
          month: currentCard.expdate.split("/")[0], //"12", // IMPORTANT TO BE IN THIS FORMAT: MM
          year: "20" + currentCard.expdate.split("/")[1], // IMPORTANT TO BE IN THIS FORMAT: YYYY
          cvv: currentCard.cvv, //"125"
        };

        try {
          console.dir("transactionData");
          console.dir(transactionData);
          const res = await pay(transactionData);

          // Calcualte how much left to pay
          if (res.data.status === "ok") {
            // Get total amount and how much payed in the current currency
            const totalPriceToPay = parseFloat(priceToPay);

            // Reduce the amount payed
            leftToPay = totalPriceToPay - currentCard.amount; // Left to pay in ILS
            leftToPay = round2(leftToPay);
            setCardPaid(cardIndex);
            savePriceToPay(leftToPay);

            // Flag that every thing went smoothly
            return true;
          }
        } catch (err) {
          console.log({ err });
        }
      } else {
        if (orderReqData) {
          // console.log("add payment in case of from upconging events");
        }

        alert("Error with payment");
      }

      // In case of any problem
      return false;
    };

    // Pay for an existing order
    const sendPaymentExistingOrder = async () => {
      // Current Currency
      const currencyData = localStorage.getItem("default-currency");
      const currentCurrecy = currencyData
        ? JSON.parse(currencyData).code
        : "ILS";

      // Gather data
      const transactionData = {
        info: `Order_By_${orderReqData.fullname.replace(/\s/g, "")}`, // HOTEL NAME HERE
        amount: currentCard.amount, //"7", // TOTAL TO PAY
        userid: currentCard.cardholderid, //"000000000", // TEUDAT ZEHOOT
        // "userid": "000000000",
        firstname: currentCard.cardholdername.split(" ")[0],
        lastname: currentCard.cardholdername.split(" ")[1]
          ? currentCard.cardholdername.split(" ")[1]
          : "",
        email: orderReqData.email,
        tash: currentCard.numberofpayments, //"7", // amount of tashloomim
        currency: currentCurrecy, // ILS | USD | EUR
        cc: currentCard.cardnumber.replace(/\s/g, ""), //"5326105300985614",
        // "cc": "5326105300985614",
        month: currentCard.expdate.split("/")[0], //"12", // IMPORTANT TO BE IN THIS FORMAT: MM
        // "month": "12",
        year: "20" + currentCard.expdate.split("/")[1], // IMPORTANT TO BE IN THIS FORMAT: YYYY
        // "year": "2025",
        cvv: currentCard.cvv, //"125"
        // "cvv": "125"
      };

      try {
        const res = await pay(transactionData);
        if (res.data.status === "ok") {
          const orderFinancialsData = {
            orderID: Number(currentOrderId),
            handlingfee: handlingFee,
            commission: baseCommissions,
            amount: Number(currentCard.amount),
          };
          updateOrderFinancials(orderFinancialsData);
        }

        // Calcualte how much left to pay
        if (res.data.status === "ok") {
          // Get total amount and how much payed in the current currency
          const totalPriceToPay = parseFloat(priceToPay);
          const totalAmountPayed = parseFloat(currentCard.amount); // Needs convertion to dedact right amount (from the current currency to ILS)

          const currentRate = localStorage.getItem("currentRate")
            ? 1 / parseFloat(localStorage.getItem("currentRate"))
            : 1;
          const fromILSToCurrentRate = totalAmountPayed * currentRate; // Calculate amount payed in ILS

          // Reduce the amount payed
          leftToPay = totalPriceToPay - fromILSToCurrentRate; // Left to pay in ILS

          setCardPaid(cardIndex);
          savePriceToPay(leftToPay);

          // Flag that every thing went smoothly
          return true;
        }
      } catch (err) {
        console.log({ err });
      }

      // In case of any problem
      return false;
    };

    // -------- Start Checkout Here --------

    // First step - Pay
    // Second step - Check that the price was fully paid
    // Third step - Book Hotel

    // Set loading on
    setSubmitLoading(true);

    // Determine which kind of payment it is
    const isNewOrder = orderReqData ? false : true;

    // Pay - Different payments types - new order / existing order
    let payedSuccessChecker;
    let leftToPay;

    if (isNewOrder) {
      payedSuccessChecker = await sendPaymentNewOrder();
    } else {
      payedSuccessChecker = await sendPaymentExistingOrder();
    }

    // If payment went succesfully and all price was payed book hotel
    if (payedSuccessChecker) {
      // Show success messege on card
      document.getElementById(`messege-${cardNumber}-suc`).style.display =
        "block";

      // Before moving on block use of that credit card
      const blockInput = () => {
        const currentCardWrapper = document.getElementById(
          `card-number-${cardNumber}`
        );

        let inputs = currentCardWrapper.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {
          // Block all inputs except amount for repayment from the same card
          if (inputs[i].name !== "amount") {
            inputs[i].readOnly = true;
          }
        }
      };

      // Before moving on check and preform actions

      blockInput();
      // Check if the order was fulley paid
      if (leftToPay === 0) {
        // Book hotel
        let res;

        if (isNewOrder) {
          const gatherBookingData = () => {
            // Get Data
            const loggedUser = JSON.parse(localStorage.getItem("UserInfo"));
            const clientData = JSON.parse(
              localStorage.getItem("agent-saved-client-info")
            );

            const { selectedRoom } = currentHotelOrder;

            // Arrange passsangers
            const passangerData = currentHotelOrder.passangerData.passengerInfo;

            let alteredPassangerDataAdults = [];
            let alteredPassangerDataKids = [];

            passangerData.forEach((room) => {
              room.forEach((passenger) => {
                let PersonDetails = {
                  PersonDetails: {
                    Name: {
                      GivenName: passenger.firstname,
                      NamePrefix: "Mr",
                      Surname: passenger.lastname,
                    },
                  },
                };

                if (!passenger.isKid) {
                  alteredPassangerDataAdults.push(PersonDetails);
                } else {
                  alteredPassangerDataKids.push(PersonDetails);
                }
              });
            });

            const alteredPassangerData = [
              ...alteredPassangerDataAdults,
              ...alteredPassangerDataKids,
            ];

            const currentRoom = currentHotelOrder.selectedHotel.Result.find(
              (r) =>
                r.PackageId === currentHotelOrder.selectedHotelPackage.packageID
            );

            let data = {
              session: currentHotelOrder.searchPhrase.session,
              checkIn: String(currentHotelOrder.searchPhrase.checkIn),
              checkOut: String(currentHotelOrder.searchPhrase.checkOut),
              package: { ...currentRoom, images: selectedRoom?.images },
              passengers: alteredPassangerData,
              commission: baseCommissions,
              agentID: loggedUser.id,
              clientID: -1,
              fullname: clientData.fullname,
              cell: clientData.phone,
              email: clientData.email,
              hotel: currentHotelOrder.selectedHotel,
              paid: true,
              remarks: currentHotelOrder.passangerData.specialReq,
              includesTaxes:
                currentHotelOrder.selectedHotelPackage.includesTaxes,
              handleFee: currentHotelOrder.ourPrice.handleFeeData.value,
              handleFeeOption: currentHotelOrder.ourPrice.handleFeeData.type,
              totalPrice:
                currentHotelOrder.ourPrice.priceWithCreditCardFee.toFixed(2),
              cardFee: cardFee.toFixed(2),
              cards,
            };
            return data;
          };

          const bookInfo = gatherBookingData();

          // New order payment
          console.dir(bookInfo);
          res = await bookHotel(bookInfo);

          const orderFinancialsData = {
            orderID: Number(res?.data?.id),
            handlingfee: handlingFee,
            commission: baseCommissions,
            amount: Number(priceToPay),
          };
          updateOrderFinancials(orderFinancialsData);
        } else {
          const data = {
            orderID: orderReqData.id,
            commission: baseCommissions,
            totalPrice: currentHotelOrder.ourPrice.priceWithCreditCardFee,
          };

          // Delayed payment
          res = await delayedPayment(data);
        }

        // If booking went smoothly - Send to summery
        if (res.data.status === "ok") {
          // Reset Order if its a new order
          if (isNewOrder) {
            localStorage.removeItem("currentOrder");
            localStorage.removeItem("agent-saved-client-info");
          }

          // Redirect to order summery success page
          if (isNewOrder) {
            navigate("/p/dashboard/success/" + res.data.id);
          } else {
            navigate("/p/dashboard/success/" + orderReqData.id);
          }
        } else {
          CustomAlertAgent(
            t("warnings.somthingwentwrong"),
            t("warnings.tryagain")
          );

          // Set loading off
          setSubmitLoading(false);

          // Disable all credit cards
          for (let num = 1; num < cardNumber.length; num++) {
            const allCards = document.getElementById(`card-number-${num}`);

            let inputs = allCards.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
              // Block all inputs except amount for repayment from the same card
              inputs[i].readOnly = true;
            }

            let buttons = allCards.getElementsByTagName("button");
            for (let i = 0; i < buttons.length; i++) {
              // Block all buttons
              buttons[i].disabled = true;
            }

            let selects = allCards.getElementsByTagName("select");
            for (let i = 0; i < selects.length; i++) {
              // Block all selects
              selects[i].disabled = true;
            }
          }

          return;
        }
      } else {
        // Set loading off
        setSubmitLoading(false);
      }

      // Set loading off
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);

      // Show err messege on card
      document.getElementById(`messege-${cardNumber}-err`).style.display =
        "block";

      // Send alert
      CustomAlertAgent(t("warnings.somthingwentwrong"), t("warnings.tryagain"));

      return;
    }
  };

  const renderPaymentsFee = () => {
    const { amount, numberofpayments } = currentCard;
    const totalPayment = parseNumber(
      parseFloat(amount) + parseFloat(currentCard.paymentInterest)
    );
    if (!amount) {
      return null;
    }
    return (
      <span style={{ color: "black", fontSize: 16 }}>
        {`שים לב, לתשלום תתווסף ריבית תשלומים בסך`}
        <span style={{ color: "red", margin: "0 5px", fontWeight: "bold" }}>
          {convertPrice(currentCard.paymentInterest)}
        </span>
        <div style={{ fontSize: 16, color: "grey" }}>
          {`(${convertPrice(totalPayment)} = ${numberofpayments} חודשים * ${convertPrice(totalPayment / numberofpayments)})`}
        </div>
      </span>
    );
  };

  return (
    <>
      <div className="splitPayment-wrapper">
        <div
          className="splitPayment-card-extand-wrapper"
          onClick={cardExpandor}
        >
          <div className="splitPayment-card-extand-item">
            <img className="splitPayment-card-extand-img" src={doneImg}></img>
            <span className="splitPayment-card-extand-title">
              {t("paymentagent.cc")}: {cardNumber}
            </span>
          </div>

          <div className="credit-card-left-topay-wrapper">
            <div
              className="credit-card-left-topay"
              style={{ opacity: currentCardPaid ? "1" : "0" }}
            >
              <label>סה"כ שולם:</label>
              <label>
                {currentCard.paid ? currentCard.amount.toFixed(2) : 0}
              </label>
            </div>

            <div className="splitPayment-card-extand-item">
              {!expandCard ? (
                <BsChevronDown></BsChevronDown>
              ) : (
                <BsChevronUp></BsChevronUp>
              )}
            </div>
          </div>
        </div>

        {/* Card Shower */}
        {expandCard && (
          <div className="payment-card-details lg:tw-w-[calc(100%_-_80px)] lg:tw-py-[20px] lg:tw-px-[40px] tw-flex tw-flex-col tw-gap-[10px]">
            {/* Card Cataloger */}
            <div className="splitPayment-card-displayer-item">
              <form
                id={`card-number-${cardNumber}`}
                onSubmit={handlePayment}
                className={`splitPayment-card lg:tw-px-[15%]`}
              >
                {/* Left to pay */}
                <div className="ci-ltp">
                  <label className="ci-ltp-title">
                    {t("paymentagent.lefttopay")}:
                  </label>
                  <label id="price-leftToPay" className="ci-ltp-value">
                    {convertPrice(parseFloat(priceToPay))}
                  </label>
                </div>

                {/* Amount & Num of payments */}
                <div className="ci-a-nop">
                  <div
                    className="ci-a-nop-item"
                    style={{ display: "row", flexWrap: "nowrap" }}
                  >
                    {currentCard.numberofpayments > 0 ? (
                      <input
                        name="amount"
                        type="number"
                        id="price-amount"
                        className="payment-agent-input payment-agent-input-amount"
                        onChange={handleInputChange}
                        onFocus={handleMarkEdit}
                        onBlur={handleUnMarkEdit}
                        placeholder={t("paymentagent.amount")}
                        value={parseNumber(currentCard.amount)}
                        disabled={currentCard.paid}
                        required
                      ></input>
                    ) : null}
                  </div>

                  <div className="ci-a-nop-item-nop">
                    <label className="ci-a-nop-item-label">
                      {t("paymentagent.numberofpayments")}:
                    </label>
                    <select
                      name="splitPayment-card-amount-input"
                      id="splitPayment-card-amount-input"
                      // disabled={currentCardPaid}
                      onChange={handlePaymentsChange}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                </div>
                {currentCard.numberofpayments > 1 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      whiteSpace: "nowrap",
                      alignItems: "center",
                      fontSize: 20,
                      marginRight: 10,
                    }}
                  >
                    {"תשלום סופי:"}
                    <span style={{ fontWeight: "bold", marginRight: 5 }}>
                      {currentCard.amountWithInterest.toFixed(2)}
                    </span>
                  </div>
                ) : null}
                {currentCard.numberofpayments > 1 ? (
                  <div className="ci-a-nop">{renderPaymentsFee()}</div>
                ) : null}

                {/* Card Number */}
                {/* Card Number */}
                <div className="ci-cn">
                  <input
                    name="cardnumber"
                    id={`cardnumber-${cardNumber}`}
                    type="text"
                    className="payment-agent-input"
                    placeholder={t("paymentagent.cardnumber")}
                    pattern="^\d{4}\s\d{4}\s\d{4}\s\d{4}$"
                    maxLength="19"
                    value={cards[cardNumber - 1].cardnumber}
                    onChange={handleInputChange}
                    onFocus={handleMarkEdit}
                    onBlur={handleUnMarkEdit}
                  ></input>
                </div>

                {/* Name & Date */}
                <div className="ci-nd">
                  <div className="ci-nd-item">
                    <input
                      name="expdate"
                      id="expdate"
                      type="text"
                      className="payment-agent-input payment-agent-input-small"
                      onChange={handleInputChange}
                      onFocus={handleMarkEdit}
                      onBlur={handleUnMarkEdit}
                      placeholder="12/28"
                      pattern="\d{2}/\d{2}"
                      maxLength="5"
                      value={cards[cardNumber - 1].expdate}
                    ></input>
                  </div>

                  <div className="ci-nd-item ci-nd-item-n">
                    <input
                      name="cardholdername"
                      type="text"
                      className="payment-agent-input"
                      onChange={handleInputChange}
                      onFocus={handleMarkEdit}
                      onBlur={handleUnMarkEdit}
                      placeholder={t("paymentagent.cardholdername")}
                      pattern="[A-Za-z\s]+"
                      value={cards[cardNumber - 1].cardholdername}
                    ></input>
                  </div>
                </div>

                {/* CVV & ID */}
                <div className="ci-cvvid">
                  <div className="ci-cvvid-item ci-cvvid-item-cvv">
                    <input
                      name="cvv"
                      type="text"
                      className="payment-agent-input credit-card-amount"
                      onChange={handleInputChange}
                      onFocus={handleMarkEdit}
                      onBlur={handleUnMarkEdit}
                      // placeholder='3 ספרות בגב הכרטיס-קוד CVV'
                      placeholder="CVV"
                      pattern="\d{3}"
                      maxLength="3"
                      value={cards[cardNumber - 1].cvv}
                    ></input>

                    <img
                      src={visa1Icon}
                      className="payment-agent-icons"
                      alt="visa icon"
                    ></img>
                  </div>

                  <div className="ci-cvvid-item ci-cvvid-item-id">
                    <input
                      name="cardholderid"
                      type="text"
                      className="payment-agent-input"
                      onChange={handleInputChange}
                      onFocus={handleMarkEdit}
                      onBlur={handleUnMarkEdit}
                      placeholder={t("paymentagent.cardholderid")}
                      pattern="\d{9}"
                      maxLength="9"
                      value={cards[cardNumber - 1].cardholderid}
                    ></input>
                  </div>
                </div>

                {/* Submit button */}
                <div className="ci-submit">
                  {!submitLoading ? (
                    <button type="submit" className="credit-card-checkout-btn">
                      {t("paymentagent.payment")}
                    </button>
                  ) : (
                    <CustomLoading
                      classLoading={"credit-card-checkout-btn-loading"}
                      labelLoading={t("paymentagent.payment")}
                    />
                  )}
                </div>

                {/* Pics */}
                <div className="ci-pics">
                  <div className="payment-agent-icons-wrapper">
                    <img
                      src={MasterCardIcon}
                      className="payment-agent-icons"
                      alt="Master Card Icon"
                    ></img>
                    <img
                      src={AmericanExpressIcon}
                      className="payment-agent-icons"
                      alt="American Express icon"
                    ></img>
                    <img
                      src={visa1Icon}
                      className="payment-agent-icons"
                      alt="visa icon"
                    ></img>
                    <img
                      src={visaIcon}
                      className="payment-agent-icons"
                      alt="visa icon"
                    ></img>
                  </div>

                  <label className="ci-pics-title">תשלום מאובטח</label>
                </div>

                {/* Error / Success messege */}
                <div className="ci-messeger">
                  <label
                    id={`messege-${cardNumber}-suc`}
                    className="ci-messeger-success"
                  >
                    כרטיס האשראי עבר בהצלחה
                  </label>
                  <label
                    id={`messege-${cardNumber}-err`}
                    className="ci-messeger-error"
                  >
                    כרטיס האשראי לא עבר
                  </label>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreditCardDetails;
