import React, { useState } from "react";
import "./StringInput.css";
import "./ActiveInput.scss";

const ActiveInput = ({
  value,
  saveBtnText = "שמור",
  cancelBtnText = "בטל",
  cancelFunc = () => {},
  saveFunc = () => {},
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [text, setText] = useState(value);
  const onFocus = () => setIsInputFocused(true);

  const clickSave = (e) => {
    e.preventDefault();
    try {
      saveFunc(text);
      setIsInputFocused(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div onFocus={onFocus}>
      <textarea
        className={isInputFocused ? "comments focused" : "comments"}
        type="text"
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      {isInputFocused && (
        <div className="buttons-wrapper">
          <button className="input-button save" onClick={clickSave}>
            {saveBtnText}
          </button>
          <button
            className="input-button cancel"
            onClick={() => setIsInputFocused(false)}
          >
            {cancelBtnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default ActiveInput;
