import React from "react";
import "./Management.scss";
import LogoImageUploader from "../../Components/ProfileImageUploader/LogoImageUploader";

const Management = () => {
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", margin: 10 }}>
        <div style={{ marginBottom: 5 }}>לוגו חברה (לחץ כדי להחליף)</div>
        <LogoImageUploader />
      </div>
    </div>
  );
};

export default Management;
