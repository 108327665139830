import React from "react";
import ReactDOM from "react-dom/client";

// CSS
import "./index.css";
import App from "./App";

// Languages
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { AppProvider } from "./contexts/AppContext";

// Add languages
i18next.use(languageDetector).init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("i18nextLng") || "he", // Default language
  resources: {
    he: {
      translation: require("./locales/he.json"),
    },
    en: {
      translation: require("./locales/en.json"),
    },
    fr: {
      translation: require("./locales/fr.json"),
    },
  },
});

// Render every thing
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18next}>
    <AppProvider>
      <App />
    </AppProvider>
  </I18nextProvider>
);
