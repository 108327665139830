import React, { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Slider } from "@mui/material";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./hotelfilters.css";

// Services
import { convertPrice } from "../../../../../services/currencyService";

const HotelFilters = ({
  setSearchResultsFiltered,
  searchResultsFiltered,
  searchResults,
}) => {
  // Translator
  const { t } = useTranslation();

  // Constants
  const currentCurrData = localStorage.getItem("default-currency");
  const currentCurr = currentCurrData
    ? JSON.parse(currentCurrData).code
    : "ILS";
  const DEAFULT_PRICE = [1, 1000000];
  // States
  const [name, setName] = useState("");
  const [highLowPrice, setHighLowPrice] = useState(DEAFULT_PRICE);
  const [starRating, setStarRating] = useState([1, 2, 3, 4, 5]);
  const [price, setPrice] = useState(highLowPrice);
  const [currentPrice, setCurrentPrice] = useState(highLowPrice);

  const [meals, setMeals] = useState([]);
  const [HighLow, setHighLow] = useState(null);
  const [HighLowTitle, setHighLowTitle] = useState(null);

  // ShowFilters
  const [isName, setIsName] = useState(false);
  const [isStarRating, setIsStarRating] = useState(false);
  const [isEditedStarRating, setIsEditedStarRating] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [isMeals, setIsMeals] = useState(false);

  const getHightLowPrice = () => {
    let highPrice = highLowPrice[0];
    let lowPrice = highLowPrice[1];

    searchResultsFiltered?.forEach((item) => {
      if (item.HighestPackagePrice > highPrice) {
        highPrice = item.HighestPackagePrice;
      }
      if (item.LowestPackagePrice < lowPrice) {
        lowPrice = item.LowestPackagePrice;
      }
    });

    setHighLowPrice([lowPrice, highPrice]);
  };
  useEffect(() => {
    getHightLowPrice();
  }, [searchResultsFiltered]);

  const getFilteredSearchResult = () => {
    let filters = {
      Name: name,
      Price: currentPrice,
      StarRating: starRating,
      Basis: meals,
      Sort: HighLow, // View high to low / low to high - For Price / star Rating
    };

    const currencyRate = localStorage.getItem("currentRate")
      ? parseFloat(localStorage.getItem("currentRate"))
      : 1;

    if (filters && searchResults) {
      const { Name, Price, StarRating, Basis, Sort } = filters;

      // --- Filter Results

      // Filter the data based on the price range and star rating
      const filteredData = searchResults.filter((item) => {
        if (item.LowestPackagePrice) {
          const itemPrice = item.LowestPackagePrice * currencyRate;
          if (Price && StarRating) {
            if (
              itemPrice >= Price[0] &&
              itemPrice <= Price[1] &&
              StarRating.includes(parseInt(item.StarRating))
            ) {
              return item;
            }

            if (
              itemPrice >= Price[0] &&
              itemPrice <= Price[1] &&
              item.StarRating === "-1"
            ) {
              return item;
            }
          } else if (Price) {
            if (itemPrice >= Price[0] && itemPrice <= Price[1]) {
              return item;
            }
          } else if (StarRating) {
            if (StarRating.includes(parseInt(item.StarRating))) {
              return item;
            } else {
              if (item.StarRating === "-1") {
                return item;
              }
            }
          } else {
            // pass
          }
        }
      });

      let filterNameData = filteredData;

      if (Name.length > 0) {
        filterNameData = filteredData.filter((item) => {
          // Name Filter
          if (item.DisplayName.toLowerCase().includes(Name.toLowerCase())) {
            return Name;
          }

          // Address filter
          if (item.Address.toLowerCase().includes(Name.toLowerCase())) {
            return Name;
          }
        });
      }

      let filterBasisData = filterNameData;
      let alteredBasis = Basis;

      if (Basis.length > 0) {
        if (Basis.includes("HB") && !Basis.includes("CB")) {
          alteredBasis.push("CB"); // Because they are the same
        } else {
          if (!Basis.includes("HB") && Basis.includes("CB")) {
            // If HB not present and CB is remove it
            alteredBasis = alteredBasis.filter((board) => board !== "CB");
          }
        }

        filterBasisData = filterNameData.filter((item) => {
          // Boards Filter
          if (item && item.boards) {
            let basisExists = false; // Flag that cehcks if the basis is inclueded

            item.boards.forEach((board) => {
              const HotelTrimmedBasis = board.split(" ").pop(); // Can be : RO / BB / HB / FB / AI

              if (alteredBasis.includes(HotelTrimmedBasis)) {
                basisExists = true; // Mark the item
              }
            });

            // Hotels with no data about boards
            if (item.boards.length === 0 && alteredBasis.length == 6) {
              basisExists = true; // Mark the item
            }

            if (basisExists) {
              return item;
            }
          }
        });
      }

      const finalAfterFiltersResults = filterBasisData;

      // Sort filters if needed
      if (Sort) {
        // --- Sort filters - 2 Cases

        // 1. Sort Price
        if (Sort.ViewFilter === "Price") {
          // Check if high to low or the opposite
          if (Sort.HighLow) {
            // Sort from high to low
            const sortedHotels = finalAfterFiltersResults.sort(
              (a, b) => b.LowestPackagePrice - a.LowestPackagePrice
            );

            return sortedHotels;
          } else {
            // Sort from low to high
            const sortedHotels = finalAfterFiltersResults.sort(
              (a, b) => a.LowestPackagePrice - b.LowestPackagePrice
            );

            return sortedHotels;
          }
        }

        // 2. Sort Star Ratings
        if (Sort.ViewFilter === "StarRating") {
          // Check if high to low or the opposite
          if (Sort.HighLow) {
            // Sort from high to low
            const sortedHotels = finalAfterFiltersResults.sort(
              (a, b) => parseInt(b.StarRating) - parseInt(a.StarRating)
            );

            return sortedHotels;
          } else {
            // Sort from low to high
            const sortedHotels = finalAfterFiltersResults.sort(
              (a, b) => parseInt(a.StarRating) - parseInt(b.StarRating)
            );

            return sortedHotels;
          }
        }
      } else {
        // If no view filter was selected just save the results as they were
        return finalAfterFiltersResults;
      }
    }
  };

  // Handle language change for the price slider
  useEffect(() => {
    let intervalId;

    // If the language changed close the price filer and clear interval
    const handleClickOnOption = () => {
      const data = localStorage.getItem("default-currency");
      const sliderCurrencyObject = document.getElementById("slider-currency");

      if (data && sliderCurrencyObject) {
        const currentGlobalCurrency = JSON.parse(data).code;
        const currentCurrencySlider = sliderCurrencyObject.innerHTML;

        // If the currencys dont match it means a change has been made - close and reset the slider
        if (currentGlobalCurrency !== currentCurrencySlider) {
          setIsPrice(false);
        }
      }

      // If drop down dont exists close interval - means that a currency has been selected\
      const currencySelectorDropDownObject = document.getElementById(
        "currencySelectorDropDown"
      );

      if (!currencySelectorDropDownObject) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    // Add the interval that will run evry 0.5 sec and check if the language have been changed
    const handleClickOnCurrencyBtn = () => {
      if (!intervalId) {
        intervalId = setInterval(() => {
          handleClickOnOption();
        }, 500);
      }
    };

    // Wait for the currency to be preperd to be changed
    const currencyBtn = document.getElementById("currencySelector");

    if (currencyBtn) {
      currencyBtn.addEventListener("click", handleClickOnCurrencyBtn);
    }

    return () => {
      // When the filters are dismounted remove any intervals or event listeners
      if (currencyBtn) {
        currencyBtn.removeEventListener("click", handleClickOnCurrencyBtn);
      }

      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const hideName = () => {
    setIsName(!isName);
  };

  const hideStarRating = () => {
    setIsStarRating(!isStarRating);
  };

  const hidePrice = () => {
    setIsPrice(!isPrice);
  };

  const hideMeals = () => {
    setIsMeals(!isMeals);
  };

  // --- Actions
  const handleNameEditing = (e) => {
    setName(e.target.value);
  };

  const handleRatingSelect = (starRatingItem) => {
    // Create a new array based on the current state
    let newStarRating = [...starRating];

    // Check if the item is already in the array
    if (newStarRating.includes(starRatingItem)) {
      // Remove the item from the array
      newStarRating = newStarRating.filter((item) => item !== starRatingItem);
    } else {
      // Add the item to the array
      newStarRating.push(starRatingItem);
    }

    // For the first selection dont keep the default filters
    if (!isEditedStarRating) {
      newStarRating = [starRatingItem];
    }

    // Set the new state
    setStarRating(newStarRating);

    // Allow the rating to be painted
    setIsEditedStarRating(true);
  };

  const handlePriceChange = (e, newPrice) => {
    const convertedMinPrice = newPrice[0];
    const convertedMaxPrice = newPrice[1];

    // setPrice([parseFloat(convertedMinPrice), parseFloat(convertedMaxPrice)]);
    setCurrentPrice([
      parseFloat(convertedMinPrice),
      parseFloat(convertedMaxPrice),
    ]);
  };

  const handleMealsSelection = (e) => {
    let currentPick = e.target.id;

    // Check if currentPick already exists in meals
    const index = meals.indexOf(currentPick);

    if (index === -1) {
      // If not in the array, add it
      setMeals([...meals, currentPick]);
    } else {
      // If already in the array, remove it
      const updatedMeals = [...meals];
      updatedMeals.splice(index, 1);
      setMeals(updatedMeals);
    }
  };

  // --- UseEffects

  // Gather filters after state updates
  useEffect(() => {
    setSearchResultsFiltered(getFilteredSearchResult());
    getHightLowPrice();
  }, [name, currentPrice, starRating, meals, HighLow]);

  const resetFiltration = () => {
    setName("");
    setPrice(DEAFULT_PRICE); // Reset to default
    setCurrentPrice(DEAFULT_PRICE);
    setStarRating([1, 2, 3, 4, 5]);
    setMeals([]);
    setHighLow(null); // Reset view
    setHighLowTitle(null);

    // Reset the Hotel Basis visually
    if (isMeals) {
      document.getElementById("RO").checked = false;
      document.getElementById("BB").checked = false;
      document.getElementById("HB").checked = false;
      document.getElementById("FB").checked = false;
      document.getElementById("AI").checked = false;
    }

    // Reset the Hotel Name visually
    if (isName) {
      document.getElementById("hotelName").value = "";
    }

    // Close all filters
    setIsName(false);
    setIsStarRating(false);
    setIsEditedStarRating(false);
    setIsPrice(false);
    setIsMeals(false);
  };

  // Price / Star Rating - sort high to low..
  const handleHighLowChange = (event, filterSortView) => {
    // Save selection
    setHighLowTitle(event.target.value);

    let bool = false; // Represents high to low or low to high - false = low to high

    // Check what kind of filter is this
    if (event.target.value === "pricehightolow") {
      bool = true;
    }

    if (event.target.value === "starratinghightolow") {
      bool = true;
    }

    // Gather data
    const data = {
      ViewFilter: filterSortView,
      HighLow: bool,
    };

    // Save filtration info
    setHighLow(data);
  };

  return (
    <>
      <div className="hotelFilters-wrapper">
        {/* Title */}
        <label className="hotelFilters-title">
          {t("filters.filterresults")}
        </label>

        {/* Filters */}
        <div
          className={`hotelFilters-filters ${
            document.documentElement.dir === "ltr"
              ? "direction-rtl"
              : "direction-ltr"
          }`}
        >
          {/* Name Filter */}
          <div className="hotelFilters-filter-wrapper">
            <div
              onClick={hideName}
              className={`hotelFilters-filters-title-wrapper ${
                document.documentElement.dir === "ltr"
                  ? "direction-rtl"
                  : "direction-ltr"
              }  `}
            >
              <span className="hotelFilters-filters-title">
                {t("filters.name")}
              </span>

              {isName ? (
                <IoIosArrowUp className="hotelFilters-filters-arrow" />
              ) : (
                <IoIosArrowDown className="hotelFilters-filters-arrow" />
              )}
            </div>

            {isName && (
              <div className="hotelfilters-price-wrapper">
                <input
                  id="hotelName"
                  className={`hotelfilters-name-filter-input ${
                    document.documentElement.dir === "rtl"
                      ? "direction-rtl"
                      : "direction-ltr"
                  }`}
                  onChange={handleNameEditing}
                  placeholder={t("filters.hotelname")}
                ></input>
              </div>
            )}
          </div>

          {/* Price Filter */}
          <div className="hotelFilters-filter-wrapper">
            <div
              onClick={hidePrice}
              className={`hotelFilters-filters-title-wrapper ${
                document.documentElement.dir === "ltr"
                  ? "direction-rtl"
                  : "direction-ltr"
              }  `}
            >
              <span className="hotelFilters-filters-title">
                {t("filters.price")}
              </span>

              {isPrice ? (
                <IoIosArrowUp className="hotelFilters-filters-arrow" />
              ) : (
                <IoIosArrowDown className="hotelFilters-filters-arrow" />
              )}
            </div>

            {isPrice && (
              <div className="hotelfilters-price-wrapper">
                <Slider
                  getAriaLabel={() => "Price range"}
                  value={currentPrice}
                  color="primary"
                  style={{ color: "#ff3703" }}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  step={10}
                  min={price ? price[0] : DEAFULT_PRICE[0]}
                  max={price ? price[1] : DEAFULT_PRICE[1]}
                />

                <div className="hotelfilters-price-values">
                  <span className="hotelfilters-price-values-title">
                    {<span id="price-filtration-from">{highLowPrice[0]}</span>}
                    <span id="slider-currency">{currentCurr}</span>
                  </span>
                  <span className="hotelfilters-price-values-title">
                    {<span id="price-filtration-until">{highLowPrice[1]}</span>}
                    {currentCurr}
                  </span>
                </div>

                {/* Sort By Price */}
                <form className="hotelfilters-price-sort-wrapper">
                  <label
                    className="hotelfilters-price-sort-radio"
                    style={
                      document.documentElement.dir === "ltr"
                        ? { direction: "rtl" }
                        : {}
                    }
                  >
                    <input
                      onChange={(e) => {
                        handleHighLowChange(e, "Price");
                      }}
                      checked={HighLowTitle === "pricehightolow"}
                      type="radio"
                      name="priceSorting"
                      value="pricehightolow"
                      className="hotelfilters-price-sort-radio-input"
                    />
                    <span className="hotelfilters-price-sort-radio-title">
                      {t("filters.pricehightolow")}
                    </span>
                  </label>

                  <label
                    className="hotelfilters-price-sort-radio"
                    style={
                      document.documentElement.dir === "ltr"
                        ? { direction: "rtl" }
                        : {}
                    }
                  >
                    <input
                      onChange={(e) => {
                        handleHighLowChange(e, "Price");
                      }}
                      checked={HighLowTitle === "pricelowtohigh"}
                      type="radio"
                      name="priceSorting"
                      value="pricelowtohigh"
                      className="hotelfilters-price-sort-radio-input"
                    />
                    <span className="hotelfilters-price-sort-radio-title">
                      {t("filters.pricelowtohigh")}
                    </span>
                  </label>
                </form>
              </div>
            )}
          </div>

          {/* Star Rating Filter */}
          <div className="hotelFilters-filter-wrapper">
            <div
              onClick={hideStarRating}
              className={`hotelFilters-filters-title-wrapper ${
                document.documentElement.dir === "ltr"
                  ? "direction-rtl"
                  : "direction-ltr"
              }  `}
            >
              <span className="hotelFilters-filters-title">
                {t("filters.starrating")}
              </span>

              {isStarRating ? (
                <IoIosArrowUp className="hotelFilters-filters-arrow" />
              ) : (
                <IoIosArrowDown className="hotelFilters-filters-arrow" />
              )}
            </div>

            {isStarRating && (
              <div className="direction-ltr">
                <div className="hotelfilters-star-rating-wrapper">
                  {[1, 2, 3, 4, 5].map((starRatingItem) => (
                    <div
                      key={starRatingItem}
                      onClick={() => {
                        handleRatingSelect(starRatingItem);
                      }}
                      className={`hotelfilters-star-rating-item ${
                        starRating.includes(starRatingItem) &&
                        isEditedStarRating
                          ? "hotelfilters-star-rating-clicked"
                          : ""
                      }`}
                    >
                      {starRatingItem}
                    </div>
                  ))}
                </div>

                {/* Sort By StarRating */}
                <form className="hotelfilters-star-sort-wrapper">
                  <label
                    className="hotelfilters-price-sort-radio"
                    style={
                      document.documentElement.dir === "ltr"
                        ? { direction: "rtl" }
                        : {}
                    }
                  >
                    <input
                      onChange={(e) => {
                        handleHighLowChange(e, "StarRating");
                      }}
                      checked={HighLowTitle === "starratinghightolow"}
                      type="radio"
                      name="starRatingSorting"
                      value="starratinghightolow"
                      className="hotelfilters-price-sort-radio-input"
                    />
                    <span className="hotelfilters-price-sort-radio-title">
                      {t("filters.starratinghightolow")}
                    </span>
                  </label>

                  <label
                    className="hotelfilters-price-sort-radio"
                    style={
                      document.documentElement.dir === "ltr"
                        ? { direction: "rtl" }
                        : {}
                    }
                  >
                    <input
                      onChange={(e) => {
                        handleHighLowChange(e, "StarRating");
                      }}
                      checked={HighLowTitle === "starratinglowtohigh"}
                      type="radio"
                      name="starRatingSorting"
                      value="starratinglowtohigh"
                      className="hotelfilters-price-sort-radio-input"
                    />
                    <span className="hotelfilters-price-sort-radio-title">
                      {t("filters.starratinglowtohigh")}
                    </span>
                  </label>
                </form>
              </div>
            )}
          </div>

          {/* Basis Filter */}
          <div className="hotelFilters-filter-wrapper">
            <div
              onClick={hideMeals}
              className={`hotelFilters-filters-title-wrapper ${
                document.documentElement.dir === "ltr"
                  ? "direction-rtl"
                  : "direction-ltr"
              }  `}
            >
              <span className="hotelFilters-filters-title">
                {t("filters.meals")}
              </span>

              {isMeals ? (
                <IoIosArrowUp className="hotelFilters-filters-arrow" />
              ) : (
                <IoIosArrowDown className="hotelFilters-filters-arrow" />
              )}
            </div>

            {isMeals && (
              <div className="hotelfilters-price-wrapper">
                <div
                  className={`hotelfilters-meal-checkboxs-wrapper ${
                    document.documentElement.dir === "ltr"
                      ? "direction-rtl"
                      : "direction-ltr"
                  } `}
                >
                  {/* Sleep only */}
                  <div className="hotelfilters-meal-checkbox-wrapper">
                    <input
                      id="RO"
                      type="checkbox"
                      className="hotelfilters-meal-checkbox"
                      onChange={handleMealsSelection}
                    ></input>
                    <label className="hotelfilters-meal-checkbox-title">
                      {t("filters.sleeponly")}
                    </label>
                  </div>

                  {/* Breakfasts */}
                  <div className="hotelfilters-meal-checkbox-wrapper">
                    <input
                      id="BB"
                      type="checkbox"
                      className="hotelfilters-meal-checkbox"
                      onChange={handleMealsSelection}
                    ></input>
                    <label className="hotelfilters-meal-checkbox-title">
                      {t("filters.breakfast")}
                    </label>
                  </div>

                  {/* Half board */}
                  <div className="hotelfilters-meal-checkbox-wrapper">
                    <input
                      id="HB"
                      type="checkbox"
                      className="hotelfilters-meal-checkbox"
                      onChange={handleMealsSelection}
                    ></input>
                    <label className="hotelfilters-meal-checkbox-title">
                      {t("filters.allmeals")}
                    </label>
                  </div>

                  {/* Full board  */}
                  <div className="hotelfilters-meal-checkbox-wrapper">
                    <input
                      id="FB"
                      type="checkbox"
                      className="hotelfilters-meal-checkbox"
                      onChange={handleMealsSelection}
                    ></input>
                    <label className="hotelfilters-meal-checkbox-title">
                      {t("filters.blord")}
                    </label>
                  </div>

                  {/* All Included */}
                  <div className="hotelfilters-meal-checkbox-wrapper">
                    <input
                      id="AI"
                      type="checkbox"
                      className="hotelfilters-meal-checkbox"
                      onChange={handleMealsSelection}
                    ></input>
                    <label className="hotelfilters-meal-checkbox-title">
                      {t("filters.allincluded")}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="hotelfilters-reset-btn-wrapper">
          <button
            type="button"
            className="hotelfilters-reset-btn"
            onClick={resetFiltration}
          >
            {t("filters.reset")}
          </button>
        </div>
      </div>
    </>
  );
};

export default HotelFilters;
