import React from "react"
import html2canvas from "html2canvas";
import { sendSuccessOrder } from "../../services/fhpService";

export const ScreenCapture = ({captureRef}) => {
    const [userInput, setUserInput] = React.useState("")


    const captureScreen = () => {
        if(!captureRef?.current){
            return null
        }
        html2canvas(captureRef.current).then((canvas) => {
            const image = canvas.toDataURL('image/png');
            sendScreenshotToServer(image)
        });
    };

    const sendScreenshotToServer = (imageData) => {
        sendSuccessOrder(imageData, userInput)
            .then(res => {
                setUserInput("")
            })
            .catch(e => {
                console.error("Cathed Error")
            })
    };


    return (
        <div>
            <span>שלח את סיכום ההזמנה במייל</span>
            <input title={"מייל לשליחה"} name="email" type="email" onChange={e => setUserInput(e.target.value)} value={userInput} style={{marginRight:25}}/>
            <button onClick={captureScreen}> שלח</button>
        </div>
    )
}

export default ScreenCapture


