import React from "react";
import FlightSearchBar from "./FlightSearchBar/flightsearchbar";
import HotelSearchBar from "./HotelSearchBar/hotelsearchbar";
import {
  HOTELS,
  PACKAGES,
  PLANES,
  useSearch,
} from "../../../contexts/SearchContext";
import PackageSearchBar from "./PackageSearchBars/packagesearchbar";
import { useTranslation } from "react-i18next";
import { OrangeButton } from "../../buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";

const SearchBarSelector = () => {
  const { searchTab, submitSearch, isSearchDisabled, getSearchDisableReasons } =
    useSearch();
  const { t } = useTranslation();

  const getComponent = () => {
    switch (searchTab) {
      case PLANES:
        return <FlightSearchBar />;
      case HOTELS:
        return <HotelSearchBar />;
      case PACKAGES:
        return <PackageSearchBar />;
      default:
        return <div>Error</div>;
    }
  };

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-flex-nowrap tw-w-[calc(100%_-_40px)] tw-pl-[20px] tw-pr-[20px]">
      <div className="tw-flex tw-flex-col tw-space-y-1 lg:tw-space-y-0 lg:tw-flex-row tw-flex-wrap tw-gap-[10px] tw-flex-1">
        {getComponent()}
        <Tooltip
          title={
            !isSearchDisabled() ? undefined : (
              <div style={{ fontSize: 20 }}>
                {getSearchDisableReasons()?.map((reason) => (
                  <div> - {reason}</div>
                ))}
              </div>
            )
          }
        >
          <div>
            <OrangeButton
              onClick={submitSearch}
              style={{ height: 40 }}
              disabled={isSearchDisabled()}
            >
              {t("hotelsearchbar.search")}
            </OrangeButton>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default SearchBarSelector;
