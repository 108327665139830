import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import FlightSvg from "../../../../assets/images/filters/Flights.svg";
import Tooltip from "@mui/material/Tooltip";
import { PiSuitcaseRolling } from "react-icons/pi";
import { IoInformationCircleOutline } from "react-icons/io5";

import { SlHandbag } from "react-icons/sl";

import "./FlightFooterRow.scss";

const FlightFooterRow = ({ flight, title }) => {
  const { t } = useTranslation();
  const isIncludeCheckedLuggage = Number(flight?.maxCheckedLuggageWeight);
  const isIncludeHandLuggage = Number(flight?.maxHandLuggageWeight);
  const getDateInfo = (date) => date.split(" ");

  const { depDate, arrDate, duration, statusRemark } = flight;

  const [depDateString, depTimeString] = getDateInfo(depDate);
  const [arrDateString, arrTimeString] = getDateInfo(arrDate);

  const durationmin = duration % 60;
  const durationhours = Math.floor(duration / 60);
  const airlineId = flight?.airlineId;

  function renderFlightInfo(title, info) {
    return (
      <div className="info-section">
        <div className="info-title-bold">{title}</div>
        {info}
      </div>
    );
  }

  return (
    <>
      <div className="flight-footer-row tw-flex tw-w-full tw-flex-row tw-flex-wrap">
        <div className="info-section">
          {flight?.flightNumber} {airlineId}
        </div>

        {renderFlightInfo(title, depDateString)}

        {renderFlightInfo(t(flight.depCity), depTimeString)}

        <div className="info-section">
          <img className="flight-icon" src={FlightSvg} alt="Home" />
          {durationhours}:{durationmin < 10 ? `0${durationmin}` : durationmin}{" "}
          שעות
        </div>

        {renderFlightInfo(t(flight.arrCity), arrTimeString)}

        <div className="luggage-section">
          <div className="luggage-row">
            <Tooltip title="תיק יד">
              <div
                className={classNames("luggage-icon-warpper", {
                  include: isIncludeHandLuggage,
                })}
              >
                <SlHandbag size={20} />
              </div>
            </Tooltip>
            {isIncludeHandLuggage ? ` ${isIncludeHandLuggage} קילו` : "לא כלול"}
          </div>
          <div className="luggage-row">
            <Tooltip title="מזוודה">
              <div
                className={classNames("luggage-icon-warpper", {
                  include: isIncludeHandLuggage,
                })}
              >
                <PiSuitcaseRolling size={20} />
              </div>
            </Tooltip>
            {isIncludeCheckedLuggage
              ? ` ${isIncludeCheckedLuggage} קילו`
              : "לא כלול"}
          </div>
        </div>
      </div>
      <div className="status-remark-row">
        <IoInformationCircleOutline size={20} />
        <div
          style={{
            alignSelf: "center",
            marginRight: "5px",
          }}
        >
          {statusRemark}
        </div>
      </div>
    </>
  );
};

export default FlightFooterRow;
