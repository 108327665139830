import React from "react";
import image0 from "./f8ac8a746555cc34e1d1232d28e27c3c-0.jpg";
import image1 from "./f8ac8a746555cc34e1d1232d28e27c3c-1.jpg";
import image2 from "./f8ac8a746555cc34e1d1232d28e27c3c-2.jpg";
import image3 from "./f8ac8a746555cc34e1d1232d28e27c3c-3.jpg";
import image4 from "./f8ac8a746555cc34e1d1232d28e27c3c-4.jpg";
import image5 from "./f8ac8a746555cc34e1d1232d28e27c3c-5.jpg";
import image6 from "./f8ac8a746555cc34e1d1232d28e27c3c-6.jpg";

const AgentTerms = () => {
  const images = [image0, image1, image2, image3, image4, image5, image6];
  const imagesStyle = { width: "100%" };
  return (
    <div
      style={{
        height: "90%",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      {images.map((src) => (
        <img src={src} style={imagesStyle} />
      ))}
    </div>
  );
};

export default AgentTerms;
