const SIDE_BAR_OPEN = "sideBarOpen";
const USER_INFO = "UserInfo";
const AGENT_SAVED_CLIENT_INFO = "agent-saved-client-info";
const imageProfileBase65 = "imageprofilebase65";
const companylogobase65 = "companylogobase65";

export function getAgentSavedClientInfo() {
  return _parseLocalStorage(AGENT_SAVED_CLIENT_INFO);
}
export function getSideBarStatus() {
  return JSON.parse(localStorage.getItem(SIDE_BAR_OPEN) || false);
}
export function setSideBarStatus(value) {
  return localStorage.setItem(SIDE_BAR_OPEN, JSON.stringify(value));
}
export function getUserInfo() {
  return _parseLocalStorage(USER_INFO);
}
export function setUserInfo(newUserInfo) {
  return localStorage.setItem(USER_INFO, JSON.stringify(newUserInfo));
}
export function setUserInfoImage(image) {
  return localStorage.setItem(
    USER_INFO,
    JSON.stringify({ ...getUserInfo(), [imageProfileBase65]: image })
  );
}
export function setUserLogoImage(image) {
  return localStorage.setItem(
    USER_INFO,
    JSON.stringify({ ...getUserInfo(), [companylogobase65]: image })
  );
}

const _parseLocalStorage = (lsKey) => JSON.parse(localStorage.getItem(lsKey));
