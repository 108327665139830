import React from "react";
import ContentLoader from "react-content-loader";

const GallarySkeleton = (props) => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={"40vh"}
    // viewBox="0 0 100% 100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="40%" height="50%" />
    <rect x="0" y="55%" rx="2" ry="2" width="22.5%" height="45%" />
    <rect x="42.5%" y="0" rx="2" ry="2" width="60%" height="100%" />
    <rect x="25%" y="80%" rx="2" ry="2" width="15%" height="20%" />
    <rect x="25%" y="55%" rx="2" ry="2" width="15%" height="20%" />
  </ContentLoader>
);

export default GallarySkeleton;
