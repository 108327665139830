import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = ({ ...params }) => {
  return (
    <SkeletonTheme baseColor="white" highlightColor="#ff3703" duration={2}>
      <Skeleton {...params} />
    </SkeletonTheme>
  );
};

export default SkeletonLoader;
