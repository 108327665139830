import React from "react";

const AdditionalInfo = ({ innerHtml = "" }) => {
  return (
    <div style={{ width: "100%", backgroundColor: "white" }}>
      <div
        style={{ textAlign: "right", rowHeight: "60px" }}
        dangerouslySetInnerHTML={{
          __html: innerHtml,
        }}
      />
    </div>
  );
};

export default AdditionalInfo;
