import React from "react";
import {
  FormFields,
  useRegistration,
} from "../../../contexts/RegistrationContext";
import RegistrationBox from "../RegistrationBox";
import "./PackageDetails.scss";
import { TransparentButton } from "../../buttons/Buttons";
import { OrangeRegistrationInput } from "../Inputs";
import { AgentTermsLink } from "../../TermsAndConditionsLink/TermsAndConditionsLink";
import classNames from "classnames";
import Tooltip from "@mui/material/Tooltip";

const PackageDetails = ({ missingFields }) => {
  const { formData, setFormKey } = useRegistration();
  const TABLE_KEYS = [
    "מוצר השירות",
    "הקמת המשתמש",
    "מערכת מלונות",
    "הפלגות",
    "חבילות נופש",
    "מאורגנים",
    "טיסות צ'ארטר",
    "דמי טיפול",
  ];
  const BASIC_AGENT = [
    "לחודש 349₪",
    "ללא עלות",
    "8%",
    "6%",
    "6%",
    "5%",
    "ללא עמלה",
    "(80%) - מסך העמלה",
  ];
  const PRO_AGENT = [
    "לחודש 499₪",
    "ללא עלות",
    "8%",
    "8%",
    "8%",
    "5%",
    "3%",
    "(90%) - מסך העמלה",
  ];

  function getTable(
    items,
    title,
    titleStyle = {},
    onClick,
    selected,
    disabled
  ) {
    const isFirstExampleTable = title === "";
    const getParamsByIndex = (index) => {
      if (index === 0) {
        return { backgroundColor: "white", color: "rgb(255, 130, 0)" };
      }
      if (index % 2 === 0) {
        return { backgroundColor: "#80808094", color: "white" };
      }
      return { backgroundColor: "rgba(241,241,241,0.58)", color: "black" };
    };
    return (
      <Tooltip title={disabled ? "חבילה לא זמינה" : undefined}>
        <div
          className={classNames("package-table", {
            example: isFirstExampleTable,
            disabled,
          })}
          style={selected ? titleStyle : null}
        >
          <div className="package-table-title" style={titleStyle}>
            {title}
          </div>
          {items.map((item, index) => (
            <div
              className={classNames("package-table-item", {
                example: isFirstExampleTable,
              })}
              style={{ ...getParamsByIndex(index) }}
            >
              {item}
            </div>
          ))}
          <span style={{ margin: 5 }}>
            {onClick ? (
              disabled ? null : (
                <TransparentButton onClick={onClick}>
                  {selected ? "החבילה נבחרה" : "בחירת חבילה"}
                </TransparentButton>
              )
            ) : null}
          </span>
        </div>
      </Tooltip>
    );
  }

  return (
    <RegistrationBox title="בחירת חבילה">
      <div
        className="agent_packages"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div className="package">
          {getTable(TABLE_KEYS, "")}
          {getTable(
            BASIC_AGENT,
            "BASIC AGENT",
            { backgroundColor: "#4e89be" },
            () => setFormKey(FormFields.ADDITIONAL_INFO.PACKAGE, "BASIC"),
            formData["package"] === "BASIC",
            true
          )}
        </div>
        <div className="package">
          {getTable(TABLE_KEYS, "")}
          {getTable(
            PRO_AGENT,
            "PRO AGENT",
            { backgroundColor: "#66c44e" },
            () => setFormKey(FormFields.ADDITIONAL_INFO.PACKAGE, "PRO"),
            formData["package"] === "PRO"
          )}
        </div>
      </div>
      <OrangeRegistrationInput
        registrationKey={FormFields.ADDITIONAL_INFO.RECOMMENDED_BY}
      />
      <div>
        <input
          type="checkbox"
          onChange={(e) =>
            setFormKey(FormFields.OTHERS.TERMS_APPROVED, e.target.checked)
          }
          defaultChecked={formData[FormFields.OTHERS.TERMS_APPROVED]}
        />
        לצפייה ואישור ב<AgentTermsLink />
        {missingFields.includes(FormFields.OTHERS.TERMS_APPROVED) && (
          <div style={{ color: "red" }}>יש לאשר את התקנון</div>
        )}
      </div>
    </RegistrationBox>
  );
};

export default PackageDetails;
