import React from "react";
import "./StringInput.css";
import InputX from "./InputX";

const StringInput = ({
  id,
  title,
  value,
  onValueChange,
  width = "180px",
  disabled = false,
  hideX = false,
  ...props
}) => {
  return (
    <div className={"string-input-wrapper tw-px-1"}>
      <div className={"string-input-title"}>{title}</div>
      <div className={"string-input-box input-default"}>
        <input
          id={id}
          value={value}
          disabled={disabled}
          className={"string-input"}
          onChange={(e) => onValueChange(e.target.value)}
        />
        {value !== "" && !disabled && !hideX && (
          <InputX onClick={() => onValueChange("")} />
        )}
      </div>
    </div>
  );
};

export default StringInput;
