import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FinanceTable from "./FinanceTable/FinanceTable";
import FinanceHeader from "./FinanceHeader";
import BalanceWidget from "./FinanceWidgets/BalanceWidget";
import WithdrawalWidget from "./FinanceWidgets/WithdrawalWidget";
import {
  getFinancesByEmail,
  createFinance,
  getFinanceData,
  approveFinanceRequest,
  declineFinanceRequest,
  setFinanceRequestComments,
} from "../../services/fhpService";

import { getUserDetails, isAdminUser } from "../../services/authService";
import { successMessage, errorMessage } from "../../services/swalService";

import "./Finance.css";

const getFinancesFromServer = async ({ setFinances, setPossibleProfit }) => {
  setFinances();
  const agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;
  let financesData = await getFinancesByEmail({
    email: agentEmail,
  });
  const balanceData = await getFinanceData({
    email: agentEmail,
  });

  setPossibleProfit(balanceData?.data?.possibleProfit);
  setFinances(financesData?.data);
};

const Finance = () => {
  const userEmail = getUserDetails()?.email;
  const isAdmin = isAdminUser();

  const [finances, setFinances] = useState();
  const [possibleProfit, setPossibleProfit] = useState();
  const [withdrawalSum, setWithdrawalSum] = useState();
  const [withdrawalEmail, setWithdrawalEmail] = useState(userEmail);
  const [invoiceString, setInvoiceStrBase64] = useState();
  const [validationMsgArr, setValidationMsgArr] = useState([]);
  const [pdfPath, setPdfPath] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFinancesFromServer({ setFinances, setPossibleProfit });
  }, []);

  const validateFinanceFields = () => {
    const validationMsg = [];
    if (!withdrawalSum) {
      validationMsg.push("סכום למשיכה לא תקין");
    }
    if (withdrawalSum > possibleProfit && !isAdmin) {
      validationMsg.push("סכום  למשיכה לא יכול להיות גבוה מהיתרה הזמינה ");
    }
    if (!invoiceString && !isAdmin) {
      validationMsg.push("חשבונית לא תקינה");
    }
    return validationMsg;
  };

  const clickAddFinance = async () => {
    setLoading(true);
    const validationMsg = await validateFinanceFields();

    if (validationMsg.length === 0) {
      createFinance({
        financeData: {
          sum: withdrawalSum,
          currency: "ILS",
          status: "waiting",
          comments: "",
          invoice: invoiceString,
          email: withdrawalEmail,
        },
      }).then((res) => {
        if (res) {
          successMessage({
            title: "הבקשה התקבלה בהצלחה",
            message: "",
            confirmButtonText: "אישור",
          });

          setInvoiceStrBase64("");
          setPdfPath("");
          setWithdrawalSum("");
          getFinancesFromServer({ setFinances, setPossibleProfit });
          setLoading(false);
        }
      });
    } else {
      setValidationMsgArr(validationMsg);
      setLoading(false);
    }
  };

  const clickApproveRequest = async ({ email, reqNumber }) => {
    setFinances();

    await approveFinanceRequest({ email, reqNumber });
    getFinancesFromServer({ setFinances, setPossibleProfit });
  };

  const clickDeclineRequest = async ({ email, reqNumber }) => {
    setFinances();

    await declineFinanceRequest({ email, reqNumber });
    getFinancesFromServer({ setFinances, setPossibleProfit });
  };

  const addRequestComments = async ({ email, reqNumber, comments }) => {
    await setFinanceRequestComments({
      email,
      reqNumber,
      comments,
    });
    getFinancesFromServer({ setFinances, setPossibleProfit });
  };

  return (
    <>
      <FinanceHeader></FinanceHeader>
      <div className="table-container">
        <div className="finance-widgets-container">
          <BalanceWidget possibleProfit={possibleProfit}></BalanceWidget>
          <WithdrawalWidget
            loading={loading}
            withdrawalSum={withdrawalSum}
            setWithdrawalSum={setWithdrawalSum}
            setInvoiceStrBase64={setInvoiceStrBase64}
            clickAddFinance={clickAddFinance}
            pdfPath={pdfPath}
            setPdfPath={setPdfPath}
            withdrawalEmail={withdrawalEmail}
            setWithdrawalEmail={setWithdrawalEmail}
          />
        </div>
        <FinanceTable
          finances={finances}
          clickApproveRequest={clickApproveRequest}
          clickDeclineRequest={clickDeclineRequest}
          addRequestComments={addRequestComments}
        ></FinanceTable>
      </div>
      <Modal
        open={validationMsgArr.length > 0}
        onClose={() => setValidationMsgArr([])}
      >
        <Box className="validation-modal">
          לא ניתן ליצור משיכה...
          <Typography>
            <div className="validation-text">
              {validationMsgArr.map((msg) => {
                return <div> • {msg} </div>;
              })}
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Finance;
