// Custom Alert

// CSS
import "./customalertagent.css";

const useTranslation = () => {
  const translations = {
    en: {
      warning: "Warning",
    },
    he: {
      warning: "שים לב",
    },
  };

  const currentLanguage = localStorage.getItem("i18nextLng")
    ? localStorage.getItem("i18nextLng")
    : "he";

  const t = (key, lang = currentLanguage) => translations[lang][key] || key;

  return { t };
};

const CustomAlertAgent = (message, messegeActionText) => {
  // Translator
  const { t } = useTranslation();

  // Function to handle button click
  function closeWarning() {
    document.getElementById("warning-component").remove();
  }

  // If you want to return a string, you can modify the return statement accordingly
  const warningContent = `
      <div class='customAlertAgent-component'>
          <div class='customAlertAgent-wrapper'>
                <div class='customAlertAgent-alert-title-wrapper'>
                    <label class='customAlertAgent-alert-title'>${t(
                      "warning"
                    )}</label>
                    <label class='customAlertAgent-alert-sub-title'>${message}</label>
                </div>
    
                <div class='customAlertAgent-alert-content-wrapper'>
                    <button id="closeWarningButton" class='customAlertAgent-alert-quit'>${messegeActionText}</button>
                </div>
          </div>
      </div>
    `;

  // Create a warningWrapper element
  const warningWrapper = document.createElement("div");

  warningWrapper.id = "warning-component";

  // Set innerHTML of the warningWrapper
  warningWrapper.innerHTML = warningContent;

  // Append the warningWrapper to the body
  document.body.appendChild(warningWrapper);

  // Add event listener to the button
  document
    .getElementById("closeWarningButton")
    .addEventListener("click", closeWarning);
};

export default CustomAlertAgent;
