import React, { createContext, useContext, useState } from "react";
import { getLdsDates, searchHotels, searchLds } from "../services/fhpService";
import { getCurrentDate, getNextMonth } from "../utils/DatesHelpers";
import { isLdsResponseEmpty } from "../utils/LDSHelpers";
import isArray from "lodash/isArray";
import useTimer from "./TimerContext";
import { isNumber, round2, useAppContext } from "./AppContext";
// import { useTimer } from "./TimerContext";

const SearchContext = createContext();

export const PLANES = "planes";
export const HOTELS = "hotels";
export const PACKAGES = "packages";

export const ADULTS = "adults";
export const KIDS = "kids";
export const INFANTS = "infants";
export const KIDS_AGES = "kidsAges";

const defaultRoomInfo = {
  rooms: [{ [ADULTS]: 2, [KIDS]: 0, [INFANTS]: 0, [KIDS_AGES]: [] }],
};
const TWENTY_MIN = 1800;

export const SearchProvider = ({ totalPrice, children }) => {
  const { calcBaseCommission, currencyRate } = useAppContext();

  const [searchTab, _setSearchTab] = useState(
    localStorage.getItem("searchTab") || HOTELS
  );

  const { setTimeLeft } = useTimer();

  const getInitRoomInfo = (fresh = false) => {
    if (!fresh) {
      if (sessionStorage.getItem("roomData")) {
        if (sessionStorage.getItem("roomData").length > 0) {
          const savedData = JSON.parse(sessionStorage.getItem("roomData"));
          return { rooms: savedData };
        }
      }
    }
    return JSON.parse(JSON.stringify(defaultRoomInfo));
  };

  // Search params
  const [roomInfo, setRoomInfo] = useState(getInitRoomInfo());
  const [calendarDateArrival, setCalendarDateArrival] = useState("");
  const [calendarDateLeave, setCalendarDateLeave] = useState("");
  let saveLocation = "";
  try {
    saveLocation = localStorage.getItem("location")
      ? JSON.parse(localStorage.getItem("location")) || ""
      : "";
  } catch (e) { }
  const [location, _setLocation] = useState(saveLocation);

  const [isSearched, setIsSearched] = useState(false);

  // Responses
  const [sessionId, setSessionId] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [rangeSearchResults, setRangeSearchResults] = useState(null);
  const [ldsDates, setLdsDates] = useState(null);
  const [passengerInfo, setPassengerInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);
  const [datesError, setDatesError] = useState(false);

  React.useEffect(() => {
    if (searchTab === PACKAGES && location !== "") {
      fetchLdsDates(location);
    }
  }, [searchTab, location]);

  React.useEffect(() => {
    if (roomInfo.rooms.length > 0) {
      sessionStorage.setItem("roomData", JSON.stringify(roomInfo.rooms));
    }
  }, [roomInfo]);

  React.useEffect(() => {
    if (error) {
      setSearchResults(null);
      setError(false);
    }
  }, [calendarDateLeave, calendarDateArrival, roomInfo]);

  function setLocation(newLocation) {
    localStorage.setItem("location", JSON.stringify(newLocation));
    setError(false);
    _setLocation(newLocation);
    setPassengerInfo(null);
    if (searchTab === PACKAGES) {
      fetchLdsDates(newLocation);
    }
  }

  function reRunSearch() {
    setTimeLeft(TWENTY_MIN);
    setSearchResults(null);
    setRangeSearchResults(null);
    setLoading(false);
    setDatesError(false);
    setLoadingDates(false);
    setError(false);
    setLoading(false);
    submitSearch();
  }

  function setSearchTab(newSearchTab) {
    localStorage.setItem("searchTab", newSearchTab);
    setError(false);
    setLoading(false);
    setSearchResults(null);
    _setLocation("");
    setRoomInfo(getInitRoomInfo(true));
    _setSearchTab(newSearchTab);
  }

  function _initBeforeSearch() {
    setError(false);
    setLoading(true);
    setPassengerInfo();
  }

  function _initAfterSearch() {
    setLoading(false);
  }

  function submitSearch() {
    setIsSearched(true);
    _initBeforeSearch();
    switch (searchTab) {
      case PLANES:
        fetchSearchData();
        return;
      case HOTELS:
        setTimeLeft(TWENTY_MIN);
        fetchSearchData();
        return;
      case PACKAGES:
        setTimeLeft(TWENTY_MIN);
        searchLDSResults();
        // fetchRangeLds();
        return;
      default:
        fetchSearchData();
        return;
    }
    return;
  }

  function getSearchPhrase() {
    const city = location.city;
    const country = location.country;
    const lat = location.latitude;
    const lon = location.longitude;
    const isHotel = location.hotelserched;

    const sortedRoomInfoJson = {
      rooms: roomInfo?.rooms.map((room) => {
        return {
          AdultsCount: room.adults,
          KidsAges: room.kidsAges,
        };
      }),
    };

    return {
      isHotel: isHotel,
      lat: lat,
      lon: lon,
      city: city,
      country: country,
      checkIn: calendarDateArrival,
      checkOut: calendarDateLeave,
      ...sortedRoomInfoJson,
      currency: "ILS",
    };
  }

  function fetchLdsDates(newLocation) {
    const { year, month } = getCurrentDate();

    async function _fetch_all_dates(monthGap = 1, numOfMonths = 3) {
      let futureMonths = [];
      for (let i = 0; i < numOfMonths; i++) {
        if (i === 0) {
          futureMonths.push(getNextMonth(month, year, monthGap));
        } else {
          const prevMonth = futureMonths[i - 1].month;
          const prevYear = futureMonths[i - 1].year;
          futureMonths.push(getNextMonth(prevMonth, prevYear));
        }
      }
      const responses = await Promise.all(
        futureMonths.map(async (futureMonth) => {
          const response = await getLdsDates({
            city: newLocation.AIRPORTCODE,
            date: `${futureMonth.month}/${futureMonth.year}`,
          });
          if (!isLdsResponseEmpty(response)) {
            return response.data.calendarLink;
          } else {
            return null;
          }
        })
      );
      const newLdsDates = responses.filter(Boolean).flat();
      setLdsDates((prevState) => [...(prevState || []), ...newLdsDates]);
    }
    setError(false);
    setLoadingDates(true);
    setDatesError(false);
    getLdsDates({ city: newLocation.AIRPORTCODE, date: `${month}/${year}` })
      .catch((err) => {
        setDatesError(
          err.exception || "אירעה שגיאה, אנא חפש יעד אחר או נסה שוב מאוחר יותר."
        );
      })
      .then((res) => {
        _fetch_all_dates();
        _fetch_all_dates(4, 8);
        if (isLdsResponseEmpty(res)) {
          setDatesError(
            "לא נמצאו תוצאות, אנא חפש יעד אחר או נסה שנית מאוחר יותר."
          );
        } else {
          setLdsDates(res.data.calendarLink);
        }
      })
      .finally(() => {
        setLoadingDates(false);
      });
  }

  async function fetchSearchData() {
    const searchPhrase = getSearchPhrase();
    searchHotels(searchPhrase).then((res) => {
      if (!res?.data || res.error) {
        setError("אירעה שגיאה");
        setSearchResults(null);
      } else {
        const { session, Result: result } = res.data;
        setSessionId(session);
        addCommissionToSearchResultGemonix(result);
        setSearchResults(result);
        _initAfterSearch();
      }
    });
  }

  function addCommissionToSearchResultGemonix(result) {
    if (result == undefined) return;
    for (const hotel of result) {
      const price = parseFloat(hotel.LowestPackagePrice);
      hotel.ourPrice = calcBaseCommission(price);
    }
  }
  const buildLdsPayload = (date_range = 0) => {
    // const { adults, kids } = getPeoplesSum(roomInfo);
    return {
      // num_infants=0, todo(sagiv): check if need
      dep_date: calendarDateArrival,
      ret_date: calendarDateLeave,
      city: location.AIRPORTCODE,
      date_range: date_range,
      room_compositions: roomInfo?.rooms,
    };
  };

  function searchLDSResults() {
    const data = buildLdsPayload();
    searchLds(data)
      .then((res) => res?.data)
      .then((res) => {
        if (res?.opStatus?.status === "NO_RESULTS") {
          setError("לא נמצאו תוצאות");
        } else {
          let dealsArr = isArray(res.dealGroup)
            ? res.dealGroup
            : [res.dealGroup];

          // // use for debug only
          // dealsArr = dealsArr.filter((item) => {
          //   return item.fullDeal.supplierDetails.supplierId != "KV";
          // });

          // // filter only rooms with infants
          // dealsArr = dealsArr.filter((item) => {
          //   const infantFare = item.fullDeal?.dealFare?.infantFare;
          //   return infantFare !== undefined && infantFare !== null;
          // });

          setSearchResults(dealsArr);
        }
      })
      // .catch((err) => setError("אירעה שגיאה אנא נסה שנית."))
      .finally(() => {
        _initAfterSearch();
      });
  }

  function _hasZeroAge(rooms) {
    for (const room of rooms) {
      if (room.kidsAges.includes(0)) {
        return true;
      }
    }
    return false;
  }

  function isSearchDisabled() {
    if (error || loading) {
      return true;
    }

    return !(
      location !== "" &&
      calendarDateArrival !== "" &&
      calendarDateLeave !== "" &&
      !_hasZeroAge(roomInfo.rooms)
    );
  }

  function getSearchDisableReasons() {
    if (error) {
      return ["אירעה שגיאה."];
    }
    if (loading) {
      return ["טוען..."];
    }
    const reasons = [];

    if (location === "") {
      reasons.push("יש לבחור יעד.");
    }
    if (!(calendarDateArrival !== "" && calendarDateLeave !== "")) {
      reasons.push("יש לבחור תאריכים.");
    }

    if (_hasZeroAge(roomInfo.rooms)) {
      reasons.push("ילד לא יכול להיות בגיל 0.");

      return reasons;
    }

    return reasons.length > 0 ? reasons : null;
  }

  const calculateNetPrice = (price, commissionPercent) => {
    if (!price) return 0;
    const numPrice = Number(price);

    return round2(
      numPrice ? numPrice - (numPrice * commissionPercent) / 100 : 0
    );
  };

  const calculatePriceWithCommissions = (
    ldsPrice,
    currencyRate,
    ldsCommission = 0
  ) => {
    const netPrice = calculateNetPrice(ldsPrice, ldsCommission);
    const priceILS = round2(netPrice * currencyRate);
    const ourPrice = calcBaseCommission(priceILS);
    ourPrice.origCurrency = calcBaseCommission(netPrice);
    ourPrice.currencyRate = round2(currencyRate);
    ourPrice.netPrice = round2(netPrice);
    ourPrice.ldsPrice = round2(ldsPrice);
    ourPrice.ldsCommission = round2(ldsCommission);
    return ourPrice;
  };

  function calculateLDSDealFare({ dealFare, fareDetails }) {
    const { adultFare, totalFare, infantFare, childFareExt } = dealFare;
    const { commission, currency } = fareDetails;
    const { commissionType, commissionValue } = commission;
    const childFare = isArray(childFareExt) ? childFareExt[0] : childFareExt;

    if (currency === "EUR") console.log(currency);

    const dealCurrencyRate =
      currency === "USD"
        ? currencyRate.USD
        : currency === "EUR"
          ? currencyRate.EUR
          : 0;

    let commissionPercent = 0;

    if (commissionType === "NET" || commissionType === "UNAVAILABLE") {
      commissionPercent = 0;
    } else if (commissionType === "PERCENT") {
      commissionPercent = Number(commissionValue);
    } else {
      console.log(commissionType);
    }

    const ourAdultFare = calculatePriceWithCommissions(
      adultFare,
      dealCurrencyRate,
      commissionPercent
    );
    const ourInfantFare = calculatePriceWithCommissions(
      infantFare,
      dealCurrencyRate,
      commissionPercent
    );
    const ourChildFare = calculatePriceWithCommissions(
      childFare?.fare,
      dealCurrencyRate,
      commissionPercent
    );
    const ourTotalFare = calculatePriceWithCommissions(
      totalFare,
      dealCurrencyRate,
      commissionPercent
    );

    return {
      ourAdultFare,
      ourInfantFare,
      ourChildFare,
      ourTotalFare,
    };
  }

  function calculateLDSDealFareForRoomsComposition({ dealFare, fareDetails }) {
    const roomsPriceTable = roomInfo.rooms.map((roomComposition) => {
      const { childFare = 0, adultFare, infantFare = 0 } = dealFare;

      let totalFare =
        Number(adultFare) * roomComposition.adults +
        Number(childFare) * roomComposition.kids +
        Number(infantFare) * roomComposition.infants;

      const ourFareDetails = calculateLDSDealFare({
        dealFare: {
          adultFare,
          totalFare,
          childFareExt: childFare,
          infantFare,
        },
        fareDetails,
      });

      return { dealFare, ourFareDetails };
    });
    const ourSumTotalFare = {};
    for (const roomPrice of roomsPriceTable) {
      const ourTotalFare = roomPrice.ourFareDetails.ourTotalFare;
      for (let key in ourTotalFare) {
        if (!isNumber(ourTotalFare[key])) {
          continue;
        }
        if (ourSumTotalFare[key] === undefined) {
          ourSumTotalFare[key] = 0;
        }
        ourSumTotalFare[key] += ourTotalFare[key];
      }
    }
    const ourPrice = {
      ...roomsPriceTable[0].ourFareDetails,
      ourTotalFare: ourSumTotalFare,
    };
    return ourPrice;
  }

  return (
    <SearchContext.Provider
      value={{
        setCalendarDateArrival,
        setCalendarDateLeave,
        calendarDateArrival,
        calendarDateLeave,
        setSearchTab: setSearchTab,
        setLocation: setLocation,
        setRoomInfo,
        searchTab,
        roomInfo,
        location,
        getSearchPhrase,
        submitSearch,
        searchResults,
        sessionId,
        loading,
        error,
        loadingDates,
        datesError,
        isSearchDisabled,
        getSearchDisableReasons,
        ldsDates,
        isSearched,
        rangeSearchResults,
        passengerInfo,
        setPassengerInfo,
        timeLeft: 202,
        reRunSearch,
        getInitRoomInfo,
        calculateLDSDealFare,
        calculateLDSDealFareForRoomsComposition,
        calculatePriceWithCommissions,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};

export default SearchContext;

export function parsePeopleCount(roomInfo) {
  const rooms = roomInfo?.rooms || [];
  const totalRooms = rooms.length;

  let totalPeople = 0;

  rooms.forEach((personData) => {
    totalPeople += personData.adults || 0;
    totalPeople += personData.kids || 0;
    totalPeople += personData.infants || 0;
  });

  return { totalRooms, totalPeople };
}

// export function getPeoplesSum(roomInfo) {
//   const rooms = roomInfo?.rooms || [];

//   let adults = 0;
//   let kids = 0;

//   rooms.forEach((personData) => {
//     adults += personData.adults || 0;
//     kids += personData.kids || 0;
//   });

//   return { adults, kids };
// }
