import React, { createContext, useContext, useEffect, useState } from "react";
import { getCurrencyRate } from "../services/currencyService";
import { loadInterestTable, loadGlobalConstant } from "../services/fhpService";
import { getUserDetails, isAuthenticated } from "../services/authService";

const USD_RATE_INFO = {
  currency: "ILS",
  targetCurrency: "USD",
};

const EUR_RATE_INFO = {
  currency: "ILS",
  targetCurrency: "EUR",
};

const gcKey = {
  CREDIT_CARD_FEE: "CREDIT_CARD_FEE",
  HR_COMMISSION: "HR_COMMISSION",
  AGENT_COMMISSION: "AGENT_COMMISSION",
  BAGGAGE_FEE_FLAT: "BAGGAGE_FEE_FLAT",
};
const AppContext = createContext();
export function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}

export const round2 = (num) => Math.round(num); //Math.round(num * 100) / 100;

export const AppProvider = ({ children }) => {
  const [state, setState] = useState({});
  const [globalConstant, setGlobalConstant] = useState({});
  const getHrCommissionPercent = () =>
    parseFloat(globalConstant[gcKey.HR_COMMISSION]);
  const getAgentCommissionPercent = () =>
    parseFloat(globalConstant[gcKey.AGENT_COMMISSION]);

  const getBaggageFeeFlat = () =>
    parseFloat(globalConstant[gcKey.BAGGAGE_FEE_FLAT]);

  const [interestTable, setInterestTable] = useState(null);
  const getCreditCardFeePercent = () =>
    parseFloat(globalConstant[gcKey.CREDIT_CARD_FEE]);

  const [isGlobalConstantLoaded, setIsGlobalConstantLoaded] = useState(false);

  const updateGlobalConstant = (newData) => {
    setGlobalConstant((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const loadGlobalConstantFromAPI = async () => {
    try {
      const extractVal = (v) =>
        res.GlobalConstant.filter((x) => x[0] === v)[0][1];
      const res = await loadGlobalConstant();
      const gc = {
        [gcKey.CREDIT_CARD_FEE]: extractVal(gcKey.CREDIT_CARD_FEE),
        [gcKey.HR_COMMISSION]: extractVal(gcKey.HR_COMMISSION),
        [gcKey.AGENT_COMMISSION]: extractVal(gcKey.AGENT_COMMISSION),
        [gcKey.BAGGAGE_FEE_FLAT]: extractVal(gcKey.BAGGAGE_FEE_FLAT),
      };
      setGlobalConstant(gc);
      setIsGlobalConstantLoaded(true);
    } catch (error) {
      console.error("Failed to fetch currency Order:", error);
      throw error;
    }
  };

  const loadInterestTableFromAPI = async () => {
    try {
      const interestTableData = await loadInterestTable();
      setInterestTable(interestTableData);
    } catch (error) {
      console.error("Failed to fetch currency Order:", error);
      throw error;
    }
  };

  const [USDrate, setUSDRate] = useState(null);
  const [EURrate, setEURRate] = useState(null);

  const loadUSDRate = async () => {
    try {
      getCurrencyRate(USD_RATE_INFO).then((res) => {
        setUSDRate(res?.rate);
      });
    } catch (error) {
      console.error("Failed to fetch currency rate:", error);
      throw error;
    }
  };

  const loadEURRate = async () => {
    try {
      getCurrencyRate(EUR_RATE_INFO).then((res) => {
        setEURRate(res?.rate);
      });
    } catch (error) {
      console.error("Failed to fetch currency rate:", error);
      throw error;
    }
  };

  const calculateCreditCardFee = (basePrice) => {
    const fee = (parseFloat(basePrice) * getCreditCardFeePercent()) / 100;
    return round2(fee);
  };
  useEffect(() => {
    loadUSDRate();
    loadEURRate();
    // loadCreditCardFeeFromAPI();
    loadInterestTableFromAPI();
    loadGlobalConstantFromAPI();
  }, []);

  const calcBaseCommission = (price) => {
    const initialPrice = round2(parseFloat(price));
    const ourPrice = { initialPrice: initialPrice };
    ourPrice.handleFeeData = {
      type: "",
      value: 0,
    };
    ourPrice.handlingFee = 0;
    ourPrice.hrCommission = round2(
      (getHrCommissionPercent() / 100) * initialPrice
    );
    ourPrice.agentCommission = round2(
      (getAgentCommissionPercent() / 100) * initialPrice
    );
    ourPrice.priceWithCommission = round2(
      initialPrice + ourPrice.hrCommission + ourPrice.agentCommission
    );
    ourPrice.creditCardFee = round2(
      calculateCreditCardFee(ourPrice.priceWithCommission)
    );
    ourPrice.priceWithCommissionAndCreditCardFee = round2(
      ourPrice.priceWithCommission + ourPrice.creditCardFee
    );
    return ourPrice;
  };

  ///// login
  const appLogin = (user) => {
    setState((prevState) => ({
      ...prevState,
      user,
    }));
  };
  const appLogout = () => {
    setState((prevState) => ({
      ...prevState,
      user: null,
    }));
  };

  const isAppLoggedIn = () => {
    if (state.user != null) return true;
    const user = getUserDetails();
    if (user) {
      appLogin(user);
      return true;
    }
    return false;
  };

  useEffect(() => {
    isAuthenticated().then((result) => {
      if (!result) {
        appLogout();
      }
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        currencyRate: {
          USD: USDrate,
          EUR: EURrate,
        },
        USDrate,
        loadUSDRate,
        EURrate,
        loadEURRate,
        getCreditCardFeePercent,
        getAgentCommissionPercent,
        getHrCommissionPercent,
        updateGlobalConstant,
        interestTable,
        setInterestTable,
        gcKey,
        globalConstant,
        calcBaseCommission,
        isGlobalConstantLoaded,
        calculateCreditCardFee,
        getBaggageFeeFlat,
        appLogin,
        appLogout,
        isAppLoggedIn,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};

export default AppContext;
