import React, { useEffect, useRef, useState } from "react";

// Langauge
import { useTranslation } from "react-i18next";

// Global Veriable
import { GoogleMapSecretKey } from "../../../../../assets/GlobalVariables";

// -- Side Functions

// Create a variable to keep track of script loading
let scriptLoaded = false;

const language = localStorage.getItem("i18nextLng")
  ? localStorage.getItem("i18nextLng")
  : "en";

// Prevent from setting script more then once
const loadGoogleMapsScript = (callback) => {
  if (!scriptLoaded) {
    scriptLoaded = true;
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GoogleMapSecretKey}&libraries=places&language=${language}`;
    script.async = true;
    script.id = "autoCompleteGoogle";
    script.onload = callback;
    document.head.appendChild(script);
  } else {
    callback();
  }
};

// Main Functions
const AutoCompleteInput = ({
  saveLocationEdit,
  location,
  saveLocationSelection,
  closeInput,
}) => {
  // Translator
  const { t } = useTranslation();

  // Refs
  const inputRef = useRef();

  // UseEffects
  useEffect(() => {
    loadGoogleMapsScript(() => {
      if (window.google && window.google.maps) {
        const autoComplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            fields: [
              "address_components",
              "types",
              "formatted_address",
              "geometry",
              "name",
            ],
          }
        );

        autoComplete.addListener("place_changed", () => {
          const place = autoComplete.getPlace();

          // Save the location
          selectPlace(place);

          // Close the input and set is functionality to false - means that we ended the editing
          closeInput(false);
          saveLocationEdit(false);

          // Clear the input field to allow new searches
          if (inputRef.current) {
            inputRef.current.value = "";
          }
        });
      }
    });
  }, []);

  // On load of the input focus on it
  useEffect(() => {
    const autoCompleteObject = document.getElementById("autocomplete-input");

    if (autoCompleteObject) {
      autoCompleteObject.focus();
    }
  }, []);

  // --- Functions

  const selectPlace = (place) => {
    // After selection close the input
    saveLocationEdit(false);

    if (!place.geometry || !place.geometry.location) {
      saveLocationSelection("");
      return;
    }

    // Access the latitude and longitude correctly and save them
    place.latitude = place.geometry.location.lat();
    place.longitude = place.geometry.location.lng();

    // Check if it's a hotel or a city
    const types = place.types;

    // -- Show the name or the address
    if (types.includes("lodging")) {
      // It's a hotel, add a 'fullname' property with the hotel name
      // translateHotelNameToEn(place.name)
      place.fullname = place.name;
      place.hotelserched = place.name;
    } else if (types.includes("locality")) {
      // It's a city, add a 'fullname' property with the formatted address
      place.fullname = place.formatted_address;
      place.hotelserched = false;
    } else {
      // Handle other types of places here
      place.fullname = place.name;
      place.hotelserched = false;
    }

    // Get the city and country name in english and save them for later access
    translatePlaceToEn(place);
  };

  const translatePlaceToEn = async (place) => {
    const lat = place.latitude;
    const lng = place.longitude;

    // Make a reverse geocoding request to Google's Geocoding API
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GoogleMapSecretKey}&language=en`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          // Extract the English name of the place or city
          const result = data.results[0];
          if (result) {
            for (const component of result.address_components) {
              if (component.types.includes("administrative_area_level_1")) {
                place.city = component.long_name;
              }

              if (component.types.includes("locality")) {
                place.city = component.long_name;
              } else if (component.types.includes("country")) {
                place.country = component.long_name;
              }
            }

            // if(!place.city || !place.country) {
            //     let city, country = getCityCountryForce(lat, lng)

            //     place.city = city;
            //     place.country = country;
            // }
            // After selection close the input
            saveLocationEdit(false);

            saveLocationSelection(place);
          } else {
            console.error("No results found.");
            saveLocationSelection("");
          }
        } else {
          console.error("Geocoding request failed.");
          saveLocationSelection("");
        }
      })
      .catch((error) => {
        alert(
          "There is a problem with that location. Please select another one."
        );
        saveLocationSelection("");
        console.error("Error:", error);
      });
  };

  // Clear search when erasing everything
  const clearSearch = (e) => {
    if (e.target.value.length === 0) {
      saveLocationSelection("");
    }
  };

  // Check if the input is being edited
  const handleFocus = () => {
    saveLocationEdit(true);
  };

  const handleBlur = () => {
    closeInput(false);
    saveLocationEdit(false);
  };

  const handleKeyPress = (event) => {
    // Check if the Enter key was pressed
    if (event.key === "Enter") {
      // Prevent the default form submission behavior
      event.preventDefault();

      // If edited close input until next hover
      closeInput(false);
      saveLocationEdit(false);
    }
  };

  return (
    <div className="autoComplete-input-box-wrapper">
      <input
        placeholder={t("hotelsearchbar.selectdestinationdesc")}
        className="autoComplete-input-box"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={clearSearch}
        defaultValue={location.fullname}
        id="autocomplete-input"
        ref={inputRef}
        onKeyDown={handleKeyPress}
        required
      />
    </div>
  );
};

export default AutoCompleteInput;
