import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./swal.scss";

const MySwal = withReactContent(Swal);
const swalOptions = {
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "אישור",
  customClass: {
    popup: "custom-swal-popup", // Use this class to apply specific styles
  },
};

const Toast = MySwal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export const showSuccessToast = (message) =>
  Toast.fire({ icon: "success", title: message });
export const showErrorToast = (message) =>
  Toast.fire({ icon: "error", title: message });

export const confirmDelete = () => {
  return MySwal.fire({
    ...swalOptions,
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
  });
};
export const successMessage = ({ title, message, confirmButtonText }) => {
  return MySwal.fire({
    ...swalOptions,
    title: title || "Success!",
    text: message,
    icon: "success",
    confirmButtonText: confirmButtonText || "OK",
  });
};
export const errorMessage = (message) => {
  return MySwal.fire({
    ...swalOptions,
    title: "שגיאה!",
    text: message,
    icon: "error",
    confirmButtonText: "אישור",
  });
};
function getScrollbarWidth() {
  // Create a temporary block element
  const block = document.createElement("div");
  block.style.visibility = "hidden";
  block.style.overflow = "scroll"; // forcing scrollbar to appear
  document.body.appendChild(block);

  // Create a child for that block that will act as the content
  const child = document.createElement("div");
  block.appendChild(child);

  // Calculate the difference between the block and child widths
  const scrollbarWidth = block.offsetWidth - child.offsetWidth;

  // Remove the block from the document
  document.body.removeChild(block);

  return scrollbarWidth;
}

export const bigErrorMessage = (title) => {
  return MySwal.fire({
    ...swalOptions,
    title: title,
    icon: "error",
    didOpen: () => {
      const scrollbarWidth = getScrollbarWidth();
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    },
    willClose: () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";
    },
  });
};
