import React from "react";

// Loading
import ReactLoading from "react-loading";

// CSS
import "./customloading.css";

const CustomLoading = ({ classLoading, labelLoading }) => {
  return (
    <div className={`customLoading-component ${classLoading}`}>
      <ReactLoading
        type={"spin"}
        color={"#1063ac"}
        width={"1rem"}
        height={"fit-content"}
      />
      <label>{labelLoading}</label>
    </div>
  );
};

export default CustomLoading;
