import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// React Icons
import { FaUser } from "react-icons/fa";
import { FaChildren } from "react-icons/fa6";

const PassangerList = ({ passengers, editInfo }) => {
  // Translator
  const { t } = useTranslation();

  // States
  const [pass, setPass] = useState([]);

  // UseEffects
  useEffect(() => {
    // If its info comes form back alter it if not alter it diffrently
    if (editInfo) {
      const transformedData = passengers.map((item) => {
        const { PersonDetails, Id } = item;
        const { Age } = PersonDetails;
        const { GivenName, Surname } = PersonDetails.Name;

        return {
          firstname: GivenName,
          lastname: Surname,
          isKid: Age ? true : false,
        };
      });

      // Sort the array so that kids come last
      const sortedArray = transformedData.sort((a, b) =>
        a.isKid && !b.isKid ? 1 : -1
      );

      setPass(sortedArray);
    } else {
      const transformedArray = passengers.flatMap((room, roomIndex) => {
        return room.map((passenger) => {
          return {
            firstname: passenger.firstname,
            lastname: passenger.lastname,
            isKid: passenger.isKid,
          };
        });
      });

      // Sort the array so that kids come last
      const sortedArray = transformedArray.sort((a, b) =>
        a.isKid && !b.isKid ? 1 : -1
      );

      setPass(sortedArray);
    }
  }, [passengers, editInfo]);

  return (
    <>
      <div className="passangerList-wrapper ci-clientInfo-wrapper">
        <label className="ci-clientInfo-main-title">
          {t("ordersummery.filluserdetails")}
        </label>

        <div className="passangerList-list-wrapper">
          {pass.map((passenger, index) => (
            <div className="passangerList-list-item" key={`passenger-${index}`}>
              <div className="passangerList-list-item-title-wrapper">
                {passenger.isKid ? (
                  <FaChildren className="passangerList-list-item-icon" />
                ) : (
                  <FaUser className="passangerList-list-item-icon" />
                )}

                <label className="passangerList-list-item-title">
                  {passenger.isKid
                    ? t("ordersummery.kid") + ":"
                    : t("ordersummery.adult") + ":"}
                </label>
              </div>

              <label className="passangerList-list-item-value">
                {passenger.firstname + " " + passenger.lastname}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PassangerList;
