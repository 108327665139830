import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// Bootstrap
import { Container, Row, Col } from "react-bootstrap";

// Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// CSS
import "./imagegallery.css";

// Images
import NoHotels from "../../../../../assets/images/icons/hotel.png";

// Services
import { getHotelsProfileImages } from "../../../../../services/fhpService";

// Skeleton
import GallarySkeleton from "./skeleton";

const ImageGallery = ({ hotelid }) => {
  // States
  const [images, setImages] = useState();

  // Translator
  const { t } = useTranslation();

  // UseEffects

  // Get all images
  useEffect(() => {
    const getProfileImageFromApi = async () => {
      const data = {
        hotelID: parseInt(hotelid), // Extract hotelId from data
      };

      const res = await getHotelsProfileImages(data);

      // Save the images
      if (res && res.data && res.data.GenericImages) {
        setImages(res.data.GenericImages.images);
      } else {
        setImages([]);
      }
    };

    getProfileImageFromApi();
  }, []);

  // --- Functions

  const zoomGallary = () => {};

  return (
    <div className="image-gallery">
      {/* --- Cover Cases */}

      {/* Loading */}
      {!images && (
        <div>
          <GallarySkeleton />
        </div>
      )}

      {/* No Images Received */}
      {images && images.length === 0 && (
        <div className="roomPlacer-nobedimage-wrapper">
          <img
            className="gallary-img-no-images"
            src={NoHotels}
            alt="No hotel images"
          ></img>
          <label className="roomPlacer-nobedimage-title">
            {t("hotelprofile.noimagesforhotel")}
          </label>
        </div>
      )}

      {/* Less Then 8 Images */}
      {images && images.length === 1 && (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={images.length > 1}
        >
          {images.map((imageUrl, index) => (
            <div key={index} className="gallery-image">
              <img src={imageUrl} alt={`Image ${index}`} />
            </div>
          ))}
        </Carousel>
      )}

      {/* More Then Images */}
      {images && images.length >= 8 && (
        <Container>
          <Row>
            <Col className="gallery-8-wrapper-1">
              <div>
                <img
                  src={images[0]}
                  className="gallery-img-1"
                  alt={`Image 0`}
                />
              </div>

              <Row>
                <div>
                  <img
                    src={images[1]}
                    className="gallery-img-2"
                    alt={`Image 1`}
                  />
                </div>

                <Col>
                  <div className="gallery-8-wrapper-1-item">
                    <img
                      src={images[2]}
                      className="gallery-img-3"
                      alt={`Image 2`}
                    />
                  </div>

                  <div className="gallery-8-wrapper-1-item">
                    <img
                      src={images[3]}
                      className="gallery-img-4"
                      alt={`Image 3`}
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col className="gallery-8-wrapper-2">
              <div className="gallery-8-wrapper-2-item">
                <img
                  src={images[4]}
                  className="gallery-img-5"
                  alt={`Image 4`}
                />
              </div>

              <div className="gallery-8-wrapper-2-item">
                <img
                  src={images[5]}
                  className="gallery-img-6"
                  alt={`Image 5`}
                />
              </div>

              <div className="gallery-8-wrapper-2-item">
                <img
                  src={images[6]}
                  className="gallery-img-7"
                  alt={`Image 6`}
                />
              </div>
            </Col>

            {/* Slider */}
            <div className="gallery-8-wrapper-3">
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={images.length > 1}
              >
                {images.slice(7).map((imageUrl, index) => (
                  <div key={index + 7} className="gallery-image">
                    <img
                      className="gallery-img-8"
                      src={imageUrl}
                      alt={`Image ${index + 7}`}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default ImageGallery;
