import React, { memo, useMemo, useState } from "react";
import "./input.css";
import { useSearch } from "../../../../../../../../../contexts/SearchContext";

const InputPassenger = memo(({ id, title, type, passengerNum, roomNumber }) => {
  const { passengerInfo, setPassengerInfo } = useSearch();

  // const [currentPassengerValue, setCurrentPassengerValue] = useState(
  //   passengerInfo[roomNumber][passengerNum - 1][id]
  // );
  const currentPassengerValue = useMemo(() => {
    return passengerInfo[roomNumber][passengerNum - 1][id];
  }, [passengerInfo, roomNumber, passengerNum, id]);

  const updatedInput = (e) => {
    const newValue = e.target.value;
    if (newValue === currentPassengerValue) {
      return;
    }
    e.preventDefault();

    // Get current room
    let passInfo = passengerInfo[roomNumber];

    const passNumInArray = passengerNum - 1;

    // Add/update the passenger with the right data
    passInfo[passNumInArray][id] = e.target.value;

    // Save the changes
    setPassengerInfo(passengerInfo);
  };

  return (
    <div className="inputPass-component">
      <label className="inputPass-title">{title}</label>

      <div className="inputPass-input-wrapper">
        <input
          id={`${id}-${passengerNum}-${roomNumber}`}
          type={type}
          value={currentPassengerValue}
          onChange={updatedInput}
          className="inputPass-input"
          placeholder={title}
          required
        />
      </div>
    </div>
  );
});

export default InputPassenger;
