import React, { useState } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./flightsinfo.css";

const FlightsInfo = () => {
  // Translator
  const { t } = useTranslation();

  return (
    <>
      <div className="flightsInfo-wrapper">flight info</div>
    </>
  );
};

export default FlightsInfo;
