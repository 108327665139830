import React from "react";

// Facebook
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// CSS
import "./facebooklogin.css";

// Icons
import FacebookLogo from "../../assets/images/icons/facebook_logo.png";

const FacebookLoginButton = () => {
  const appId = "2219057211637294";

  const responseFacebook = (response) => {
    // Handle the response from Facebook login
  };

  return (
    <FacebookLogin
      appId={appId}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      render={(renderProps) => (
        <button className="facebook-login-button" onClick={renderProps.onClick}>
          <img
            src={FacebookLogo}
            alt="Facebook Logo"
            className="facebook-logo"
          />
        </button>
      )}
    />
  );
};

export default FacebookLoginButton;

// import React, { useEffect } from 'react';

// const FacebookLogin = () => {
//   useEffect(() => {
//     // Load the Facebook SDK asynchronously
//     (function (d, s, id) {
//       const script = d.createElement(s);
//       script.id = id;
//       script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0&appId=2219057211637294&autoLogAppEvents=1';
//       script.crossOrigin = 'anonymous';
//       d.getElementsByTagName('head')[0].appendChild(script);
//     })(document, 'script', 'facebook-jssdk');
//   }, []);

//   const checkLoginState = () => {
//     window.FB.getLoginStatus(function (response) {
//       if (response.status === 'connected') {
//         // User is logged in with Facebook
//         const accessToken = response.authResponse.accessToken;
//         console.log('Access Token:', accessToken);
//         // You can send the accessToken to your server for further authentication or API calls
//       } else {
//         // User is not logged in with Facebook
//         console.log('User is not logged in.');
//       }
//     });
//   };

//   return (
//     <div>
//       <div id="fb-root"></div>
//       {/* <div className="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="true" data-onlogin="checkLoginState();"></div> */}
//       <div
//         className="fb-like"
//         data-share="true"
//         data-width="450"
//         data-show-faces="true">
//     </div>
//     </div>
//   );
// };

// export default FacebookLogin;
