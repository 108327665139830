import React from "react";
import FlightFooterRow from "./FlightFooterRow";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";

import "./FlightsFooter.scss";

const FlightsFooter = ({ flights }) => {
  const { t } = useTranslation();
  if (!flights) {
    return <div>Error getting flights</div>;
  }
  return (
    <>
      <FlightFooterRow
        flight={flights[0]?.leg || {}}
        title={t("packages.firstWayFlight")}
      />
      <Divider orientation="horizontal" flexItem variant="middle" />

      <FlightFooterRow
        flight={flights[1]?.leg || {}}
        title={t("packages.returnFlight")}
      />
    </>
  );
};

export default FlightsFooter;
