import React, { useEffect, useState, useRef } from "react";
import { useSearch } from "../../contexts/SearchContext";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "../../services/swalService";
import { RiTimerLine } from "react-icons/ri";
import "./Timer.scss";
import { useTimer } from "../../contexts/TimerContext";

const secondsToTime = (totalSeconds) => {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

const Timer = () => {
  const { reRunSearch } = useSearch();
  const { timeLeft } = useTimer();
  const navigate = useNavigate();

  if (timeLeft === 0) {
    errorMessage("הזמן המוקצב לחיפוש אזל, הינך מועבר לעמוד חיפוש").finally(
      () => {
        reRunSearch();
        navigate("/p/dashboard/searchoffer");
      }
    );
    return null;
  }

  return (
    <div className="timer">
      <RiTimerLine style={{ margin: "0 10px" }} />
      לחיפוש זה נותרו
      <div className="time">{` ${secondsToTime(timeLeft)} `}</div>
      דקות
    </div>
  );
};

export default Timer;
