import React from "react";
import "./Stages.scss";
import classNames from "classnames";

const Stages = ({
  stages,
  currentIndex,
  setCurrentStageIndex,
  missingFields,
  titleStyle = {},
  lineStyle = {},
}) => {
  function renderStage(title, neededFields, index, disabled) {
    const active = index === currentIndex;
    const hasMissingFields =
      neededFields?.some((field) => missingFields?.includes(field)) ?? false;
    return (
      <div
        key={`registration-stage-${index}`}
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {index !== 0 && <div className={"stage-line"} style={lineStyle} />}
        <div
          className={classNames("stage-container", {
            "stage-container-unactive": !active,
            "missing-fields": hasMissingFields,
            disabled,
          })}
          onClick={(e) => (disabled ? null : setCurrentStageIndex(index))}
        >
          <div
            className={classNames("stage-icon tw-hidden lg:tw-block", {
              "stage-icon-active": active,
            })}
          />
          <div
            className={classNames("stage-title  lg:tw-text-[20px]", {
              "stage-title-active": active,
            })}
          >
            {title}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"stages-wrapper lg:tw-px-5"}>
      {stages.map(({ title, neededFields, disabled }, index) =>
        renderStage(title, neededFields, index, disabled)
      )}
    </div>
  );
};

export default Stages;
