import React from "react";
import { useSearch } from "../../../../contexts/SearchContext";
import DestinationInput from "../SubComponents/DestinationInput/DestinationInput";
import DatesInput from "../SubComponents/DatesInput/DatesInput";
import PeopleCounterInput from "../SubComponents/PeopleCounterInput/PeopleCounterInput";
import "./flightsearchbar.css";

const FlightSearchBar = () => {
  const {
    calendarDateArrival,
    setCalendarDateArrival,
    calendarDateLeave,
    setCalendarDateLeave,
    roomInfo,
    setRoomInfo,
    location,
    setLocation,
  } = useSearch();

  return (
    <>
      <DestinationInput location={location} setLocation={setLocation} />

      <DatesInput
        calendarDateArrival={calendarDateArrival}
        calendarDateLeave={calendarDateLeave}
        setCalendarDateArrival={setCalendarDateArrival}
        setCalendarDateLeave={setCalendarDateLeave}
      />

      <PeopleCounterInput roomInfo={roomInfo} setRoomInfo={setRoomInfo} />
    </>
  );
};

export default FlightSearchBar;
