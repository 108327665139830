import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// Components
import LanguageSelector from "../../LanguageSelector/languageselector";
import CurrencySelector from "../../CurrencySelector/currencyselector";
import RouteLocationDisplayer from "./subComponents/routeLocationDisplayer";

// CSS
import "./uppernavbarclient.css";

// Images
import ProfileIcon from "../../../assets/images/navbar/profile.png";
import Logo from "../../../assets/images/hotelsriver-logo.jpg";

// Services
import { getUserDetails } from "../../../services/authService";
import { ROUTES } from "../../../Routes/Routes";
import Tooltip from "@mui/material/Tooltip";
import { getUserInfo } from "../../../services/localStorage";

const UpperNavBarClient = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companylogobase65 } = getUserInfo() || {};

  // States
  const [isOpen, setIsOpen] = useState(false);

  // Routes with bottom navbar - needs to be updated with every new route
  const pathsWithBottomNav = ["hotelprofile"];

  // Add css to spcific routes
  useEffect(() => {
    const currentPath = window.location.pathname.split("/")[1];

    for (let path of pathsWithBottomNav) {
      if (path === currentPath) {
        // Add css class
        document
          .getElementById("upper-nav-bar")
          .classList.add("upper-nav-bar-with-bottom-nav");
        document
          .getElementById("upper-nav-bar-client")
          .classList.add("upper-nav-bar-client-with-bottom-nav");
      }
    }
  }, []);

  return (
    <div id="upper-nav-bar" className="upper-nav-bar">
      <div className="upper-nav-bar-client-wrapper">
        <div id="upper-nav-bar-client" className="upper-nav-bar-client">
          <button
            className="dropdown-toggle"
            onClick={() => navigate(`/${ROUTES.LOGIN}`)}
          >
            <img src={ProfileIcon} alt="acount" className="currency-icon" />
            <span> {t("navbar.account")} </span>
            <span
              className={`arrow-icon arrow-icon-currency ${
                isOpen ? "arrow-icon-up" : "arrow-icon-down"
              }`}
            ></span>
          </button>

          {/*<div>*/}
          {/*  <CurrencySelector />*/}
          {/*</div>*/}

          <div>
            <LanguageSelector />
          </div>

          <div className="navbar-item-wrapper-client">
            <Link className="navbar-item-client" to="/hotels">
              {t("navbar.hotels")}{" "}
            </Link>
          </div>

          <div className="navbar-item-wrapper-client tw-hidden lg:tw-block">
            <Link className="navbar-item-client" to="/about">
              {t("navbar.about")}{" "}
            </Link>
          </div>

          <div className="navbar-item-wrapper-client tw-hidden lg:tw-block">
            <Link className="navbar-item-client" to="/contactus">
              {t("navbar.contactus")}{" "}
            </Link>
          </div>
        </div>
        <Tooltip title={"לחץ למעבר לעמוד הראשי"}>
          <img
            className="logo-img"
            src={companylogobase65 || Logo}
            alt="Hotels River Logo"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`/p/dashboard/home`)}
          />
        </Tooltip>
      </div>
      <RouteLocationDisplayer />
    </div>
  );
};

export default UpperNavBarClient;
